import { toast } from 'react-toastify';
import { assign, createMachine } from 'xstate';

import { ApiCompanySubscriptionBillingFrequency } from '../../../api/company-subscription';
import {
  ApiSubscriptionPlanPeriodUnit,
  ApiSubscriptionPlanTierType,
  subscriptionPlanPricingApi,
  SubscriptionPlanTier,
} from '../../../api/subscription-plan-pricing';
import logger from '../../../utils/logger';
import { assertEventType } from '../../../utils/xstate-helpers';

export interface ExistingSubscriptionInformation {
  planId: string;
  tierId: string;
  tierName: string;
  seats: number;
  billingFrequency: ApiCompanySubscriptionBillingFrequency;
}

interface PricingPageMachineContext {
  existingSubscription: ExistingSubscriptionInformation;

  planTiers: SubscriptionPlanTier[];

  selectedCurrency: string;

  billingPeriod: ApiSubscriptionPlanPeriodUnit;
  customTierSeats: string;
  discountCode: string;

  userFacingErrorMessage?: string;
}

type PricingPageMachineEvent =
  | {
      type: 'SELECT_TIER';
      data: {
        tier: SubscriptionPlanTier;
      };
    }
  | {
      type: 'TOGGLE_BILLING_PERIOD';
    }
  | {
      type: 'CHANGE_CUSTOM_SEATS';
      data: {
        seats: string;
      };
    }
  | {
      type: 'REPORT_CUSTOM_SEATS_FETCH_SUCCESS';
      data: {
        planTier: SubscriptionPlanTier;
      };
    }
  | {
      type: 'REPORT_CUSTOM_SEATS_FETCH_FAILED';
    }
  | {
      type: 'UPDATE_DISCOUNT_CODE';
      data: {
        discountCode: string;
      };
    }
  | {
      type: 'APPLY_DISCOUNT_CODE';
    }
  | {
      type: 'REPORT_DISCOUNT_CODE_VALID';
    }
  | {
      type: 'REPORT_DISCOUNT_CODE_INVALID';
    }
  | {
      type: 'REPORT_PLAN_TIERS_FETCH_SUCCESS';
      data: {
        planTiers: SubscriptionPlanTier[];
      };
    }
  | {
      type: 'REPORT_PLAN_TIERS_FETCH_FAILED';
    }
  | {
      type: 'RETRY_PLAN_TIER_FETCH';
    };

export const pricingPageMachine = createMachine<PricingPageMachineContext, PricingPageMachineEvent>(
  {
    id: 'pricingPageMachine',
    type: 'parallel',
    states: {
      plans: {
        id: 'plans',
        initial: 'fetchingPlans',
        states: {
          fetchingPlans: {
            initial: 'initialFetch',
            states: {
              initialFetch: {},
              subsequentFetch: {},
            },
            invoke: {
              src: 'fetchPlans',
              onError: {
                target: 'failed',
              },
            },
            on: {
              REPORT_PLAN_TIERS_FETCH_SUCCESS: {
                target: 'idle',
                actions: 'cachePlanTiers',
              },
              REPORT_PLAN_TIERS_FETCH_FAILED: {
                target: 'failed',
              },
            },
          },
          idle: {
            type: 'parallel',
            states: {
              customSeats: {
                id: 'customSeats',
                initial: 'unknown',
                states: {
                  unknown: {
                    always: [
                      { cond: 'customSeatsShouldContactSales', target: 'contactSales' },
                      { target: 'inRange' },
                    ],
                  },
                  inRange: {
                    initial: 'idle',
                    states: {
                      idle: {},
                      debouncingFetch: {
                        on: {
                          CHANGE_CUSTOM_SEATS: [
                            { cond: 'customSeatsIsInputInvalid', target: 'idle' },
                            {
                              cond: 'customSeatsIsEmpty',
                              actions: ['cacheCustomSeats'],
                              target: 'idle',
                            },
                            {
                              cond: 'customSeatsShouldContactSales',
                              actions: ['cacheCustomSeats'],
                              target: '#customSeats.contactSales',
                            },
                            { actions: ['cacheCustomSeats'], target: 'debouncingFetch' },
                          ],
                        },
                        after: {
                          350: {
                            target: 'fetchingCustomSeatPricing',
                          },
                        },
                      },
                      fetchingCustomSeatPricing: {
                        invoke: {
                          src: 'fetchCustomSeatPricing',
                        },
                        on: {
                          CHANGE_CUSTOM_SEATS: {},
                          REPORT_CUSTOM_SEATS_FETCH_SUCCESS: {
                            target: 'idle',
                            actions: 'cacheCustomSeatsPricing',
                          },
                          REPORT_CUSTOM_SEATS_FETCH_FAILED: {
                            target: 'failed',
                          },
                        },
                      },
                      failed: {},
                    },
                  },
                  contactSales: {},
                },
                on: {
                  CHANGE_CUSTOM_SEATS: [
                    { cond: 'customSeatsIsInputInvalid' },
                    {
                      cond: 'customSeatsShouldContactSales',
                      actions: ['cacheCustomSeats'],
                      target: '.contactSales',
                    },
                    {
                      cond: 'customSeatsIsEmpty',
                      actions: ['cacheCustomSeats'],
                      target: '.inRange.idle',
                    },
                    { actions: ['cacheCustomSeats'], target: '.inRange.debouncingFetch' },
                  ],
                },
              },
            },
            on: {
              SELECT_TIER: {
                actions: 'cacheSelectedTier',
              },
              TOGGLE_BILLING_PERIOD: {
                actions: 'toggleBillingPeriod',
                target: 'fetchingPlans.subsequentFetch',
              },
            },
          },
          failed: {
            on: {
              RETRY_PLAN_TIER_FETCH: {
                actions: ['clearPlanTiers'],
                target: ['fetchingPlans', '#discountCode.reset'],
              },
            },
          },
        },
      },
      discountCode: {
        id: 'discountCode',
        initial: 'idle',
        states: {
          idle: {
            initial: 'checkingValidity',
            states: {
              checkingValidity: {
                always: [
                  {
                    cond: 'discountCodeFormatValid',
                    target: 'valid',
                  },
                  {
                    target: 'invalid',
                  },
                ],
              },
              invalid: {},
              valid: {
                on: {
                  APPLY_DISCOUNT_CODE: {
                    target: '#discountCode.validating',
                  },
                },
              },
            },
            on: {
              UPDATE_DISCOUNT_CODE: {
                actions: ['cacheDiscountCode'],
                target: '.checkingValidity',
              },
            },
          },
          validating: {
            invoke: {
              src: 'validateDiscountCode',
              onError: {
                target: ['failure', '#plans.fetchingPlans.subsequentFetch'],
              },
            },
            on: {
              REPORT_DISCOUNT_CODE_VALID: {
                target: ['applied', '#plans.fetchingPlans.subsequentFetch'],
              },
              REPORT_DISCOUNT_CODE_INVALID: {
                target: 'idle',
                actions: 'notifyDiscountCodeInvalid',
              },
            },
          },
          applied: {},
          failure: {
            entry: 'clearDiscountCode',
            always: {
              target: 'idle',
            },
          },
          reset: {
            entry: 'clearDiscountCode',
            always: {
              target: 'idle',
            },
          },
        },
      },
    },
  },
  {
    actions: {
      cachePlanTiers: assign({
        planTiers: (ctx, ev) => {
          assertEventType(ev, 'REPORT_PLAN_TIERS_FETCH_SUCCESS');

          return ev.data.planTiers;
        },
      }),
      cacheCustomSeatsPricing: assign({
        planTiers: (ctx, ev) => {
          assertEventType(ev, 'REPORT_CUSTOM_SEATS_FETCH_SUCCESS');

          return ctx.planTiers.map((tier) => {
            if (tier.type === ApiSubscriptionPlanTierType.Fixed) {
              return tier;
            }

            return ev.data.planTier;
          });
        },
      }),
      clearPlanTiers: assign({
        planTiers: (ctx, ev) => {
          return [];
        },
      }),

      toggleBillingPeriod: assign({
        billingPeriod: (ctx, ev) => {
          return ctx.billingPeriod === ApiSubscriptionPlanPeriodUnit.Month
            ? ApiSubscriptionPlanPeriodUnit.Year
            : ApiSubscriptionPlanPeriodUnit.Month;
        },
      }),

      cacheDiscountCode: assign({
        discountCode: (ctx, ev) => {
          assertEventType(ev, 'UPDATE_DISCOUNT_CODE');

          return ev.data.discountCode;
        },
      }),
      clearDiscountCode: assign({
        discountCode: (ctx, ev) => {
          return '';
        },
      }),

      cacheCustomSeats: assign({
        customTierSeats: (ctx, ev) => {
          assertEventType(ev, 'CHANGE_CUSTOM_SEATS');

          return ev.data.seats;
        },
      }),

      notifyDiscountCodeInvalid: (ctx) => {
        toast.error(`${ctx.discountCode} is not a valid promo code`);
      },
    },
    guards: {
      discountCodeFormatValid: (ctx, ev) => {
        return ctx.discountCode.trim().length > 0;
      },
      customSeatsIsEmpty: (ctx, ev) => {
        assertEventType(ev, 'CHANGE_CUSTOM_SEATS');

        return ev.data.seats === '';
      },
      customSeatsIsInputInvalid: (ctx, ev) => {
        assertEventType(ev, 'CHANGE_CUSTOM_SEATS');

        // Block anything that isn't an empty string or a whole number
        if (ev.data.seats === '') {
          return false;
        }

        return !/[0-9]/.test(ev.data.seats);
      },
      customSeatsShouldContactSales: (ctx, ev) => {
        const MAX_SEATS = 500;

        if (ev.type === 'CHANGE_CUSTOM_SEATS') {
          return parseInt(ev.data.seats, 10) > MAX_SEATS;
        }

        return parseInt(ctx.customTierSeats, 10) > MAX_SEATS;
      },
    },
    services: {
      fetchPlans: (ctx, ev) => (cb) => {
        const fixedPricingRequest = subscriptionPlanPricingApi.getFixedSubscriptionPlanTiers({
          currencyCode: ctx.selectedCurrency,
          billingPeriod: ctx.billingPeriod,
          discountCode: ctx.discountCode,
        });

        const customPricingRequest = subscriptionPlanPricingApi.getCustomSubscriptionPlanTiers({
          currencyCode: ctx.selectedCurrency,
          billingPeriod: ctx.billingPeriod,
          discountCode: ctx.discountCode,
          seats: parseInt(ctx.customTierSeats, 10),
        });

        Promise.all([fixedPricingRequest, customPricingRequest])
          .then(([fixedPricing, customPricing]) => {
            cb({
              type: 'REPORT_PLAN_TIERS_FETCH_SUCCESS',
              data: {
                planTiers: [...fixedPricing.planTiers, customPricing.planTier],
              },
            });
          })
          .catch((e) => {
            logger.logError(e);

            cb({ type: 'REPORT_PLAN_TIERS_FETCH_FAILED' });
          });
      },
      fetchCustomSeatPricing: (ctx, ev) => (cb) => {
        subscriptionPlanPricingApi
          .getCustomSubscriptionPlanTiers({
            currencyCode: ctx.selectedCurrency,
            billingPeriod: ctx.billingPeriod,
            discountCode: ctx.discountCode,
            seats: parseInt(ctx.customTierSeats, 10),
          })
          .then((data) => {
            cb({
              type: 'REPORT_CUSTOM_SEATS_FETCH_SUCCESS',
              data: {
                planTier: data.planTier,
              },
            });
          })
          .catch((e) => {
            logger.logError(e);

            cb({ type: 'REPORT_CUSTOM_SEATS_FETCH_FAILED' });
          });
      },
      validateDiscountCode: (ctx, ev) => (cb) => {
        subscriptionPlanPricingApi
          .validateDiscountCode({ discountCode: ctx.discountCode })
          .then((data) => {
            if (data.valid) {
              cb({ type: 'REPORT_DISCOUNT_CODE_VALID' });
            } else {
              cb({ type: 'REPORT_DISCOUNT_CODE_INVALID' });
            }
          })
          .catch((e) => {
            logger.logError(e);
            cb({ type: 'REPORT_DISCOUNT_CODE_INVALID' });
          });
      },
    },
  }
);
