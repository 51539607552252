import { toast } from 'react-toastify';
import { assign, createMachine } from 'xstate';

import { ApiTeam, teamApi } from '../../../api/team';
import logger from '../../../utils/logger';
import { RelocateUserOptions } from './models';

interface TeamsListMachineContext {
  teams: ApiTeam[];
  error?: string;
}

type TeamsListMachineEvent =
  | { type: 'RETRY' }
  | { type: 'RELOAD' }
  | { type: 'RELOCATE_USER'; data: RelocateUserOptions }
  | { type: 'ADD_TEAM'; data: { team: ApiTeam } }
  | { type: 'REFETCH_TEAMS' };

type TeamsListMachineState =
  | {
      value: 'idle';
      context: TeamsListMachineContext & {
        teams: [];
        error: undefined;
      };
    }
  | {
      value: 'loading';
      context: TeamsListMachineContext;
    }
  | {
      value: 'ready';
      context: TeamsListMachineContext & { teams: ApiTeam[]; error: undefined };
    }
  | {
      value: 'error';
      context: TeamsListMachineContext & { teams: []; error: string };
    };

export const teamsListMachine = createMachine<
  TeamsListMachineContext,
  TeamsListMachineEvent,
  TeamsListMachineState
>(
  {
    id: 'teamsList',
    initial: 'loading',
    context: {
      teams: [],
      error: undefined,
    },
    states: {
      loading: {
        id: 'loading',
        entry: ['clearError'],
        invoke: {
          id: 'fetchTeams',
          src: 'fetchTeams',
          onDone: {
            target: 'ready',
            actions: ['cacheTeams'],
          },
          onError: {
            target: 'error',
            actions: ['setTeamFetchError'],
          },
        },
      },
      ready: {
        initial: 'idle',
        entry: ['clearError'],
        states: {
          idle: {
            on: {
              RELOCATE_USER: {
                target: 'relocatingUser',
              },
              ADD_TEAM: {
                target: 'idle',
                actions: ['cacheNewTeam'],
              },
              REFETCH_TEAMS: {
                target: '#loading',
              },
            },
          },
          relocatingUser: {
            entry: ['cacheOptimisticUserRelocation'],
            invoke: {
              id: 'relocateUser',
              src: 'relocateUser',
              onDone: {
                target: 'idle',
              },
              onError: {
                target: 'idle',
                actions: ['cacheUndoUserRelocation', 'notifyUserRelocationError'],
              },
            },
          },
        },
      },
      error: {
        id: 'error',
      },
    },
    on: {
      RELOAD: {
        target: 'loading',
      },
    },
  },
  {
    actions: {
      clearError: assign({
        error: (_) => undefined,
      }),
      setTeamFetchError: assign({
        error: (_) => {
          return 'Something went wrong whilst fetching the teams';
        },
      }),
      cacheTeams: assign({
        teams: (_, ev: any) => {
          return ev.data.teams;
        },
      }),
      cacheNewTeam: assign({
        teams: (ctx, ev: any) => {
          return [ev.data.team, ...ctx.teams];
        },
      }),
      cacheOptimisticUserRelocation: assign({
        teams: (ctx, ev: any) => {
          try {
            return relocateUser(ctx.teams, ev.data);
          } catch (e) {
            logger.logError(e, {
              extra: {
                userId: ev.data.userId,
                fromTeamId: ev.data.toTeamId,
                toTeamId: ev.data.fromTeamId,
              },
            });
          }

          return ctx.teams;
        },
      }),
      cacheUndoUserRelocation: assign({
        teams: (ctx, ev: any) => {
          return relocateUser(ctx.teams, {
            userId: ev.data.userId,
            fromTeamId: ev.data.toTeamId,
            toTeamId: ev.data.fromTeamId,
          });
        },
      }),
      notifyUserRelocationError: (ctx, ev) => {
        toast.error('Something went wrong whilst moving the user');
      },
    },
    services: {
      fetchTeams: async () => {
        return teamApi.getTeams();
      },
      relocateUser: async (_, ev: any) => {
        return teamApi.addUserToTeam(ev.data.userId, ev.data.toTeamId);
      },
    },
  }
);

const findUser = (teams: ApiTeam[], teamId: number, userId: number) => {
  const fromTeam = teams.find((team) => team.id === teamId);

  if (!fromTeam) {
    throw new Error('The team the user is moving from does not exist');
  }

  const user = fromTeam.teamUsers.find((user) => user.id === userId);

  if (!user) {
    throw new Error('Could not find a user who has been relocated');
  }

  return user;
};

const relocateUser = (teams: ApiTeam[], relocateOpts: RelocateUserOptions) => {
  const { fromTeamId, toTeamId, userId } = relocateOpts;

  const user = findUser(teams, fromTeamId, userId);

  return teams.reduce<ApiTeam[]>((acc, team) => {
    if (fromTeamId === team.id) {
      return acc.concat({
        ...team,
        teamUsers: team.teamUsers.filter((user) => user.id !== userId),
      });
    }

    if (toTeamId === team.id) {
      return acc.concat({
        ...team,
        teamUsers: team.teamUsers.concat(user),
      });
    }

    return acc.concat(team);
  }, []);
};
