import * as React from 'react';
import { animated } from 'react-spring';
import { Transition } from 'react-spring/renderprops.cjs';
import { Machine } from 'xstate';

import { Banner } from './common/Banner';

const RSAnimatedBanner = animated(Banner);

export const animatedBannerMachine = Machine({
  id: 'animatedBanner',
  initial: 'closed',
  states: {
    closed: {
      on: {
        OPEN: 'opening',
      },
      after: {
        1500: {
          target: 'opening',
          cond: 'shouldAutoShow',
        },
      },
    },
    opening: {
      invoke: {
        src: 'openingBanner',
        onDone: { target: 'opened' },
      },
      on: {
        CLOSE: 'closing',
      },
    },
    opened: {
      on: {
        CLOSE: 'closing',
      },
    },
    closing: {
      invoke: {
        src: 'closingBanner',
        onDone: { target: 'closed' },
      },
      on: {
        OPEN: 'opening',
      },
    },
  },
});

interface AnimatedBannerProps {
  isOpen: boolean;
  variant?: string;
  onDismiss: () => void;
}

export const AnimatedBanner: React.FC<AnimatedBannerProps> = (props) => {
  return (
    <Transition
      items={props.isOpen}
      config={(_item, type) => {
        if (type !== 'leave') {
          return {};
        }

        return { duration: 300 };
      }}
      from={{ opacity: 0, marginTop: '-50px', transform: 'translate3d(0vw, 0, 0)' }}
      enter={{ opacity: 1, marginTop: '0', transform: 'translate3d(0vw, 0, 0)' }}
      leave={{ opacity: 0, marginTop: '-50px', transform: 'translate3d(0vw, 0, 0)' }}
    >
      {(show) =>
        show &&
        ((innerProps) => (
          <RSAnimatedBanner
            variant={props.variant}
            containerStyles={innerProps}
            onDismiss={props.onDismiss}
          >
            {props.children}
          </RSAnimatedBanner>
        ))
      }
    </Transition>
  );
};
