import styled from '@emotion/styled/macro';
import * as React from 'react';

interface StarProps extends Omit<React.SVGProps<any>, 'ref'> {
  outlineColour?: string;
  backgroundColour?: string;
  hoverColour?: string;
}

interface ContainerProps {
  outlineColour: string;
  backgroundColour: string;
  hoverColour?: string;
}

const Container = styled.svg<ContainerProps>`
  cursor: pointer;

  polygon {
    fill: ${(props: ContainerProps) => props.backgroundColour};
    transition: all 200ms ease-in-out;
  }

  path {
    fill: ${(props: ContainerProps) => props.outlineColour};
    transition: all 200ms ease-in-out;
  }

  &:hover {
    polygon,
    path {
      fill: ${(props: ContainerProps) => props.hoverColour};
    }
  }
`;

export const Star: React.FC<StarProps> = ({
  outlineColour = '#203333',
  backgroundColour = 'transparent',
  hoverColour,
  ...props
}) => (
  <Container
    outlineColour={outlineColour}
    backgroundColour={backgroundColour}
    hoverColour={hoverColour}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    {...props}
  >
    <polygon points="249.66 382.47 108.64 456.61 135.58 299.58 21.49 188.37 179.15 165.46 249.66 22.6 320.17 165.46 477.84 188.37 363.75 299.58 390.68 456.61 249.66 382.47" />
    <path d="M250,57.68l59.22,120,4.65,9.43,10.4,1.51,132.41,19.24-95.81,93.4-7.53,7.34,1.78,10.36,22.62,131.88L259.31,388.55,250,383.66l-9.31,4.89L122.26,450.82l22.62-131.88,1.78-10.36-7.53-7.34-95.81-93.4L175.73,188.6l10.4-1.51,4.65-9.43L250,57.68m0-45.19L172.85,168.81.34,193.88,125.17,315.56,95.7,487.38,250,406.26l154.3,81.12L374.83,315.56,499.66,193.88,327.15,168.81,250,12.49Z" />
  </Container>
);
