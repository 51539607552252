import * as React from 'react';

import useTimeout from '../../hooks/useTimeout';

interface ScrollToTopProps {
  after?: number;
}

export const ScrollToTop: React.FC<ScrollToTopProps> = ({ after = 0, children }) => {
  useTimeout(() => {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  }, after);

  return <React.Fragment>{children}</React.Fragment>;
};
