import * as React from 'react';

import { Box } from '../../../components/common/Box';

interface BasicHintProps {
  onClick?: () => void;
}

export const BasicHint: React.FC<BasicHintProps> = (props) => {
  if (!props.children) {
    return null;
  }

  return (
    <Box
      sx={{
        bg: 'primary',
        color: 'white',
        p: 3,
        fontSize: '0.85rem',
        cursor: props.onClick ? 'cursor' : 'default',
      }}
      onClick={props.onClick}
    >
      <Box as="span" sx={{ fontWeight: 700 }}>
        Example:
      </Box>{' '}
      <Box as="span" sx={{ fontStyle: 'italic' }}>
        {props.children}
      </Box>
    </Box>
  );
};
