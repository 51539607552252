import { authApi } from '../../api/auth';
import { client, IMPERSONATE_USER_HEADER_KEY } from '../../api/client';
import logger from '../logger';

export const AD_BUILDER_AUTH_API_KEY = 'ad_builder_auth:api_key';
export const AD_BUILDER_AUTH_REFRESH_TOKEN = 'ad_builder_auth:refresh_token';
const IMPERSONATE_USER_VALUE_LOCAL_STORAGE_KEY = 'ad_builder:admin_impersonate_user';

export const AuthUtils = {
  getApiKey: () => localStorage.getItem(AD_BUILDER_AUTH_API_KEY),
  getRefreshToken: () => localStorage.getItem(AD_BUILDER_AUTH_REFRESH_TOKEN),
  setTokens: (apiKey: string, refreshToken: string) => {
    AuthUtils.setApiKey(apiKey);
    AuthUtils.setRefreshToken(refreshToken);
  },
  setApiKey: (apiKey: string) => {
    localStorage.setItem(AD_BUILDER_AUTH_API_KEY, apiKey);
    client.defaults.headers.common['Authorization'] = `Bearer ${apiKey}`;
  },
  setRefreshToken: (refreshToken: string) => {
    localStorage.setItem(AD_BUILDER_AUTH_REFRESH_TOKEN, refreshToken);
  },
  clearTokens: () => {
    localStorage.removeItem(AD_BUILDER_AUTH_API_KEY);
    localStorage.removeItem(AD_BUILDER_AUTH_REFRESH_TOKEN);
    delete client.defaults.headers.common['Authorization'];
  },
  refreshApiKey: async () => {
    const refreshToken = AuthUtils.getRefreshToken();

    if (!refreshToken) {
      return null;
    }

    try {
      const result = await authApi.refresh(refreshToken);

      if (!result.apiKey) {
        return null;
      }

      AuthUtils.setApiKey(result.apiKey);

      return result.apiKey;
    } catch (e) {
      logger.logError(e);
      return null;
    }
  },
  decodeApiToken: (token: string) => {
    return JSON.parse(atob(token.split('.')[1]));
  },
  getSwitchUserHeaderValue: () => {
    return localStorage.getItem(IMPERSONATE_USER_VALUE_LOCAL_STORAGE_KEY);
  },
  setSwitchUserHeaderValue: (value?: string) => {
    if (!value) {
      delete client.defaults.headers.common[IMPERSONATE_USER_HEADER_KEY];
      localStorage.removeItem(IMPERSONATE_USER_VALUE_LOCAL_STORAGE_KEY);
    } else {
      client.defaults.headers.common[IMPERSONATE_USER_HEADER_KEY] = value;
      localStorage.setItem(IMPERSONATE_USER_VALUE_LOCAL_STORAGE_KEY, value);
    }
  },
  setSharedLoginCookie: () => {
    document.cookie = 'login=true;domain=.adbuilder.io;path=/';
  },
  clearSharedLoginCookie: () => {
    document.cookie =
      'login=false;domain=.adbuilder.io;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  },
};
