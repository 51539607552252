import { client } from './client';

export type JobTitles = string[];

export interface ApiAdvertJobTitle {
  jobTitles: JobTitles;
}

const fetchAdvertJobTitles = async (): Promise<ApiAdvertJobTitle> => {
  const response = await client.get<ApiAdvertJobTitle>(`/listing/job-titles`);
  return response.data;
};

export const advertJobTitlesApi = { fetchAdvertJobTitles };
