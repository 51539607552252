import { toast } from 'react-toastify';
import { assign, createMachine } from 'xstate';

import { analytics } from '../../../analytics/analytics';
import { accountSetupApi } from '../../../api/account-setup';
import { ApiCompany } from '../../../api/auth';
import {
  subscriptionPlanPricingApi,
  SubscriptionPlanTier,
} from '../../../api/subscription-plan-pricing';
import { isAxiosError } from '../../../api/util';
import { UserFacingError } from '../../../Error/BaseErrors';
import logger from '../../../utils/logger';
import { assertEventType } from '../../../utils/xstate-helpers';

interface RegistrationWizardCheckoutFormMachineContext {
  company: ApiCompany;
  discountCode: string;
  planTiers: SubscriptionPlanTier[];
  selectedTier?: SubscriptionPlanTier;
  currencies: string[];
  selectedCurrency: string;
}

type RegistrationWizardCheckoutFormMachineEvents =
  | {
      type: 'SELECT_TIER';
      data: {
        tier: SubscriptionPlanTier;
      };
    }
  | {
      type: 'CREATE_SUBSCRIPTION';
      data: {
        accountingEmail: string;
        billingDetails: {
          addressLineOne: string;
          addressLineTwo: string;
          addressCity: string;
          addressPostalCode: string;
          addressCountryCode: string;
          vatNumber: string;
        };
        stripe: {
          token: string;
          lastFour: string;
        };
      };
    }
  | {
      type: 'REPORT_SUBSCRIPTION_CREATED';
    }
  | {
      type: 'REPORT_SUBSCRIPTION_CREATION_FAILED';
      data: {
        error: string;
      };
    }
  | {
      type: 'CHANGE_CURRENCY';
      data: {
        currency: string;
      };
    }
  | {
      type: 'UPDATE_DISCOUNT_CODE';
      data: {
        discountCode: string;
      };
    }
  | {
      type: 'APPLY_DISCOUNT_CODE';
    }
  | {
      type: 'REPORT_DISCOUNT_CODE_VALID';
    }
  | {
      type: 'REPORT_DISCOUNT_CODE_INVALID';
    }
  | {
      type: 'REPORT_PLAN_TIERS_FETCH_SUCCESS';
      data: {
        planTiers: SubscriptionPlanTier[];
      };
    }
  | {
      type: 'REPORT_PLAN_TIERS_FETCH_FAILED';
    }
  | {
      type: 'RETRY_PLAN_TIER_FETCH';
    };

export const registrationWizardCheckoutFormMachine = createMachine<
  RegistrationWizardCheckoutFormMachineContext,
  RegistrationWizardCheckoutFormMachineEvents
>(
  {
    id: 'registrationWizardCheckoutForm',
    type: 'parallel',
    states: {
      plans: {
        id: 'plans',
        initial: 'fetchingPlans',
        states: {
          fetchingPlans: {
            invoke: {
              src: 'fetchPlans',
            },
            on: {
              REPORT_PLAN_TIERS_FETCH_SUCCESS: {
                target: 'idle',
                actions: 'cachePlanTiers',
              },
              REPORT_PLAN_TIERS_FETCH_FAILED: {
                target: 'failed',
              },
            },
          },
          idle: {
            on: {
              SELECT_TIER: {
                actions: 'cacheSelectedTier',
              },
              CREATE_SUBSCRIPTION: {
                target: 'creatingSubscription',
              },
              CHANGE_CURRENCY: {
                actions: 'cacheCurrency',
                target: 'fetchingPlans',
              },
            },
          },
          creatingSubscription: {
            invoke: {
              src: 'createSubscription',
            },
            on: {
              REPORT_SUBSCRIPTION_CREATED: {
                target: 'subscriptionCreated',
                actions: ['logSubscriptionStarted', 'logConversion', 'onSubscriptionCreated'],
              },
              REPORT_SUBSCRIPTION_CREATION_FAILED: {
                target: 'idle',
                actions: ['notifySubscriptionCreationError'],
              },
            },
          },
          subscriptionCreated: {
            type: 'final',
          },
          failed: {
            on: {
              RETRY_PLAN_TIER_FETCH: {
                actions: ['clearPlanTiers'],
                target: ['fetchingPlans', '#discountCode.reset'],
              },
            },
          },
        },
      },
      discountCode: {
        id: 'discountCode',
        initial: 'idle',
        states: {
          idle: {
            initial: 'checkingValidity',
            states: {
              checkingValidity: {
                always: [
                  {
                    cond: 'discountCodeFormatValid',
                    target: 'valid',
                  },
                  {
                    target: 'invalid',
                  },
                ],
              },
              invalid: {},
              valid: {
                on: {
                  APPLY_DISCOUNT_CODE: {
                    target: '#discountCode.validating',
                  },
                },
              },
            },
            on: {
              UPDATE_DISCOUNT_CODE: {
                actions: ['cacheDiscountCode'],
                target: '.checkingValidity',
              },
            },
          },
          validating: {
            invoke: {
              src: 'validateDiscountCode',
              onError: {
                target: ['failure', '#plans.fetchingPlans'],
              },
            },
            on: {
              REPORT_DISCOUNT_CODE_VALID: {
                target: ['applied', '#plans.fetchingPlans'],
              },
              REPORT_DISCOUNT_CODE_INVALID: {
                target: 'idle',
                actions: 'notifyDiscountCodeInvalid',
              },
            },
          },
          applied: {},
          failure: {
            entry: 'clearDiscountCode',
            always: {
              target: 'idle',
            },
          },
          reset: {
            entry: 'clearDiscountCode',
            always: {
              target: 'idle',
            },
          },
        },
      },
    },
  },
  {
    actions: {
      cacheSelectedTier: assign({
        selectedTier: (ctx, ev) => {
          assertEventType(ev, 'SELECT_TIER');

          return ev.data.tier;
        },
      }),

      cacheCurrency: assign({
        selectedCurrency: (ctx, ev) => {
          assertEventType(ev, 'CHANGE_CURRENCY');

          return ev.data.currency;
        },
      }),

      cacheDiscountCode: assign({
        discountCode: (ctx, ev) => {
          assertEventType(ev, 'UPDATE_DISCOUNT_CODE');

          return ev.data.discountCode;
        },
      }),
      clearDiscountCode: assign({
        discountCode: (ctx, ev) => {
          return '';
        },
      }),

      cachePlanTiers: assign({
        planTiers: (ctx, ev) => {
          assertEventType(ev, 'REPORT_PLAN_TIERS_FETCH_SUCCESS');

          return ev.data.planTiers;
        },
        selectedTier: (ctx, ev) => {
          assertEventType(ev, 'REPORT_PLAN_TIERS_FETCH_SUCCESS');

          // try and match it with the existing selected tier
          const matching = ev.data.planTiers.find((tier) => {
            return tier.id === ctx.selectedTier?.id;
          });

          if (!matching) {
            return ev.data.planTiers[0];
          }

          return matching;
        },
      }),
      clearPlanTiers: assign({
        planTiers: (ctx, ev) => {
          return [];
        },
      }),

      notifyDiscountCodeInvalid: (ctx) => {
        toast.error(`${ctx.discountCode} is not a valid promo code`);
      },
      notifySubscriptionCreationError: (ctx, ev) => {
        assertEventType(ev, 'REPORT_SUBSCRIPTION_CREATION_FAILED');
        toast.error(ev.data.error);
      },

      logSubscriptionStarted: (ctx) => {
        const seats = ctx.selectedTier?.seats;

        if (!seats) {
          logger.logError(new Error('seats should be set at this point'), {
            extra: { machine: JSON.stringify(ctx) },
          });
          return;
        }

        analytics.subscriptionCreated(ctx.company.businessType, seats);
      },

      logConversion: (ctx) => {
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'conversion', {
            send_to: 'AW-990773192/QQXGCP6v6dECEMj_t9gD',
            event_callback: () => {},
          });
        }
      },
    },
    guards: {
      discountCodeFormatValid: (ctx, ev) => {
        return ctx.discountCode.trim().length > 0;
      },
    },
    services: {
      fetchPlans: (ctx, ev) => (cb) => {
        subscriptionPlanPricingApi
          .getFixedSubscriptionPlanTiers({
            currencyCode: ctx.selectedCurrency,
            discountCode: ctx.discountCode,
          })
          .then((data) => {
            cb({
              type: 'REPORT_PLAN_TIERS_FETCH_SUCCESS',
              data: {
                planTiers: data.planTiers,
              },
            });
          })
          .catch((e) => {
            console.error(e);

            cb({ type: 'REPORT_PLAN_TIERS_FETCH_FAILED' });
          });
      },
      validateDiscountCode: (ctx, ev) => (cb) => {
        subscriptionPlanPricingApi
          .validateDiscountCode({ discountCode: ctx.discountCode })
          .then((data) => {
            if (data.valid) {
              cb({ type: 'REPORT_DISCOUNT_CODE_VALID' });
            } else {
              cb({ type: 'REPORT_DISCOUNT_CODE_INVALID' });
            }
          })
          .catch((e) => {
            logger.logError(e);
            cb({ type: 'REPORT_DISCOUNT_CODE_INVALID' });
          });
      },
      createSubscription: (ctx, ev) => (cb) => {
        assertEventType(ev, 'CREATE_SUBSCRIPTION');

        if (!ctx.selectedTier) {
          throw new UserFacingError('No plan tier was selected');
        }

        accountSetupApi
          .createSubscription({
            companyId: ctx.company.id,
            billingDetails: {
              accountingEmail: ev.data.accountingEmail,
              vatNumber: ev.data.billingDetails.vatNumber,
              address: {
                lineOne: ev.data.billingDetails.addressLineOne,
                lineTwo: ev.data.billingDetails.addressLineTwo,
                postalCode: ev.data.billingDetails.addressPostalCode,
                city: ev.data.billingDetails.addressCity,
                country: ev.data.billingDetails.addressCountryCode,
              },
              stripe: {
                token: ev.data.stripe.token,
                lastFour: ev.data.stripe.lastFour,
              },
            },
            planId: ctx.selectedTier.planId,
            seats: ctx.selectedTier.seats,
            discountToken: ctx.discountCode,
          })
          .then((data) => {
            if (data.error) {
              cb({
                type: 'REPORT_SUBSCRIPTION_CREATION_FAILED',
                data: {
                  error: data.error,
                },
              });

              return;
            }

            cb({ type: 'REPORT_SUBSCRIPTION_CREATED' });
          })
          .catch((e) => {
            logger.logError(e);

            let errorMessage = 'Something went wrong whilst creating the subscription';

            if (isAxiosError(e) && e.response?.data?.error) {
              errorMessage = e.response.data.error;
            }

            cb({
              type: 'REPORT_SUBSCRIPTION_CREATION_FAILED',
              data: {
                error: errorMessage,
              },
            });
          });
      },
    },
  }
);
