import { RouteComponentProps } from '@reach/router';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Downshift from 'downshift';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import { useState } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';

import { companyBillingInformationApi } from '../../../api/company-billing-information';
import { companySubscriptionApi } from '../../../api/company-subscription';
import { ApiSubscriptionPlanPeriodUnit } from '../../../api/subscription-plan-pricing';
import { Box } from '../../../components/common/Box';
import { Button } from '../../../components/common/Button';
import { Flex } from '../../../components/common/Flex';
import {
  ErrorMessage as ThemedErrorMessage,
  Label,
  TextField,
} from '../../../components/common/form';
import { Heading } from '../../../components/common/Heading';
import { Link } from '../../../components/common/Link';
import { SelectMenu, SelectMenuItem } from '../../../components/common/Select';
import { Text } from '../../../components/common/Text';
import { Fade } from '../../../components/Headless/Fade';
import { Toggle } from '../../../components/Headless/Toggle';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { PlanHeader } from '../../../components/PlanHeader';
import { SubscriptionPlanTierPrice } from '../../../components/SubscriptionPlanTierPrice';
import { useUser } from '../../../context/UserContext';
import { UserFacingError } from '../../../Error/BaseErrors';
import { subscriptionTheme } from '../../../theme/subscriptionTheme';
import { ThemeProvider } from '../../../theme/ThemeProvider';
import countries from '../../../utils/countries.json';
import { companyDetailsSchema } from '../../../utils/form-validation/company-details';
import logger from '../../../utils/logger';

interface BillingDetailsProps extends RouteComponentProps {}

export const BillingDetails: React.FC<BillingDetailsProps> = (props) => {
  const user = useUser();
  const elements = useElements();
  const stripe = useStripe();

  const [updatingCard, setUpdatingCard] = useState(false);
  const [updatingCompanyDetails, setUpdatingCompanyDetails] = useState(false);

  const {
    status,
    data,
    refetch: refetchBillingDetails,
  } = companySubscriptionApi.useCompanySubscriptionQuery(user.company.id);

  if (['idle', 'loading'].includes(status)) {
    return (
      <Flex sx={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Text pb={4}>Fetching your billing details...</Text>
        <LoadingSpinner />
      </Flex>
    );
  }

  if (status === 'error' || !data) {
    return (
      <Box>
        <Text>Something went wrong whilst fetching your billing details.</Text>
      </Box>
    );
  }

  const { subscription, card, companyDetails, companyName } = data;

  const handleCardDetailsChanged = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    if (!stripe || !elements) {
      throw new Error('Attempted to updated card details but stripe has not loaded.');
    }

    if (!data) {
      throw new Error('Not sure how this got called without a response...');
    }

    try {
      setUpdatingCard(true);

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        throw new UserFacingError('Something went wrong whilst processing your card.');
      }

      const stripeResult = await stripe.createToken(cardElement, {
        name: `${user.firstName} ${user.lastName}`,
        address_line1: companyDetails.address.lineOne,
        address_line2: companyDetails.address.lineTwo || '',
        address_city: companyDetails.address.city,
        address_zip: companyDetails.address.postalCode,
        address_country: companyDetails.address.country,
      });

      if (stripeResult.error || !stripeResult.token) {
        setUpdatingCard(false);
        return;
      }

      await companySubscriptionApi.updateCard(user.company.id, stripeResult.token.id);
      setUpdatingCard(false);
      toast.success('Card updated');
      refetchBillingDetails();
    } catch (e) {
      setUpdatingCard(false);
      logger.logError(e);
      toast.error('Something went wrong whilst updating the details');
    }
  };

  return (
    <ThemeProvider theme={subscriptionTheme as TSThemeFixMe}>
      <Box pr="4" pb="10">
        <Flex sx={{ alignItems: 'center', pb: 5 }}>
          <Box mr="5">
            <PlanHeader title="Selected Plan:" plan={subscription.tierName} />
          </Box>

          <Box>
            <Link to="/subscriptions/change">
              <Text sx={{ fontWeight: 500, mb: 0 }}>Change Plan &rarr;</Text>
            </Link>
          </Box>
        </Flex>

        <Box sx={{ pb: 5, m: 0, borderBottom: 1, borderColor: 'grey.3' }}>
          <Box>
            <Text
              sx={{
                color: 'accent',
                fontSize: 4,
                fontWeight: 700,
                mb: 0,
                lineHeight: '1.8rem',
              }}
            >
              Total Cost:
            </Text>
          </Box>

          <SubscriptionPlanTierPrice
            amount={subscription.price / 100}
            period={
              subscription.periodUnit === ApiSubscriptionPlanPeriodUnit.Month ? 'month' : 'year'
            }
            currencyCode={subscription.currencyCode}
            textSx={{ fontSize: '2.5rem' }}
          />
        </Box>

        <Box sx={{ py: 7, m: 0, borderBottom: 1, borderColor: 'grey.3' }}>
          <Box mb="3">
            <Heading
              sx={{
                color: 'accent',
                fontSize: '1.75rem',
                fontWeight: 500,
                letterSpacing: '-0.05rem',
              }}
            >
              Credit Card Details:
            </Heading>
          </Box>

          <Text mb="5">We have a card ending {card.lastFour} on file.</Text>

          <Toggle>
            {({ on, setToggleValue }) => (
              <form onSubmit={handleCardDetailsChanged}>
                <Box mb={5}>
                  <Box
                    sx={{
                      padding: '1rem',
                      borderRadius: '4px',
                      backgroundColor: 'rgba(248, 248, 248)',
                      border: '2px solid rgba(225, 225, 225)',
                      fontSize: '1rem',
                    }}
                  >
                    <CardElement
                      id="card-number"
                      options={{ hidePostalCode: true }}
                      onChange={() => setToggleValue(true)}
                    />
                  </Box>
                </Box>

                <Fade active={on}>
                  <Button
                    type="submit"
                    variant={updatingCard ? 'disabled' : 'primary'}
                    disabled={updatingCard}
                  >
                    Update Your Card
                  </Button>
                </Fade>
              </form>
            )}
          </Toggle>
        </Box>

        <Box pt="7" pb="5" m="0">
          <Box mb="3">
            <Heading
              sx={{
                color: 'accent',
                fontSize: '1.75rem',
                fontWeight: 500,
                letterSpacing: '-0.05rem',
              }}
            >
              Company Information:
            </Heading>
          </Box>

          <Text mb={5}>This is what will appear on your invoices.</Text>

          <Formik
            initialValues={{
              companyName: companyName,
              addressLineOne: companyDetails.address.lineOne,
              addressLineTwo: companyDetails.address.lineTwo,
              addressLineThree: companyDetails.address.lineThree,
              addressCity: companyDetails.address.city,
              addressPostalCode: companyDetails.address.postalCode,
              addressCountry: countries.find((c) => c.code === companyDetails.address.country) as {
                code: string;
                name: string;
              },
              accountingEmail: companyDetails.accountingEmail,
            }}
            validationSchema={companyDetailsSchema}
            onSubmit={async (values) => {
              try {
                setUpdatingCompanyDetails(true);

                await companyBillingInformationApi.updateCompanyDetails(user.company.id, {
                  companyName: values.companyName,
                  accountingEmail: values.accountingEmail,
                  address: {
                    lineOne: values.addressLineOne,
                    lineTwo: values.addressLineTwo,
                    lineThree: values.addressLineThree,
                    postalCode: values.addressPostalCode,
                    city: values.addressCity,
                    country: values.addressCountry.code,
                  },
                });

                setUpdatingCompanyDetails(false);
                toast.success('Details updated');
                refetchBillingDetails();
              } catch (e) {
                setUpdatingCompanyDetails(false);
                logger.logError(e);
                toast.error('Something went wrong whilst updating the details');
              }
            }}
          >
            {({ dirty }) => (
              <Form>
                <Box sx={{ pb: 3 }}>
                  <Label htmlFor="companyName">
                    Company Name*
                    <Field name="companyName">
                      {({ field }: FieldProps) => (
                        <React.Fragment>
                          <TextField id={field.name} type="text" {...field} />
                          <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                        </React.Fragment>
                      )}
                    </Field>
                  </Label>
                </Box>

                <div>
                  <Heading
                    as="h4"
                    sx={{
                      fontSize: '1.2rem',
                      color: 'rgb(50, 146, 207)',
                      margin: '2rem 0px 1rem',
                      fontWeight: 500,
                      letterSpacing: '-0.01rem',
                    }}
                  >
                    Company Address:
                  </Heading>
                  <Box sx={{ pb: 3 }}>
                    <Label htmlFor="addressLineOne">
                      Line one*
                      <Field name="addressLineOne">
                        {({ field }: FieldProps) => (
                          <React.Fragment>
                            <TextField id={field.name} type="text" {...field} />
                            <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                          </React.Fragment>
                        )}
                      </Field>
                    </Label>
                  </Box>
                  <Box sx={{ pb: 3 }}>
                    <Label htmlFor="addressLineTwo">
                      Line two
                      <Field name="addressLineTwo">
                        {({ field }: FieldProps) => (
                          <React.Fragment>
                            <TextField id={field.name} type="text" {...field} />
                            <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                          </React.Fragment>
                        )}
                      </Field>
                    </Label>
                  </Box>
                  <Box sx={{ pb: 3 }}>
                    <Label htmlFor="addressLineThree">
                      Line three
                      <Field name="addressLineThree">
                        {({ field }: FieldProps) => (
                          <React.Fragment>
                            <TextField id={field.name} type="text" {...field} />
                            <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                          </React.Fragment>
                        )}
                      </Field>
                    </Label>
                  </Box>
                  <Box sx={{ pb: 3 }}>
                    <Label htmlFor="addressCity">
                      City*
                      <Field name="addressCity">
                        {({ field }: FieldProps) => (
                          <React.Fragment>
                            <TextField id={field.name} type="text" {...field} />
                            <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                          </React.Fragment>
                        )}
                      </Field>
                    </Label>
                  </Box>
                  <Box sx={{ pb: 3 }}>
                    <Label htmlFor="addressPostalCode">
                      Post code*
                      <Field name="addressPostalCode">
                        {({ field }: FieldProps) => (
                          <React.Fragment>
                            <TextField id={field.name} type="text" {...field} />
                            <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                          </React.Fragment>
                        )}
                      </Field>
                    </Label>
                  </Box>
                  <Box sx={{ pb: 3 }}>
                    <Label htmlFor="address-country">
                      Country*
                      <Field id="address-country" type="text" name="addressCountry">
                        {({ form, field, meta }: FieldProps) => (
                          <Downshift
                            initialInputValue={field.value.name}
                            itemToString={(item) => (item ? item.name : '')}
                            onChange={(selection) => {
                              form.setFieldValue(field.name, selection || {});
                              form.setFieldTouched(field.name);
                            }}
                          >
                            {({
                              getInputProps,
                              getItemProps,
                              getMenuProps,
                              isOpen,
                              inputValue,
                              highlightedIndex,
                              selectedItem,
                            }) => (
                              <div>
                                <Box sx={{ position: 'relative' }}>
                                  <TextField
                                    {...getInputProps<any>({
                                      isOpen,
                                      field,
                                      form,
                                      meta,
                                    })}
                                  />
                                  <SelectMenu
                                    {...getMenuProps()}
                                    sx={{
                                      position: 'absolute',
                                      top: 'calc(100% + 0.5rem)',
                                      maxHeight: '220px',
                                      width: '100%',
                                      boxShadow: isOpen ? 'selectMenu' : undefined,
                                    }}
                                  >
                                    {isOpen
                                      ? countries
                                          .filter(
                                            (country) =>
                                              !inputValue ||
                                              country.name
                                                .toLowerCase()
                                                .includes(inputValue.toLowerCase())
                                          )
                                          .map((country, index) => (
                                            <SelectMenuItem
                                              {...getItemProps({
                                                key: country.code,
                                                index,
                                                item: country,
                                              })}
                                              sx={{
                                                backgroundColor:
                                                  highlightedIndex === index
                                                    ? 'lightgray'
                                                    : undefined,
                                                fontWeight:
                                                  selectedItem === country ? 'bold' : 'normal',
                                                fontSize: '0.9rem',
                                                px: '1.1rem',
                                                py: '0.8rem',
                                              }}
                                            >
                                              {country.name}
                                            </SelectMenuItem>
                                          ))
                                      : null}
                                  </SelectMenu>
                                </Box>
                              </div>
                            )}
                          </Downshift>
                        )}
                      </Field>
                    </Label>
                  </Box>
                </div>

                <Box pt={5}>
                  <Box sx={{ pb: 3 }}>
                    <Label htmlFor="accounting-email">
                      Accounting Email*
                      <Field name="accountingEmail">
                        {({ field }: FieldProps) => (
                          <React.Fragment>
                            <TextField id={field.name} type="email" {...field} />
                            <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                          </React.Fragment>
                        )}
                      </Field>
                    </Label>
                  </Box>

                  <Text mb={5}>Billing receipts will be sent to this address.</Text>
                </Box>

                <Fade active={dirty}>
                  <Button
                    type="submit"
                    variant={updatingCompanyDetails ? 'disabled' : 'primary'}
                    disabled={updatingCompanyDetails}
                  >
                    Update Company Information
                  </Button>
                </Fade>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
