import axios from 'axios';

import { ApiErrorCodes } from '../Error/ApiErrors';
import { UserFacingError } from '../Error/BaseErrors';
import { AuthUtils } from '../utils/auth/auth-utils';
import { ApiFormError } from '../utils/errors/ApiFormError';
import { ResponseStatus } from './response-status';

export const IMPERSONATE_USER_HEADER_KEY = 'x-switch-user';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

if (process.env.NODE_ENV === 'development') {
  client.defaults.params = {};
  client.defaults.params['XDEBUG_SESSION_START'] = 'PHPSTORM';
}

if (AuthUtils.getSwitchUserHeaderValue()) {
  client.defaults.headers.common[
    IMPERSONATE_USER_HEADER_KEY
  ] = AuthUtils.getSwitchUserHeaderValue();
}

export type ClientType = typeof client;

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isCancel(error)) {
      // Do nothing
      return Promise.reject(error);
    }

    if (!error.response) {
      // Do nothing
      return Promise.reject(error);
    }

    const {
      response: { status, data },
    } = error;

    const handler = handledErrors.find((item) => item.check(status, data, error));

    return handler?.handle(status, data, error);
  }
);

const handledErrors = [
  {
    // isApiFormError
    check: (status: any, data: any, _error: any) => {
      return (
        status === ResponseStatus.BadRequest &&
        data.code === ResponseStatus.BadRequest &&
        data.errors &&
        data.errors.children
      );
    },
    handle: (_status: any, data: any, _error: any) => {
      const fields = data.errors.children;

      const fieldErrors = Object.keys(fields).reduce((acc: any, fieldKey: string) => {
        if (!fields[fieldKey].errors) {
          return acc;
        }

        acc[fieldKey] = fields[fieldKey].errors[0];
        return acc;
      }, {});

      const rootErrors = data.errors?.errors ?? [];

      return Promise.reject(new ApiFormError('Encountered an issue', fieldErrors, rootErrors));
    },
  },
  {
    // UserFacingError
    check: (_status: any, data: any, _error: any) => {
      return data?.errorCode === ApiErrorCodes.USER_FACING_EXCEPTION;
    },
    handle: (_status: any, data: any, _error: any) => {
      return Promise.reject(new UserFacingError(data?.message));
    },
  },
  {
    // Fallback
    check: (_status: any, _data: any, _error: any) => {
      return true;
    },
    handle: (_status: any, _data: any, error: any) => {
      return Promise.reject(error);
    },
  },
];

export { client };
