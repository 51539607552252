import { useContext, useMemo } from 'react';
import * as React from 'react';

import useLocalStorage from '../hooks/useLocalStorage';

export enum UserPreference {
  ShowNoSalaryWarning = 'showNoSalaryWarning',
  ShowOverwriteAdvertWarning = 'showOverwriteAdvertWarning',
  SmallDevicesWarningCleared = 'smallDevicesWarningCleared',
}

interface State {
  [UserPreference.ShowNoSalaryWarning]: boolean;
  [UserPreference.ShowOverwriteAdvertWarning]: boolean;
  [UserPreference.SmallDevicesWarningCleared]: boolean | undefined;
}

type Updater = (userPrefs: State) => void;

const UserPreferencesStateContext = React.createContext<State | undefined>(undefined);
const UserPreferencesUpdaterContext = React.createContext<Updater | undefined>(undefined);

const defaults = {
  [UserPreference.ShowNoSalaryWarning]: true,
  [UserPreference.ShowOverwriteAdvertWarning]: true,
  [UserPreference.SmallDevicesWarningCleared]: undefined,
};

export const UserPreferencesProvider: React.FC = (props) => {
  const [storedUserPrefs, setStoredUserPrefs] = useLocalStorage<State>(
    'ad_builder:user_preferences',
    defaults
  );

  const value = useMemo(() => {
    return {
      ...defaults,
      ...storedUserPrefs.content,
    };
  }, [storedUserPrefs]);

  return (
    <UserPreferencesStateContext.Provider value={value}>
      <UserPreferencesUpdaterContext.Provider value={setStoredUserPrefs}>
        {props.children}
      </UserPreferencesUpdaterContext.Provider>
    </UserPreferencesStateContext.Provider>
  );
};

export const useUserPreferencesState = () => {
  const userPreferences = useContext(UserPreferencesStateContext);

  if (typeof userPreferences === undefined) {
    throw new Error('useUserPreferencesState must be used within a UserPreferencesProvider');
  }

  return userPreferences as State;
};

export const useUserPreferencesUpdater = () => {
  const updater = useContext(UserPreferencesUpdaterContext);

  if (typeof updater === undefined) {
    throw new Error('useUserPreferencesUpdated must be used within a UserPreferencesProvider');
  }

  return updater as Updater;
};

export const useUserPreferences = (): [State, Updater] => {
  return [useUserPreferencesState(), useUserPreferencesUpdater()];
};
