import { Redirect } from '@reach/router';
import { AxiosError } from 'axios';
import { useContext, useMemo, useState } from 'react';
import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { Advert, advertApi } from '../../api/advert';
import { ResponseStatus } from '../../api/response-status';
import { Flex } from '../../components/common/Flex';
import { FullPageSpinner } from '../../components/FullPageSpinner';
import logger from '../../utils/logger';
import { AdvertBuilderHeader } from './AdvertBuilderHeader';
import { AdvertBuilderPages } from './utils/advertBuilderPages';

type AdvertId = number | 'new';

export enum NavigationDirection {
  Backwards,
  Forwards,
}

interface ContextValue {
  advert: Advert | undefined;
  isNewAdvert: boolean;
  navigationDirection: NavigationDirection;
  updateAdvert: (advert: Advert) => void;
  formPageNavigation: (currentPage: AdvertBuilderPages, nextPage: AdvertBuilderPages) => void;
}

const AdvertBuilderContext = React.createContext<ContextValue | undefined>(undefined);

const calculateNavigationDirection = (
  currentPage: AdvertBuilderPages,
  nextPage: AdvertBuilderPages
) => {
  if (currentPage === nextPage) {
    return NavigationDirection.Forwards;
  }

  const pageOrder = [
    AdvertBuilderPages.Basics,
    AdvertBuilderPages.Package,
    AdvertBuilderPages.Candidate,
    AdvertBuilderPages.Role,
    AdvertBuilderPages.Company,
    AdvertBuilderPages.Advert,
  ];

  // If we get to the current page before the next page then the user is navigating forwards otherwise they're navigating backwards
  const firstMatchedPage = pageOrder.find((page) => page === currentPage || page === nextPage);

  if (typeof firstMatchedPage === 'undefined') {
    throw new Error('The user should not be able to navigate to a page that does not exist');
  }

  return firstMatchedPage === currentPage
    ? NavigationDirection.Forwards
    : NavigationDirection.Backwards;
};

interface AdvertBuilderProviderProps {
  advertId: AdvertId;
}

export const AdvertBuilderProvider: React.FC<AdvertBuilderProviderProps> = ({
  children,
  advertId,
}) => {
  const isNewAdvert = advertId === 'new';

  const queryClient = useQueryClient();

  const { status, data: advert, error } = useQuery(
    ['advert', advertId],
    async () => {
      const response = await advertApi.fetchAdvert(advertId as number);

      return response.advert;
    },
    {
      enabled: !isNewAdvert,
      refetchOnWindowFocus: false,
      onError: (error: AxiosError) => {
        logger.logError(error);
      },
    }
  );

  const [navigationDirection, setNavigationDirection] = useState(NavigationDirection.Forwards);

  const contextValue = useMemo(() => {
    return {
      advert: advert,
      isNewAdvert,
      navigationDirection,
      updateAdvert: (advert: Advert) => {
        if (isNewAdvert) {
          return;
        }

        queryClient.setQueryData(['advert', advertId], advert);
      },
      formPageNavigation: (currentPage: AdvertBuilderPages, nextPage: AdvertBuilderPages) => {
        const direction = calculateNavigationDirection(currentPage, nextPage);
        setNavigationDirection(direction);
      },
    };
  }, [advertId, navigationDirection, advert, isNewAdvert, queryClient, setNavigationDirection]);

  if (!isNewAdvert && ['idle', 'loading'].includes(status)) {
    return (
      <FullPageSpinner>
        <Flex
          sx={{
            flex: '1',
            minHeight: '100%',
            flexDirection: 'column',
          }}
        >
          <AdvertBuilderHeader title="Loading advert" helperText="Fetching the advert content" />
        </Flex>
      </FullPageSpinner>
    );
  }

  if (status === 'error' && error?.response?.status === ResponseStatus.NotFound) {
    return <Redirect path="" from="" to="/dashboard" noThrow />;
  }

  if (status === 'error') {
    // TODO - handle the error better...
    return (
      <FullPageSpinner>
        <Flex sx={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          Something went wrong whilst loading the advert
        </Flex>
      </FullPageSpinner>
    );
  }

  return (
    <AdvertBuilderContext.Provider value={contextValue}>{children}</AdvertBuilderContext.Provider>
  );
};

export const useAdvertBuilder = () => {
  const ctx = useContext(AdvertBuilderContext);

  if (typeof ctx === undefined) {
    throw new Error('useAdvertBuilder must be used within a AdvertBuilderProvider');
  }

  return ctx as ContextValue;
};
