import { MenuItem } from '@reach/menu-button';
import { useCallback } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';

import { Advert } from '../../../api/advert';
import { Box } from '../../../components/common/Box';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalTextButton,
  ModalTitle,
} from '../../../components/common/Modal';
import { useToggle } from '../../../hooks/useToggle';
import logger from '../../../utils/logger';
import { JobSummary } from '.';

interface DeleteActionProps {
  advert: Advert;
  onDelete: () => void;
}

export const DeleteAction: React.FC<DeleteActionProps> = (props) => {
  const { advert, onDelete } = props;

  const { on: modalOpen, setOn, setOff } = useToggle();

  const onDeleteAdvert = useCallback(async () => {
    try {
      onDelete();
      setOff();
    } catch (e) {
      logger.logError(e as any);
      toast.success('Advert deleted successfully');
    }
  }, [setOff, onDelete]);

  return (
    <React.Fragment>
      <Modal
        aria-label="Delete advert confirmation"
        variant="destructive"
        isOpen={modalOpen}
        onDismiss={setOff}
      >
        <ModalClose onClick={setOff} />
        <ModalContainer>
          <ModalTitle>Are you sure you want to delete this advert?</ModalTitle>

          <JobSummary advert={advert} />

          <Box py="3" />

          <ModalActions>
            <ModalButton type="button" data-testid="delete-advert" onClick={onDeleteAdvert}>
              I'm sure, delete this advert
            </ModalButton>

            <ModalTextButton type="button" onClick={setOff}>
              Cancel
            </ModalTextButton>
          </ModalActions>
        </ModalContainer>
      </Modal>

      <MenuItem key="delete" data-testid="advert-actions-delete" onSelect={setOn}>
        Delete Advert
      </MenuItem>
    </React.Fragment>
  );
};
