import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { Slide, ToastContainer } from 'react-toastify';

import { AuthenticatedApp } from './AuthenticatedApp';
import { LocationChangeObserver } from './components/Headless/LocationChangeObserver';
import { useAuth } from './context/AuthContext';
import { UnauthenticatedApp } from './UnauthenticatedApp';

const App = () => {
  const { user } = useAuth();

  return (
    <Fragment>
      <Helmet defaultTitle="AdBuilder" titleTemplate="%s | AdBuilder" />

      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}

      <ToastContainer
        position="top-right"
        autoClose={2000}
        transition={Slide}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable={false}
        pauseOnHover={false}
      />

      <LocationChangeObserver />
    </Fragment>
  );
};

export default App;
