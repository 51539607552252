import { useService } from '@xstate/react';
import { useMemo } from 'react';
import * as React from 'react';

import { Advert } from '../../api/advert';
import { functionalUpdate } from '../../utils/common';
import { AdvertTableFilter, AdvertTableMachineService } from './machines/advertTableMachine';
import { FilterCollection } from './machines/filterCollectionManagerMachine';

interface ContextState {
  state: AdvertTableMachineService['state'];
  filters: AdvertTableMachineService['state']['context']['filters'];
  teamMembers: AdvertTableMachineService['state']['context']['teamMembers'];
  tags: AdvertTableMachineService['state']['context']['advertTags'];
  hasActiveFilters: boolean;
  isFilterActive: (filterName: string) => boolean;
  isSorted: (sortName: string) => boolean;
  isSortedDescending: (sortName: string) => boolean | undefined;
}

type SetFilterUpdater = (
  currentValue: AdvertTableFilter | AdvertTableFilter[]
) => AdvertTableFilter | AdvertTableFilter[];

interface ContextActions {
  setFilter: (
    filterName: string,
    value: AdvertTableFilter | AdvertTableFilter[] | SetFilterUpdater
  ) => void;
  clearFilter: (filterName: string) => void;
  clearAllFilters: () => void;
  applySavedFilter: (filterCollection: FilterCollection) => void;
  setSortBy: (sortName: string) => void;
  openQuickViewModal: (advert: Advert, variationId?: number) => void;
  onFavourite: (advert: Advert, variationId: number) => void;
  onUnfavourite: (advert: Advert) => void;
  deleteAdvert: (advert: Advert) => void;
}

const AdvertTableMachineStateContext = React.createContext<ContextState | undefined>(undefined);
const AdvertTableMachineActionsContext = React.createContext<ContextActions | undefined>(undefined);

interface AdvertTableMachineProviderProps {
  service: AdvertTableMachineService;
}

export const AdvertTableMachineProvider: React.FC<AdvertTableMachineProviderProps> = ({
  children,
  service,
}) => {
  const [state, send] = useService(service);

  const stateContextValue: ContextState = useMemo(() => {
    const isSorted = (sortName: string) => {
      const { sortBy } = state.context;

      if (!sortBy) {
        return false;
      }

      return sortBy.name === sortName;
    };

    return {
      state: state,
      filters: state.context.filters,
      teamMembers: state.context.teamMembers,
      tags: state.context.advertTags,
      hasActiveFilters: state.context.filters.size > 0,

      isFilterActive: (filterName: string) => {
        return state.context.filters.has(filterName);
      },
      isSorted,
      isSortedDescending: (sortName: string) => {
        const { sortBy } = state.context;

        if (!sortBy || !isSorted(sortName)) {
          return undefined;
        }

        return sortBy.descending;
      },
    };
  }, [state]);

  const actions: ContextActions = useMemo(() => {
    return {
      setFilter: (filterName: string, value: any) => {
        const previousValue = state.context.filters.get(filterName);

        const newValue = functionalUpdate(value, previousValue);

        send({
          type: 'FILTER.UPDATE',
          data: {
            filter: {
              name: filterName,
              value: newValue,
            },
          },
        });
      },
      clearFilter: (filterName: string) => {
        send({
          type: 'FILTER.CLEAR',
          data: {
            filter: {
              name: filterName,
            },
          },
        });
      },
      clearAllFilters: () => {
        send({
          type: 'FILTER.CLEAR_ALL',
        });
      },
      applySavedFilter: (filterCollection: FilterCollection) => {
        send({
          type: 'FILTER.APPLY_SAVED_FILTER',
          data: {
            filterCollection,
          },
        });
      },
      setSortBy: (sortName: string) => {
        send({
          type: 'SORT.UPDATE',
          data: {
            sortBy: sortName,
          },
        });
      },
      openQuickViewModal: (advert: Advert, variationId?: number) => {
        send({
          type: 'OPEN_QUICK_VIEW_MODAL',
          data: {
            advert,
            variationId,
          },
        });
      },
      onFavourite: (advert: Advert, variationId: number) => {
        send({
          type: 'FAVOURITE_ADVERT',
          data: {
            advert,
            variationId,
          },
        });
      },
      onUnfavourite: (advert: Advert) => {
        send({
          type: 'UNFAVOURITE_ADVERT',
          data: {
            advert,
          },
        });
      },
      deleteAdvert: (advert: Advert) => {
        send({
          type: 'DELETE_ADVERT',
          data: {
            advert,
          },
        });
      },
    };
  }, [send, state.context.filters]);

  return (
    <AdvertTableMachineStateContext.Provider value={stateContextValue}>
      <AdvertTableMachineActionsContext.Provider value={actions}>
        {children}
      </AdvertTableMachineActionsContext.Provider>
    </AdvertTableMachineStateContext.Provider>
  );
};

export const useAdvertTableMachineState = () => {
  const context = React.useContext(AdvertTableMachineStateContext);

  if (context === undefined) {
    throw new Error('useAdvertTableMachineState must be used within a AdvertTableMachineProvider');
  }

  return context;
};

export const useAdvertTableMachineActions = () => {
  const context = React.useContext(AdvertTableMachineActionsContext);

  if (context === undefined) {
    throw new Error(
      'useAdvertTableMachineActions must be used within a AdvertTableMachineProvider'
    );
  }

  return context;
};

export const useAdvertTableMachine = (): [
  ReturnType<typeof useAdvertTableMachineState>,
  ReturnType<typeof useAdvertTableMachineActions>
] => {
  return [useAdvertTableMachineState(), useAdvertTableMachineActions()];
};
