import { Link as RRLink } from '@reach/router';
import * as React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

import { Box, BoxProps } from './Box';

export interface LinkProps extends BoxProps {
  to: string;
  sx?: ThemeUIStyleObject;
}

export const Link: React.FC<LinkProps> = ({ sx, ...props }) => {
  return (
    <Box
      as={RRLink}
      sx={{ color: 'primary', cursor: 'pointer', textDecoration: 'underline', ...sx }}
      {...props}
    />
  );
};
