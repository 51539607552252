import '@reach/dialog/styles.css';

import styled from '@emotion/styled/macro';
import { DialogContent, DialogOverlay, DialogProps } from '@reach/dialog';
import { PropsWithoutRef, useContext, useMemo } from 'react';
import * as React from 'react';
import { animated, useTransition } from 'react-spring';

import { useTheme } from '../../hooks/useTheme';
import { Close } from '../Icon/Close';
import { Box, BoxProps } from './Box';
import { Button, ButtonProps } from './Button';
import { Flex } from './Flex';
import { Heading, HeadingProps } from './Heading';
import { IconButton } from './IconButton';
import { Text } from './Text';

const AnimatedDialogOverlay = animated(DialogOverlay);
const AnimatedDialogContent: any = animated(DialogContent);

interface ModalContextValue {
  variant: ModalVariant;
}

const ModalContext = React.createContext<ModalContextValue>({ variant: 'neutral' });

const useModalContext = () => {
  const ctx = useContext(ModalContext);

  return ctx;
};

export const ModalClose: React.FC<ButtonProps> = ({ style, ...props }) => {
  const ctx = useModalContext();
  const theme = useTheme();

  const color =
    style && style.color
      ? style.color
      : ctx.variant === 'destructive'
      ? theme.colors.white
      : undefined;

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '1.25rem',
          right: '0.5rem',
          color,
        }}
      >
        <IconButton sx={{ color }} style={style} title="close" {...props}>
          <Text as="span" sx={{ fontSize: '20px' }}>
            <Close />
          </Text>
        </IconButton>
      </Box>
    </Box>
  );
};

export const ModalContainer = styled(Box)`
  padding: 3.125rem;
  letter-spacing: -0.01rem;

  @media (max-width: 750px) {
    padding: 3.125rem 1.5rem;
  }
`;

interface ModalTitleProps extends HeadingProps {
  color?: string;
}

export const ModalTitle = React.forwardRef(({ color, sx, ...props }: ModalTitleProps, ref: any) => {
  const ctx = useModalContext();

  return (
    <Heading
      ref={ref}
      sx={{
        fontSize: '2.25rem',
        fontWeight: 'semibold',
        color: color ?? ctx.variant === 'destructive' ? 'white' : 'grey.9',
        pb: 6,
        letterSpacing: '-0.065rem',
        lineHeight: '1.2',
        ...sx,
      }}
      {...props}
    />
  );
});

interface ModalContentProps extends PropsWithoutRef<BoxProps> {
  color?: string;
}

export const ModalContent: React.FC<ModalContentProps> = ({ color, sx, ...props }) => {
  const ctx = useModalContext();

  return (
    <Box
      as="section"
      sx={{ color: color ?? ctx.variant === 'destructive' ? 'white' : 'inherit', pb: 7, ...sx }}
      {...props}
    />
  );
};

interface ModalActionsProps {
  justifyContent?: string;
  alignItems?: string;
}

export const ModalActions = styled(Flex)<ModalActionsProps>`
  justify-content: ${(props: ModalActionsProps) => props.justifyContent || 'flex-start'};
  align-items: ${(props: ModalActionsProps) => props.alignItems || 'center'};
`;

export const ModalButton = React.forwardRef(({ sx, ...props }: ButtonProps, ref: any) => {
  const ctx = useModalContext();

  return (
    <Button
      ref={ref}
      variant="primaryInverted"
      sx={{
        px: ctx.variant === 'destructive' ? '2.25rem' : 4,
        py: 3,
        border: '2px solid #dddddd',
        fontSize: 2,
        fontWeight: 'semibold',
        letterSpacing: '-0.015rem',
        marginRight: '1rem',
        ...sx,
      }}
      {...props}
    />
  );
});

interface ModalTextButtonProps extends ButtonProps {
  children?: React.ReactNode;
  type: string;
  onClick: () => void;
}

export const ModalTextButton = React.forwardRef(
  ({ sx, ...props }: ModalTextButtonProps, ref: any) => {
    const ctx = useModalContext();

    const textColor = ctx.variant === 'destructive' ? 'white' : 'accent';

    return (
      <Button
        sx={{
          color: textColor,
          cursor: 'pointer',
          px: 4,
          py: 3,
          textAlign: 'center',
          background: 'none',
          fontSize: 2,
          fontWeight: 'semibold',
          letterSpacing: '-0.015rem',
          border: '2px solid transparent',
          transition: 'all 200ms ease-in-out',
          ':hover': {
            background: 'rgba(204, 203, 203, 0.2)',
            border: '2px solid rgba(204, 203, 203, 0.5)',
            color: textColor,
          },
          ...sx,
        }}
        ref={ref}
        {...props}
      />
    );
  }
);

export const ModalKeyInformation = styled(ModalContent)`
  background: ${(props) => props.theme.backgrounds.header.background};
  background-size: ${(props) => props.theme.backgrounds.header.backgroundSize};
  padding: 1rem;
  color: #ffffff;
  border: 2px solid #ffffff;
`;

export type ModalVariant = 'neutral' | 'destructive';

interface ModalProps extends DialogProps {
  variant?: ModalVariant;
  style?: any;
  contentWrapperStyles?: any;
}

const defaultStyles = {
  position: 'relative',
  maxWidth: '750px',
  padding: 0,
};

const contentWrapperDefaultStyles: any = {
  position: 'relative',
  outline: '10px solid rgba(255, 255, 255, 0.4)',
};

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onDismiss,
  variant = 'neutral',
  style,
  contentWrapperStyles,
  children,
  ...props
}) => {
  const theme = useTheme();

  const variantStyle = (() => {
    switch (variant) {
      case 'destructive':
        return {
          background: 'linear-gradient(135deg, rgb(155, 28, 28) 0%, rgb(211,47,47) 100%)',
          outline: 'rgba(255, 255, 255, 0.6) solid 10px',
        };
      case 'neutral':
        return {
          backgroundColor: theme.colors.white,
        };
      default:
        return {};
    }
  })();

  const contentAdditionalProps = (() => {
    switch (variant) {
      case 'destructive':
        return { 'data-dialog-content-destructive': '1' };
      case 'neutral':
        return { 'data-dialog-content-neutral': '1' };
      default:
        return {};
    }
  })();

  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0, transform: `translate3d(0px, -100px, 0px)` },
    enter: { opacity: 1, transform: `translate3d(0px, 0px, 0px)` },
    leave: { opacity: 0, transform: `translate3d(0px, -100px, 0px)` },
  });

  const contextValue = useMemo(() => {
    return {
      variant,
    };
  }, [variant]);

  return (
    <ModalContext.Provider value={contextValue}>
      {transitions.map(
        ({ item, key, props: animationStyles }) =>
          item && (
            <AnimatedDialogOverlay
              as="div"
              key={key}
              style={{ opacity: animationStyles.opacity }}
              onDismiss={onDismiss}
            >
              <AnimatedDialogContent
                {...props}
                {...contentAdditionalProps}
                style={{
                  ...defaultStyles,
                  ...style,
                  ...animationStyles,
                }}
              >
                <Box
                  style={{
                    ...contentWrapperDefaultStyles,
                    ...variantStyle,
                    ...contentWrapperStyles,
                  }}
                >
                  {children}
                </Box>
              </AnimatedDialogContent>
            </AnimatedDialogOverlay>
          )
      )}
    </ModalContext.Provider>
  );
};
