import { client } from './client';
import { ApiCompanyBillingInformation, ApiCompanySubscription } from './company-subscription';

export type ApiKey = string;

export type ApiRefreshToken = string;

export enum AuthorityLevel {
  User = 1,
  Owner = 2,
}

export interface AuthorityLevelOption {
  label: string;
  value: AuthorityLevel;
}

export const AuthorityLevelOptions: AuthorityLevelOption[] = [
  { label: 'Owner', value: AuthorityLevel.Owner },
  { label: 'User', value: AuthorityLevel.User },
];

export const getAuthorityLevelOption = (authorityLevel: AuthorityLevel) => {
  return AuthorityLevelOptions.find((option) => option.value === authorityLevel);
};

export type UserRegistrationStatus = 'awaiting_company' | 'awaiting_subscription' | 'complete';

export interface ApiUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  displayName: string;
  isCompanyOwner: boolean;
  isTeamOwner: boolean;
  isAdmin: boolean;
  company: ApiCompany;
  teamId: number;
  registrationStatus: UserRegistrationStatus;
}

export type CompanySubscriptionStatus =
  | 'not_created'
  | 'in_trial'
  | 'active'
  | 'cancelling'
  | 'cancelled'
  | 'pausing'
  | 'paused';

export const companySubscriptionStatusNames: { [key in CompanySubscriptionStatus]: string } = {
  not_created: 'Not created',
  in_trial: 'In trial',
  active: 'Active',
  cancelling: 'Cancelling',
  cancelled: 'Cancelled',
  pausing: 'Pausing',
  paused: 'Paused',
};

export interface ApiCompany {
  id: number;
  companyName: string;
  licencesInUse: number;
  subscriptionStatus: CompanySubscriptionStatus;
  subscriptionCurrentPeriodEnds: string;
  companyBillingInformation?: ApiCompanyBillingInformation;
  companySubscription: ApiCompanySubscription;
  businessType: BusinessType;
  noEditCompleteAds: boolean;
}

export const isUKCountry = (company: ApiCompany): boolean => {
  // If the company has given us billing info then use that otherwise base it on their browser timezone

  if (company.companyBillingInformation?.address.country) {
    return company.companyBillingInformation?.address.country === 'GB';
  }

  return Intl.DateTimeFormat().resolvedOptions().timeZone === 'Europe/London';
};

export interface AuthCredentials {
  email: string;
  password: string;
}

export enum BusinessType {
  RecruitmentAgency = 1,
  DirectEmployer = 2,
}

export const businessTypes = [
  { id: BusinessType.RecruitmentAgency, name: 'Recruitment Agency' },
  { id: BusinessType.DirectEmployer, name: 'Direct Employer' },
];

export interface RegistrationMetadata {
  timeZone: string;
  source: string;
  variant: string;
  deviceType: 'desktop' | 'tablet' | 'mobile';
}

export interface RegistrationInformation {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  metadata: RegistrationMetadata;
  recaptcha: string;
  companyInformation?: {
    companyName: string;
    businessType: number;
    employeeCount?: number;
    usageGoal?: number;
  };
  teamInvites?: {
    firstName: string;
    email: string;
  }[];
}

export interface ResetPasswordInformation {
  email: string;
  password: string;
  token: string;
}

export interface InviteRegistrationInformation {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  token: string;
  metadata: RegistrationMetadata;
}

export interface RegistrationSuccess {
  apiKey: ApiKey;
  refreshToken: ApiRefreshToken;
  user: ApiUser;
}

export interface LoginSuccess {
  apiKey: ApiKey;
  refreshToken: ApiRefreshToken;
  user: ApiUser;
}

export interface ApiTokenRefreshSuccess {
  apiKey: ApiKey;
  refreshToken: ApiRefreshToken;
}

export interface InviteRegistrationSuccess {
  apiKey: ApiKey;
  refreshToken: ApiRefreshToken;
  user: ApiUser;
}

const register = async (info: RegistrationInformation): Promise<RegistrationSuccess> => {
  const requestParams: any = {
    firstName: info.firstName,
    lastName: info.lastName,
    email: info.email,
    plainPassword: info.password,
    metadata: info.metadata,
  };

  if (info.companyInformation) {
    requestParams.companyInformation = info.companyInformation;
  }

  if (info.teamInvites) {
    requestParams.teamInvites = info.teamInvites;
  }

  const response = await client.post('/auth/register', requestParams, {
    headers: { 'g-recaptcha-response': info.recaptcha },
  });

  return {
    apiKey: response.data.apiKey,
    refreshToken: response.data.refreshToken,
    user: response.data.user,
  };
};

const login = async (credentials: AuthCredentials): Promise<LoginSuccess> => {
  const response = await client.post('/auth/login', credentials);

  return {
    apiKey: response.data.apiKey,
    refreshToken: response.data.refreshToken,
    user: response.data.user,
  };
};

const refresh = async (refreshToken: ApiRefreshToken): Promise<ApiTokenRefreshSuccess> => {
  const response = await client.post('/auth/refresh', { refreshToken });

  return {
    apiKey: response.data.apiKey,
    refreshToken: response.data.refreshToken,
  };
};

const fetchUser = async (): Promise<{ user: ApiUser }> => {
  const response = await client.get('/user/me');

  return {
    user: response.data.user,
  };
};

interface UpdateUserValues {
  email: string;
  firstName: string;
  lastName: string;
}

const updateUser = async (values: UpdateUserValues): Promise<{ user: ApiUser }> => {
  const response = await client.post('/user/me', values);

  return {
    user: response.data.user,
  };
};

const registerFromInvite = async (
  info: InviteRegistrationInformation
): Promise<InviteRegistrationSuccess> => {
  const response = await client.post('/auth/invite-register', {
    firstName: info.firstName,
    lastName: info.lastName,
    email: info.email,
    plainPassword: info.password,
    token: info.token,
    metadata: info.metadata,
  });

  return {
    apiKey: response.data.apiKey,
    refreshToken: response.data.refreshToken,
    user: response.data.user,
  };
};

const forgotPassword = async (email: string): Promise<{ passwordResetEmailSent: boolean }> => {
  const response = await client.post('/auth/forgot-password', {
    email,
  });

  return {
    passwordResetEmailSent: response.data.passwordResetEmailSent,
  };
};

const resetPassword = async (
  info: ResetPasswordInformation
): Promise<{ passwordReset: boolean }> => {
  const response = await client.post('/auth/reset-password', info);

  return {
    passwordReset: response.data.passwordReset,
  };
};

interface ChangePasswordValues {
  password: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

const changePassword = async (
  values: ChangePasswordValues
): Promise<{ passwordChanged: boolean }> => {
  const response = await client.post('/user/me/change-password', {
    password: values.password,
    newPassword: {
      first: values.newPassword,
      second: values.newPasswordConfirmation,
    },
  });

  return {
    passwordChanged: response.data.passwordChanged,
  };
};

export const authApi = {
  register,
  login,
  refresh,
  fetchUser,
  updateUser,
  registerFromInvite,
  forgotPassword,
  resetPassword,
  changePassword,
};
