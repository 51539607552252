import { useMachine } from '@xstate/react';
import { useEffect } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';
import { assign, createMachine } from 'xstate';

import { Box } from '../../components/common/Box';
import { Tooltip } from '../../components/common/Tooltip';
import { Star } from '../../components/Icon';
import { usePrevious } from '../../hooks/usePrevious';
import { useTheme } from '../../hooks/useTheme';

interface FavouriteMachineContext {
  isFavourited: boolean;
}

type FavouriteMachineEvent =
  | {
      type: 'FAVOURITE';
    }
  | {
      type: 'CONTROLLED_SET_FAVOURITED';
    }
  | {
      type: 'UNFAVOURITE';
    }
  | {
      type: 'CONTROLLED_SET_UNFAVOURITED';
    };

const favouriteMachine = createMachine<FavouriteMachineContext, FavouriteMachineEvent>(
  {
    id: 'favourite',
    initial: 'unknown',
    context: {
      isFavourited: false,
    },
    states: {
      unknown: {
        always: [
          { target: 'favourited', cond: (context) => context && context.isFavourited },
          { target: 'unfavourited', cond: (context) => context && !context.isFavourited },
        ],
      },
      unfavourited: {
        on: {
          FAVOURITE: {
            target: 'favouriting',
            cond: 'canFavourite',
          },
          CONTROLLED_SET_FAVOURITED: {
            target: 'favourited',
            actions: assign({
              isFavourited: (ctx) => true,
            }),
          },
        },
      },
      favourited: {
        on: {
          UNFAVOURITE: {
            target: 'unfavouriting',
            cond: 'canUnfavourite',
          },
          CONTROLLED_SET_UNFAVOURITED: {
            target: 'unfavourited',
            actions: assign({
              isFavourited: (ctx) => false,
            }),
          },
        },
      },
      favouriting: {
        invoke: {
          src: 'favourite',
          onDone: {
            target: 'favourited',
            actions: assign({
              isFavourited: (ctx) => true,
            }),
          },
          onError: {
            target: 'failure',
          },
        },
        on: {
          UNFAVOURITE: 'unfavouriting',
        },
      },
      unfavouriting: {
        invoke: {
          src: 'unfavourite',
          onDone: {
            target: 'unfavourited',
            actions: assign({
              isFavourited: (ctx) => false,
            }),
          },
          onError: {
            target: 'failure',
          },
        },
        on: {
          FAVOURITE: 'favouriting',
        },
      },
      failure: {
        entry: ['notifyError'],
        always: [
          { target: 'favourited', cond: (context) => context && context.isFavourited },
          { target: 'unfavourited', cond: (context) => context && !context.isFavourited },
        ],
      },
    },
  },
  {
    guards: {
      canFavourite: (context: FavouriteMachineContext) => {
        return !context.isFavourited;
      },
      canUnfavourite: (context: FavouriteMachineContext) => {
        return context.isFavourited;
      },
    },
  }
);

interface FavouriteVariationButtonProps {
  initialValue: boolean;
  onFavourite: (variationId: number) => Promise<void>;
  onUnfavourite: () => Promise<void>;
}

export const FavouriteVariationButton: React.FC<FavouriteVariationButtonProps> = ({
  initialValue,
  onFavourite,
  onUnfavourite,
  ...props
}) => {
  const theme = useTheme();
  const [current, send] = useMachine(favouriteMachine, {
    context: {
      isFavourited: initialValue,
    },
    actions: {
      notifyError: (context) => {
        toast.error(
          `Encountered an issue whilst ${context.isFavourited ? 'unfavouriting' : 'favouriting'}`
        );
      },
    },
    services: {
      favourite: async (ctx: FavouriteMachineContext, event: any) => {
        onFavourite(event.variationId);
      },
      unfavourite: onUnfavourite,
    },
  });

  const previousInitialValue = usePrevious(initialValue);

  // Not sure about this. The button itself can be used in isolation as a uncontrolled component.
  // However, as we only want one favourite per advert we need to toggle this value if another variation is favourited
  useEffect(() => {
    if (typeof previousInitialValue === 'undefined' || previousInitialValue === initialValue) {
      return;
    }

    send(initialValue ? 'CONTROLLED_SET_FAVOURITED' : 'CONTROLLED_SET_UNFAVOURITED');
  }, [initialValue, previousInitialValue, send]);

  const { isFavourited } = current.context;

  return (
    <Box sx={{ position: 'relative' }}>
      <Tooltip label={isFavourited ? 'Unfavourite this version' : 'Favourite this version'}>
        <div>
          <Star
            {...props}
            width="20"
            height="20"
            outlineColour={isFavourited ? theme.colors.accent : undefined}
            backgroundColour={isFavourited ? theme.colors.accent : 'transparent'}
            hoverColour={isFavourited ? theme.colors.accent : theme.colors.blue[3]}
            onClick={(e: any) => {
              e.preventDefault();

              if (current.matches('favourited') || current.matches('favouriting')) {
                send('UNFAVOURITE');
                return;
              }

              if (current.matches('unfavourited') || current.matches('unfavouriting')) {
                send('FAVOURITE');
              }
            }}
          />
        </div>
      </Tooltip>
    </Box>
  );
};
