import styled from '@emotion/styled/macro';
import { useState } from 'react';
import * as React from 'react';

import { Box } from '../../../components/common/Box';
import { Button } from '../../../components/common/Button';
import { Flex } from '../../../components/common/Flex';
import { Label } from '../../../components/common/form';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTextButton,
  ModalTitle,
} from '../../../components/common/Modal';
import { Text } from '../../../components/common/Text';
import { useUserPreferences } from '../../../context/UserPreferencesContext';

export interface AdvertFormProps {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}

const Form = styled.form`
  font-size: 1.125rem;
`;

export const AdvertForm: React.FC<AdvertFormProps> = (props) => (
  <Form autoComplete="off" onSubmit={props.onSubmit}>
    {props.children}
  </Form>
);

const AdvertFormQuestionsContainerChildren = styled.div`
  > div {
    &:not(:first-of-type) {
      border-top: 1px solid #cccccc;
    }
  }
`;

export const AdvertFormQuestionsContainer: React.FC<{ outerSx?: any; innerSx?: any }> = (props) => (
  <Flex sx={{ justifyContent: 'center', width: '100%', px: 5, ...props.outerSx }}>
    <Flex
      sx={{
        flexDirection: 'column',
        maxWidth: '800px',
        width: '100%',
        ...props.innerSx,
      }}
    >
      <AdvertFormQuestionsContainerChildren>{props.children}</AdvertFormQuestionsContainerChildren>
    </Flex>
  </Flex>
);

interface AdvertFormButtonContainerProps {
  pinToBottom: boolean;
}

const AdvertFormButtonContainer: React.FC<AdvertFormButtonContainerProps> = (props) => {
  if (props.pinToBottom) {
    return (
      <Box sx={{ position: 'sticky', bottom: 0 }}>
        <Flex
          sx={{
            bg: 'white',
            justifyContent: 'center',
            width: '100%',
            borderTop: '1px solid #cccccc',
          }}
        >
          <Flex
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'center',
              flexDirection: 'row',
              maxWidth: '800px',
              width: '100%',
              py: '0.8rem',
            }}
          >
            {props.children}
          </Flex>
        </Flex>
      </Box>
    );
  }

  return (
    <Flex sx={{ width: '100%', justifyContent: 'center' }}>
      <Flex
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexDirection: 'row',
          maxWidth: '800px',
          width: '100%',
          pb: '9',
        }}
      >
        {props.children}
      </Flex>
    </Flex>
  );
};

interface AdvertOverwriteWarningModalProps {
  isOpen: boolean;
  onConfirm: (options: { dontShowAgain: boolean }) => void;
  onCancel: (options: { dontShowAgain: boolean }) => void;
}

const AdvertOverwriteWarningModal: React.FC<AdvertOverwriteWarningModalProps> = (props) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  return (
    <Modal
    aria-label="Overwrite variations confirmation"
      data-testid="confirm-advert-overwrite"
      variant="destructive"
      isOpen={props.isOpen}
      onDismiss={() => props.onCancel({ dontShowAgain })}
    >
      <ModalClose type="button" onClick={() => props.onCancel({ dontShowAgain })} />
      <ModalContainer>
        <ModalTitle sx={{ pb: 4 }}>Save and go to advert</ModalTitle>

        <ModalContent sx={{ pb: 0 }}>
          <Text sx={{ mb: 5, pb: 2, color: '#e8e8e8' }}>
            Are you sure? If you proceed you will overwrite your old adverts and any manual amends
            you previously made.
          </Text>
        </ModalContent>

        <ModalActions>
          <ModalButton mr={3} onClick={() => props.onConfirm({ dontShowAgain })}>
            Yes I'm sure
          </ModalButton>

          <ModalTextButton type="button" onClick={() => props.onCancel({ dontShowAgain })}>
            Cancel
          </ModalTextButton>
        </ModalActions>

        <Box mt="5">
          <Label sx={{ color: 'white', fontSize: 2 }}>
            <input
              type="checkbox"
              value={dontShowAgain as any}
              onChange={() => setDontShowAgain(!dontShowAgain)}
            />
            Don't show me again
          </Label>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

interface AdvertFormButtonsProps {
  isFormComplete: boolean;
  isLastStep: boolean;
  isSubmitting: boolean;
  hasErrors: boolean;
  onSubmit: (shouldRedirect: boolean) => void;
}

export const AdvertFormButtons: React.FC<AdvertFormButtonsProps> = (props) => {
  const [userPreferences, updateUserPreferences] = useUserPreferences();
  const [showOverwriteAdvertWarning, setShowOverwriteAdvertWarning] = useState(false);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (props.hasErrors) {
      // Submit even though we have errors to force the errors to show
      props.onSubmit(true);

      return;
    }

    if (userPreferences.showOverwriteAdvertWarning) {
      setShowOverwriteAdvertWarning(true);
      return;
    }

    props.onSubmit(true);
  };

  const nextStep = {
    variant: props.isLastStep ? 'complete' : 'nextStep',
    text: props.isLastStep ? 'Build your advert' : 'Next Step',
  };

  return (
    <>
      <AdvertFormButtonContainer pinToBottom={props.isFormComplete}>
        {Boolean(props.isFormComplete && !props.isLastStep) && (
          <Box sx={{ mr: 3 }}>
            <Button
              variant="complete"
              type="submit"
              disabled={props.isSubmitting}
              data-testid="save-and-redirect"
              sx={{ letterSpacing: '-0.02rem' }}
              onClick={onSubmit}
            >
              Save and go to advert{' '}
              <Box as="span" sx={{ ml: 1 }}>
                &rarr;
              </Box>
            </Button>
          </Box>
        )}

        <Button
          variant={nextStep.variant}
          type="submit"
          disabled={props.isSubmitting}
          sx={{ letterSpacing: '-0.02rem' }}
          data-testid="save-advert"
        >
          {nextStep.text}{' '}
          <Box as="span" sx={{ ml: 1 }}>
            &rarr;
          </Box>
        </Button>
      </AdvertFormButtonContainer>

      <AdvertOverwriteWarningModal
        isOpen={showOverwriteAdvertWarning}
        onConfirm={(options: { dontShowAgain: boolean }) => {
          setShowOverwriteAdvertWarning(false);
          props.onSubmit(true);
          updateUserPreferences({
            ...userPreferences,
            showOverwriteAdvertWarning: !options.dontShowAgain,
          });
        }}
        onCancel={(options: { dontShowAgain: boolean }) => {
          setShowOverwriteAdvertWarning(false);
          updateUserPreferences({
            ...userPreferences,
            showOverwriteAdvertWarning: !options.dontShowAgain,
          });
        }}
      />
    </>
  );
};
