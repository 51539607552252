import styled from '@emotion/styled/macro';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import { Link as RRLink, Match } from '@reach/router';
import { useCallback } from 'react';
import * as React from 'react';
import { useQueryClient } from 'react-query';

import { advertApi } from '../api/advert';
import { companyApi } from '../api/company';
import { Box } from '../components/common/Box';
import { Button } from '../components/common/Button';
import { Flex } from '../components/common/Flex';
import { Text } from '../components/common/Text';
import { useAuth } from '../context/AuthContext';
import { useCompany } from '../context/CompanyContext';
import { useUser } from '../context/UserContext';
import { useWindowSize } from '../context/WindowSizeContext';
import { useTheme } from '../hooks/useTheme';
import { useToggle } from '../hooks/useToggle';
import { CaretDown, CaretUp } from './Icon';
import { ImpersonateUserModal } from './Modal/ImpersonateUserModal';
import { AdminOnly } from './Permissions/AdminOnly';
import { CompanyOwnerOnly } from './Permissions/CompanyOwnerOnly';
import { ProfileModal } from './ProfileModal';
import { SubscriptionCancelledBanner } from './SubscriptionCancelledBanner';
import { TutorialsSubMenu } from './TutorialsSubMenu';

export const AppBar: React.FC = () => {
  const theme = useTheme();
  const windowSize = useWindowSize();
  const { logout } = useAuth();
  const user = useUser();
  const { company } = useCompany();
  const queryClient = useQueryClient();

  const {
    on: impersonateUserModalVisible,
    setOn: showImpersonateUserModal,
    setOff: dismissImpersonateUserModal,
  } = useToggle();

  const {
    on: profileModalVisible,
    setOn: showProfileModal,
    setOff: dismissProfileModal,
  } = useToggle();

  const prefetchDashboardData = advertApi.usePrefetchAdvertCountsFn();

  const prefetchSettingsData = useCallback(async () => {
    if (!company) {
      return;
    }

    await Promise.all([
      queryClient.prefetchQuery(['companyMembers', company.id], () => {
        return companyApi.fetchCompanyMembers(company.id);
      }),
      queryClient.prefetchQuery(['companyInvites', company.id], () => {
        return companyApi.fetchCompanyInvites(company.id);
      }),
    ]);
  }, [company, queryClient]);

  return (
    <React.Fragment>
      {company?.subscriptionStatus === 'cancelling' && <SubscriptionCancelledBanner />}

      <StyledHeader>
        <Flex sx={{ justifyContent: 'space-between', width: '100%' }}>
          <Flex sx={{ alignItems: 'center' }}>
            <Box mr="5">
              <LogoLink to="/dashboard">{theme.components.logo.light}</LogoLink>
            </Box>
            <Nav>
              <Link to="/dashboard" onMouseEnter={prefetchDashboardData}>
                Dashboard
              </Link>
              <CompanyOwnerOnly>
                <Link to="/settings" onMouseEnter={prefetchSettingsData}>
                  Settings
                </Link>
              </CompanyOwnerOnly>
              <AdminOnly>
                <Link to="/admin">Admin</Link>
              </AdminOnly>
              <Link href={process.env.REACT_APP_KNOWLEDGE_BASE_URL} target="_blank">
                Learn
              </Link>

              <TutorialsSubMenu />
            </Nav>
          </Flex>
          {windowSize.width <= 550 ? null : (
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-end',
                position: 'relative',
              }}
            >
              <Menu>
                {({ isOpen }: { isOpen: boolean }) => (
                  <React.Fragment>
                    <Button
                      as={MenuButton}
                      variant="text"
                      data-testid="header-dropdown-menu"
                      sx={{
                        py: 1,
                        '&:hover, &:focus': {
                          textDecoration: 'none',
                          background: 'transparent',
                        },
                      }}
                    >
                      {user && (
                        <Text as="span" color="white" mr="2">
                          {user.email}
                        </Text>
                      )}
                      <Text as="span" color="white">
                        {isOpen ? <CaretUp /> : <CaretDown />}
                      </Text>
                    </Button>

                    <MenuList>
                      <MenuItem data-testid="profile" onSelect={showProfileModal}>
                        Profile
                      </MenuItem>
                      <MenuItem data-testid="logout" onSelect={logout}>
                        Logout
                      </MenuItem>
                      <AdminOnly>
                        <MenuItem data-testid="impersonate" onSelect={showImpersonateUserModal}>
                          Impersonate User
                        </MenuItem>
                      </AdminOnly>
                    </MenuList>
                  </React.Fragment>
                )}
              </Menu>
            </Flex>
          )}
        </Flex>
      </StyledHeader>

      <ProfileModal isOpen={profileModalVisible} onClose={dismissProfileModal} />

      <AdminOnly>
        <ImpersonateUserModal
          isOpen={impersonateUserModalVisible}
          onClose={dismissImpersonateUserModal}
        />
      </AdminOnly>
    </React.Fragment>
  );
};

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  height: 75px;
  background: ${(props) => props.theme.backgrounds.header.background};
  background-size: ${(props) => props.theme.backgrounds.header.backgroundSize};
  padding: 0 2rem;
`;

const Nav = styled.nav``;

const LogoLink = styled(RRLink)`
  margin-right: ${(props) => props.theme.space[5]};
`;

const Link = ({ ref, to, href, ...props }: any) => {
  if (!to && !href) {
    throw new Error("PageHeaderTab requires either a 'to' or 'href' property");
  }

  if (href) {
    return (
      <AppBarLinkContainer>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content  */}
        <a href={href} {...props} />
      </AppBarLinkContainer>
    );
  }

  return (
    <Match path={`${to}/*`}>
      {({ match }) => (
        <AppBarLinkContainer active={Boolean(match)}>
          <RRLink to={to} {...props} />
        </AppBarLinkContainer>
      )}
    </Match>
  );
};

export const AppBarLinkContainer = styled(({ active = false, ...rest }) => <Box {...rest} />)`
  display: inline;

  a,
  span {
    position: relative;
    margin-right: ${(props) => props.theme.space[5]};
    padding-bottom: ${(props) => props.theme.space[2]};
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    color: ${(props: any) =>
      props.active ? props.theme.colors.white : props.theme.colors.headerLink};
    font-size: ${(props) => props.theme.fontSizes[1]};
    font-weight: 500;
    transition: color 250ms ease-in;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      height: 3px;
      width: ${(props: any) => (props.active ? '100%' : '80%')};
      opacity: ${(props: any) => (props.active ? '1' : '0')};
      background-color: ${(props: any) =>
        props.active ? props.theme.colors.blue[5] : props.theme.colors.white};
      transition: transform 250ms, width 250ms, opacity 250ms;
    }
    &:hover,
    :focus {
      color: ${(props: any) => props.theme.colors.white};

      &:after {
        width: 100%;
        opacity: 1;
      }
    }
  }
`;
