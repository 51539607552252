import styled from '@emotion/styled/macro';
import * as React from 'react';

import { AppBar } from './AppBar';
import { Flex } from './common/Flex';
import { LoadingSpinner } from './LoadingSpinner';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const FullPageSpinner: React.FC = ({ children }) => (
  <Main>
    <AppBar />

    {children || (
      <Flex
        data-testid="full-page-spinner"
        sx={{
          flex: '1',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingSpinner />
      </Flex>
    )}
  </Main>
);
