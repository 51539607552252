import { PropsWithoutRef } from 'react';
import * as React from 'react';

import { Box, BoxProps } from './Box';

export type HeadingProps = Partial<
  PropsWithoutRef<BoxProps & Omit<React.HTMLProps<HTMLHeadingElement>, 'as' | 'children'>>
>;

export const Heading = React.forwardRef<any, HeadingProps>((props, ref) => (
  <Box ref={ref} as="h2" variant="heading" {...props} __themeKey="text" />
));
