import { RouteComponentProps } from '@reach/router';
import * as React from 'react';

import { useAuth } from '../../context/AuthContext';

export const Logout: React.FC<RouteComponentProps> = () => {
  const { logout } = useAuth();

  logout();

  return null;
};
