import { TooltipProps } from '@reach/tooltip';
import * as React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

import { Tooltip } from './common/Tooltip';

interface ColouredTooltipProps extends TooltipProps {
  label: React.ReactNode;
  isVisible?: boolean;
  sx?: ThemeUIStyleObject;
}

export const ColouredTooltip: React.FC<ColouredTooltipProps> = ({
  sx,
  isVisible = true,
  children,
  ...rest
}) => {
  if (!isVisible) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <Tooltip
      sx={{
        color: 'white',
        letterSpacing: '-0.01rem',
        fontWeight: 500,
        p: 3,
        border: '2px solid #ffffff',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};
