import styled from '@emotion/styled/macro';
import { Link as RRLink, RouteComponentProps } from '@reach/router';
import { ErrorMessage, Formik } from 'formik';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Box } from '../../components/common/Box';
import { Button } from '../../components/common/Button';
import { Flex } from '../../components/common/Flex';
import { TextField } from '../../components/common/form';
import { Heading } from '../../components/common/Heading';
import { Image } from '../../components/common/Image';
import { LinkedInOAuthSignIn } from '../../components/LinkedInOAuthSignIn';
import { useAuth } from '../../context/AuthContext';
import { FlagGuard } from '../../hooks/useFlag';
import { useTheme } from '../../hooks/useTheme';
import { ApiFormError } from '../../utils/errors/ApiFormError';
import { EmailSchema, PasswordSchema } from '../../utils/form-validation/user-details';
import logger from '../../utils/logger';
import { AuthFormFooter } from './AuthFormFooter';
import { LeftContainer, RightContainer, SplitPageContainer } from './SplitPage';
import { Summary } from './Summary';

interface LoginProps extends RouteComponentProps {}

const LoginFormSchema = Yup.object().shape({
  email: EmailSchema,
  password: PasswordSchema,
});

export const Login: React.FC<LoginProps> = () => {
  const { login } = useAuth();
  const theme = useTheme();

  return (
    <React.Fragment>
      <Helmet title="Login" />
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={LoginFormSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await login(values);
          } catch (e) {
            if (e instanceof ApiFormError) {
              setErrors(e.errors);
            } else if (e.response && e.response.status === 401) {
              setErrors({ password: 'Invalid credentials' });
            } else {
              logger.logError(e);
              toast.error('Something went wrong whilst logging in');
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <SplitPageContainer>
            <LeftContainer>
              <Flex
                sx={{
                  height: '100%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <FormContainer onSubmit={handleSubmit}>
                  <Box pb={5}>
                    <a href="https://adbuilder.io">
                      <Image
                        src={theme.assets.logo.dark}
                        alt="AdBuilder logo"
                        sx={{ width: '15rem' }}
                      />
                    </a>
                  </Box>

                  <Heading
                    as="h1"
                    sx={{
                      fontSize: 7,
                      letterSpacing: '-0.075rem',
                      pb: 5,
                      color: 'text',
                      fontWeight: 'semibold',
                      minWidth: '4rem',
                    }}
                  >
                    Sign In
                  </Heading>

                  <Flex sx={{ flexDirection: 'row', paddingBottom: 5 }}>
                    <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                      <TextField
                        type="email"
                        name="email"
                        placeholder="Email address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <ErrorMessage name="email" />
                    </Flex>
                  </Flex>

                  <Flex sx={{ flexDirection: 'row', paddingBottom: 5 }}>
                    <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                      <TextField
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />

                      <ErrorMessage name="password" />
                    </Flex>
                  </Flex>
                  <Flex
                    sx={{
                      flexDirection: 'row',
                      paddingBottom: 5,
                      justifyContent: 'space-between',
                      '@media screen and (max-width: 475px)': {
                        flexDirection: 'column',
                      },
                    }}
                  >
                    <Flex
                      sx={{
                        '@media screen and (max-width: 475px)': {
                          justifyContent: 'center',
                          paddingBottom: 4,
                        },
                      }}
                    >
                      <Button
                        variant="submit"
                        type="submit"
                        disabled={isSubmitting}
                        data-testid="sign-in"
                        sx={{
                          fontWeight: 500,
                          letterSpacing: '-0.01rem',
                          fontSize: '1.1rem',
                        }}
                      >
                        Sign In &rarr;
                      </Button>
                    </Flex>

                    <Flex
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        '@media screen and (max-width: 475px)': {
                          justifyContent: 'center',
                        },
                      }}
                    >
                      <ForgotPasswordLink
                        to={`/forgot-password?e=${encodeURIComponent(values.email)}`}
                      >
                        <Box
                          sx={{
                            borderBottom: '2px solid rgb(51, 51, 51)',
                            ':hover': {
                              borderBottom: (theme: TSThemeFixMe) => {
                                return `2px solid ${theme.colors.accent}`;
                              },
                            },
                          }}
                        >
                          Forgot your password?
                        </Box>
                      </ForgotPasswordLink>
                    </Flex>
                  </Flex>

                  <FlagGuard flag="linkedin_oauth_enabled" defaultVal={false}>
                    {({ value: isActive }) => {
                      return isActive ? <LinkedInOAuthSignIn /> : null;
                    }}
                  </FlagGuard>

                  <AuthFormFooter email={values.email} />
                </FormContainer>
              </Flex>
            </LeftContainer>
            <RightContainer>
              <Summary />
            </RightContainer>
          </SplitPageContainer>
        )}
      </Formik>
    </React.Fragment>
  );
};

const FormContainer = styled.form`
  padding-right: 3rem;
  padding-left: 3rem;

  @media (min-width: 1500px) {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  @media (min-width: 1700px) {
    padding-right: 6rem;
    padding-left: 6rem;
  }
`;

const Link = styled(RRLink)`
  color: ${(props) => props.theme.colors.grey[9]};

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }
`;

const ForgotPasswordLink = styled(Link)`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  letter-spacing: -0.01rem;
  text-decoration: none;
  padding-right: 0;

  @media (min-width: 1500px) {
    padding-right: 3rem;
  }

  @media (min-width: 1700px) {
    padding-right: 4.5rem;
  }
`;
