import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';

import { analytics } from '../../../analytics/analytics';
import { ApiTeam, teamApi, TeamData } from '../../../api/team';
import { Box } from '../../../components/common/Box';
import { Button } from '../../../components/common/Button';
import {
  ErrorMessage as ThemedErrorMessage,
  Label,
  TextField,
} from '../../../components/common/form';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTextButton,
  ModalTitle,
} from '../../../components/common/Modal';
import { useToggle } from '../../../hooks/useToggle';
import { ApiFormError } from '../../../utils/errors/ApiFormError';
import logger from '../../../utils/logger';

interface CreateTeamProps {
  onTeamCreated: (data: ApiTeam) => void;
}

export const CreateTeam: React.FC<CreateTeamProps> = (props) => {
  const { on: isOpen, toggle, set } = useToggle();

  const dismissModal = () => set(false);

  return (
    <React.Fragment>
      <Button
        sx={{ fontWeight: 'semibold', fontSize: 2, letterSpacing: '-0.01rem' }}
        onClick={toggle}
      >
        Create new Team
      </Button>

      <Modal
        aria-label="Create team modal"
        isOpen={isOpen}
        data-testid="create-team-modal"
        onDismiss={dismissModal}
      >
        <ModalClose onClick={dismissModal} />
        <ModalContainer>
          <ModalTitle>Team details</ModalTitle>

          <NewTeamForm
            onSubmit={async (data) => {
              const result = await teamApi.createTeam(data);

              analytics.newTeamCreated();

              props.onTeamCreated(result.team);
              dismissModal();
            }}
            onCancel={() => {
              dismissModal();
            }}
          />
        </ModalContainer>
      </Modal>
    </React.Fragment>
  );
};

const createTeamValidationSchema = Yup.object().shape({
  name: Yup.string().max(50, 'Too Long').required(),
});

interface NewTeamFormProps {
  onSubmit: (data: TeamData) => Promise<void>;
  onCancel: () => void;
}

export const NewTeamForm: React.FC<NewTeamFormProps> = (props) => {
  return (
    <Formik
      initialValues={{ name: '' }}
      validationSchema={createTeamValidationSchema}
      onSubmit={async (values, actions) => {
        try {
          await props.onSubmit(values);
        } catch (e) {
          if (e instanceof ApiFormError) {
            actions.setErrors(e.errors);
          } else {
            logger.logError(e);
            actions.setErrors({
              name: 'Something went wrong whilst creating the Team',
            });
          }
          actions.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <ModalContent>
            <Label sx={{ fontSize: '14px', fontWeight: 700 }}>
              Name
              <Field name="name">
                {({ field }: FieldProps) => (
                  <React.Fragment>
                    <TextField
                      id={field.name}
                      type="text"
                      placeholder="Sales, IT, Finance"
                      sx={{ background: 'white', borderColor: 'grey.3' }}
                      {...field}
                    />
                    <Box mt={2}>
                      <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                    </Box>
                  </React.Fragment>
                )}
              </Field>
            </Label>
          </ModalContent>

          <ModalActions>
            <ModalButton type="submit" mr={2} disabled={isSubmitting}>
              Create
            </ModalButton>

            <ModalTextButton type="button" onClick={props.onCancel}>
              Cancel
            </ModalTextButton>
          </ModalActions>
        </Form>
      )}
    </Formik>
  );
};
