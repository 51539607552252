import { ColumnOptions, DateCell } from '.';

export const buildSharedAtColumn = (options: ColumnOptions) => {
  return {
    id: 'sharedAt',
    Header: () => 'Shared',
    accessor: 'sharedAt',
    Cell: ({ value }: { value: Date }) => <DateCell>{value}</DateCell>,
    width: options.width,
  };
};
