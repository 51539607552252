export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export function wrap<T>(val: T): () => T {
  return () => val;
}

export const randomNumberInRange = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const negate = (predicate: any) => {
  if (typeof predicate !== 'function') {
    throw new Error('Function is required');
  }

  return function (...args: any[]) {
    return !predicate.apply(this, args);
  };
};

export const functionalUpdate = (updater: any, old: any) => {
  return typeof updater === 'function' ? updater(old) : updater;
};

export function appendIfNotPresent<T>(arr: T[], item: T) {
  if (!arr.includes(item)) {
    arr.push(item);
  }
}
