import { BusinessType } from '../api/auth';
import logger from '../utils/logger';

export const analytics = {
  pageView: () => {
    trackStandardEvent('PageView');
  },
  registrationComplete: (businessType: BusinessType) => {
    trackStandardEvent('CompleteRegistration');
    trackStandardEvent('StartTrial');

    trackCustomEvent(
      businessType === BusinessType.RecruitmentAgency
        ? 'StartTrialRecruitmentAgency'
        : 'StartTrialDirectEmployer'
    );
  },
  invitationRegistrationComplete: (businessType: BusinessType) => {
    trackCustomEvent(
      businessType === BusinessType.RecruitmentAgency
        ? 'CompleteRegistrationRecruitmentAgency'
        : 'CompleteRegistrationDirectEmployer'
    );
  },
  subscriptionCheckoutStarted: () => {
    trackStandardEvent('InitiateCheckout');
  },
  paymentInformationAdded: () => {
    trackStandardEvent('AddPaymentInfo');
  },
  subscriptionCreated: (businessType: BusinessType, seats: number) => {
    trackStandardEvent('Subscribe');

    trackCustomEvent(
      businessType === BusinessType.RecruitmentAgency
        ? 'SubscriptionCreatedRecruitmentAgency'
        : 'SubscriptionCreatedDirectEmployer',
      { seats }
    );
  },
  contact: () => {
    trackStandardEvent('Contact');
  },
  quickViewModalOpened: (advertId: number) => {
    trackCustomEvent('QuickViewModalOpened', { advertId });
  },
  advertDuplicated: (advertId: number) => {
    trackCustomEvent('DuplicateAdvert', { advertId });
  },
  advertDeleted: (advertId: number) => {
    trackCustomEvent('DeleteAdvert', { advertId });
  },
  advertShared: (advertId: number) => {
    trackCustomEvent('ShareAdvert', { advertId });
  },
  advertFavourited: (advertId: number) => {
    trackCustomEvent('FavouriteAdvert', { advertId });
  },
  advertUnfavourited: (advertId: number) => {
    trackCustomEvent('UnfavouriteAdvert', { advertId });
  },
  dashboardAdvertSearch: (phrase: string, resultCount: number) => {
    trackCustomEvent('DashboardAdvertSearch', { phrase, resultCount });
  },
  dashboardFilterByCompany: () => {
    trackCustomEvent('DashboardFilterByCompany');
  },
  dashboardFilterByCreatedBy: () => {
    trackCustomEvent('DashboardFilterByCreatedBy');
  },
  dashboardFilterByTags: () => {
    trackCustomEvent('DashboardFilterByTags');
  },
  dashboardSort: (field: string) => {
    trackCustomEvent('DashboardSort', { field });
  },
  dashboardPaginationChange: () => {
    trackCustomEvent('DashboardPaginationChange');
  },
  newUserInvited: () => {
    trackCustomEvent('NewUserInvited');
  },
  userRemovedFromCompany: () => {
    trackCustomEvent('RemovedUserFromCompany');
  },
  companyInviteRevoked: () => {
    trackCustomEvent('CompanyInvitedRevoked');
  },
  newTeamCreated: () => {
    trackCustomEvent('NewTeamCreated');
  },
};

type ExtraData = {
  [key: string]: any;
};

const trackStandardEvent = (eventName: string, extraData?: ExtraData) => {
  if (process.env.NODE_ENV === 'production') {
    window.fbq('track', eventName, extraData);
  }

  logger.addAnalyticsBreadcrumb(eventName, extraData);
};

type CustomEventName =
  | 'StartTrialRecruitmentAgency'
  | 'StartTrialDirectEmployer'
  | 'SubscriptionCreatedRecruitmentAgency'
  | 'SubscriptionCreatedDirectEmployer'
  | 'CompleteRegistrationRecruitmentAgency'
  | 'CompleteRegistrationDirectEmployer'
  | 'QuickViewModalOpened'
  | 'DuplicateAdvert'
  | 'DeleteAdvert'
  | 'ShareAdvert'
  | 'FavouriteAdvert'
  | 'UnfavouriteAdvert'
  | 'DashboardAdvertSearch'
  | 'DashboardFilterByCompany'
  | 'DashboardFilterByCreatedBy'
  | 'DashboardFilterByTags'
  | 'DashboardSort'
  | 'DashboardPaginationChange'
  | 'NewUserInvited'
  | 'RemovedUserFromCompany'
  | 'CompanyInvitedRevoked'
  | 'NewTeamCreated';

const trackCustomEvent = (eventName: CustomEventName, extraData?: ExtraData) => {
  if (process.env.NODE_ENV === 'production') {
    window.fbq('trackCustom', eventName, extraData);
  }

  logger.addAnalyticsBreadcrumb(eventName, extraData);
};
