import { Accordion } from '@reach/accordion';
import { Component } from '@reach/component-component';
import { Redirect, RouteComponentProps, useNavigate } from '@reach/router';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ThemeProvider } from '@theme-ui/theme-provider';
import { useMachine } from '@xstate/react';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedNumber, FormattedPlural } from 'react-intl';
import * as Yup from 'yup';

import { analytics } from '../../analytics/analytics';
import { ApiCompany } from '../../api/auth';
import { DEFAULT_CURRENCY, SUPPORTED_CURRENCIES } from '../../api/subscription-plan-pricing';
import { AccordionButton, AccordionItem, AccordionPanel } from '../../components/common/Accordion';
import { BasicSelect } from '../../components/common/BasicSelect';
import { Box } from '../../components/common/Box';
import { Button } from '../../components/common/Button';
import { ExternalLink } from '../../components/common/ExternalLink';
import { Flex } from '../../components/common/Flex';
import {
  ErrorMessage as ThemedErrorMessage,
  Label,
  LabelProps,
  TextField,
} from '../../components/common/form';
import { Grid } from '../../components/common/Grid';
import { Heading } from '../../components/common/Heading';
import { Image } from '../../components/common/Image';
import { Text } from '../../components/common/Text';
import { Tooltip } from '../../components/common/Tooltip';
import { Fade } from '../../components/Headless/Fade';
import { RegistrationWizard } from '../../components/Registration/RegistrationWizardContainer';
import { REGISTRATION_WIZARD_STEPS } from '../../config/registration';
import { useAuth } from '../../context/AuthContext';
import { useCompany } from '../../context/CompanyContext';
import { useUser } from '../../context/UserContext';
import { useWindowSize } from '../../context/WindowSizeContext';
import { UserFacingError } from '../../Error/BaseErrors';
import { useTheme } from '../../hooks/useTheme';
import countries from '../../utils/countries.json';
import {
  AddressLineOneSchema,
  AddressLineTwoSchema,
  CitySchema,
  CountrySchema,
  isEUCountry,
  PostalCodeSchema,
  VATSchema,
} from '../../utils/form-validation/company-details';
import logger from '../../utils/logger';
import { registrationWizardCheckoutFormMachine } from './machines/registrationWizardCheckoutForm.machine';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string);

export const SetupCreateSubscription: React.FC<RouteComponentProps> = () => {
  const theme = useTheme();
  const { company } = useCompany();

  if (!company) {
    logger.logError(
      new Error('The user does not have a company yet. This should not be reachable')
    );

    return <Redirect path="/" from="/" to="/account-setup/company" noThrow />;
  }

  if (company.subscriptionStatus !== 'not_created') {
    logger.logMessage('The user already has a subscription');
    return <Redirect path="/" from="/" to="/dashboard" noThrow />;
  }

  return (
    <React.Fragment>
      <Helmet title="Create subscription" />

      <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
        <Box p={4}>
          <a href="https://adbuilder.io">
            <Image sx={{ width: '12rem' }} src={theme.assets.logo.dark} alt="AdBuilder logo" />
          </a>
        </Box>
      </Box>

      <RegistrationWizard.Container>
        <RegistrationWizard.FreeTrialHeader />
        <RegistrationWizard.StepIndicator activeStepIndex={2} steps={REGISTRATION_WIZARD_STEPS} />
        <RegistrationWizard.Content>
          <CreateSubscriptionForm />
        </RegistrationWizard.Content>
      </RegistrationWizard.Container>
    </React.Fragment>
  );
};

const theme = {
  forms: {
    select: {
      menu: {
        position: 'absolute',
        top: 'calc(100% + 0.5rem)',
        maxHeight: '220px',
        width: '100%',
        left: 0,
      },
    },
  },
};

const CreateSubscriptionForm = () => {
  const navigate = useNavigate();
  const { company, refetchCompany } = useCompany();

  if (!company) {
    logger.logError(
      new Error('The user does not have a company yet. This should not be reachable')
    );

    return <Redirect path="/" from="/" to="/account-setup/company" noThrow />;
  }

  return (
    <ThemeProvider theme={theme as TSThemeFixMe}>
      <Box sx={{ position: 'relative', p: 5 }}>
        <Heading
          sx={{
            textAlign: 'center',
            fontWeight: 500,
            fontSize: [5, 6],
            pb: [2, 1],
            letterSpacing: '-0.05rem',
          }}
        >
          Confirm your account.
        </Heading>
        <Heading
          as="h5"
          sx={{
            textAlign: 'center',
            fontWeight: 400,
            fontSize: 1,
            color: 'rgba(0,0,0,0.65)',
            letterSpacing: '-0.01rem',
          }}
        >
          Try any AdBuilder plan free for 7 days and cancel anytime.
        </Heading>

        <Box sx={{ pb: 7 }} />

        <Elements stripe={stripePromise}>
          <CheckoutForm
            company={company}
            onSubscriptionCreated={async () => {
              await refetchCompany();
              navigate('/dashboard');
            }}
          />
        </Elements>
      </Box>
    </ThemeProvider>
  );
};

const checkoutFormSchema = Yup.object().shape({
  addressLineOne: AddressLineOneSchema,
  addressLineTwo: AddressLineTwoSchema,
  addressCity: CitySchema,
  addressPostalCode: PostalCodeSchema,
  addressCountry: CountrySchema,
  vatNumber: VATSchema,
});

const defaultCountry = countries.find((country) => country.code === 'GB') as Country;

interface CheckoutFormProps {
  company: ApiCompany;
  onSubscriptionCreated: () => Promise<void>;
}

const CheckoutForm = (props: CheckoutFormProps) => {
  const { logout } = useAuth();
  const user = useUser();
  const stripe = useStripe();
  const elements = useElements();

  const [cardErrorMessage, setCardErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    analytics.subscriptionCheckoutStarted();
  }, []);

  const [current, send] = useMachine(registrationWizardCheckoutFormMachine, {
    context: {
      company: props.company,
      discountCode: '',
      planTiers: [],
      selectedTier: undefined,
      currencies: SUPPORTED_CURRENCIES,
      selectedCurrency: DEFAULT_CURRENCY,
    },
    actions: {
      onSubscriptionCreated: (ctx, ev) => {
        props.onSubscriptionCreated();
      },
    },
  });

  return (
    <Box>
      <Box sx={{ pb: 5 }}>
        <FormLabel htmlFor="tier">
          <Text sx={{ mb: '3px' }}> What AdBuilder plan would you like to trial?</Text>
        </FormLabel>
        {current.matches({ plans: 'failed' }) ? (
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              pt: 3,
              pb: 2,
              mt: 3,
              borderLeft: '4px solid',
              borderLeftColor: 'error',
              backgroundColor: 'grey.2',
            }}
          >
            <Text sx={{ fontWeight: 400, pb: 2 }}>
              Something went wrong whilst fetching the plans
            </Text>
            <Button
              type="button"
              variant="primaryInverted"
              onClick={() => {
                send({ type: 'RETRY_PLAN_TIER_FETCH' });
              }}
            >
              Retry
            </Button>
          </Flex>
        ) : current.matches({ plans: 'fetchingPlans' }) ? (
          <Flex sx={{ flexDirection: 'column', alignItems: 'center', pt: 3, pb: 2 }}>
            <Box sx={{ color: 'accent', pb: 2 }}>
              <i className="fas fa-spinner fa-2x fa-spin" />
            </Box>

            <Box>Fetching available plans</Box>
          </Flex>
        ) : (
          <BasicSelect
            name="tier"
            items={current.context.planTiers}
            selectedItem={current.context.selectedTier}
            buttonSx={{ p: '0.75rem 0.5rem' }}
            renderButtonText={(item) => (item ? <PlanTierLabel {...item} /> : null)}
            renderItem={(item) => (item ? <PlanTierLabel {...item} /> : null)}
            onChange={(item) => {
              if (typeof item === 'undefined') {
                return;
              }

              send({ type: 'SELECT_TIER', data: { tier: item } });
            }}
          />
        )}
      </Box>

      <Box sx={{ pb: 5 }}>
        <FormLabel htmlFor="discountCode">Got a promo code?</FormLabel>
        <Grid sx={{ gridTemplateColumns: ['1fr 1fr', '300px auto'], columnGap: '10px', mt: '3px' }}>
          <React.Fragment>
            <TextField
              name="discountCode"
              type="text"
              disabled={
                current.matches({ plans: 'idle' }) === false ||
                current.matches({ discountCode: 'idle' }) === false
              }
              value={current.context.discountCode}
              onChange={(e) => {
                send({
                  type: 'UPDATE_DISCOUNT_CODE',
                  data: { discountCode: e.currentTarget.value },
                });
              }}
            />
          </React.Fragment>

          <Box>
            <Button
              type="button"
              sx={{ p: '0.75rem 0.5rem', fontWeight: 500 }}
              variant={
                current.matches({ plans: 'idle' }) === false ||
                current.matches({ discountCode: 'idle' }) === false ||
                current.matches({ discountCode: { idle: 'invalid' } })
                  ? 'disabled'
                  : 'accent'
              }
              disabled={
                current.matches({ plans: 'idle' }) === false ||
                current.matches({ discountCode: 'idle' }) === false ||
                current.matches({ discountCode: { idle: 'invalid' } })
              }
              onClick={() => {
                send({ type: 'APPLY_DISCOUNT_CODE' });
              }}
            >
              Apply
            </Button>
          </Box>
        </Grid>
      </Box>

      <Box sx={{ pb: 5 }}>
        <Box
          as="ul"
          sx={{
            listStyle: 'none',
            fontSize: 1,
            py: 3,
            p: 0,
            '> li': {
              display: 'grid',
              gridTemplateColumns: 'min-content auto',
              columnGap: 3,
              pb: 2,
            },
          }}
        >
          <li>
            <Box as="i" className="fas fa-check" sx={{ color: 'primary' }} /> Your card will not be
            charged until the end of your 7-day trial
          </li>
          <li>
            <Box as="i" className="fas fa-check" sx={{ color: 'primary' }} /> Self-cancel at any
            time
          </li>
          <li>
            <Box as="i" className="fas fa-check" sx={{ color: 'primary' }} /> We'll notify you 3
            days before your first charge
          </li>
        </Box>
      </Box>

      <Formik
        initialValues={{
          addressLineOne: '',
          addressLineTwo: '',
          addressCity: '',
          addressPostalCode: '',
          addressCountry: defaultCountry,
          vatNumber: '',
        }}
        validationSchema={checkoutFormSchema}
        onSubmit={async (values) => {
          if (
            current.matches({ plans: 'creatingSubscription' }) ||
            current.matches({ plans: 'subscriptionCreated' })
          ) {
            return;
          }

          if (!stripe || !elements) {
            throw new UserFacingError(
              'Attempted to updated card details but stripe has not loaded'
            );
          }

          const cardElement = elements.getElement(CardElement);

          if (!cardElement) {
            throw new UserFacingError('Something went wrong whilst processing your card number');
          }

          const stripeResult = await stripe.createToken(cardElement, {
            name: `${user.firstName} ${user.lastName}`,
            address_line1: values.addressLineOne,
            address_line2: values.addressLineTwo,
            address_city: values.addressCity,
            address_zip: values.addressPostalCode,
            address_country: values.addressCountry.code,
          });

          if (stripeResult.error || !stripeResult.token || !stripeResult.token.card) {
            throw new UserFacingError('Something went wrong whilst processing your card number');
          }

          send({
            type: 'CREATE_SUBSCRIPTION',
            data: {
              accountingEmail: user.email,
              billingDetails: {
                addressLineOne: values.addressLineOne,
                addressLineTwo: values.addressLineTwo,
                addressCity: values.addressCity,
                addressPostalCode: values.addressPostalCode,
                addressCountryCode: values.addressCountry.code,
                vatNumber: isEUCountry(values.addressCountry) ? values.vatNumber : '',
              },
              stripe: {
                token: stripeResult.token.id,
                lastFour: stripeResult.token.card.last4,
              },
            },
          });
        }}
      >
        {({ touched, values, submitCount, setFieldValue }) => {
          return (
            <React.Fragment>
              <Component
                countryTouched={touched.addressCountry}
                selectedCurrency={current.context.selectedCurrency}
                didUpdate={({
                  prevProps,
                  props,
                }: {
                  props: { selectedCurrency: string; countryTouched: boolean };
                  prevProps: { selectedCurrency: string };
                }) => {
                  if (
                    props.countryTouched ||
                    props.selectedCurrency === prevProps.selectedCurrency
                  ) {
                    return;
                  }

                  let code =
                    props.selectedCurrency === 'GBP'
                      ? 'GB'
                      : props.selectedCurrency === 'USD'
                      ? 'US'
                      : null;
                  if (!code) {
                    return;
                  }

                  const country = countries.find((country) => country.code === code);
                  if (!country) {
                    return;
                  }

                  setFieldValue('addressCountry', country);
                }}
              />

              <Form>
                <Box sx={{ pb: 5 }}>
                  <Flex
                    sx={{
                      flexDirection: ['column', 'row'],
                      justifyContent: 'space-between',
                      mb: 2,
                    }}
                  >
                    <FormLabel>Card details:</FormLabel>
                    <CreditCardHelper />
                  </Flex>
                  <Box
                    sx={{
                      padding: '1rem',
                      border: '1px solid #cccccc',
                      borderRadius: '4px',
                      backgroundColor: 'grey.0',
                      mt: '3px',
                    }}
                  >
                    <CardElement
                      id="card-number"
                      options={{ hidePostalCode: true }}
                      onChange={(e) => {
                        setCardErrorMessage(e.error ? e.error.message : undefined);
                      }}
                    />
                  </Box>
                  {submitCount >= 1 && cardErrorMessage ? (
                    <ThemedErrorMessage>{cardErrorMessage}</ThemedErrorMessage>
                  ) : null}

                  <Box>
                    <Text
                      sx={{
                        fontWeight: 500,
                        fontSize: 1,
                        letterSpacing: '-0.02rem',
                        mt: 3,
                        textAlign: 'right',
                      }}
                    >
                      Not ready to give us your card details?{' '}
                      <ExternalLink
                        href="https://adbuilder.io/demo/"
                        target="_top"
                        sx={{
                          color: 'accent',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          display: ['block', 'inline'],
                        }}
                        onClick={() => {
                          analytics.contact();
                        }}
                      >
                        Book a demo &#8594;
                      </ExternalLink>
                    </Text>
                  </Box>
                </Box>

                <Box sx={{ pb: 5 }}>
                  <Heading
                    as="h3"
                    sx={{
                      color: 'accent',
                      fontWeight: 500,
                      pb: 3,
                      letterSpacing: '-0.05rem',
                      pt: 3,
                    }}
                  >
                    Billing details:
                  </Heading>

                  <Grid
                    sx={{
                      gridTemplateColumns: [null, 'auto auto'],
                      columnGap: 3,
                      rowGap: 5,
                      pb: 5,
                    }}
                  >
                    <Box>
                      <FormLabel htmlFor="addressLineOne">
                        Line 1:
                        <Field name="addressLineOne">
                          {({ field }: FieldProps) => (
                            <React.Fragment>
                              <TextField id={field.name} type="text" {...field} />
                              <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                            </React.Fragment>
                          )}
                        </Field>
                      </FormLabel>
                    </Box>

                    <Box>
                      <FormLabel htmlFor="addressLineTwo">
                        Line 2:
                        <Field name="addressLineTwo">
                          {({ field }: FieldProps) => (
                            <React.Fragment>
                              <TextField id={field.name} type="text" {...field} />
                              <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                            </React.Fragment>
                          )}
                        </Field>
                      </FormLabel>
                    </Box>

                    <Box>
                      <FormLabel htmlFor="addressCity">
                        Town / city:
                        <Field name="addressCity">
                          {({ field }: FieldProps) => (
                            <React.Fragment>
                              <TextField id={field.name} type="text" {...field} />
                              <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                            </React.Fragment>
                          )}
                        </Field>
                      </FormLabel>
                    </Box>

                    <Box>
                      <FormLabel htmlFor="addressPostalCode">
                        Postcode / Zip code:
                        <Field name="addressPostalCode">
                          {({ field }: FieldProps) => (
                            <React.Fragment>
                              <TextField id={field.name} type="text" {...field} />
                              <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                            </React.Fragment>
                          )}
                        </Field>
                      </FormLabel>
                    </Box>
                  </Grid>

                  <Box sx={{ pb: 5 }}>
                    <FormLabel htmlFor="address-country">
                      <Text sx={{ mb: '3px' }}>Country:</Text>
                    </FormLabel>
                    <Field type="text" name="addressCountry">
                      {({ form, field }: FieldProps) => (
                        <CountrySelect
                          countries={countries}
                          selected={field.value}
                          onChange={(country) => {
                            form.setFieldTouched(field.name, true);
                            form.setFieldValue(field.name, country);
                          }}
                        />
                      )}
                    </Field>
                  </Box>

                  <Fade active={isEUCountry(values.addressCountry)}>
                    <Box sx={{ pb: 5 }}>
                      <FormLabel htmlFor="vatNumber">
                        VAT Number:
                        <Field name="vatNumber">
                          {({ field }: FieldProps) => (
                            <React.Fragment>
                              <TextField id={field.name} {...field} />
                              <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                            </React.Fragment>
                          )}
                        </Field>
                      </FormLabel>
                    </Box>
                  </Fade>
                </Box>

                <Flex
                  sx={{
                    flexDirection: ['column', 'row'],
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pb: 5,
                  }}
                >
                  <Button
                    type="submit"
                    variant="primaryInverted"
                    disabled={current.matches({ plans: 'creatingSubscription' })}
                    sx={{ px: 5, py: '1.25rem', fontSize: 3, fontWeight: 500, minWidth: '200px' }}
                  >
                    <Box>
                      <Text as="span" sx={{ pr: 2 }}>
                        Start my trial
                      </Text>
                      {current.matches({ plans: 'creatingSubscription' }) ? (
                        <i className="fas fa-spinner fa fa-spin" />
                      ) : (
                        <React.Fragment>&#8594;</React.Fragment>
                      )}
                    </Box>
                  </Button>

                  <Box>
                    <Text sx={{ fontWeight: 500, letterSpacing: '-0.02rem' }}>
                      Already got an account?{' '}
                      <Button
                        variant="text"
                        onClick={() => {
                          logout('/login');
                        }}
                        sx={{
                          borderRadius: '0px',
                          fontWeight: 500,
                          color: 'rgb(50, 146, 207)',
                          letterSpacing: '-0.01rem',
                          borderBottom: '2px solid',
                          '&:hover': {
                            textDecoration: 'none',
                            borderBottom: '2px solid #3292cf',
                          },
                        }}
                      >
                        Log in &rarr;
                      </Button>
                    </Text>
                  </Box>
                </Flex>
              </Form>
            </React.Fragment>
          );
        }}
      </Formik>
    </Box>
  );
};

const CreditCardHelper = () => {
  const { width } = useWindowSize();

  const Label = () => (
    <Text
      sx={{
        fontWeight: 500,
        fontSize: 1,
        letterSpacing: '-0.02rem',
        textAlign: ['left', 'right'],
        color: 'accent',
        cursor: 'pointer',
        textDecoration: 'underline',
      }}
    >
      Why do we need your card details?
    </Text>
  );

  const HelperText = () => (
    <React.Fragment>
      <Text sx={{ fontWeight: 500, pb: 2 }}>
        Don't worry, you won't be charged until your free trial ends.
      </Text>
      <Text sx={{ pb: 2 }}>
        We ask for your payment information to provide a seamless subscription experience.
      </Text>
      <Text>
        You can cancel anytime in the next 7 days to avoid being charged, and we'll send you an
        email remined 3 days before your free trial ends.
      </Text>
    </React.Fragment>
  );

  return width <= 768 ? (
    <Accordion collapsible>
      <AccordionItem>
        <AccordionButton sx={{ p: 0, bg: 'unset' }}>
          <Label />
        </AccordionButton>

        <AccordionPanel
          sx={{ fontSize: 1, borderTop: '1px solid', mt: 2, border: '1px solid #cccccc' }}
        >
          <HelperText />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : (
    <Tooltip sx={{ maxWidth: '450px' }} label={<HelperText />}>
      <Box>
        <Label />
      </Box>
    </Tooltip>
  );
};

interface PlanTierLabelProps {
  name: string;
  seats: number;
  price: number;
  currencyCode: string;
  billingPeriod: string;
}

const PlanTierLabel = (props: PlanTierLabelProps) => {
  return (
    <Text as="span" sx={{ fontSize: [1, 3] }}>
      {props.name} - {props.seats} user{' '}
      <FormattedPlural value={props.seats} one="licence" other="licences" /> -{' '}
      <Text as="span" sx={{ fontWeight: '700' }}>
        <FormattedNumber style="currency" currency={props.currencyCode} value={props.price / 100} />
      </Text>{' '}
      / {props.billingPeriod}
    </Text>
  );
};

interface Country {
  name: string;
  code: string;
}

interface CountrySelectProps {
  countries: Country[];
  selected: Country;
  onChange: (country: Country) => void;
}

const CountrySelect: React.FC<CountrySelectProps> = (props) => {
  return (
    <BasicSelect
      name="country"
      items={props.countries}
      selectedItem={props.selected}
      buttonSx={{ p: '0.75rem 0.5rem' }}
      renderButtonText={(item) => item.name}
      renderItem={(item) => item.name}
      onChange={props.onChange}
    />
  );
};

const FormLabel = (props: LabelProps) => {
  return <Label sx={{ fontSize: '0.8rem', fontWeight: 700 }} {...props} />;
};
