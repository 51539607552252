import * as React from 'react';
import { FormattedNumber } from 'react-intl';

import { Text } from './common/Text';

interface SubscriptionPlanTierPriceProps {
  /**
   * The amount is in the lowest denomination for the currency
   * For currencies with decimals (example: GBP = pence, USD = cents) the value $1 would be 100
   * For currencies without decimals (example: Yen) the value ¥1 would be 1
   */
  amount: number;

  /**
   * How frequent the plan changes
   */
  period: 'month' | 'year';

  /**
   * Used to determine how the amount is formatted
   */
  currencyCode: string;

  /**
   * Overwrite the container text styles
   */
  textSx?: any;

  /**
   * Overwrite the number text styles
   */
  numberTextSx?: any;
}

export const SubscriptionPlanTierPrice: React.FC<SubscriptionPlanTierPriceProps> = React.memo(
  (props) => {
    return (
      <Text
        sx={{
          fontWeight: 'bold',
          fontSize: '3.5rem',
          mt: 0,
          mb: '0.75rem',
          letterSpacing: '-0.15rem',
          ...props.textSx,
        }}
      >
        <Text as="span" sx={{ ...props.numberTextSx }}>
          <FormattedNumber
            style="currency"
            currency={props.currencyCode}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
            value={props.amount}
          />
        </Text>
        <Text
          as="span"
          sx={{ fontWeight: 'normal', fontSize: 2, color: 'grey.5', letterSpacing: '-0.01rem' }}
        >
          /{props.period}
        </Text>
      </Text>
    );
  }
);
