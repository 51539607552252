import { client } from './client';

const getDiscountInformation = async (
  token: string
): Promise<{
  expiresAt: Date;
}> => {
  const response = await client.get(`/user-drip-discount-code/${token}`);

  return {
    expiresAt: new Date(response.data.expiresAt),
  };
};

export const userDripDiscountCodeApi = {
  getDiscountInformation,
};
