import * as React from 'react';

import { useUser } from '../../context/UserContext';

interface CompanyOwnerOnlyProps {
  fallback?: React.ReactNode;
}

export const CompanyOwnerOnly: React.FC<CompanyOwnerOnlyProps> = ({
  children,
  fallback = null,
}) => {
  const user = useUser();

  return <React.Fragment>{user && user.isCompanyOwner ? children : fallback}</React.Fragment>;
};
