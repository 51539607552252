import { FastField, FieldProps } from 'formik';
import * as React from 'react';

import { BasicSelect } from '../../../components/common/BasicSelect';
import { Box } from '../../../components/common/Box';
import { BasicHint } from './BasicHint';
import { QuestionFieldProps } from './QuestionFieldProps';

interface SelectQuestionProps {
  name: string;
  question: QuestionFieldProps;
}

export const SelectQuestion: React.FC<SelectQuestionProps> = (props) => {
  const items = props.question.options || [];

  return (
    <FastField name={props.name}>
      {({ field, form }: FieldProps) => {
        return (
          <React.Fragment>
            <BasicSelect
              name={field.name}
              items={items}
              selectedItem={field.value || items[0]}
              renderButtonText={(item) => item.label}
              renderItem={(item) => item.label}
              onChange={(item) => {
                form.setFieldValue(field.name, item);
                form.setFieldTouched(field.name);
              }}
            />

            {props.question.hint && (
              <Box sx={{ mt: 5 }}>
                <BasicHint>{props.question.hint}</BasicHint>
              </Box>
            )}
          </React.Fragment>
        );
      }}
    </FastField>
  );
};
