import * as React from 'react';

import { Box } from '../../../components/common/Box';

interface ClearFiltersButtonProps {
  onClick: () => void;
}

export const ClearFiltersButton: React.FC<ClearFiltersButtonProps> = ({ onClick }) => {
  return (
    <Box as="button" sx={{ bg: 'unset', border: 'none', cursor: 'pointer' }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36 36"
        fill="none"
        stroke="#3292cf"
        strokeWidth="4"
        strokeMiterlimit="2.6131"
        height="22px"
        width="22px"
      >
        <path d="M1.41 35.41l34-34" />
        <path d="M35.41 35.41l-34-34" />
      </svg>
    </Box>
  );
};
