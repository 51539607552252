import styled from '@emotion/styled/macro';
import Downshift from 'downshift';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';

import { ApiTeam, teamApi } from '../../../api/team';
import { Box } from '../../../components/common/Box';
import { Button } from '../../../components/common/Button';
import { TextField } from '../../../components/common/form';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTextButton,
  ModalTitle,
} from '../../../components/common/Modal';
import { SelectMenu, SelectMenuItem } from '../../../components/common/Select';
import { Text } from '../../../components/common/Text';
import { useToggle } from '../../../hooks/useToggle';
import logger from '../../../utils/logger';

const Input: any = styled(TextField)``;

interface DeleteTeamProps {
  team: ApiTeam;
  onTeamTransfered: () => void;
}

export const DeleteTeam: React.FC<DeleteTeamProps> = (props) => {
  const { on: isOpen, toggle } = useToggle();
  const [otherTeams, setOtherTeams] = useState<ApiTeam[] | Error>([]);
  const [selectedTeam, setSelectedTeam] = useState<ApiTeam | null>(null);
  const [isEmptyTeam, setIsEmptyTeam] = useState(getIsEmptyTeam(props));

  const transferTeam = async (teamId: number, transferToTeamId: number) => {
    await teamApi.transferTeam(teamId, transferToTeamId);
    props.onTeamTransfered();
  };

  useEffect(() => {
    setIsEmptyTeam(getIsEmptyTeam(props));
  }, [props]);

  useEffect(() => {
    if (isEmptyTeam && !(otherTeams instanceof Error) && otherTeams.length) {
      setSelectedTeam(otherTeams.find((team) => team.id !== props.team.id) || null);
    }
  }, [isEmptyTeam, otherTeams, props.team.id]);

  useEffect(() => {
    let ignore = false;

    const fetchTeams = async () => {
      try {
        const response = await teamApi.getTeams();

        if (!ignore) {
          const other = response.teams.filter((otherTeam) => otherTeam.id !== props.team.id);
          setOtherTeams(other);
        }
      } catch (e) {
        logger.logError(e);

        if (!ignore) {
          setOtherTeams(e);
        }
      }
    };

    fetchTeams();

    return () => {
      ignore = true;
    };
  }, [props.team]);

  return (
    <React.Fragment>
      <Button
        variant="danger"
        sx={{ fontWeight: 'semibold', fontSize: 2, letterSpacing: '-0.01rem' }}
        onClick={toggle}
      >
        Delete Team
      </Button>

      <Modal
        aria-label="delete and transfer team"
        isOpen={isOpen}
        data-testid="delete-team-modal"
        onDismiss={toggle}
      >
        <ModalClose onClick={toggle} />
        <ModalContainer>
          <ModalTitle>
            {isEmptyTeam ? (
              <>
                Are you sure you want to delete the team <strong>{props.team.name}</strong>?
              </>
            ) : (
              'Where should we move the adverts and users to?'
            )}
          </ModalTitle>
          <ModalContent>
            <Text>
              {isEmptyTeam ? (
                'Once deleted, the team will permanently be removed.'
              ) : (
                <>
                  Pick another team to transfer the <strong>{props.team.name}</strong> adverts and
                  users to:
                </>
              )}
            </Text>

            {!isEmptyTeam ? (
              otherTeams instanceof Error ? (
                <Text>Something went wrong whilst fetching the other teams</Text>
              ) : (
                <Downshift
                  selectedItem={selectedTeam}
                  onChange={setSelectedTeam}
                  itemToString={(item) => (item ? item.name : '')}
                >
                  {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    isOpen,
                    inputValue,
                    highlightedIndex,
                    selectedItem,
                    openMenu,
                  }) => {
                    const MAX_RESULTS_TO_SHOW = 10;

                    return (
                      <div>
                        <Box sx={{ position: 'relative' }}>
                          <Input
                            {...getInputProps({
                              isOpen,
                              onFocus: openMenu,
                              sx: { backgroundColor: 'white' },
                              'data-testid': 'delete-team-modal-transfer-list-input',
                            })}
                          />
                          <SelectMenu
                            data-testid="delete-team-modal-transfer-list"
                            {...getMenuProps()}
                            sx={{
                              position: 'absolute',
                              top: 'calc(100% + 0.5rem)',
                              maxHeight: '220px',
                              width: '100%',
                              boxShadow: isOpen ? 'selectMenu' : undefined,
                            }}
                          >
                            {isOpen
                              ? otherTeams
                                  .filter(
                                    (otherTeam) =>
                                      !inputValue ||
                                      otherTeam.name
                                        .toLowerCase()
                                        .includes(inputValue.toLowerCase())
                                  )
                                  .filter((_, index) => index < MAX_RESULTS_TO_SHOW)
                                  .map((team, index) => (
                                    <SelectMenuItem
                                      {...getItemProps({
                                        key: team.id,
                                        index,
                                        item: team,
                                      })}
                                      sx={{
                                        backgroundColor:
                                          highlightedIndex === index ? 'lightgray' : undefined,
                                        fontWeight: selectedItem === team ? 'bold' : 'normal',
                                        fontSize: '0.9rem',
                                        px: '1.1rem',
                                        py: '0.8rem',
                                      }}
                                    >
                                      {team.name}
                                    </SelectMenuItem>
                                  ))
                              : null}
                          </SelectMenu>
                        </Box>
                      </div>
                    );
                  }}
                </Downshift>
              )
            ) : null}
          </ModalContent>

          <ModalActions>
            <ModalButton
              variant={selectedTeam ? 'primaryInverted' : 'disabled'}
              disabled={!selectedTeam}
              mr={2}
              onClick={async () => {
                if (!selectedTeam) {
                  return;
                }

                try {
                  await transferTeam(props.team.id, selectedTeam.id);
                  toggle();
                } catch (e) {
                  logger.logError(e);
                  toast.error('Something went wrong whilst transferring the Team');
                }
              }}
            >
              {isEmptyTeam ? 'Yes, delete' : 'Delete & Transfer'}
            </ModalButton>

            <ModalTextButton type="button" onClick={toggle}>
              {isEmptyTeam ? 'No, cancel' : 'Cancel'}
            </ModalTextButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    </React.Fragment>
  );
};

const getIsEmptyTeam = (props: DeleteTeamProps) =>
  props.team.userCount === 0 && props.team.advertCount === 0;
