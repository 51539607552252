import 'react-virtualized/styles.css';

import styled from '@emotion/styled/macro';
import { useState } from 'react';
import * as React from 'react';
import { AutoSizer, List, ListRowRenderer } from 'react-virtualized';

import { Box } from './common/Box';
import { Flex } from './common/Flex';
import { Label, TextField } from './common/form';
import { Search } from './Icon';

interface MultipleUserSelectorProps {
  users: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    selected: boolean;
  }[];
  onUserToggled: (userId: number) => void;
}

export const MultipleUserSelector: React.FC<MultipleUserSelectorProps> = ({
  users,
  onUserToggled,
}) => {
  const [filterText, setFilterText] = useState('');

  const filteredUsers = users.filter((user) =>
    [user.firstName, user.lastName, user.email]
      .join(' ')
      .toLowerCase()
      .includes(filterText.toLowerCase())
  );

  const renderRow: ListRowRenderer = ({ key, index, style }) => {
    const user = filteredUsers[index];

    return (
      <Flex
        key={key}
        style={style}
        sx={{
          alignItems: 'center',
          bg: user.selected ? '#c1def1' : undefined,
          px: 3,
        }}
      >
        <Label sx={{ fontWeight: 'normal', fontSize: '1rem' }}>
          <Box as="span" sx={{ mr: 2 }}>
            <input
              type="checkbox"
              checked={user.selected}
              onChange={() => onUserToggled(user.id)}
            />
          </Box>
          {user.firstName} {user.lastName} ({user.email})
        </Label>
      </Flex>
    );
  };

  return (
    <AutoSizer>
      {({ width }) => (
        <React.Fragment>
          <Box mb="5">
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '0.95rem',
                  left: '0.75rem',
                }}
              >
                <Search style={{ color: '#aaaaaa' }} />
              </Box>

              <TextField
                type="text"
                placeholder="Enter their name or select from the list below"
                sx={{ width, pl: '2rem', fontSize: '1.1rem' }}
                value={filterText}
                onChange={(e: any) => setFilterText(e.target.value)}
              />
            </Box>
          </Box>
          <StyledList
            width={width}
            height={250}
            rowCount={filteredUsers.length}
            rowHeight={40}
            rowRenderer={renderRow}
          />
        </React.Fragment>
      )}
    </AutoSizer>
  );
};

const StyledList = styled(List as TSFixMe)`
  border-width: ${(props) => props.theme.borderWidths[2]};
  border-style: solid;
  border-color: #e1e1e1;
  border-radius: ${(props) => props.theme.radii[2]}px;
  background-color: rgb(248, 248, 248);
`;
