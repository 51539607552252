import { RouteComponentProps } from '@reach/router';
import * as React from 'react';

import { AppBar } from './AppBar';
import { Box } from './common/Box';
import { Flex } from './common/Flex';
import { ErrorBoundary } from './ErrorBoundary';
import { useActiveOverlay } from './Overlay/ActiveOverlayContext';

export interface AppShellProps extends RouteComponentProps {
  backgroundColor?: string;
  // Gross but hacky fix
  hideAppBar?: boolean;
}

export const AppShell: React.FC<AppShellProps> = ({
  children,
  backgroundColor = 'white',
  hideAppBar = false,
}) => {
  const { hasActiveOverlay } = useActiveOverlay();

  return (
    <Flex
      sx={{ flexDirection: 'column', minHeight: '100vh' }}
      className={hasActiveOverlay ? 'overlay-blur' : undefined}
    >
      {hideAppBar ? null : <AppBar />}
      <ErrorBoundary>
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', bg: backgroundColor }}>
          {children}
        </Box>
      </ErrorBoundary>
    </Flex>
  );
};
