import { useMachine } from '@xstate/react';
import { useEffect } from 'react';
import * as React from 'react';

import { AnimatedBanner, animatedBannerMachine } from '../../components/AnimatedBanner';
import { Box } from '../../components/common/Box';
import { Text } from '../../components/common/Text';
import { DebugOnly } from '../../components/Headless/DebugOnly';
import { sleep } from '../../utils/common';

interface InvalidFormBannerProps {
  errors?: { [key: string]: any };
}

export const InvalidFormBanner: React.FC<InvalidFormBannerProps> = ({ errors }) => {
  const [state, send] = useMachine(animatedBannerMachine, {
    guards: {
      shouldAutoShow: () => false,
    },
    services: {
      openingBanner: async () => sleep(350),
      closingBanner: async () => {
        await sleep(350);
      },
    },
  });

  useEffect(() => {
    if (errors && Object.keys(errors).length) {
      send('OPEN');
    }
  }, [errors, send]);

  const isOpened = state.matches('opened');
  const isOpening = state.matches('opening');

  return (
    <AnimatedBanner variant="danger" isOpen={isOpened || isOpening} onDismiss={() => send('CLOSE')}>
      <Box sx={{ width: '100%' }}>
        <Text sx={{ color: 'white', fontWeight: 500, textAlign: 'center', fontSize: 1 }}>
          There are some questions that you haven't correctly completed.
        </Text>

        <Text sx={{ color: 'whitefade80', fontWeight: 300, textAlign: 'center', fontSize: 1 }}>
          Look for ones with a red box below them.
        </Text>

        {errors && (
          <DebugOnly>
            {Object.keys(errors).map((key) => {
              const error = (errors as any)[key];

              return (
                <div key={key}>
                  <Text color="white">
                    {key}: {error}
                  </Text>
                </div>
              );
            })}
          </DebugOnly>
        )}
      </Box>
    </AnimatedBanner>
  );
};
