import { forwardRef } from 'react';
import { Box as BaseBox, BoxOwnProps as BaseBoxOwnProps, BoxProps as BaseBoxProps } from 'theme-ui';

export interface BoxOwnProps extends BaseBoxOwnProps {}

export interface BoxProps extends Partial<BaseBoxProps> {
  __themeKey?: string;
  __css?: any;
}

export const Box = forwardRef<any, BoxProps>((props, ref) => {
  return <BaseBox ref={ref} {...props} />;
});
