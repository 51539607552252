import * as React from 'react';

import { Close } from '../Icon';
import { Flex } from './Flex';
import { IconButton } from './IconButton';
import { Text } from './Text';

interface BannerProps {
  variant?: string;
  containerStyles?: any;
  // ew...
  innerContainerStyles?: any;
  onDismiss?: () => void;
}

export const Banner: React.FC<BannerProps> = ({
  children,
  variant = 'primary',
  containerStyles,
  innerContainerStyles,
  onDismiss,
}) => {
  const getVariant = (key: string) => `banner.${variant}.${key}`;

  return (
    <Flex variant={getVariant('container')} style={containerStyles}>
      <Flex
        sx={{ maxWidth: '1000px', width: '100%', justifyContent: 'space-between' }}
        style={innerContainerStyles}
      >
        <Flex sx={{ flex: 11 }}>{children}</Flex>
        {onDismiss && (
          <Flex sx={{ flex: 1 }}>
            <IconButton
              variant={getVariant('closeButton')}
              title="Don't show again"
              onClick={onDismiss}
            >
              <Text as="span" variant={getVariant('closeButtonInner')}>
                <Close />
              </Text>
            </IconButton>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
