import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';

import { gradeContentApi, Grading } from '../api/grade-content';
import { Button } from './common/Button';
import { Flex } from './common/Flex';
import { Heading } from './common/Heading';
import { PopoverHover } from './common/PopoverHover';
import {
  InclusionScorePanel,
  ReadabilityScorePanel,
  ScoreIndicator,
} from './ContentEditor/AdGrader/ScorePanel';

interface QuickViewVariationGradingProps {
  content?: string;
}

export const QuickViewVariationGrading: React.FC<QuickViewVariationGradingProps> = ({
  content,
}) => {
  const [grading, setGrading] = useState<Grading>();
  const [wordCount, setWordCount] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const updateGrading = useCallback(
    async (forceRetry: boolean = false) => {
      if (!content || loading || grading || (error && !forceRetry)) {
        return;
      }

      const wc = content.length > 0 ? content.split(/\s+/).length : 0;
      setWordCount(wc);
      setLoading(true);

      try {
        const g = await gradeContentApi.grade({ content, wordCount: wc });
        setError(false);
        setGrading(g.grading);
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [content, grading, loading, error]
  );

  useEffect(() => {
    updateGrading();
  }, [updateGrading]);

  return (
    <>
      {loading && <Heading sx={{ ...gradeHeadingStyles, pr: 4 }}>Loading AdGrader...</Heading>}

      {error && !loading && (
        <Flex sx={{ ml: 'auto', alignItems: 'center', gap: 2 }}>
          <Heading sx={{ ...gradeHeadingStyles, color: 'error', pr: 4 }}>
            Error loading AdGrader
          </Heading>

          <Button
            type="button"
            variant="accent"
            sx={{ mr: 3, px: 4, py: 3, fontWeight: 500 }}
            onClick={() => updateGrading(true)}
          >
            Retry
          </Button>
        </Flex>
      )}

      {grading && !loading && wordCount !== undefined && (
        <Flex sx={{ ml: 'auto', alignItems: 'center', gap: 3 }}>
          <Heading sx={gradeHeadingStyles}>Inclusivity:</Heading>
          <PopoverHover
            content={
              <InclusionScorePanel
                score={grading.inclusivity.score}
                genderResult={grading.inclusivity.genderResult}
                collectionResults={grading.inclusivity.collectionResults}
                onContentSelected={() => {}}
                disableInteractive={true}
              />
            }
          >
            <ScoreIndicator score={grading.inclusivity.score} size="medium" />
          </PopoverHover>

          <Heading sx={gradeHeadingStyles}>Readability:</Heading>
          <PopoverHover
            content={
              <ReadabilityScorePanel
                score={grading.readability.score}
                readingEase={grading.readability.readingEase}
                readingGrade={grading.readability.readingGrade}
                wordCount={wordCount}
                collectionResults={grading.readability.collectionResults}
                onContentSelected={() => {}}
                disableInteractive={true}
              />
            }
          >
            <ScoreIndicator score={grading.readability.score} size="medium" />
          </PopoverHover>
        </Flex>
      )}
    </>
  );
};

const gradeHeadingStyles = {
  fontWeight: 500,
  color: 'accent',
  letterSpacing: '-0.025rem',
  fontSize: 3,
  ml: 'auto',
};
