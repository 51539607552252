import { useActor } from '@xstate/react';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { ActorRefFrom, assign, createMachine, Sender } from 'xstate';

import { RuntimeError } from '../../../Error/BaseErrors';
import { assertEventType, assertEventTypeOneOf } from '../../../utils/xstate-helpers';
import { useAdvertTableMachineState } from '../AdvertTableMachineContext';
import { AdvertTableFilter, AdvertTableFilters } from './advertTableMachine';

export interface FilterCollection {
  name: string;
  filters: AdvertTableFilters;
}

type FilterCollections = Map<string, FilterCollection>;

interface FilterCollectionManagerMachineContext {
  storage: ReturnType<typeof makeFilerCollectionStorage>;
  collections: FilterCollections;
  collectionToSave?: FilterCollection;
}

export type FilterCollectionManagerMachineEvent =
  | {
      type: 'SAVE_NEW_FILTER_COLLECTION';
      data: {
        collection: FilterCollection;
      };
    }
  | {
      type: 'OVERWRITE_EXISTING_FILTER_COLLECTION';
    }
  | {
      type: 'CANCEL_OVERWRITE';
    }
  | {
      type: 'DELETE_FILTER_COLLECTION';
      data: {
        name: string;
      };
    }
  | {
      type: 'RELOAD';
    }
  | {
      type: 'REPORT_EXISTING_FILTER_COLLECTIONS';
      data: {
        collections: FilterCollections;
      };
    }
  | {
      type: 'REPORT_UPDATE_FILTER_COLLECTIONS';
      data: {
        collections: FilterCollections;
      };
    };

type FilterCollectionManagerMachineTypeState =
  | {
      value: 'initialising';
      context: FilterCollectionManagerMachineContext;
    }
  | {
      value: { ready: 'idle' } | { ready: 'saved' } | { ready: 'removingCollection' };
      context: FilterCollectionManagerMachineContext;
    }
  | {
      value: { ready: 'saving' } | { ready: 'nameConflict' };
      context: FilterCollectionManagerMachineContext & {
        collectionToSave: FilterCollection;
      };
    }
  | {
      value: 'failure';
      context: FilterCollectionManagerMachineContext;
    };

export type FilterCollectionManagerService = ActorRefFrom<typeof filterCollectionManagerMachine>;

/**
 * Manages accessing, updating, and deleting filter collections.
 */
export const filterCollectionManagerMachine = createMachine<
  FilterCollectionManagerMachineContext,
  FilterCollectionManagerMachineEvent,
  FilterCollectionManagerMachineTypeState
>(
  {
    id: 'filterCollectionManager',
    initial: 'initialising',
    context: {
      storage: {
        getFilterCollections: () => {
          throw new Error('Please specify storage when building the machine');
        },
        saveFilterCollections: () => {
          throw new Error('Please specify storage when building the machine');
        },
      },
      collections: new Map(),
    },
    states: {
      initialising: {
        id: 'initialising',
        entry: ['resetContext'],
        invoke: {
          src: 'fetchExistingCollections',
          onError: '#failure',
        },
        on: {
          REPORT_EXISTING_FILTER_COLLECTIONS: {
            actions: ['cacheFilterCollections'],
            target: 'ready.idle',
          },
        },
      },
      ready: {
        states: {
          idle: {
            on: {
              SAVE_NEW_FILTER_COLLECTION: [
                {
                  cond: 'isDuplicateName',
                  actions: 'cacheCollectionToSave',
                  target: 'nameConflict',
                },
                {
                  actions: 'cacheCollectionToSave',
                  target: 'saving',
                },
              ],
              DELETE_FILTER_COLLECTION: {
                target: 'removingCollection',
              },
            },
          },
          saving: {
            invoke: {
              src: 'saveFilterCollection',
              onError: '#failure',
            },
            on: {
              REPORT_UPDATE_FILTER_COLLECTIONS: {
                actions: ['clearCollectionToSave', 'cacheFilterCollections'],
                target: 'saved',
              },
            },
          },
          saved: {
            entry: 'notifyFilterCollectionSaved',
            after: {
              500: {
                target: '#initialising',
              },
            },
          },
          nameConflict: {
            on: {
              OVERWRITE_EXISTING_FILTER_COLLECTION: {
                target: 'saving',
              },
              CANCEL_OVERWRITE: {
                target: 'idle',
              },
            },
          },
          removingCollection: {
            invoke: {
              src: 'removeFilterCollection',
              onError: '#failure',
            },
            on: {
              REPORT_UPDATE_FILTER_COLLECTIONS: {
                actions: ['cacheFilterCollections'],
                target: 'idle',
              },
            },
          },
        },
      },
      failure: {
        id: 'failure',
        entry: console.debug,
      },
    },
    on: {
      RELOAD: {
        target: 'initialising',
      },
    },
  },
  {
    actions: {
      resetContext: assign({
        collections: (ctx) => new Map(),
        collectionToSave: (ctx) => undefined,
      }),
      cacheFilterCollections: assign({
        collections: (ctx, ev) => {
          assertEventTypeOneOf(ev, [
            'REPORT_EXISTING_FILTER_COLLECTIONS',
            'REPORT_UPDATE_FILTER_COLLECTIONS',
          ]);

          return ev.data.collections;
        },
      }),
      cacheCollectionToSave: assign({
        collectionToSave: (ctx, ev) => {
          assertEventType(ev, 'SAVE_NEW_FILTER_COLLECTION');

          return ev.data.collection;
        },
      }),
      clearCollectionToSave: assign({
        collectionToSave: (ctx, ev) => {
          return undefined;
        },
      }),
      notifyFilterCollectionSaved: () => {
        toast.success('Filter saved');
      },
    },
    guards: {
      isDuplicateName: (ctx, ev) => {
        assertEventType(ev, 'SAVE_NEW_FILTER_COLLECTION');

        return ctx.collections.has(ev.data.collection.name);
      },
    },
    services: {
      fetchExistingCollections: (ctx) => (
        callback: Sender<FilterCollectionManagerMachineEvent>
      ) => {
        const collections = ctx.storage.getFilterCollections();

        callback({
          type: 'REPORT_EXISTING_FILTER_COLLECTIONS',
          data: {
            collections,
          },
        });
      },
      saveFilterCollection: (ctx, ev) => (callback) => {
        assertEventTypeOneOf(ev, [
          'SAVE_NEW_FILTER_COLLECTION',
          'OVERWRITE_EXISTING_FILTER_COLLECTION',
        ]);

        const collectionToSave = ctx.collectionToSave;

        if (!collectionToSave) {
          throw new RuntimeError(
            'Something went wrong whilst saving the filters',
            'Called `saveFilterCollection` without setting a `collectionToSave` in machine context'
          );
        }

        const updatedCollections = new Map(ctx.collections);
        updatedCollections.set(collectionToSave.name, collectionToSave);

        ctx.storage.saveFilterCollections(updatedCollections);

        callback({
          type: 'REPORT_UPDATE_FILTER_COLLECTIONS',
          data: {
            collections: updatedCollections,
          },
        });
      },
      removeFilterCollection: (ctx, ev) => (callback) => {
        assertEventType(ev, 'DELETE_FILTER_COLLECTION');

        const updatedCollections = new Map(ctx.collections);
        updatedCollections.delete(ev.data.name);

        ctx.storage.saveFilterCollections(updatedCollections);

        callback({
          type: 'REPORT_UPDATE_FILTER_COLLECTIONS',
          data: {
            collections: updatedCollections,
          },
        });
      },
    },
  }
);

export const createFilterCollectionManagerMachine = (storageId: string) => {
  return filterCollectionManagerMachine.withContext({
    collections: new Map(),
    storage: makeFilerCollectionStorage(storageId),
  });
};

type SerializedFilterCollections = {
  [key: string]: {
    name: string;
    filters: {
      [key: string]: AdvertTableFilter | AdvertTableFilter[];
    };
  };
};

const makeFilerCollectionStorage = (storageId: string) => {
  const FILTER_COLLECTION_KEY = `ad_builder:dashboard:${storageId}:filter_collections`;

  return {
    getFilterCollections: () => {
      const serializedCollections = localStorage.getItem(FILTER_COLLECTION_KEY);

      if (!serializedCollections) {
        return new Map();
      }

      const parsed = JSON.parse(serializedCollections);

      return deserializeFilterCollections(parsed);
    },
    saveFilterCollections: (collections: FilterCollections) => {
      const serialized = serailizeFilterCollections(collections);

      localStorage.setItem(FILTER_COLLECTION_KEY, JSON.stringify(serialized));
    },
  };
};

const serailizeFilterCollections = (collections: FilterCollections) => {
  let serialized: SerializedFilterCollections = {};

  const filterCollections = Object.fromEntries(collections);

  for (const [key, value] of Object.entries(filterCollections)) {
    serialized[key] = {
      name: value.name,
      filters: Object.fromEntries(value.filters),
    };
  }

  return serialized;
};

const deserializeFilterCollections = (collections: SerializedFilterCollections) => {
  const deserialized: FilterCollections = new Map();

  for (const [key, value] of Object.entries(collections)) {
    const filters: AdvertTableFilters = new Map();

    for (const [filterKey, filterValue] of Object.entries(value.filters)) {
      filters.set(filterKey, filterValue);
    }

    const filterCollection: FilterCollection = {
      name: value.name,
      filters,
    };

    deserialized.set(key, filterCollection);
  }

  return deserialized;
};

export const useFilterCollectionManager = () => {
  const { state: advertTableState } = useAdvertTableMachineState();

  const [state, send] = useActor(
    advertTableState.context.filterCollectionManagerServiceRef as FilterCollectionManagerService
  );

  const value = useMemo(() => {
    return {
      state,
      collections: state.context.collections,
      collectionToSave: state.context.collectionToSave,

      saveFilterCollection: (collection: FilterCollection) => {
        send({ type: 'SAVE_NEW_FILTER_COLLECTION', data: { collection } });
      },
      overwriteExistingFilterCollection: () => {
        send({ type: 'OVERWRITE_EXISTING_FILTER_COLLECTION' });
      },
      cancelOverwrite: () => {
        send({ type: 'CANCEL_OVERWRITE' });
      },
      deleteFilterCollection: (name: string) => {
        send({ type: 'DELETE_FILTER_COLLECTION', data: { name } });
      },
      reload: () => {
        send({ type: 'RELOAD' });
      },
    };
  }, [state, send]);

  return value;
};
