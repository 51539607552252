import * as React from 'react';
import { Assign, ForwardRef } from 'theme-ui';

import { Box, BoxOwnProps } from './Box';

export interface ExternalLinkProps extends Assign<React.ComponentProps<'a'>, BoxOwnProps> {}

export const ExternalLink: ForwardRef<
  HTMLAnchorElement,
  ExternalLinkProps
> = React.forwardRef((props: TSThemeFixMe, ref) => (
  <Box ref={ref} as="a" {...props} __themeKey="links" />
));
