import styled from '@emotion/styled/macro';
import { Link, LinkGetProps, LinkProps } from '@reach/router';
import * as React from 'react';

import { useTheme } from '../hooks/useTheme';

interface LinkMenuProps {
  links: {
    title: string;
    path: string;
  }[];
}

export const LinkMenu: React.FC<LinkMenuProps> = (props) => (
  <Nav>
    <List>
      {props.links.map(({ title, path }) => (
        <ListItem key={path}>
          <NavLink to={path}>{title}</NavLink>
        </ListItem>
      ))}
    </List>
  </Nav>
);

const Nav = styled.nav`
  width: 25vw;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 0 2rem;
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  transition: color 200ms ease-in;

  &:hover {
    color: ${(props) => `${props.theme.colors.accent} !important`};
  }
`;

const NavLink: React.FC<LinkProps<any>> = ({ ref, ...props }) => {
  const theme = useTheme();

  return (
    <MenuLink
      {...props}
      getProps={({ isCurrent }: LinkGetProps) => ({
        style: {
          color: isCurrent ? theme.colors.accent : theme.colors.text,
          fontWeight: isCurrent ? '500' : 'initial',
        },
      })}
    />
  );
};
