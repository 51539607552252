import * as React from 'react';

import { Box } from '../../components/common/Box';

interface DividerProps {
  sxOverride?: any;
}

export const Divider: React.FC<DividerProps> = (props) => {
  return (
    <Box
      as="hr"
      sx={{
        my: 7,
        width: '100%',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: 'none',
        borderTop: '1px solid #cccccc',
        bg: 'grey.3',
        color: 'grey.3',
        ...props.sxOverride,
      }}
    />
  );
};
