import { useQuery } from 'react-query';

import { client } from './client';
import { Form, formatForm } from './form';

const fetchForms = async (): Promise<Form[]> => {
  const response = await client.get('/form');

  return response.data.map(formatForm);
};

export interface FormOverview {
  id: number;
  name: string;
  description: string;
  slug: string;
  defaultForm: boolean;
}

const fetchFormOverviews = async (): Promise<FormOverview[]> => {
  const response = await client.get('/form/overview');

  return response.data;
};

const useFormOverviewsQuery = () => {
  return useQuery(['formOverviews'], fetchFormOverviews);
};

export const advertFormApi = {
  fetchForms,
  fetchFormOverviews,

  useFormOverviewsQuery,
};
