import styled from '@emotion/styled/macro';
import * as React from 'react';

import { Button, ButtonProps } from './Button';

const TransparentButton = styled(Button)`
  padding: 0.25rem 0.6rem 0 0.6rem;
  background-color: transparent;
  color: inherit;
  border: none;
  cursor: pointer;
  border-radius: 0;
  min-width: auto;
  &:hover,
  &:focus {
    background-color: transparent !important;
    color: inherit;
  }
  & > div {
    display: flex;
  }
`;

interface IconButtonProps extends ButtonProps {
  title: string;
  defaultValue?: any;
}

export const IconButton: React.FC<IconButtonProps> = ({ children, css, ...props }) => (
  <TransparentButton {...props}>
    <div>{children}</div>
  </TransparentButton>
);

IconButton.displayName = 'IconButton';
