import { forwardRef, PropsWithoutRef } from 'react';

import { CaretDown, CaretUp } from '../Icon';
import { Box, BoxProps } from './Box';
import { Flex, FlexProps } from './Flex';

export type SelectButtonProps = Partial<PropsWithoutRef<FlexProps & { isOpen: boolean }>>;

export const SelectButton = forwardRef<any, SelectButtonProps>(
  ({ sx, isOpen = false, ...props }, ref) => {
    return (
      <Flex ref={ref} as="button" sx={sx} variant="forms.select.button" {...props}>
        <Box>{props.children}</Box>
        <Box>{isOpen ? <CaretUp /> : <CaretDown />}</Box>
      </Flex>
    );
  }
);

export type SelectMenuProps = Partial<PropsWithoutRef<BoxProps>>;

export const SelectMenu = forwardRef<any, SelectMenuProps>(({ sx, ...props }, ref) => (
  <Box as="ul" ref={ref} sx={sx} variant="forms.select.menu" {...props} />
));

export type SelectMenuItemProps = Partial<PropsWithoutRef<BoxProps>>;

export const SelectMenuItem = forwardRef<any, SelectMenuItemProps>(({ sx, ...props }, ref) => (
  <Box as="li" ref={ref} sx={sx} variant="forms.select.item" {...props} />
));
