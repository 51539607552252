import { ThemeUIStyleObject } from '@theme-ui/css';
import { useSelect } from 'downshift';
import * as React from 'react';

import { useTheme } from '../../hooks/useTheme';
import { Box } from './Box';
import { SelectButton, SelectMenu, SelectMenuItem } from './Select';

interface BasicSelectProps<ItemType> {
  name: string;
  items: ItemType[];
  selectedItem: ItemType;
  buttonSx?: ThemeUIStyleObject;
  renderButtonText: (item: ItemType) => React.ReactNode;
  renderItem: (item: ItemType, index: number) => React.ReactNode;
  onChange: (item: ItemType) => void;
}

export function BasicSelect<ItemType>(props: BasicSelectProps<ItemType>) {
  const theme = useTheme();

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: props.items,
    selectedItem: props.selectedItem,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        props.onChange(selectedItem);
      }
    },
  });

  return (
    <Box sx={{ position: 'relative' }}>
      <SelectButton
        {...getToggleButtonProps({
          type: 'button',
        })}
        isOpen={isOpen}
        sx={{
          borderColor: isOpen ? `${theme.colors.accent} !important` : undefined,
          ...props.buttonSx,
        }}
        data-testid={`${props.name}`}
      >
        {selectedItem ? props.renderButtonText(selectedItem) : null}
      </SelectButton>

      <SelectMenu
        {...getMenuProps()}
        sx={{
          boxShadow: isOpen ? 'selectMenu' : undefined,
        }}
        data-testid={`${props.name}-menu`}
      >
        {isOpen && props.items.length
          ? props.items.map((item, index) => {
              return (
                <SelectMenuItem
                  key={`${index}`}
                  sx={{
                    cursor: 'pointer',
                    ...(highlightedIndex === index
                      ? { backgroundColor: '#eeeeee', borderRadius: '4px' }
                      : {}),
                  }}
                  {...getItemProps({ item, index })}
                >
                  {props.renderItem(item, index)}
                </SelectMenuItem>
              );
            })
          : null}
      </SelectMenu>
    </Box>
  );
}
