import {
  Menu as ReachMenu,
  MenuButton as ReachMenuButton,
  MenuItem as ReachMenuItem,
  MenuList as ReachMenuList,
} from '@reach/menu-button';
import * as React from 'react';

import { Rotate } from '../Headless/Rotate';
import { CaretDown } from '../Icon';
import { Box } from './Box';
import { Flex } from './Flex';

type SelectValue = number | string;

interface SelectOption {
  label: string;
  value: SelectValue;
}

interface SelectProps {
  id?: string;
  value: SelectValue;
  options: SelectOption[];
  renderOption?: (option: SelectOption) => React.ReactNode;
  onChange: (newValue: SelectValue) => void;
}

export const Menu: React.FC<SelectProps> = (props) => {
  const selectedOption = props.options.find((option) => option.value === props.value);

  if (!selectedOption) {
    console.debug(props.value, props.options);
    throw new Error('The provided value does not match a value of one of the options');
  }

  const buttonId = props.id && `${props.id}-button`;
  const listId = props.id && `${props.id}-list`;

  return (
    <ReachMenu>
      {({ isOpen }: { isOpen: boolean }) => (
        <React.Fragment>
          <Box
            as={ReachMenuButton}
            id={buttonId}
            data-testid={buttonId}
            variant="forms.select.button"
            sx={{
              display: 'inline-block',
              px: 2,
              py: 0,
              width: '85px',
              height: '40px',
            }}
          >
            <Flex sx={{ justifyContent: 'space-between', px: 2 }}>
              <span>{selectedOption.label}</span>{' '}
              <span>
                <Rotate active={isOpen}>
                  <CaretDown />
                </Rotate>
              </span>
            </Flex>
          </Box>

          <Box
            as={ReachMenuList}
            id={listId}
            data-testid={listId}
            variant="forms.select.menu"
            sx={{
              maxHeight: 'none',
              width: '85px',
            }}
          >
            {props.options.map((option) => (
              <Box
                as={ReachMenuItem}
                key={option.value}
                onSelect={() => {
                  props.onChange(option.value);
                }}
                variant="forms.select.item"
              >
                {props.renderOption ? props.renderOption(option) : option.label}
              </Box>
            ))}
          </Box>
        </React.Fragment>
      )}
    </ReachMenu>
  );
};
