const flagMap = {
  linkedin_oauth_enabled: () => {
    if (typeof process.env.REACT_APP_LINKEDIN_OAUTH_ENABLED === 'undefined') {
      return undefined;
    }

    return process.env.REACT_APP_LINKEDIN_OAUTH_ENABLED === 'true';
  },
  adgrader_enabled: () => {
    if (typeof process.env.REACT_APP_FLAG_ADGRADER_ENABLED === 'undefined') {
      return undefined;
    }

    return process.env.REACT_APP_FLAG_ADGRADER_ENABLED === 'true';
  },
};

type FlagMap = typeof flagMap;
type FlagMapKey = keyof FlagMap;
type FlagMapValue<Key extends FlagMapKey> = NonNullable<ReturnType<FlagMap[Key]>>;

export function useFlag<Key extends FlagMapKey>(
  key: Key,
  defaultVal: FlagMapValue<Key>
): FlagMapValue<Key> {
  if (typeof flagMap[key] === 'undefined') {
    return defaultVal;
  }

  const value = flagMap[key]();

  if (typeof value === 'undefined') {
    return defaultVal;
  }

  return value as FlagMapValue<Key>;
}

interface FlagGuardChildrenProps<Key extends FlagMapKey> {
  value: FlagMapValue<Key>;
}

interface FlagGuardProps<Key extends FlagMapKey> {
  flag: Key;
  defaultVal: FlagMapValue<Key>;
  children: (props: FlagGuardChildrenProps<Key>) => JSX.Element | null;
}

export function FlagGuard<Key extends FlagMapKey>(props: FlagGuardProps<Key>) {
  const flagValue = useFlag(props.flag, props.defaultVal);

  return props.children({ value: flagValue });
}
