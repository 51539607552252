import styled from '@emotion/styled';
import Popover, { positionRight } from '@reach/popover';
import React, { ReactNode, useRef } from 'react';

import { useToggle } from '../../hooks/useToggle';
import { Box } from './Box';

interface PopoverHoverProps {
  content: ReactNode;
}

export const PopoverHover: React.FC<PopoverHoverProps> = ({ children, content }) => {
  const ref = useRef(null);
  const { on: hovering, set: setHovering } = useToggle();

  return (
    <>
      <div ref={ref} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
        {children}
      </div>

      {hovering && (
        <Popover targetRef={ref} position={positionRight} style={{ zIndex: 5 }}>
          <PopoverContentContainer>{content}</PopoverContentContainer>
        </Popover>
      )}
    </>
  );
};

const PopoverContentContainer = styled(Box)`
  outline: rgba(50, 146, 207, 0.4) solid 6px;
  background: white;
  margin: 6px;
  padding: 0.75rem;
`;
