import styled from '@emotion/styled/macro';
import orderBy from 'lodash.orderby';
import * as React from 'react';

import { analytics } from '../../../analytics/analytics';
import { ApiUser } from '../../../api/auth';
import { Box } from '../../../components/common/Box';
import { Button } from '../../../components/common/Button';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTextButton,
  ModalTitle,
} from '../../../components/common/Modal';
import { Table, TableBody, TableCell, TableHead, TableRow } from '../../../components/common/Table';
import { EditUserModal } from '../../../components/Modal/EditUserModal';
import { CompanyOwnerOnly } from '../../../components/Permissions/CompanyOwnerOnly';

const Strong = styled.strong`
  font-weight: 600;
`;

interface UserListProps {
  currentUser: ApiUser;
  users: ApiUser[];
  fetchingMembers: boolean;
  error?: string;
  actionsBackgroundColour?: string;
  onFetchMembers: () => void;
  onRemoveMember: (member: ApiUser) => void;
}

export const UserList: React.FC<UserListProps> = ({
  currentUser,
  users,
  fetchingMembers,
  error,
  onFetchMembers,
  onRemoveMember,
  actionsBackgroundColour = '#ffffff',
}) => {
  const [userToEdit, setUserToEdit] = React.useState<ApiUser | undefined>(undefined);
  const [userToRemove, setUserToRemove] = React.useState<ApiUser | undefined>(undefined);

  const orderedUsers = orderBy(users, ['isCompanyOwner', 'firstName'], ['desc', 'asc']);

  return (
    <Box pb="3" data-testid="company-member-list">
      <Table
        loading={fetchingMembers}
        loadingRows={2}
        loadingColumns={4}
        sx={{ fontSize: '0.9rem' }}
      >
        <TableHead>
          <TableRow>
            <TableCell width="12.5%">Name</TableCell>
            <TableCell width="35%">Email Address</TableCell>
            <TableCell width="17.5%">Authority Level</TableCell>
            <TableCell width="10%">Team Owner</TableCell>
            <CompanyOwnerOnly>
              <TableCell width="25%">&nbsp;</TableCell>
            </CompanyOwnerOnly>
          </TableRow>
        </TableHead>
        <TableBody slim bg="grey.1">
          {error ? (
            <div>Something went wrong whilst fetching users</div>
          ) : (
            orderedUsers.map((user: ApiUser) => (
              <TableRow key={user.id}>
                <TableCell width="12.5%">
                  <Strong>{user.firstName}</Strong>
                </TableCell>
                <TableCell width="35%">{user.email}</TableCell>
                <TableCell width="17.5%">
                  {user.isCompanyOwner ? <span>Admin / Owner</span> : <span>User</span>}
                </TableCell>
                <TableCell width="10%">
                  <span>{user.isTeamOwner ? 'Yes' : 'No'}</span>
                </TableCell>
                <CompanyOwnerOnly>
                  <TableCell
                    bg={actionsBackgroundColour}
                    width="25%"
                    sx={{ border: 'none !important' }}
                  >
                    <Button
                      type="button"
                      variant="primary"
                      sx={{
                        px: 4,
                        py: 2,
                        mr: 3,
                        fontWeight: 'semibold',
                        minWidth: '6rem',
                        letterSpacing: '-0.01rem',
                        fontSize: '0.85rem',
                      }}
                      onClick={() => {
                        setUserToEdit(user);
                      }}
                    >
                      Edit
                    </Button>

                    {Boolean(userToEdit && userToEdit.id === user.id) && (
                      <EditUserModal
                        isOpen={Boolean(userToEdit && userToEdit.id === user.id)}
                        user={userToEdit ?? undefined}
                        onUserEdited={() => {
                          setUserToEdit(undefined);
                          onFetchMembers();
                        }}
                        onClose={() => {
                          setUserToEdit(undefined);
                        }}
                      />
                    )}

                    {users.length > 1 && user.id !== currentUser.id && (
                      <React.Fragment>
                        <Button
                          variant="danger"
                          type="button"
                          sx={{
                            px: 4,
                            py: 2,
                            fontWeight: 'semibold',
                            minWidth: '6rem',
                            letterSpacing: '-0.01rem',
                            fontSize: '0.85rem',
                          }}
                          onClick={() => {
                            setUserToRemove(user);
                          }}
                        >
                          Remove
                        </Button>

                        <Modal
                        aria-label="Delete user confirmation"
                          variant="destructive"
                          isOpen={userToRemove ? userToRemove.id === user.id : false}
                          onDismiss={() => setUserToRemove(undefined)}
                        >
                          <ModalClose onClick={() => setUserToRemove(undefined)} />
                          <ModalContainer>
                            <ModalTitle style={{ paddingBottom: 0 }}>
                              <Box>Are you sure you want to remove</Box>
                            </ModalTitle>
                            <Box py="3">
                              <Box sx={{ p: 3, bg: 'grey.3', color: 'blackfade85', fontSize: 4 }}>
                                {userToRemove && userToRemove.email}
                              </Box>
                            </Box>
                            <ModalTitle>
                              <Box>from the user list?</Box>
                            </ModalTitle>

                            <ModalContent>
                              You will still be able to access their adverts from the main
                              dashboard. However, removing them from this list will stop their
                              ability to login in, and create and view adverts.
                            </ModalContent>
                            <ModalActions>
                              <ModalButton
                                type="button"
                                mr="3"
                                onClick={async () => {
                                  await onRemoveMember(user);
                                  analytics.userRemovedFromCompany();
                                  onFetchMembers();
                                  setUserToRemove(undefined);
                                }}
                              >
                                I'm sure, delete this user
                              </ModalButton>

                              <ModalTextButton
                                type="button"
                                onClick={() => setUserToRemove(undefined)}
                              >
                                Cancel
                              </ModalTextButton>
                            </ModalActions>
                          </ModalContainer>
                        </Modal>
                      </React.Fragment>
                    )}
                  </TableCell>
                </CompanyOwnerOnly>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
