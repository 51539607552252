import { addHours } from 'date-fns';
import isAfter from 'date-fns/isAfter';
import qs from 'qs';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as React from 'react';

import { userDripDiscountCodeApi } from '../api/user-drip-discount-code';
import { isAxiosError } from '../api/util';

const DISCOUNT_TOKEN_URL_QUERY_STRING_KEY = 'dripDiscountToken';
const DISCOUNT_TOKEN_LOCAL_STORAGE_KEY = 'ad_builder:drip_discount_token';

export enum DiscountCodeStatus {
  Fetching,
  Active,
  Expired,
  NotFound,
}

export enum TokenSource {
  Url,
  Storage,
  NA,
}

interface ContextData {
  status: DiscountCodeStatus;
  expiresAt?: Date;
  token?: string;
  source?: TokenSource;
}

interface ContextValue extends ContextData {
  clearDiscount: () => void;
  dev_ToggleDiscount: () => void;
}

export const DripIntegrationDiscountFlowContext = React.createContext<ContextValue | undefined>(
  undefined
);

const extractTokenFromUrl = () => {
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const discountToken = queryParams[DISCOUNT_TOKEN_URL_QUERY_STRING_KEY];

  return typeof discountToken === 'string' ? discountToken : null;
};

const fetchTokenFromLocalStorage = () => {
  return window.localStorage.getItem(DISCOUNT_TOKEN_LOCAL_STORAGE_KEY);
};

const getDiscountToken = () => {
  let token = extractTokenFromUrl();

  if (token) {
    return {
      source: TokenSource.Url,
      token,
    };
  }

  token = fetchTokenFromLocalStorage();

  if (token) {
    return {
      source: TokenSource.Storage,
      token: fetchTokenFromLocalStorage(),
    };
  }

  return {
    source: TokenSource.NA,
    token: undefined,
  };
};

const storeTokenInLocalStorage = (token: string) => {
  window.localStorage.setItem(DISCOUNT_TOKEN_LOCAL_STORAGE_KEY, token);
};

const removeTokenFromLocalStorage = () => {
  window.localStorage.removeItem(DISCOUNT_TOKEN_LOCAL_STORAGE_KEY);
};

const fetchDiscountInformation = async (token: string) => {
  return userDripDiscountCodeApi.getDiscountInformation(token);
};

export const DripIntegrationDiscountFlowProvider: React.FC = (props) => {
  const [discountInformation, setDiscountInformation] = useState<ContextData>({
    status: DiscountCodeStatus.Fetching,
  });

  useEffect(() => {
    let ignored = false;

    const checkForDiscount = async () => {
      const { token, source } = getDiscountToken();

      if (!token) {
        return;
      }

      storeTokenInLocalStorage(token);

      try {
        const response = await fetchDiscountInformation(token);

        if (ignored) {
          return;
        }

        const expired = isAfter(new Date(), response.expiresAt);

        setDiscountInformation({
          status: expired ? DiscountCodeStatus.Expired : DiscountCodeStatus.Active,
          expiresAt: response.expiresAt,
          token,
          source,
        });

        if (expired) {
          removeTokenFromLocalStorage();
        }
      } catch (e) {
        if (isAxiosError(e) && e.response?.status === 404) {
          removeTokenFromLocalStorage();
        }

        setDiscountInformation({
          status: DiscountCodeStatus.NotFound,
        });
      }
    };

    checkForDiscount();

    return () => {
      ignored = true;
    };
  }, []);

  const clearDiscount = useCallback(() => {
    removeTokenFromLocalStorage();

    setDiscountInformation({
      status: DiscountCodeStatus.NotFound,
    });
  }, []);

  const dev_ToggleDiscount = useCallback(() => {
    if (discountInformation.status === DiscountCodeStatus.Active) {
      setDiscountInformation({
        status: DiscountCodeStatus.NotFound,
      });
    } else {
      setDiscountInformation({
        status: DiscountCodeStatus.Active,
        expiresAt: addHours(new Date(), 1),
        token: 'abc',
        source: TokenSource.Storage,
      });
    }
  }, [discountInformation.status]);

  const contextValue = useMemo(() => {
    return {
      ...discountInformation,
      clearDiscount,
      dev_ToggleDiscount,
    };
  }, [discountInformation, clearDiscount, dev_ToggleDiscount]);

  return (
    <DripIntegrationDiscountFlowContext.Provider value={contextValue}>
      {props.children}
    </DripIntegrationDiscountFlowContext.Provider>
  );
};

export const useDripIntegrationDiscountFlow = () => {
  const context = useContext(DripIntegrationDiscountFlowContext);

  if (context === undefined) {
    throw new Error(
      `useDripIntegrationDiscountFlow must be used within a DripIntegrationDiscountFlowProvider`
    );
  }

  return context;
};
