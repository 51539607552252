import { Dispatch, useCallback, useState } from 'react';

import logger from '../utils/logger';

interface StoredValue<T> {
  content: T;
  storedAt: number;
}

export default function useLocalStorage<T>(
  key: string,
  initialValue: T | undefined = undefined
): [StoredValue<T>, Dispatch<T | undefined>] {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : { content: initialValue, storedAt: null };
    } catch (error) {
      return { content: initialValue, storedAt: null };
    }
  });

  const setValue = useCallback(
    (value: T | undefined) => {
      try {
        if (typeof value === 'undefined') {
          setStoredValue(undefined);
          window.localStorage.removeItem(key);
          return;
        }

        const toStore = { content: value, storedAt: Date.now() };

        setStoredValue(toStore);
        window.localStorage.setItem(key, JSON.stringify(toStore));
      } catch (error) {
        logger.logError(error);
      }
    },
    [key, setStoredValue]
  );

  return [storedValue, setValue];
}
