import format from 'date-fns/format';
import * as React from 'react';

import { Hide } from '../../../components/common/Hide';
import { Text } from '../../../components/common/Text';

interface DateCellProps {
  children: Date;
}

export const DateCell: React.FC<DateCellProps> = (props) => {
  return (
    <React.Fragment>
      <Hide xs sm md lg>
        <Text sx={{ m: 0, fontSize: 1 }}>{format(props.children, 'do LLLL yyyy')}</Text>
      </Hide>

      <Hide xl>
        <Text sx={{ m: 0, fontSize: 1 }}>{format(props.children, 'do LLL')}</Text>
      </Hide>
    </React.Fragment>
  );
};
