import { client } from './client';

interface CompanyDetailsConfig {
  companyName: string;
  accountingEmail: string;
  address: {
    lineOne: string;
    lineTwo?: string;
    lineThree?: string;
    postalCode: string;
    city: string;
    country: string;
  };
}

const updateCompanyDetails = async (
  companyId: number,
  details: CompanyDetailsConfig
): Promise<{ updated: boolean }> => {
  const { companyName, ...billingInformation } = details;

  await client.post(`/company/${companyId}/billing-information`, {
    companyName,
    billingInformation,
  });

  return { updated: true };
};

export const companyBillingInformationApi = {
  updateCompanyDetails,
};
