export const SORT_KEYS = {
  JOB_TITLE: 'jobTitle',
  COMPANY: 'company',
  LOCATION: 'location',
  CREATED_AT: 'createdAt',
  CREATED_BY: 'createdBy',
  SHARED_AT: 'sharedAt',
};

export const FILTER_KEYS = {
  JOB_TYPE: 'jobType',
  POSITION_TYPE: 'positionType',
  COMPANY: 'company',
  LOCATION: 'location',
  TAGS: 'tags',
  CREATED_BY: 'createdBy',
};
