import { useEffect } from 'react';

import logger from '../utils/logger';

export const useLogError = (error?: any) => {
  useEffect(() => {
    if (error) {
      logger.logError(error);
    }
  }, [error]);
};
