import styled from '@emotion/styled/macro';
import { useHover, usePress } from '@react-aria/interactions';
import { useMachine } from '@xstate/react';
import { useContext, useEffect, useImperativeHandle, useRef } from 'react';
import * as React from 'react';
import { createMachine } from 'xstate';

import { BusinessType } from '../api/auth';
import { useUser } from '../context/UserContext';
import { AppBarLinkContainer } from './AppBar';
import { Box } from './common/Box';
import { Heading } from './common/Heading';
import { Text } from './common/Text';
import { ChevronDown } from './Icon';

// When the menu is open we set the body to overflow hidden.
// Subsequently this hides the scrollbar which causes layout movement issues
// To compensate for this we add a margin right of 15px to keep the layout the same
const SCROLL_BAR_MARGIN_OFFSET = '15px';

const TutorialsSubMenuContext = React.createContext({
  isMenuActive: false,
  shouldReplaceScollbar: true,
});

const tutorialsSubMenuMachine = createMachine({
  id: 'tutorialsSubMenu',
  initial: 'closed',
  states: {
    closed: {
      id: 'closed',
      on: {
        MENU_HOVER_START: '#open.temporary',
        MENU_CLICKED: '#open.forced',
      },
    },
    open: {
      id: 'open',
      states: {
        temporary: {
          on: {
            MENU_CLICKED: 'forced',
            MENU_HOVER_END: '#closed',
            CLOSE: '#closed',
          },
        },
        forced: {
          on: {
            CLOSE: '#closed',
          },
        },
      },
    },
  },
});

export const TutorialsSubMenu = React.memo(() => {
  const [current, send] = useMachine(tutorialsSubMenuMachine);

  const { hoverProps } = useHover({
    onHoverStart: () => {
      send('MENU_HOVER_START');
    },
    onHoverEnd: () => {
      send('MENU_HOVER_END');
    },
  });

  const { pressProps } = usePress({
    onPress: () => {
      send(current.matches({ open: 'forced' }) ? 'CLOSE' : 'MENU_CLICKED');
    },
  });

  const isMenuActive = current.matches('open');
  const shouldReplaceScollbar = document.body.scrollHeight > document.body.clientHeight;

  useEffect(() => {
    if (!shouldReplaceScollbar) {
      return;
    }

    if (isMenuActive) {
      document.body.style.overflow = 'hidden';
      document.body.style.marginRight = SCROLL_BAR_MARGIN_OFFSET;
    } else {
      document.body.style.overflow = 'initial';
      document.body.style.marginRight = '0';
    }
  }, [isMenuActive, shouldReplaceScollbar]);

  const contextValue = React.useMemo(() => {
    return {
      isMenuActive: isMenuActive,
      shouldReplaceScollbar: shouldReplaceScollbar,
    };
  }, [isMenuActive, shouldReplaceScollbar]);

  return (
    <TutorialsSubMenuContext.Provider value={contextValue}>
      <AppBarLinkContainer
        {...hoverProps}
        {...pressProps}
        sx={
          isMenuActive
            ? {
                '&:hover': {
                  span: {
                    color: 'white',
                    '&:after': {
                      width: '100%',
                      opacity: '1',
                    },
                  },
                },
              }
            : {}
        }
      >
        <Box as="span" sx={{ mr: 1 }}>
          Tutorials
          <ChevronDown style={{ fontSize: '10px', marginLeft: '0.5rem' }} />
        </Box>{' '}
        <Menu />
      </AppBarLinkContainer>
    </TutorialsSubMenuContext.Provider>
  );
});

const Menu: React.FC = () => {
  const user = useUser();
  const { isMenuActive, shouldReplaceScollbar } = useContext(TutorialsSubMenuContext);

  return (
    <StyledMenu isActive={isMenuActive} shouldReplaceScollbar={shouldReplaceScollbar}>
      {user.isAdmin ? (
        <React.Fragment>
          <IntroVideoItem />
          <NavigatingTheDashboardMenuItem />
          <CreatingANewTeamMenuItem />
          <AddingNewUsersMenuItem />
          <EditingYourFinalAdvertMenuItem />
          <SharingAnAdvertMenuItem />
          <StealingYourTeamsAdvertsMenuItem />
          <ChoosingTheRightJobTitle />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <IntroVideoItem />
          <NavigatingTheDashboardMenuItem />
          <EditingYourFinalAdvertMenuItem />
          <SharingAnAdvertMenuItem />
          <StealingYourTeamsAdvertsMenuItem />
          <ChoosingTheRightJobTitle />
        </React.Fragment>
      )}
    </StyledMenu>
  );
};

const IntroVideoItem = () => {
  const user = useUser();

  const recruitmentAgencyEmbedId = 'vjsyccjphp';
  const directEmployerEmbedId = '6t9c1d74ja';

  const embedId = !user.company
    ? recruitmentAgencyEmbedId
    : user.company.businessType === BusinessType.RecruitmentAgency
    ? recruitmentAgencyEmbedId
    : directEmployerEmbedId;

  return (
    <MenuItem
      title="Welcome to AdBuilder"
      description="Start here to discover how AdBuilder works"
      embedId={embedId}
    />
  );
};

const NavigatingTheDashboardMenuItem = () => {
  return (
    <MenuItem
      title="Navigating the Dashboard"
      description="Learn how to fully utilse the dashboard."
      embedId="xbmq7yc2qc"
    />
  );
};

const CreatingANewTeamMenuItem = () => {
  return (
    <MenuItem
      title="Creating A New Team"
      description="Learn how to group people into teams."
      embedId="oducjjmunt"
    />
  );
};

const AddingNewUsersMenuItem = () => {
  return (
    <MenuItem
      title="Adding New Users"
      description="Learn how invite your team to Adbuilder."
      embedId="gj09hqqqyp"
    />
  );
};

const EditingYourFinalAdvertMenuItem = () => {
  return (
    <MenuItem
      title="Editing Your Final Advert"
      description="Learn how to tweak your final advert."
      embedId="8jxxvdkg5t"
    />
  );
};

const SharingAnAdvertMenuItem = () => {
  return (
    <MenuItem
      title="Sharing an Advert"
      description="Learn how to share an advert with your team."
      embedId="mb4n1bmpsw"
    />
  );
};

const StealingYourTeamsAdvertsMenuItem = () => {
  return (
    <MenuItem
      title="Stealing Your Team's Adverts"
      description="Learn how to use an existing advert as the foundation for your next role."
      embedId="9bge0mzrrg"
    />
  );
};

const ChoosingTheRightJobTitle = () => {
  return (
    <MenuItem
      title="Choosing the right job title"
      description="Learn how to pick the job titles that get searched for."
      embedId="y1d8m5o7rz"
    />
  );
};

interface MenuItemProps {
  title: string;
  description: string;
  embedId: string;
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const thumbnailRef = useRef();

  return (
    <Box sx={{ width: '240px' }}>
      <Heading
        as="h3"
        sx={{
          cursor: 'pointer',
          color: 'white',
          fontWeight: 500,
          pb: '10px',
          fontSize: 2,
          minHeight: '10px',
        }}
        onClick={() => {
          if (thumbnailRef.current) {
            (thumbnailRef.current as any).playVideo();
          }
        }}
      >
        {props.title}
      </Heading>

      <Text
        sx={{ color: 'headerLink', fontSize: 1, fontWeight: 400, pb: '25px', minHeight: '56px' }}
      >
        {props.description}
      </Text>

      <Thumbnail ref={thumbnailRef as any} embedId={props.embedId} />
    </Box>
  );
};

interface ThumbnailProps {
  embedId: string;
}

const Thumbnail = React.forwardRef<any, ThumbnailProps>((props, ref) => {
  const { isMenuActive } = useContext(TutorialsSubMenuContext);
  const hasLoadedPreviously = useRef(false);
  const embedRef = useRef<HTMLDivElement | undefined>();

  useImperativeHandle(ref, () => ({
    playVideo() {
      if (embedRef.current) {
        const videoContainer = embedRef.current.querySelector('.wistia_click_to_play > div');

        if (videoContainer) {
          (videoContainer as any).click();
        }
      }
    },
  }));

  useEffect(() => {
    if (isMenuActive) {
      hasLoadedPreviously.current = true;
    }
  }, [hasLoadedPreviously, isMenuActive]);

  if (!hasLoadedPreviously.current && !isMenuActive) {
    return null;
  }

  return (
    <div
      className="wistia_responsive_padding"
      style={{ padding: '56.25% 0 0 0', position: 'relative' }}
    >
      <div
        className="wistia_responsive_wrapper"
        style={{
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      >
        <div
          ref={embedRef as any}
          className={`wistia_embed wistia_async_${props.embedId} popover=true`}
          style={{ height: '100%', position: 'relative', width: '100%' }}
        >
          <div
            className="wistia_swatch"
            style={{
              height: '100%',
              left: 0,
              opacity: 0,
              overflow: 'hidden',
              position: 'absolute',
              top: 0,
              transition: 'opacity 200ms',
              width: '100%',
            }}
          >
            <img
              src={`https://fast.wistia.com/embed/medias/${props.embedId}/swatch`}
              style={{
                filter: 'blur(5px)',
                height: '100%',
                objectFit: 'contain',
                width: '100%',
              }}
              alt=""
              aria-hidden="true"
              onLoad={(e) => {
                if (e.currentTarget.parentNode) {
                  (e.currentTarget.parentNode as any).style.opacity = 1;
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

interface StyledMenuProps {
  isActive: boolean;
  shouldReplaceScollbar: boolean;
}

const StyledMenu = styled(Box)<StyledMenuProps>`
  display: grid;
  gap: 60px;
  grid-template-columns: auto;
  justify-content: space-around;
  position: fixed;
  width: calc(
    100vw - ${(props) => (props.shouldReplaceScollbar ? SCROLL_BAR_MARGIN_OFFSET : '0px')}
  );
  height: calc(100vh - 53px);
  left: 0;
  top: 53px;
  padding: 50px;
  background: ${(props) => props.theme.backgrounds.header.background};
  background-size: ${(props) => props.theme.backgrounds.header.backgroundSize};
  visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
  overflow-y: ${(props) => (props.isActive ? 'auto' : 'hidden')};
  overflow-x: hidden;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: all 200ms ease;
  color: white;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 5px -1px, rgba(0, 0, 0, 0.07) 0px 7px 10px 0px,
    rgba(0, 0, 0, 0.06) 0px 12px 18px 0px;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, auto);
  }

  @media (min-width: 960px) {
    grid-template-columns: repeat(3, auto);
    height: auto;
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, auto);
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(5, auto);
  }

  @media (min-width: 1920px) {
    grid-template-columns: repeat(6, auto);
  }
`;
