import { useCallback, useContext, useMemo } from 'react';
import * as React from 'react';

import { ApiCompany } from '../api/auth';
import { companySubscriptionApi } from '../api/company-subscription';
import { RuntimeError } from '../Error/BaseErrors';
import { useAuth } from './AuthContext';
import { useUser } from './UserContext';

interface CompanyContextValue {
  company: ApiCompany | null;
  pauseSubscription: () => Promise<{ paused: boolean; currentPeriodEnds: Date }>;
  resumeSubscription: () => Promise<{ resumed: boolean; currentPeriodEnds: Date }>;
  refetchCompany: () => Promise<void>;
}

const initialContextValue: CompanyContextValue = {
  company: {} as any,
  pauseSubscription: async () => ({} as any),
  resumeSubscription: async () => ({} as any),
  refetchCompany: async () => ({} as any),
};

export const CompanyContext = React.createContext(initialContextValue);

interface CompanyProviderProps {}

export const CompanyProvider: React.FC<CompanyProviderProps> = (props) => {
  const { fetchUser } = useAuth();
  const user = useUser();

  if (!user) {
    throw new Error('The CompanyProvider requires a user from the UserContext to be present');
  }

  const { company } = user;
  const companyId = company?.id;

  const pauseSubscription = useCallback(async () => {
    if (!companyId) {
      throw new RuntimeError(
        'Something went wrong whilst pausing the subscription',
        'The user does not have a company yet. This should not be reachable'
      );
    }

    const response = await companySubscriptionApi.pauseSubscription(companyId);
    await fetchUser();

    return response;
  }, [companyId, fetchUser]);

  const resumeSubscription = useCallback(async () => {
    if (!companyId) {
      throw new RuntimeError(
        'Something went wrong whilst resuming the subscription',
        'The user does not have a company yet. This should not be reachable'
      );
    }

    return companySubscriptionApi.resumeSubscription(companyId);
  }, [companyId]);

  const refetchCompany = useCallback(async () => {
    await fetchUser();
  }, [fetchUser]);

  const contextValue = useMemo(() => {
    return { company, pauseSubscription, resumeSubscription, refetchCompany };
  }, [company, pauseSubscription, refetchCompany, resumeSubscription]);

  return <CompanyContext.Provider value={contextValue} {...props} />;
};

export const useCompany = () => {
  const context = useContext(CompanyContext);

  if (context === undefined) {
    throw new Error(`useCompany must be used within a CompanyProvider`);
  }

  return context;
};
