import { useMemo } from 'react';
import * as React from 'react';

import { ApiAdvertTag } from '../../../api/advert-tags';
import { ColouredTooltip } from '../../../components/ColouredTooltip';
import { Box } from '../../../components/common/Box';
import { Flex } from '../../../components/common/Flex';
import { Text } from '../../../components/common/Text';
import { Search } from '../../../components/Icon';
import { SelectedItemContainer, SelectedItemLabel } from '../../../components/Tag';
import { useWindowSize } from '../../../context/WindowSizeContext';
import { useToggle } from '../../../hooks/useToggle';
import { useAdvertTableMachine } from '../AdvertTableMachineContext';
import { ActiveFilterPill, ActiveFilterPillList } from '../DashboardTable';
import { AdvertTableFilter } from '../machines/advertTableMachine';
import { TagAutocomplete } from '../TagAutocomplete';
import { ColumnOptions, FILTER_KEYS, validateIsFilterArray } from '.';

export const buildTagsColumn = (options: ColumnOptions) => {
  const getSelectedTags = (
    availableTags: ApiAdvertTag[],
    selectedFilterTags: AdvertTableFilter | AdvertTableFilter[] = []
  ) => {
    validateIsFilterArray(selectedFilterTags);

    const selectedTags = [];

    for (const filterTag of selectedFilterTags) {
      const tag = availableTags.find((tag) => tag.id === filterTag.value);

      if (tag) {
        selectedTags.push(tag);
      }
    }

    return selectedTags;
  };

  const formatTagToFilterTag = (tag: ApiAdvertTag): AdvertTableFilter => {
    return {
      label: tag.label,
      value: tag.id,
    };
  };

  return {
    id: 'tags',
    Header: () => {
      const [
        { tags: availableTags, filters },
        { setFilter, clearFilter },
      ] = useAdvertTableMachine();
      const { on, toggle } = useToggle();

      const selectedFilterTags = filters.get(FILTER_KEYS.TAGS);

      const selectedTags = useMemo(() => {
        return getSelectedTags(availableTags, selectedFilterTags);
      }, [availableTags, selectedFilterTags]);

      return (
        <React.Fragment>
          <Flex
            sx={{
              alignItems: 'center',
              '.chevron-wrapper': {
                opacity: 0,
                ml: '-8px',
                color: 'textDim',
                lineHeight: 0,
                transition: 'all 150ms ease-in',
              },
              '&:hover': {
                '.chevron-wrapper': {
                  opacity: 1,
                  ml: 0,
                },
              },
            }}
            onClick={toggle}
          >
            <Flex sx={{ flexDirection: 'column' }}>
              <Text as="span">Tags</Text>

              <TagAutocomplete
                isOpen={on}
                tags={availableTags}
                selectedTags={selectedTags}
                onTagsChanged={(tags) => {
                  const filterTags = tags.map(formatTagToFilterTag);
                  setFilter(FILTER_KEYS.TAGS, filterTags);
                }}
                onClose={() => {
                  toggle();
                }}
              />
            </Flex>

            <Flex sx={{ flexDirection: 'column', ml: '2' }}>
              <Text className="chevron-wrapper">
                <Search />
              </Text>
            </Flex>
          </Flex>

          {selectedTags.length > 0 ? <Box sx={{ height: '4px' }} /> : null}

          <ActiveFilterPillList>
            {selectedTags.map((tag) => {
              return (
                <ActiveFilterPill
                  key={tag.id}
                  borderColor={tag.borderColor}
                  onClear={() => {
                    const currentFilterTags = filters.get(FILTER_KEYS.TAGS);

                    if (Array.isArray(currentFilterTags) && currentFilterTags.length <= 1) {
                      clearFilter(FILTER_KEYS.TAGS);
                      return;
                    }

                    setFilter(FILTER_KEYS.TAGS, (currentFilterTags = []) => {
                      validateIsFilterArray(currentFilterTags);

                      return currentFilterTags.filter((filterTag) => {
                        return filterTag.value !== tag.id;
                      });
                    });
                  }}
                >
                  {tag.label}
                </ActiveFilterPill>
              );
            })}
          </ActiveFilterPillList>
        </React.Fragment>
      );
    },
    accessor: 'tags',
    Cell: ({ value: tags }: { value: ApiAdvertTag[] }) => {
      const windowSize = useWindowSize();
      const [{ tags: availableTags, filters }, { setFilter }] = useAdvertTableMachine();
      const { on: advertTagsDropdownOpen, toggle: toggleAdvertTagsDropdown } = useToggle();

      const orderedTags = tags.sort((a) => (a.system ? -1 : 0));

      const maxTagsToRender = Boolean(windowSize.width && windowSize.width < 768) ? 1 : 3;
      const remainingTagCount = orderedTags.length - maxTagsToRender;

      const selectedFilterTags = filters.get(FILTER_KEYS.TAGS);

      const selectedTags = useMemo(() => {
        return getSelectedTags(availableTags, selectedFilterTags);
      }, [availableTags, selectedFilterTags]);

      return (
        <Box sx={{ position: 'relative' }}>
          {orderedTags.slice(0, maxTagsToRender).map((tag) => (
            <SelectedItemContainer
              key={tag.id}
              backgroundColor={tag.backgroundColor}
              borderColor={tag.borderColor}
              onClick={() => {
                setFilter(FILTER_KEYS.TAGS, (currentFilterTags = []) => {
                  validateIsFilterArray(currentFilterTags);

                  return [...currentFilterTags, formatTagToFilterTag(tag)];
                });
              }}
            >
              <ColouredTooltip label="Filter list by this tag" sx={{ bg: 'black' }}>
                <SelectedItemLabel>{tag.label}</SelectedItemLabel>
              </ColouredTooltip>
            </SelectedItemContainer>
          ))}

          {remainingTagCount > 0 && (
            <React.Fragment>
              <span
                style={{ cursor: 'pointer', fontSize: '0.9rem' }}
                onClick={() => toggleAdvertTagsDropdown()}
                onKeyDown={() => toggleAdvertTagsDropdown()}
                role="button"
                tabIndex={0}
              >
                + {remainingTagCount} more
              </span>
              <TagAutocomplete
                isOpen={advertTagsDropdownOpen}
                tags={orderedTags}
                selectedTags={selectedTags}
                onTagsChanged={(tags) => {
                  const filterTags = tags.map(formatTagToFilterTag);

                  setFilter(FILTER_KEYS.TAGS, filterTags);
                }}
                onClose={toggleAdvertTagsDropdown}
              />
            </React.Fragment>
          )}
        </Box>
      );
    },
    width: options.width,
  };
};
