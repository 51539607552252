import { ActorRefFrom, assign, createMachine } from 'xstate';

import { Advert } from '../../../api/advert';
import { assertEventType } from '../../../utils/xstate-helpers';

export interface QuickViewMachineContext {
  advert?: Advert;
  initialVariationId?: number;
}

type QuickViewMachineEvent =
  | {
      type: 'OPEN';
      advert: Advert;
      variationId?: number;
    }
  | {
      type: 'CLOSE';
    };

type QuickViewMachineState =
  | {
      value: 'closed' | 'closing';
      context: {
        advert: undefined;
        initialVariationId: undefined;
      };
    }
  | {
      value: 'opening' | 'opened';
      context: {
        advert: Advert;
        initialVariationId?: number;
      };
    };

export type QuickViewService = ActorRefFrom<typeof quickViewMachine>;

export const quickViewMachine = createMachine<
  QuickViewMachineContext,
  QuickViewMachineEvent,
  QuickViewMachineState
>(
  {
    id: 'quickViewModal',
    initial: 'closed',
    context: {
      advert: undefined,
      initialVariationId: undefined,
    },
    states: {
      closing: {
        after: {
          500: 'closed',
        },
      },
      closed: {
        entry: ['resetContext'],
        on: {
          OPEN: {
            actions: [],
            target: 'opening',
          },
        },
      },
      opening: {
        entry: ['setAdvertAndInitialVariation'],
        after: {
          500: 'opened',
        },
      },
      opened: {
        on: {
          CLOSE: 'closing',
        },
      },
    },
  },
  {
    actions: {
      setAdvertAndInitialVariation: assign((_, ev) => {
        assertEventType(ev, 'OPEN');

        return {
          advert: ev.advert,
          initialVariationId: ev.variationId,
        };
      }),
      resetContext: assign({
        advert: (_ctx, _ev) => {
          return undefined;
        },
        initialVariationId: (_ctx, _ev) => {
          return undefined;
        },
      }),
    },
  }
);
