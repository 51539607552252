import { FastField, FieldProps } from 'formik';
import * as React from 'react';

import { booleanTypes } from '../../../api/advert';
import { BasicSelect } from '../../../components/common/BasicSelect';
import { Box } from '../../../components/common/Box';
import { BasicHint } from './BasicHint';
import { QuestionFieldProps } from './QuestionFieldProps';

interface BooleanQuestionProps {
  name: string;
  question?: QuestionFieldProps;
}

export const BooleanQuestion: React.FC<BooleanQuestionProps> = React.memo((props) => {
  return (
    <FastField name={props.name}>
      {({ field, form }: FieldProps) => {
        return (
          <React.Fragment>
            <BasicSelect
              name={field.name}
              items={booleanTypes}
              selectedItem={field.value}
              renderButtonText={(item) => item.label}
              renderItem={(item) => item.label}
              onChange={(item) => {
                form.setFieldTouched(field.name);
                form.setFieldValue(field.name, item);
              }}
            />

            {props?.question?.hint && (
              <Box sx={{ mt: 5 }}>
                <BasicHint>{props.question.hint}</BasicHint>
              </Box>
            )}
          </React.Fragment>
        );
      }}
    </FastField>
  );
});
