import { Component } from 'react';

import { RuntimeError, UserFacingError } from '../../Error/BaseErrors';
import logger from '../../utils/logger';
import { ErrorBoundaryFallback } from './ErrorBoundaryFallback';

interface ErrorBoundaryProps {
  children?: any;
  FallbackComponent?: any;
  onError?: (error: Error, componentStack: string) => void;
}

interface ErrorInfo {
  componentStack: string;
}

interface ErrorBoundaryState {
  error?: Error;
  userFacingErrorMessage?: string;
  info?: ErrorInfo;
  eventId?: string;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static defaultProps = {
    FallbackComponent: ErrorBoundaryFallback,
  };

  state = {
    error: undefined,
    userFacingErrorMessage: undefined,
    info: undefined as ErrorInfo | undefined,
    eventId: undefined,
  };

  componentDidCatch(error: Error, info: ErrorInfo): void {
    const { onError } = this.props;

    if (typeof onError === 'function') {
      try {
        onError && onError(error, info ? info.componentStack : '');
      } catch (ignoredError) {}
    }

    this.setState({
      error,
      info,
      userFacingErrorMessage: error instanceof UserFacingError ? error.message : undefined,
    });

    if (process.env.NODE_ENV === 'production') {
      const extra: any = {
        componentStack: info.componentStack,
      };

      if (error instanceof RuntimeError) {
        extra.internalMessage = error.getInternalMessage();
      }

      const { sentry } = logger.logError(error, {
        extra,
      });

      this.setState({ eventId: sentry.id });
    }
  }

  reset = () => {
    this.setState({
      error: undefined,
      userFacingErrorMessage: undefined,
      info: undefined,
      eventId: undefined,
    });
  };

  render() {
    const { children, FallbackComponent } = this.props;
    const { error, info, eventId, userFacingErrorMessage } = this.state;

    if (error !== undefined) {
      return (
        <FallbackComponent
          componentStack={info ? info.componentStack : ''}
          error={error}
          sentryEventId={eventId}
          userFacingErrorMessage={userFacingErrorMessage}
          reset={this.reset}
        />
      );
    }

    return children || null;
  }
}
