import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';

import { Box } from '../../components/common/Box';
import { ErrorMessage as ThemedErrorMessage, Label, TextField } from '../../components/common/form';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTextButton,
  ModalTitle,
} from '../../components/common/Modal';
import { Text } from '../../components/common/Text';
import { RuntimeError } from '../../Error/BaseErrors';
import { AdvertTableFilters } from './machines/advertTableMachine';
import { useFilterCollectionManager } from './machines/filterCollectionManagerMachine';

interface FilterCollectionFormModalProps {
  isOpen: boolean;
  filtersToSave: AdvertTableFilters;
  onDismiss: () => void;
}

export const FilterCollectionFormModal: React.FC<FilterCollectionFormModalProps> = ({
  isOpen,
  filtersToSave,
  onDismiss,
}) => {
  const {
    state,
    collectionToSave,
    saveFilterCollection,
    overwriteExistingFilterCollection,
    cancelOverwrite,
    reload,
  } = useFilterCollectionManager();

  const handleDismiss = React.useCallback(() => {
    onDismiss();
    reload();
  }, [onDismiss, reload]);

  React.useEffect(() => {
    if (state.matches({ ready: 'saved' })) {
      handleDismiss();
    }
  }, [state, handleDismiss]);

  return (
    <Modal aria-label="Save filters" isOpen={isOpen} onDismiss={handleDismiss}>
      <ModalClose onClick={handleDismiss} />

      <ModalContainer>
        {state.matches('initialising') || state.matches({ ready: 'idle' }) ? (
          <SaveFiltersForm
            initialFilterName={collectionToSave?.name ?? ''}
            onSubmit={(filterName: string) => {
              saveFilterCollection({ name: filterName, filters: filtersToSave });
            }}
            onCancel={handleDismiss}
          />
        ) : state.matches({ ready: 'saving' }) ? (
          <SavingFilter />
        ) : state.matches({ ready: 'saved' }) ? (
          <FilterSaved onClose={handleDismiss} />
        ) : state.matches({ ready: 'nameConflict' }) ? (
          <FilterNameConflictWarning
            filterName={collectionToSave?.name ?? ''}
            onAccept={overwriteExistingFilterCollection}
            onCancel={cancelOverwrite}
          />
        ) : state.matches('failure') ? (
          <Failure onRetryClicked={reload} />
        ) : (
          <NotImplemented state={state} />
        )}
      </ModalContainer>
    </Modal>
  );
};

const SavedFilterSchema = Yup.object().shape({
  filterName: Yup.string().required().min(1),
});

interface SaveFilterFormProps {
  initialFilterName: string;
  onSubmit: (filterName: string) => void;
  onCancel: () => void;
}

const SaveFiltersForm: React.FC<SaveFilterFormProps> = (props) => {
  return (
    <Formik
      initialValues={{ filterName: props.initialFilterName }}
      validationSchema={SavedFilterSchema}
      onSubmit={(values) => {
        props.onSubmit(values.filterName);
      }}
    >
      {({ isSubmitting }) => (
        <React.Fragment>
          <ModalTitle>New saved filter.</ModalTitle>
          <Form>
            <ModalContent>
              <Box>
                <Label htmlFor="filterName">
                  Saved filter name
                  <Field name="filterName">
                    {({ field }: FieldProps) => (
                      <React.Fragment>
                        <TextField id="filterName" type="text" {...field} />
                        <Box mt={2}>
                          <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                        </Box>
                      </React.Fragment>
                    )}
                  </Field>
                </Label>
              </Box>
            </ModalContent>

            <ModalActions>
              <ModalButton data-testid="create-new-filter" type="submit" disabled={isSubmitting}>
                Save filter
              </ModalButton>

              <ModalTextButton type="button" onClick={props.onCancel}>
                Cancel
              </ModalTextButton>
            </ModalActions>
          </Form>
        </React.Fragment>
      )}
    </Formik>
  );
};

interface FilterNameConflictWarningProps {
  filterName: string;
  onAccept: () => void;
  onCancel: () => void;
}

const FilterNameConflictWarning: React.FC<FilterNameConflictWarningProps> = (props) => {
  return (
    <React.Fragment>
      <ModalTitle>Filter name in use.</ModalTitle>

      <ModalContent>
        You already have a saved filted named{' '}
        <Text as="span" sx={{ color: 'accent' }}>
          {props.filterName}
        </Text>
        . Do you want to overwrite it?
      </ModalContent>

      <ModalActions>
        <ModalButton data-testid="overwrite-existing-filter" type="button" onClick={props.onAccept}>
          Overwrite existing filter
        </ModalButton>

        <ModalTextButton type="button" onClick={props.onCancel}>
          Cancel
        </ModalTextButton>
      </ModalActions>
    </React.Fragment>
  );
};

const SavingFilter: React.FC = () => {
  return (
    <React.Fragment>
      <ModalTitle>Saving your new filter.</ModalTitle>
    </React.Fragment>
  );
};

interface FilterSavedProps {
  onClose: () => void;
}

const FilterSaved: React.FC<FilterSavedProps> = (props) => {
  return (
    <React.Fragment>
      <ModalTitle>Your filter has been saved.</ModalTitle>
    </React.Fragment>
  );
};

interface FailureProps {
  onRetryClicked: () => void;
}

const Failure: React.FC<FailureProps> = (props) => {
  return (
    <React.Fragment>
      <ModalTitle>Something went wrong.</ModalTitle>

      <ModalContent>Something went wrong whilst saving the filter.</ModalContent>

      <ModalActions>
        <ModalButton type="button" onClick={props.onRetryClicked}>
          Retry
        </ModalButton>
      </ModalActions>
    </React.Fragment>
  );
};

const NotImplemented: React.FC<{ state: any }> = ({ state }) => {
  console.debug('Handle me', state.value);
  throw new RuntimeError(
    `Something went wrong whilst saving the filter`,
    `Attempted to a render a state that isn't handled`
  );
};
