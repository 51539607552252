import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { useRef } from 'react';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { AdvertTypeSelectionModal } from '../../components/AdvertTypeSelectionModal';
import { AppHeader, AppHeaderTab, AppHeaderTabs } from '../../components/AppHeader';
import { AppShell } from '../../components/AppShell';
import { Box } from '../../components/common/Box';
import { Button } from '../../components/common/Button';
import { Flex } from '../../components/common/Flex';
import { Tooltip } from '../../components/common/Tooltip';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { Fade } from '../../components/Headless/Fade';
import { ScrollToTop } from '../../components/Headless/ScrollToTop';
import { Toggle } from '../../components/Headless/Toggle';
import { Bell, Search } from '../../components/Icon';
import { CompanyOwnerRoute } from '../../components/RouteTypes/CompanyOwnerRoute';
import { useUser } from '../../context/UserContext';
import { useToggle } from '../../hooks/useToggle';
import { DashboardContextProvider, useDashboardState } from './DashboardContext';
import { AllAdverts } from './screens/AllAdverts';
import { FavouriteAdverts } from './screens/FavouriteAdverts';
import { IncompleteAdverts } from './screens/IncompleteAdverts';
import { MyAdverts } from './screens/MyAdverts';
import { SharedAdverts } from './screens/SharedAdverts';
import { TeamAdverts } from './screens/TeamAdverts';
import { SearchInput } from './SearchInput';

interface DashboardProps extends RouteComponentProps {}

export const Dashboard: React.FC<DashboardProps> = () => {
  return (
    <React.Fragment>
      <DashboardPage>
        <ErrorBoundary>
          <Router primary={false}>
            <MyAdverts path="my-adverts" />
            <IncompleteAdverts path="incomplete-adverts" />
            <TeamAdverts path="team-adverts" />
            <FavouriteAdverts path="favourite-adverts" />
            <SharedAdverts path="shared-adverts" />

            <CompanyOwnerRoute path="/">
              <AllAdverts path="all-adverts" />
            </CompanyOwnerRoute>

            <Redirect path="/" from="/" to="/dashboard/my-adverts" noThrow />
          </Router>
        </ErrorBoundary>
      </DashboardPage>
    </React.Fragment>
  );
};

interface DashboardPageProps {}

const DashboardPage: React.FC<DashboardPageProps> = ({ children }) => {
  return (
    <React.Fragment>
      <Helmet title="Dashboard" />

      <DashboardContextProvider>
        <AppShell backgroundColor="#f6f6f6">
          <ScrollToTop after={1000}>
            <div>
              <Box
                sx={{
                  '@media screen and (max-width: 650px)': {
                    display: 'none',
                  },
                }}
              >
                <Header />
              </Box>

              <Tabs />
              {children}
            </div>
          </ScrollToTop>
        </AppShell>
      </DashboardContextProvider>
    </React.Fragment>
  );
};

const Header = () => {
  const user = useUser();
  const { on, set } = useToggle();

  return (
    <React.Fragment>
      <AppHeader
        title={`Welcome back${user && user.firstName ? ', ' + user.firstName : ''}`}
        renderRight={
          <React.Fragment>
            <Button
              data-testid="create-new-advert-toggle"
              variant="primaryInverted"
              sx={{
                fontWeight: 500,
                fontSize: 2,
                letterSpacing: '-0.01rem',
              }}
              onClick={() => set(true)}
            >
              Create a New Advert +
            </Button>

            <AdvertTypeSelectionModal isOpen={on} onDismiss={() => set(false)} />
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

const SearchInputTab: React.FC = React.memo(() => {
  const inputRef = useRef<HTMLInputElement>();

  return (
    <ErrorBoundary FallbackComponent={() => <Box>Something went wrong whilst searching</Box>}>
      <Toggle
        onToggleCallback={() => {
          setTimeout(() => {
            inputRef.current && inputRef.current.focus();
          }, 0);
        }}
      >
        {(toggleProps) => (
          <AppHeaderTab
            as={Box}
            sx={{
              cursor: 'pointer',
              '&:hover, &:focus': {
                '::after': {
                  opacity: 0,
                },
              },
            }}
            onClick={() => {
              toggleProps.onToggle();
            }}
          >
            <Flex
              sx={{
                width: '100%',
                height: '100%',
                alignItems: 'flex-end',
                ml: 5,
              }}
            >
              <Tooltip
                label="Search all adverts by job title, company name, location or the person who created the advert"
                sx={{ maxWidth: '300px' }}
              >
                <Box
                  sx={{
                    display: 'inline',
                    pr: '16px',
                    color: toggleProps.on ? 'white' : 'inactive',
                    transition: '200ms color ease-in',
                    '&:hover, &:focus': {
                      color: 'white',
                    },
                  }}
                >
                  <Search />
                </Box>
              </Tooltip>

              <Fade active={toggleProps.on}>
                <Box
                  sx={{ position: 'relative', top: '6px', textAlign: 'left' }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                  }}
                >
                  <SearchInput ref={inputRef} />
                </Box>
              </Fade>
            </Flex>
          </AppHeaderTab>
        )}
      </Toggle>
    </ErrorBoundary>
  );
});

const Tabs = () => {
  const user = useUser();
  const { advertCounts, countFetchRequestStatus } = useDashboardState();

  if (countFetchRequestStatus === 'loading') {
    return null;
  }

  const tabs = [
    {
      label: 'My Adverts',
      path: '/dashboard/my-adverts',
    },
    advertCounts.incomplete > 0 && {
      label: `Incomplete Adverts (${advertCounts.incomplete})`,
      path: '/dashboard/incomplete-adverts',
    },
    {
      label: 'Team Adverts',
      path: '/dashboard/team-adverts',
    },
    advertCounts.shared > 0 && {
      label: advertCounts.unseenShared ? (
        <strong>
          <Box sx={{ position: 'relative' }}>
            Shared Adverts ({advertCounts.shared})
            <Box sx={{ position: 'absolute', top: '-10px', right: '-10px' }}>
              <Bell />
            </Box>
          </Box>
        </strong>
      ) : (
        `Shared Adverts (${advertCounts.shared})`
      ),
      path: '/dashboard/shared-adverts',
    },
    advertCounts.favourite > 0 && {
      label: `Favourite Adverts (${advertCounts.favourite})`,
      path: '/dashboard/favourite-adverts',
    },
    user.isCompanyOwner && {
      label: `All Adverts (${advertCounts.company})`,
      path: '/dashboard/all-adverts',
    },
  ];

  const filteredTabs = tabs.filter((tab) => tab !== false) as {
    label: string | JSX.Element;
    path: string;
  }[];

  return (
    <AppHeaderTabs>
      {filteredTabs.map((tab) => {
        return (
          <AppHeaderTab key={tab.path} to={tab.path}>
            {tab.label}
          </AppHeaderTab>
        );
      })}

      <SearchInputTab key="search-input" />
    </AppHeaderTabs>
  );
};
