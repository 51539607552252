import { useQuery } from 'react-query';

import { client } from './client';

export enum ApiPaymentEventType {
  SUCCEEDED = 1,
  FAILED = 2,
}

export interface ApiPaymentEvent {
  id: number;
  type: ApiPaymentEventType;
  title: string;
  amount: number;
  currency: string;
  createdAt: string;
}

const fetchPaymentHistory = async (
  companyId: number
): Promise<{ paymentHistory: ApiPaymentEvent[] }> => {
  const response = await client.get(`/company/${companyId}/payment-history`);

  return { paymentHistory: response.data.paymentHistory };
};

const useCompanyPaymentHistoryQuery = (companyId: number) => {
  return useQuery(['paymentHistory', companyId], () => fetchPaymentHistory(companyId), {
    cacheTime: 0,
  });
};

const fetchPaymentEventPdf = async (
  companyId: number,
  eventId: number
): Promise<{ url: string }> => {
  const response = await client.get(
    `/company/${companyId}/payment-history/events/${eventId}/invoice`
  );

  return { url: response.data.url };
};

export const companyPaymentHistoryApi = {
  fetchPaymentHistory,
  fetchPaymentEventPdf,

  useCompanyPaymentHistoryQuery,
};
