import styled from '@emotion/styled/macro';
import { Fragment } from 'react';

import { Box } from '../../components/common/Box';
import { Button } from '../../components/common/Button';
import { Flex } from '../../components/common/Flex';
import { Heading } from '../../components/common/Heading';
import { Text } from '../../components/common/Text';

const SummaryContainer = styled(Flex)`
  width: 80%;
  flex-direction: column;
  justify-content: center;
  padding-right: 0rem;
  padding-left: 0rem;

  @media (min-width: 1500px) {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  @media (min-width: 1700px) {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }
`;

const VideoContainer = styled(Flex)`
  width: 80%;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 1500px) {
    padding-right: 3rem;
  }

  @media (min-width: 1700px) {
    padding-right: 4.5rem;
  }
`;

const Video = styled.video`
  width: 100%;
  max-width: 30rem;
  height: auto;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.1), 0 12px 17px 2px rgba(0, 0, 0, 0.07),
    0 5px 22px 4px rgba(0, 0, 0, 0.06);

  @media (min-width: 1300px) {
    max-width: 35rem;
  }

  @media (min-width: 1500px) {
    max-width: none;
  }
`;

export const Summary = () => {
  return (
    <Fragment>
      <SummaryContainer>
        <Box mb={8}>
          <Heading
            as="h2"
            sx={{
              fontSize: 7,
              fontWeight: 'semibold',
              lineHeight: '1.1',
              letterSpacing: '-0.075rem',
              color: 'white',
              pb: 7,
              m: 0,
            }}
          >
            Quickly build the best job adverts.
          </Heading>

          <Text
            sx={{
              fontSize: '1.1rem',
              fontWeight: 400,
              color: 'whitefade80',
              pb: 7,
              m: 0,
              letterSpacing: '-0.01rem',
              lineHeight: '1.8rem',
            }}
          >
            Create expert-level job adverts in less than 10 minutes with AdBuilder. Zero creativity
            required.
          </Text>

          <Button
            as="a"
            href="https://adbuilder.io"
            variant="primaryInverted"
            sx={{
              display: 'inline-block',
              px: '30px',
              py: '20px',
              fontSize: '1.1rem',
              fontWeight: 500,
              letterSpacing: '-0.01rem',
              textDecoration: 'none',
            }}
          >
            Learn More About{' '}
            <Box as="span" sx={{ whiteSpace: 'nowrap' }}>
              AdBuilder &rarr;
            </Box>
          </Button>
        </Box>
      </SummaryContainer>

      <VideoContainer>
        <Box>
          <Video autoPlay muted loop>
            <source
              src="https://adbuilder.io/wp-content/uploads/2020/09/Looping-video.mp4"
              type="video/mp4"
            />
          </Video>
        </Box>
      </VideoContainer>
    </Fragment>
  );
};
