import { Router } from '@reach/router';

import { DiscountCountdownBanner } from './components/DiscountCountdownBanner';
import { ErrorBoundary } from './components/ErrorBoundary';
import { ForgotPassword } from './screens/Auth/ForgotPassword';
import { Invitation } from './screens/Auth/Invitation';
import { OAuthResponseHandler } from './screens/Auth/LinkedInOAuthRedirect';
import { Login } from './screens/Auth/Login';
import { Registration } from './screens/Auth/Registration';
import { ResetPassword } from './screens/Auth/ResetPassword';
import { authTheme } from './theme/authTheme';
import { ThemeProvider } from './theme/ThemeProvider';

export const UnauthenticatedApp = () => (
  <ThemeProvider theme={authTheme as TSThemeFixMe}>
    <ErrorBoundary>
      <DiscountCountdownBanner />
      <Router style={{ height: '100%' }}>
        <Login default path="login" />
        <Registration path="register" />
        <ForgotPassword path="forgot-password" />
        <ResetPassword path="reset-password" />
        <Invitation path="invitation" />
      </Router>
      <OAuthResponseHandler />
    </ErrorBoundary>
  </ThemeProvider>
);
