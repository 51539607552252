import { useThemeUI } from 'theme-ui';

import { IAppTheme } from '../theme/appTheme';

export const useTheme = () => {
  const { theme } = useThemeUI();

  return (theme as unknown) as IAppTheme;
};

interface ThemeConsumerProps {
  children: (theme: IAppTheme) => any;
}

export const ThemeConsumer = (props: ThemeConsumerProps) => {
  const theme = useTheme();

  return props.children(theme);
};
