import { useQuery } from 'react-query';

import { ApiUser } from './auth';
import { client } from './client';
import { ApiPaymentEvent } from './company-payment-history';
import { ApiSubscriptionPlanPeriodUnit } from './subscription-plan-pricing';

export enum ApiCompanySubscriptionBillingFrequency {
  Monthly = 1,
  Annually = 2,
}

export interface ApiCompanyBillingInformation {
  accountingEmail: string;
  address: {
    lineOne: string;
    lineTwo: string;
    lineThree: string;
    postalCode: string;
    city: string;
    country: string;
  };
  lastFour: string;
}

export interface ApiCompanySubscription {
  planId: string;
  tierId: string;
  tierName: string;
  seats: number;
  currency: string;
  currentPeriodEnd: Date;
  payments: ApiPaymentEvent[];
  billingFrequency: ApiCompanySubscriptionBillingFrequency;
  cancelledAt: Date;
  cancelledBy: ApiUser;
}

export interface ApiCompanySubscriptionDetails {
  subscription: {
    tierName: string;
    currentPeriodEnds: Date;
    price: number;
    period: number;
    periodUnit: ApiSubscriptionPlanPeriodUnit;
    currencyCode: string;
  };
  card: {
    lastFour: string;
  };
  companyName: string;
  companyDetails: ApiCompanyBillingInformation;
}

export interface BillingDetails {
  accountingEmail: string;
  vatNumber: string;
  address: {
    lineOne: string;
    lineTwo?: string;
    lineThree?: string;
    postalCode: string;
    city: string;
    country: string;
  };
  stripe?: {
    token: string;
    lastFour: string;
  };
}

const fetchSubscription = async (companyId: number): Promise<ApiCompanySubscriptionDetails> => {
  const response = await client.get(`/company/${companyId}/subscription`);

  return response.data;
};

const useCompanySubscriptionQuery = (companyId: number) => {
  return useQuery(['companySubscription', companyId], () => fetchSubscription(companyId), {
    cacheTime: 0,
  });
};

export interface UpdateSubscriptionRequestParams {
  companyId: number;
  planId: string;
  seats: number;
  discountToken?: string;
}

const updateSubscription = async (params: UpdateSubscriptionRequestParams) => {
  const response = await client.post(`/company/${params.companyId}/subscription/change`, {
    planId: params.planId,
    seats: params.seats,
    discountToken: params.discountToken,
  });

  return { subscriptionChanged: response.data.subscriptionChanged };
};

const cancelSubscription = async (
  companyId: number,
  reason: number
): Promise<{ cancelled: boolean }> => {
  await client.post(`/company/${companyId}/subscription/cancel`, {
    reason,
  });

  return { cancelled: true };
};

const pauseSubscription = async (
  companyId: number
): Promise<{ paused: boolean; currentPeriodEnds: Date }> => {
  const response = await client.post(`/company/${companyId}/subscription/pause`);

  return { paused: response.data.paused, currentPeriodEnds: response.data.currentPeriodEnds };
};

const resumeSubscription = async (
  companyId: number
): Promise<{ resumed: boolean; currentPeriodEnds: Date }> => {
  const response = await client.post(`/company/${companyId}/subscription/resume`);

  return { resumed: response.data.resumed, currentPeriodEnds: response.data.currentPeriodEnds };
};

const updateCard = async (companyId: number, token: string): Promise<{ cardUpdated: boolean }> => {
  const response = await client.post(`/company/${companyId}/subscription/update-card`, {
    sourceToken: token,
  });

  return { cardUpdated: response.data.cardUpdated };
};

export const companySubscriptionApi = {
  fetchSubscription,
  updateSubscription,
  pauseSubscription,
  resumeSubscription,
  cancelSubscription,
  updateCard,

  useCompanySubscriptionQuery,
};
