import { RouteComponentProps } from '@reach/router';
import qs from 'qs';
import React from 'react';

import { businessTypes, RegistrationMetadata } from '../../api/auth';
import { getRegistrationMetadata } from '../../utils/auth/registration-utils';
import { RegistrationWizardVariant } from './RegistrationVariants/RegistrationWizardVariant';

const VARIANT_WIZARD = 'wizard';
const REGISTRATION_VARIANTS = [VARIANT_WIZARD];

interface RegistrationProps extends RouteComponentProps {}

export const Registration: React.FC<RegistrationProps> = (props) => {
  const sharedValues = useRegistrationSharedValues(props.location?.search ?? '');

  return (
    <RegistrationWizardVariant
      initialValues={sharedValues.initialFormValues}
      metadata={sharedValues.metadata}
    />
  );
};

/**
 * Parse the query params and merge them with the default options
 */
export const useRegistrationSharedValues = (locationSearch: string) => {
  const sharedValues = React.useMemo(() => {
    return {
      initialFormValues: getInitialFormValues(locationSearch),
      metadata: getMetadata(locationSearch),
    };
  }, [locationSearch]);

  return sharedValues;
};

const getInitialFormValues = (locationSearch: string) => {
  const queryParams = qs.parse(locationSearch, { ignoreQueryPrefix: true });

  const values = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    businessType: businessTypes[0],
    recaptcha: '',
  };

  const { email: urlEmail, drip_email: dripEmail, bt: urlBusinessType } = queryParams;

  if (typeof urlBusinessType === 'string') {
    const matchedType = businessTypes.find((type) => type.id === parseInt(urlBusinessType, 10));

    values.businessType = matchedType ?? businessTypes[0];
  }

  if (typeof urlEmail === 'string') {
    values.email = urlEmail;
  } else if (typeof dripEmail === 'string') {
    values.email = dripEmail;
  }

  return values;
};

const getMetadata = (locationSearch: string): RegistrationMetadata => {
  let metadata = getRegistrationMetadata(locationSearch);
  const fallbackVariant = VARIANT_WIZARD;

  if (metadata.variant.length === 0) {
    metadata.variant = fallbackVariant;
  } else {
    metadata.variant = REGISTRATION_VARIANTS.includes(metadata.variant)
      ? metadata.variant
      : fallbackVariant;
  }

  return metadata;
};
