import { useQuery } from 'react-query';

import { ApiUser, AuthorityLevel } from './auth';
import { client } from './client';
import { ApiInvite } from './invitation';

export interface ApiAdvertCompany {
  id: number;
  rootId: number;
  name: string;
  data: { [key: string]: string };
}

interface FetchCompanyMembersResponse {
  companyMembers: ApiUser[];
}

const fetchCompanyMembers = async (companyId: number): Promise<FetchCompanyMembersResponse> => {
  const response = await client.get(`/company/${companyId}/members`);

  return {
    companyMembers: response.data.companyMembers,
  };
};

const useCompanyMembers = (companyId: number) => {
  return useQuery<FetchCompanyMembersResponse, Error>(['companyMembers', companyId], async () => {
    return fetchCompanyMembers(companyId);
  });
};

export interface EditCompanyMemberInformation {
  firstName: string;
  lastName: string;
  authorityLevel: AuthorityLevel;
  team: number;
}

const editCompanyMember = async (
  companyId: number,
  companyMemberId: number,
  info: EditCompanyMemberInformation
): Promise<{ user: ApiUser }> => {
  const response = await client.post(`/company/${companyId}/members/${companyMemberId}`, info);

  return response.data;
};

const removeCompanyMember = async (
  companyId: number,
  userId: number
): Promise<{ removed: boolean }> => {
  const response = await client.post(`/company/${companyId}/members/${userId}/remove`);

  return {
    removed: response.data.removed,
  };
};

interface FetchCompanyInvitesResponse {
  invites: ApiInvite[];
}

const fetchCompanyInvites = async (companyId: number): Promise<FetchCompanyInvitesResponse> => {
  const response = await client.get(`/company/${companyId}/invites/active`);

  return {
    invites: response.data.invites,
  };
};

const useCompanyInvites = (companyId: number) => {
  return useQuery<FetchCompanyInvitesResponse, Error>(['companyInvites', companyId], async () => {
    return fetchCompanyInvites(companyId);
  });
};

const fetchCompanyExpiredInvites = async (
  companyId: number
): Promise<FetchCompanyInvitesResponse> => {
  const response = await client.get(`/company/${companyId}/invites/expired`);

  return {
    invites: response.data.invites,
  };
};

const useCompanyExpiredInvites = (companyId: number) => {
  return useQuery<FetchCompanyInvitesResponse, Error>(
    ['companyExpiredInvites', companyId],
    async () => {
      return fetchCompanyExpiredInvites(companyId);
    }
  );
};

const revokeCompanyInvite = async (
  companyId: number,
  inviteId: number
): Promise<{ invite: ApiInvite }> => {
  const response = await client.post(`/company/${companyId}/invites/${inviteId}/revoke`);

  return {
    invite: response.data.invite,
  };
};

const resendCompanyInvite = async (
  companyId: number,
  inviteId: number
): Promise<{ invite: ApiInvite }> => {
  const response = await client.post(`/company/${companyId}/invites/${inviteId}/resend`);

  return {
    invite: response.data.invite,
  };
};

export const companyApi = {
  fetchCompanyMembers,
  editCompanyMember,
  removeCompanyMember,
  fetchCompanyInvites,
  revokeCompanyInvite,
  resendCompanyInvite,

  useCompanyMembers,
  useCompanyInvites,
  useCompanyExpiredInvites,
};
