import { Link as RRLink } from '@reach/router';
import * as React from 'react';

import { Advert } from '../../../api/advert';
import { Tooltip } from '../../../components/common/Tooltip';
import { Pen } from '../../../components/Icon';
import { useTheme } from '../../../hooks/useTheme';
import { FavouriteAdvertButton } from '../FavouriteAdvertButton';

interface FavouriteOrEditActionCellProps {
  advert: Advert;
  onFavourite: (variationId: number) => Promise<void>;
  onUnfavourite: () => Promise<void>;
  onQuickView: (advert: Advert, variationId: number) => void;
}

export const FavouriteOrEditActionCell: React.FC<FavouriteOrEditActionCellProps> = (props) => {
  const theme = useTheme();

  return props.advert.isComplete ? (
    <FavouriteAdvertButton
      advert={props.advert}
      onFavourite={props.onFavourite}
      onUnfavourite={props.onUnfavourite}
      onQuickView={(variationId) => {
        props.onQuickView(props.advert, variationId);
      }}
    />
  ) : (
    <Tooltip label="Complete this advert">
      <RRLink to={`/advert/${props.advert.id}`} data-testid="edit-advert-icon">
        <Pen size="20px" hoverColour={theme.colors.blue[3]} />
      </RRLink>
    </Tooltip>
  );
};
