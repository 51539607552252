import qs from 'qs';

import { getDeviceType } from '../device';

export const getRegistrationMetadata = (locationSearch: string) => {
  const { variant, source, utm_source } = qs.parse(locationSearch, { ignoreQueryPrefix: true });

  return {
    variant: typeof variant === 'string' ? variant : '',
    source:
      typeof utm_source === 'string' ? utm_source : typeof source === 'string' ? source : 'direct',
    deviceType: getDeviceType(),
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'Europe/London',
  };
};
