import * as React from 'react';

import { Box } from '../../components/common/Box';
import { Flex } from '../../components/common/Flex';
import { SubscriptionPageHeader } from './SubscriptionPageHeader';

interface SubscriptionPageErrorProps {
  title: string;
  subTitle: string;
  errorMessage: string;
  renderAction: React.ReactNode;
}

export const SubscriptionPageError: React.FC<SubscriptionPageErrorProps> = (props) => {
  return (
    <Box>
      <SubscriptionPageHeader title={props.title} subTitle={props.subTitle} />

      <Box sx={{ height: '3.75rem' }} />
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Box pb={3}>{props.errorMessage}</Box>

        {props.renderAction}
      </Flex>
    </Box>
  );
};
