import '@reach/tabs/styles.css';

import {
  Tab as ReachTab,
  TabList as ReachTabList,
  TabListProps as ReachTabListProps,
  TabPanel as ReachTabPanel,
  TabPanelProps as ReachTabPanelProps,
  TabPanels as ReachTabPanels,
  TabPanelsProps as ReachTabPanelsProps,
  TabProps as ReachTabProps,
  Tabs as ReachTabs,
  TabsProps as ReachTabsProps,
} from '@reach/tabs';
import * as React from 'react';
import { Assign, ForwardRef } from 'theme-ui';

import { Box, BoxProps } from './Box';

export type TabsProps = ReachTabsProps;

export const Tabs = ReachTabs;

export interface TabListProps extends Assign<ReachTabListProps, BoxProps> {}

export const TabList: ForwardRef<HTMLDivElement, TabListProps> = React.forwardRef((props, ref) => {
  return <Box as={ReachTabList} variant="primary" {...props} ref={ref} __themeKey="tabs.tabList" />;
});

export interface TabProps extends ReachTabProps {
  isSelected?: boolean;
}

export const Tab: React.FC<TabProps> = React.forwardRef((props, ref) => {
  return (
    <Box
      as={ReachTab}
      // @ts-ignore
      type="button"
      variant={props.isSelected ? 'selected' : 'primary'}
      {...props}
      ref={ref}
      __themeKey="tabs.tab"
    >
      {props.children}
    </Box>
  );
});

export type TabPanelsProps = ReachTabPanelsProps;

export const TabPanels: React.FC<TabPanelsProps> = React.forwardRef((props, ref) => {
  return (
    <Box as={ReachTabPanels} variant="primary" {...props} ref={ref} __themeKey="tabs.tabPanels" />
  );
});

export type TabPanelProps = ReachTabPanelProps;

export const TabPanel: React.FC<TabPanelProps> = React.forwardRef((props, ref) => {
  return (
    <Box as={ReachTabPanel} variant="primary" {...props} ref={ref} __themeKey="tabs.tabPanel" />
  );
});
