import LogRocket from 'logrocket';
import { useContext, useEffect } from 'react';
import * as React from 'react';

import { ApiUser } from '../api/auth';
import { useAuth } from './AuthContext';

const initialContextValue: any = {};

export const UserContext = React.createContext<ApiUser>(initialContextValue);

interface UserProviderProps {}

export const UserProvider: React.FC<UserProviderProps> = (props) => {
  const { user } = useAuth();

  if (!user) {
    throw new Error('The UserProvider requires a user from the AuthContext to be present');
  }

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      LogRocket.identify(user.id.toString(), {
        name: user.displayName,
        email: user.email,
      });
    }
  }, [user]);

  return <UserContext.Provider value={user} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }

  return context;
};
