export const builderTheme = {
  forms: {
    label: {
      color: 'blackfade90',
      letterSpacing: '-0.01rem',
      fontWeight: 'normal',
      fontSize: '1.125rem',
    },
    input: {
      display: 'block',
      width: '100%',
      px: 3,
      py: 5,
      backgroundColor: 'white',
      color: 'blackfade90',
      borderStyle: '0',
      borderWidth: '1',
      borderColor: 'grey.3',
      borderRadius: '0',
      fontSize: '2',
      fontWeight: 'normal',
      letterSpacing: '-0.01rem',
      lineHeight: '1.5rem',
      transition: 'all 200ms ease-in-out',
      WebkitAppearance: 'none',
      '&::-webkit-input-placeholder': {
        color: 'blackfade35',
      },
      '&:hover': {
        borderColor: '#777777',
      },
      '&:focus': {
        borderColor: (t: any) => t.colors.accent,
      },
    },
    select: {
      button: {
        display: 'block',
        width: '100%',
        px: 3,
        py: 5,
        backgroundColor: 'white',
        color: 'blackfade90',
        borderStyle: '0',
        borderWidth: '1',
        borderColor: 'grey.3',
        borderRadius: '0',
        fontSize: '2',
        fontWeight: 'normal',
        letterSpacing: '-0.01rem',
        lineHeight: '1.5rem',
        transition: 'all 200ms ease-in-out',
        WebkitAppearance: 'none',
        '&:hover, &:focus': {
          borderColor: '#777777',
        },
      },
      menu: {
        zIndex: 2,
        position: 'absolute',
        width: '100%',
        top: 'calc(100% + 1rem)',
        left: 0,
        maxHeight: '250px',
      },
      item: {
        fontSize: '0.9rem',
        padding: 3,
      },
    },
    errorMessage: {
      mt: 2,
    },
  },
};
