import { MenuItem } from '@reach/menu-button';
import * as React from 'react';

import { Advert } from '../../../api/advert';

interface QuickViewActionProps {
  advert: Advert;
  onQuickView: () => void;
}

export const QuickViewAction: React.FC<QuickViewActionProps> = (props) => {
  return (
    <MenuItem key="quick-view" data-testid="advert-actions-quick-view" onSelect={props.onQuickView}>
      Quick View
    </MenuItem>
  );
};
