import styled from '@emotion/styled/macro';
import * as React from 'react';
import Transition, { TransitionStatus } from 'react-transition-group/Transition';

interface FadeProps {
  active: boolean;
  duration?: number;
  containerStyles?: React.CSSProperties;
}

const transitionStyles = {
  entering: { opacity: 0, display: 'block' },
  entered: { opacity: 1 },
  exiting: {},
  exited: { display: 'none' },
  unmounted: {},
};

interface ContainerProps {
  duration: number;
}

const Container = styled.div<ContainerProps>`
  transition: ${(props: ContainerProps) => `opacity ${props.duration}ms ease-in-out`};
  opacity: 0;
  width: 100%;
`;

export const Fade: React.FC<FadeProps> = ({
  active,
  duration = 200,
  containerStyles = {},
  children,
}) => (
  <Transition in={active} timeout={duration}>
    {(state: TransitionStatus) => (
      <Container style={{ ...transitionStyles[state], ...containerStyles }} duration={duration}>
        {children}
      </Container>
    )}
  </Transition>
);
