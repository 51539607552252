import { RuntimeError } from '../../../Error/BaseErrors';
import { AdvertTableFilter } from '../machines/advertTableMachine';

export function validateIsFilterArray(
  value: AdvertTableFilter | AdvertTableFilter[] | undefined
): asserts value is AdvertTableFilter[] | undefined {
  if (!Array.isArray(value)) {
    throw new RuntimeError(
      'Tag filter should be an array, single item given',
      'Something went wrong loading the dashboard'
    );
  }
}

export function validateIsSingularFilter(
  value: AdvertTableFilter | AdvertTableFilter[] | undefined
): asserts value is AdvertTableFilter | undefined {
  if (Array.isArray(value)) {
    throw new RuntimeError(
      'Tag filter should be a single item, array given',
      'Something went wrong loading the dashboard'
    );
  }
}
