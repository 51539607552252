import { useSelect } from 'downshift';
import React from 'react';

import { Box } from '../common/Box';
import { Flex } from '../common/Flex';
import { Grid } from '../common/Grid';
import { Heading } from '../common/Heading';
import { SelectButton, SelectMenu, SelectMenuItem } from '../common/Select';
import { Text } from '../common/Text';
import { Check } from '../Icon';
import { RegistrationFormLabel } from '../RegistrationFormLabel';

interface RegistrationWizardContainerProps {
  children: React.ReactChild | React.ReactChild[];
}

const RegistrationWizardContainer = (props: RegistrationWizardContainerProps) => {
  return (
    <Flex sx={{ minHeight: '100vh', bg: 'offWhite', justifyContent: 'center', pt: 10 }}>
      <Box sx={{ width: '700px', px: [3, null] }}>{props.children}</Box>
    </Flex>
  );
};

const RegistrationWizardContent: React.FC = (props) => {
  return (
    <Box
      sx={{
        bg: 'white',
        width: '100%',
        boxShadow:
          '0 4px 5px -1px rgba(0, 0, 0, 0.1), 0 7px 10px 0 rgba(0, 0, 0, 0.07), 0 12px 18px 0 rgba(0, 0, 0, 0.06)',
        mb: 5,
        borderRadius: '8px',
        border: '1px solid #e1e1e1',
      }}
    >
      {props.children}
    </Box>
  );
};

const RegistrationWizardFreeTrialHeader = () => {
  return (
    <React.Fragment>
      <Box
        sx={{
          textAlign: 'center',
          lineHeight: '1.1',
          letterSpacing: '-0.075rem',
        }}
      >
        <Heading sx={{ color: 'accent', fontWeight: 500, fontSize: [5, 7] }}>
          Start your free trial today.
        </Heading>

        <Heading sx={{ fontWeight: 500, fontSize: [5, 7] }}>
          Change how you recruit forever.
        </Heading>
      </Box>

      <Box sx={{ pb: 8 }} />
    </React.Fragment>
  );
};

interface RegistrationWizardStepIndicatorProps {
  steps: string[];
  activeStepIndex: number;
}

const RegistrationWizardStepIndicator = (props: RegistrationWizardStepIndicatorProps) => {
  return (
    <Flex
      sx={{ width: '100%', justifyContent: 'space-between', pb: 3, letterSpacing: '-0.025rem' }}
    >
      {props.steps.map((step, index) => {
        const isStepActive = props.activeStepIndex === index;
        const isStepCompleted = props.activeStepIndex > index;

        return (
          <Grid
            key={index}
            sx={{
              gridTemplateColumns: '25px auto',
              columnGap: [2, 3],
              alignItems: 'center',
              fontWeight: 500,
              color: isStepActive || isStepCompleted ? 'blackfade85' : 'blackfade35',
            }}
          >
            <Flex
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                pt: '0',
                borderRadius: '50%',
                border: '3px solid',
                borderColor: isStepCompleted ? 'white' : 'accent',
                bg: isStepCompleted ? 'primary' : isStepActive ? '#5ba8d9' : undefined,
                color: isStepCompleted || isStepActive ? 'white' : 'accent',
                fontWeight: 700,
                letterSpacing: '-0.025rem',

                width: ['25px', '30px'],
                height: ['25px', '30px'],
                lineHeight: ['25px', '30px'],
                fontSize: 1,
              }}
            >
              {isStepCompleted ? (
                <Box sx={{ fontSize: [0, 1] }}>
                  <Check />{' '}
                </Box>
              ) : (
                <Text as="span">{index + 1}</Text>
              )}
            </Flex>
            <Text sx={{ fontSize: [1, 3] }}>{step}</Text>
          </Grid>
        );
      })}
    </Flex>
  );
};

interface RegistrationWizardSelectItem {
  id: number;
  name: string;
}

interface RegistrationWizardSelectProps {
  name: string;
  label: string;
  items: RegistrationWizardSelectItem[];
  selectedItem: RegistrationWizardSelectItem;
  onChange: (item: RegistrationWizardSelectItem) => void;
}

const RegistrationWizardSelect: React.FC<RegistrationWizardSelectProps> = (props) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: props.items,
    selectedItem: props.selectedItem,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        props.onChange(selectedItem);
      }
    },
  });

  return (
    <Box sx={{ position: 'relative' }}>
      <RegistrationFormLabel {...getLabelProps()}>{props.label}</RegistrationFormLabel>
      <SelectButton
        {...getToggleButtonProps({ type: 'button' })}
        isOpen={isOpen}
        data-testid={`${props.name}`}
      >
        {selectedItem ? selectedItem.name : null}
      </SelectButton>

      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          top: 'calc(100% + 1rem)',
          zIndex: 2,
        }}
      >
        <SelectMenu
          {...getMenuProps()}
          sx={{ boxShadow: isOpen ? 'selectMenu' : undefined }}
          data-testid={`${props.name}-menu`}
        >
          {isOpen
            ? props.items.map((item, index) => (
                <SelectMenuItem
                  key={`${item.id}`}
                  sx={
                    highlightedIndex === index
                      ? { backgroundColor: '#eeeeee', borderRadius: '4px' }
                      : {}
                  }
                  {...getItemProps({ item, index })}
                >
                  {item.name}
                </SelectMenuItem>
              ))
            : null}
        </SelectMenu>
      </Box>
    </Box>
  );
};

export const RegistrationWizard = {
  Container: RegistrationWizardContainer,
  Content: RegistrationWizardContent,
  FreeTrialHeader: RegistrationWizardFreeTrialHeader,
  StepIndicator: RegistrationWizardStepIndicator,
  Select: RegistrationWizardSelect,
};
