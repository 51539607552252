import * as React from 'react';

import { Box } from '../../components/common/Box';
import { Flex } from '../../components/common/Flex';
import { SubscriptionPageHeader } from './SubscriptionPageHeader';

interface SubscriptionPageLoadingProps {
  title: string;
  subTitle: string;
}

export const SubscriptionPageLoading: React.FC<SubscriptionPageLoadingProps> = (props) => {
  return (
    <Box>
      <SubscriptionPageHeader title={props.title} subTitle={props.subTitle} />

      <Box sx={{ height: '3.75rem' }} />
      <Flex sx={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        Loading...
      </Flex>
    </Box>
  );
};
