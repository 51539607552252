import { useContext, useMemo } from 'react';
import * as React from 'react';
import { QueryStatus, useQuery, useQueryClient } from 'react-query';

import { advertApi, ApiAdvertCounts } from '../../api/advert';
import { useLogError } from '../../hooks/useLogError';

export enum DashboardAdvertCountNames {
  User = 'user',
  Incomplete = 'incomplete',
  Team = 'team',
  Shared = 'shared',
  UnseenShared = 'unseenShared',
  Favourite = 'favourite',
  Company = 'company',
}

interface State {
  countFetchRequestStatus: QueryStatus;
  advertCounts: ApiAdvertCounts;
}

interface Actions {
  incrementCount: (countName: DashboardAdvertCountNames) => void;
  decrementCount: (countName: DashboardAdvertCountNames) => void;
}

const DashboardStateContext = React.createContext<State | undefined>(undefined);
const DashboardActionsContext = React.createContext<Actions | undefined>(undefined);

const DEFAULT_COUNTS: ApiAdvertCounts = {
  [DashboardAdvertCountNames.User]: 0,
  [DashboardAdvertCountNames.Incomplete]: 0,
  [DashboardAdvertCountNames.Team]: 0,
  [DashboardAdvertCountNames.Shared]: 0,
  [DashboardAdvertCountNames.UnseenShared]: 0,
  [DashboardAdvertCountNames.Favourite]: 0,
  [DashboardAdvertCountNames.Company]: 0,
};

export const DashboardContextProvider: React.FC = (props) => {
  const queryClient = useQueryClient();

  const { status, data: advertCounts, error } = useQuery(
    ['advertCounts'],
    advertApi.fetchAdvertCounts,
    {
      refetchOnWindowFocus: false,
    }
  );
  useLogError(error);

  const contextState: State = useMemo(() => {
    return {
      countFetchRequestStatus: status,
      advertCounts: advertCounts ?? DEFAULT_COUNTS,
    };
  }, [status, advertCounts]);

  const contextActions: Actions = useMemo(() => {
    return {
      incrementCount: (countName) => {
        queryClient.setQueryData(['advertCounts'], (counts: any) => {
          return { ...counts, [countName]: counts[countName] + 1 };
        });
      },
      decrementCount: (countName) => {
        queryClient.setQueryData(['advertCounts'], (counts: any) => {
          return { ...counts, [countName]: counts[countName] - 1 };
        });
      },
    };
  }, [queryClient]);

  return (
    <DashboardStateContext.Provider value={contextState}>
      <DashboardActionsContext.Provider value={contextActions}>
        {props.children}
      </DashboardActionsContext.Provider>
    </DashboardStateContext.Provider>
  );
};

export const useDashboardState = () => {
  const state = useContext(DashboardStateContext);

  if (typeof state === undefined) {
    throw new Error('useDashboardState must be used within a DashboardProvider');
  }

  return state as State;
};

export const useDashboardActions = () => {
  const actions = useContext(DashboardActionsContext);

  if (typeof actions === undefined) {
    throw new Error('useDashboardActions must be used within a DashboardProvider');
  }

  return actions as Actions;
};

export const useDashboard = (): [State, Actions] => {
  return [useDashboardState(), useDashboardActions()];
};
