import { Sender } from '@xstate/react/lib/types';
import { toast } from 'react-toastify';
import {
  assign,
  createMachine,
  Interpreter,
  InvokeCallback,
  Receiver,
  send,
  spawn,
  SpawnedActorRef,
} from 'xstate';

import { analytics } from '../../../analytics/analytics';
import {
  Advert,
  advertApi,
  ApiAdvertSummaryListResult,
  FetchAdvertsOptions,
} from '../../../api/advert';
import { ApiAdvertTag } from '../../../api/advert-tags';
import { ApiUser } from '../../../api/auth';
import { Paginated } from '../../../api/types';
import { assertEventType, fetchParallel } from '../../../utils/xstate-helpers';
import { DashboardAdvertCountNames } from '../DashboardContext';
import { quickViewMachine, QuickViewService } from '../machines/quickViewMachine';
import {
  createFilterCollectionManagerMachine,
  FilterCollection,
  FilterCollectionManagerService,
} from './filterCollectionManagerMachine';

export type PaginationData = {
  page: number;
  perPage: number;
  totalPages: number;
};

interface ErrorContext {
  message: string;
}

export interface AdvertTableFilter {
  label: string;
  value: any;
}

export type AdvertTableFilters = Map<string, AdvertTableFilter | AdvertTableFilter[]>;

type AdvertFavouriterActor = SpawnedActorRef<AdvertFavouriterReceiveEvent>;
type AdvertUnfavouriterActor = SpawnedActorRef<AdvertUnfavouriterReceiveEvent>;
type AdvertDeleterActor = SpawnedActorRef<AdvertDeleterReceiveEvent>;

interface AdvertTableContext {
  id: string;

  quickViewModalServiceRef?: QuickViewService;
  favouriteAdvertServiceRef?: AdvertFavouriterActor;
  unfavouriteAdvertServiceRef?: AdvertUnfavouriterActor;
  deleteAdvertServiceRef?: AdvertDeleterActor;
  filterCollectionManagerServiceRef?: FilterCollectionManagerService;

  columns: any[];

  adverts: Advert[];
  teamMembers: ApiUser[];
  advertTags: ApiAdvertTag[];

  filters: AdvertTableFilters;
  pagination: PaginationData;
  sortBy?: { name: string; descending: boolean };

  error?: ErrorContext;
}

type AdvertTableExternalEvent =
  | { type: 'FILTER.UPDATE'; data: { filter: { name: string; value: any } } }
  | { type: 'FILTER.CLEAR'; data: { filter: { name: string } } }
  | { type: 'FILTER.CLEAR_ALL' }
  | { type: 'FILTER.APPLY_SAVED_FILTER'; data: { filterCollection: FilterCollection } }
  | { type: 'SORT.UPDATE'; data: { sortBy: string } }
  | { type: 'PAGINATION.GO_TO_PAGE'; data: { page: number } }
  | { type: 'PAGINATION.UPDATE_PER_PAGE'; data: { perPage: number } }
  | { type: 'OPEN_QUICK_VIEW_MODAL'; data: { advert: Advert; variationId?: number } }
  | { type: 'FAVOURITE_ADVERT'; data: { advert: Advert; variationId: number } }
  | { type: 'FAVOURITE_ADVERT_SUCCESS'; data: { advertId: number } }
  | { type: 'FAVOURITE_ADVERT_FAILED' }
  | { type: 'UNFAVOURITE_ADVERT'; data: { advert: Advert } }
  | { type: 'UNFAVOURITE_ADVERT_SUCCESS'; data: { advertId: number } }
  | { type: 'UNFAVOURITE_ADVERT_FAILED' }
  | { type: 'DELETE_ADVERT'; data: { advert: Advert } }
  | { type: 'DELETE_ADVERT_SUCCESS'; data: { advertId: number } }
  | { type: 'DELETE_ADVERT_FAILED' };

type AdvertTableInternalEvent =
  | { type: 'done.invoke.fetchAdverts'; data: Paginated<Advert> }
  | { type: 'done.invoke.fetchCompanyMembers'; data: ApiUser[] }
  | { type: 'done.invoke.fetchAdvertTags'; data: ApiAdvertTag[] };

type AdvertTableEvent = AdvertTableExternalEvent | AdvertTableInternalEvent;

type AdvertTableTypeState =
  | {
      value: 'initialising';
      context: AdvertTableContext;
    }
  | {
      value:
        | 'fetchingInitialData'
        | 'fetchingAdverts'
        | { ready: 'withData' }
        | { ready: 'withoutData' };
      context: AdvertTableContext & {
        quickViewModalServiceRef: QuickViewService;
        favouriteAdvertServiceRef: AdvertFavouriterActor;
        unfavouriteAdvertServiceRef: AdvertUnfavouriterActor;
        deleteAdvertServiceRef: AdvertDeleterActor;
        filterCollectionManagerServiceRef: FilterCollectionManagerService;
      };
    }
  | {
      value: 'failure';
      context: AdvertTableContext & {
        quickViewModalServiceRef: QuickViewService;
        favouriteAdvertServiceRef: AdvertFavouriterActor;
        unfavouriteAdvertServiceRef: AdvertUnfavouriterActor;
        deleteAdvertServiceRef: AdvertDeleterActor;
        filterCollectionManagerServiceRef: FilterCollectionManagerService;
        error: ErrorContext;
      };
    };

export type AdvertTableMachineService = Interpreter<AdvertTableContext, any, AdvertTableEvent>;

interface BuildAdvertTableMachineConfig {
  id: string;
  fetchAdverts: (options: FetchAdvertsOptions) => Promise<ApiAdvertSummaryListResult>;
  fetchCompanyMembers: () => Promise<{ companyMembers: ApiUser[] }>;
  fetchAdvertTags: () => Promise<{ tags: ApiAdvertTag[] }>;
  incrementDashboardCounter: (countName: DashboardAdvertCountNames) => void;
  decrementDashboardCounter: (countName: DashboardAdvertCountNames) => void;
}

export const buildAdvertTableMachine = (config: BuildAdvertTableMachineConfig) => {
  return createMachine<AdvertTableContext, AdvertTableEvent, AdvertTableTypeState>(
    {
      id: `${config.id}-advert-table`,
      initial: 'initialising',
      context: {
        id: config.id,
        columns: [],
        adverts: [],
        teamMembers: [],
        advertTags: [],
        filters: new Map(),
        pagination: {
          page: 1,
          perPage: 10,
          totalPages: 1,
        },
        sortBy: undefined,
      },
      states: {
        initialising: {
          entry: assign({
            quickViewModalServiceRef: (ctx, ev) => {
              return spawn(quickViewMachine, 'quick-view-service') as QuickViewService;
            },
            favouriteAdvertServiceRef: (ctx, ev) => {
              return spawn(advertFavouriter as InvokeCallback, 'favourite-advert-service');
            },
            unfavouriteAdvertServiceRef: (ctx, ev) => {
              return spawn(advertUnfavouriter as InvokeCallback, 'unfavourite-advert-service');
            },
            deleteAdvertServiceRef: (ctx, ev) => {
              return spawn(advertDeleter as InvokeCallback, 'delete-advert-service');
            },
            filterCollectionManagerServiceRef: (ctx, ev) => {
              return spawn(
                createFilterCollectionManagerMachine(config.id),
                'filter-collection-manager-service'
              );
            },
          }),
          always: {
            target: 'fetchingInitialData',
          },
        },
        fetchingInitialData: {
          ...fetchParallel<AdvertTableContext>([
            {
              state: 'advert',
              service: 'fetchAdverts',
              onDone: 'cacheAdverts',
              onError: { target: '#failure' },
            },
            {
              state: 'teamMembers',
              service: 'fetchCompanyMembers',
              onDone: 'cacheTeamMembers',
              onError: { target: '#failure' },
            },
            {
              state: 'advertTags',
              service: 'fetchAdvertTags',
              onDone: 'cacheAdvertTags',
              onError: { target: '#failure' },
            },
          ]),
          onDone: { target: 'ready' },
        },
        fetchingAdverts: {
          invoke: {
            id: 'fetchAdverts',
            src: 'fetchAdverts',
            onDone: { target: 'ready', actions: 'cacheAdverts' },
            onError: 'failure',
          },
        },
        ready: {
          initial: 'unknown',
          states: {
            unknown: {
              always: [{ target: 'withData', cond: 'hasAdverts' }, { target: 'withoutData' }],
            },
            withData: {},
            withoutData: {},
          },
          on: {
            'FILTER.UPDATE': {
              actions: 'cacheFilter',
              target: 'fetchingAdverts',
            },
            'FILTER.CLEAR': {
              actions: 'clearFilter',
              target: 'fetchingAdverts',
            },
            'FILTER.CLEAR_ALL': {
              actions: 'clearAllFilters',
              target: 'fetchingAdverts',
            },
            'FILTER.APPLY_SAVED_FILTER': {
              actions: 'cacheFilterFromSavedFilter',
              target: 'fetchingAdverts',
            },
            'SORT.UPDATE': [
              {
                cond: { type: 'isNewSortField' },
                actions: 'cacheSortBy',
                target: 'fetchingAdverts',
              },
              {
                cond: { type: 'isSortDefaultDirection' },
                actions: 'toggleSortBy',
                target: 'fetchingAdverts',
              },
              {
                actions: 'clearSortBy',
                target: 'fetchingAdverts',
              },
            ],
            'PAGINATION.GO_TO_PAGE': {
              actions: 'cachePaginationPage',
              target: 'fetchingAdverts',
            },
            'PAGINATION.UPDATE_PER_PAGE': {
              actions: 'cachePaginationPerPage',
              target: 'fetchingAdverts',
            },
            OPEN_QUICK_VIEW_MODAL: {
              actions: 'openQuickViewModal',
            },
            FAVOURITE_ADVERT: {
              actions: 'executeFavouriteAdvert',
            },
            FAVOURITE_ADVERT_SUCCESS: {
              actions: [
                'markAdvertAsFavourited',
                'incrementDashboardFavouriteCounter',
                'logAdvertFavourited',
              ],
            },
            FAVOURITE_ADVERT_FAILED: {
              actions: ['notifyFavouriteAdvertFailed'],
            },
            UNFAVOURITE_ADVERT: {
              actions: 'executeUnfavouriteAdvert',
            },
            UNFAVOURITE_ADVERT_SUCCESS: {
              actions: [
                'markAdvertAsUnfavourited',
                'decrementDashboardFavouriteCounter',
                'logAdvertUnfavourited',
              ],
            },
            UNFAVOURITE_ADVERT_FAILED: {
              actions: ['notifyUnFavouriteAdvertFailed'],
            },
            DELETE_ADVERT: {
              actions: 'executeDeleteAdvert',
            },
            DELETE_ADVERT_SUCCESS: {
              actions: ['decrementDashboardCountersForDeletedAdvert', 'logAdvertDeleted'],
              target: 'fetchingAdverts',
            },
            DELETE_ADVERT_FAILED: {
              actions: ['notifyDeleteAdvertFailed'],
            },
          },
        },
        failure: {
          id: 'failure',
          entry: (ctx, err) => {
            console.debug(err);
          },
        },
      },
    },
    {
      actions: {
        cacheAdverts: assign({
          adverts: (ctx, ev) => {
            assertEventType(ev, 'done.invoke.fetchAdverts');

            return ev.data.items;
          },
          pagination: (ctx, ev) => {
            assertEventType(ev, 'done.invoke.fetchAdverts');

            return {
              page: ev.data.page,
              perPage: ev.data.limit,
              totalPages: ev.data.pages,
            };
          },
        }),
        cacheTeamMembers: assign({
          teamMembers: (ctx, ev) => {
            assertEventType(ev, 'done.invoke.fetchCompanyMembers');

            return ev.data;
          },
        }),
        cacheAdvertTags: assign({
          advertTags: (ctx, ev) => {
            assertEventType(ev, 'done.invoke.fetchAdvertTags');

            return ev.data;
          },
        }),
        cacheFilter: assign({
          filters: (ctx, ev) => {
            assertEventType(ev, 'FILTER.UPDATE');

            const { name, value } = ev.data.filter;

            return ctx.filters.set(name, value);
          },
        }),
        clearFilter: assign({
          filters: (ctx, ev) => {
            assertEventType(ev, 'FILTER.CLEAR');

            ctx.filters.delete(ev.data.filter.name);

            return new Map(ctx.filters);
          },
        }),
        clearAllFilters: assign({
          filters: (ctx, ev) => {
            assertEventType(ev, 'FILTER.CLEAR_ALL');

            return new Map();
          },
        }),
        cacheFilterFromSavedFilter: assign({
          filters: (ctx, ev) => {
            assertEventType(ev, 'FILTER.APPLY_SAVED_FILTER');

            return new Map(ev.data.filterCollection.filters);
          },
        }),
        cacheSortBy: assign({
          sortBy: (ctx, ev) => {
            assertEventType(ev, 'SORT.UPDATE');

            return {
              name: ev.data.sortBy,
              descending: false,
            };
          },
        }),
        toggleSortBy: assign({
          sortBy: (ctx, ev) => {
            assertEventType(ev, 'SORT.UPDATE');

            return {
              name: ev.data.sortBy,
              descending: true,
            };
          },
        }),
        clearSortBy: assign({
          sortBy: (ctx, ev) => {
            return undefined;
          },
        }),
        cachePaginationPage: assign({
          pagination: (ctx, ev) => {
            assertEventType(ev, 'PAGINATION.GO_TO_PAGE');

            return {
              ...ctx.pagination,
              page: ev.data.page,
            };
          },
        }),
        cachePaginationPerPage: assign({
          pagination: (ctx, ev) => {
            assertEventType(ev, 'PAGINATION.UPDATE_PER_PAGE');

            return {
              ...ctx.pagination,
              page: 1,
              perPage: ev.data.perPage,
            };
          },
        }),
        markAdvertAsFavourited: assign({
          adverts: (ctx, ev) => {
            assertEventType(ev, 'FAVOURITE_ADVERT_SUCCESS');

            return ctx.adverts.map((ad: Advert) => ({
              ...ad,
              isFavourited: ad.id === ev.data.advertId ? true : ad.isFavourited,
            }));
          },
        }),
        markAdvertAsUnfavourited: assign({
          adverts: (ctx, ev) => {
            assertEventType(ev, 'UNFAVOURITE_ADVERT_SUCCESS');

            return ctx.adverts.map((ad: Advert) => ({
              ...ad,
              isFavourited: ad.id === ev.data.advertId ? false : ad.isFavourited,
            }));
          },
        }),

        openQuickViewModal: send(
          (ctx, ev) => {
            assertEventType(ev, 'OPEN_QUICK_VIEW_MODAL');

            return {
              type: 'OPEN',
              advert: ev.data.advert,
              variationId: ev.data.variationId,
            } as any;
          },
          { to: (ctx: any) => ctx.quickViewModalServiceRef as any }
        ),
        executeFavouriteAdvert: send(
          (ctx, ev) => {
            assertEventType(ev, 'FAVOURITE_ADVERT');

            return {
              type: 'FAVOURITE_ADVERT',
              data: { advert: ev.data.advert, variationId: ev.data.variationId },
            } as TSFixMe;
          },
          {
            to: (ctx) => ctx.favouriteAdvertServiceRef as TSFixMe,
          }
        ),
        executeUnfavouriteAdvert: send(
          (ctx, ev) => {
            assertEventType(ev, 'UNFAVOURITE_ADVERT');

            return {
              type: 'UNFAVOURITE_ADVERT',
              data: { advert: ev.data.advert },
            } as TSFixMe;
          },
          {
            to: (ctx) => ctx.unfavouriteAdvertServiceRef as TSFixMe,
          }
        ),
        executeDeleteAdvert: send(
          (ctx, ev) => {
            assertEventType(ev, 'DELETE_ADVERT');

            return {
              type: 'DELETE_ADVERT',
              data: { advert: ev.data.advert },
            } as TSFixMe;
          },
          {
            to: (ctx) => ctx.deleteAdvertServiceRef as TSFixMe,
          }
        ),

        incrementDashboardFavouriteCounter: () => {
          config.incrementDashboardCounter(DashboardAdvertCountNames.Favourite);
        },
        decrementDashboardFavouriteCounter: () => {
          config.decrementDashboardCounter(DashboardAdvertCountNames.Favourite);
        },
        decrementDashboardCountersForDeletedAdvert: (ctx, ev) => {
          assertEventType(ev, 'DELETE_ADVERT_SUCCESS');

          const advert = ctx.adverts.find((advert) => advert.id === ev.data.advertId);

          if (!advert) {
            return;
          }

          const toDecrement = [DashboardAdvertCountNames.Company];

          if (advert.isFavourited) {
            toDecrement.push(DashboardAdvertCountNames.Favourite);
          }

          if (!advert.isComplete) {
            toDecrement.push(DashboardAdvertCountNames.Incomplete);
          }

          toDecrement.forEach(config.decrementDashboardCounter);
        },

        notifyFavouriteAdvertFailed: () => {
          toast.error('Something went wrong whilst favouriting the advert');
        },
        notifyUnfavouriteAdvertFailed: () => {
          toast.error('Something went wrong whilst unfavouriting the advert');
        },
        notifyDeleteAdvertFailed: () => {
          toast.error('Something went wrong whilst deleting the advert');
        },

        logAdvertFavourited: (_, ev) => {
          assertEventType(ev, 'FAVOURITE_ADVERT_SUCCESS');

          analytics.advertFavourited(ev.data.advertId);
        },
        logAdvertUnfavourited: (_, ev) => {
          assertEventType(ev, 'UNFAVOURITE_ADVERT_SUCCESS');

          analytics.advertUnfavourited(ev.data.advertId);
        },
        logAdvertDeleted: (_, ev) => {
          assertEventType(ev, 'DELETE_ADVERT_SUCCESS');

          analytics.advertDeleted(ev.data.advertId);
        },
      },
      guards: {
        hasAdverts: (ctx) => ctx.adverts.length > 0,
        isNewSortField: (ctx, ev) => {
          assertEventType(ev, 'SORT.UPDATE');

          return !ctx.sortBy || ctx.sortBy.name !== ev.data.sortBy;
        },
        isSortDefaultDirection: (ctx, ev) => {
          if (!ctx.sortBy) {
            return false;
          }

          return !ctx.sortBy.descending;
        },
      },
      services: {
        fetchAdverts: async (ctx) => {
          const sortFields = ctx.sortBy
            ? [
                {
                  field: ctx.sortBy.name,
                  direction: ctx.sortBy.descending ? ('DESC' as const) : ('ASC' as const),
                },
              ]
            : [];

          const filters = Array.from(ctx.filters, ([field, value]) => {
            return {
              field,
              value: Array.isArray(value) ? value.map((val) => val.value) : value.value,
            };
          });

          return config.fetchAdverts({
            page: ctx.pagination.page,
            perPage: ctx.pagination.perPage,
            sortFields,
            filters,
          });
        },
        fetchCompanyMembers: async () => {
          const response = await config.fetchCompanyMembers();

          return response.companyMembers;
        },
        fetchAdvertTags: async () => {
          const response = await config.fetchAdvertTags();

          return response.tags;
        },
      },
    }
  );
};

type AdvertFavouriterSendEvent =
  | { type: 'FAVOURITE_ADVERT_SUCCESS'; data: { advertId: number } }
  | { type: 'FAVOURITE_ADVERT_FAILED' };

type AdvertFavouriterReceiveEvent = {
  type: 'FAVOURITE_ADVERT';
  data: { advert: Advert; variationId: number };
};

const advertFavouriter = (
  send: Sender<AdvertFavouriterSendEvent>,
  onReceive: Receiver<AdvertFavouriterReceiveEvent>
) => {
  onReceive((event) => {
    const { advert, variationId } = event.data;

    advertApi
      .favouriteAdvertVariation(advert.id, variationId)
      .then((response) => {
        send({
          type: 'FAVOURITE_ADVERT_SUCCESS',
          data: {
            advertId: response.advert.id,
          },
        });
      })
      .catch(() => {
        send({ type: 'FAVOURITE_ADVERT_FAILED' });
      });
  });

  return () => {};
};

type AdvertUnfavouriterSendEvent =
  | { type: 'UNFAVOURITE_ADVERT_SUCCESS'; data: { advertId: number } }
  | { type: 'UNFAVOURITE_ADVERT_FAILED' };

type AdvertUnfavouriterReceiveEvent = {
  type: 'UNFAVOURITE_ADVERT';
  data: { advert: Advert };
};

const advertUnfavouriter = (
  send: Sender<AdvertUnfavouriterSendEvent>,
  onReceive: Receiver<AdvertUnfavouriterReceiveEvent>
) => {
  onReceive((event) => {
    const { advert } = event.data;

    advertApi
      .unfavouriteAdvert(advert.id)
      .then((response) => {
        send({
          type: 'UNFAVOURITE_ADVERT_SUCCESS',
          data: {
            advertId: response.advert.id,
          },
        });
      })
      .catch(() => {
        send({ type: 'UNFAVOURITE_ADVERT_FAILED' });
      });
  });

  return () => {};
};

type AdvertDeleterSendEvent =
  | { type: 'DELETE_ADVERT_SUCCESS'; data: { advertId: number } }
  | { type: 'DELETE_ADVERT_FAILED' };

type AdvertDeleterReceiveEvent = {
  type: 'DELETE_ADVERT';
  data: { advert: Advert };
};

const advertDeleter = (
  send: Sender<AdvertDeleterSendEvent>,
  onReceive: Receiver<AdvertDeleterReceiveEvent>
) => {
  onReceive((event) => {
    const { advert } = event.data;

    advertApi
      .deleteAdvert(advert.id)
      .then((response) => {
        send({
          type: 'DELETE_ADVERT_SUCCESS',
          data: {
            advertId: response.advert.id,
          },
        });
      })
      .catch(() => {
        send({ type: 'DELETE_ADVERT_FAILED' });
      });
  });

  return () => {};
};
