import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { AppShell, AppShellProps } from '../../components/AppShell';
import { builderTheme } from '../../theme/builderTheme';
import { ThemeProvider } from '../../theme/ThemeProvider';
import { AdvertBuilderProvider } from './AdvertBuilderContext';

type AdvertBuilderAppShellProps = AppShellProps & RouteComponentProps<{ advertId: string }>;

export const AdvertBuilderAppShell: React.FC<AdvertBuilderAppShellProps> = ({
  advertId,
  ...rest
}) => {
  if (!advertId) {
    throw new Error('No Advert Id provided');
  }

  const id = advertId === 'new' ? advertId : parseInt(advertId, 10);

  if (id !== 'new' && isNaN(id)) {
    throw new Error('The advert id must be a number');
  }

  return (
    <React.Fragment>
      <Helmet title="Advert Builder" />

      <AdvertBuilderProvider advertId={id}>
        <ThemeProvider theme={builderTheme as TSThemeFixMe}>
          <AppShell backgroundColor="#f6f6f6" {...rest} />
        </ThemeProvider>
      </AdvertBuilderProvider>
    </React.Fragment>
  );
};
