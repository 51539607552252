import { useEffect } from 'react';

import { useCompany } from '../context/CompanyContext';
import { useUser } from '../context/UserContext';

export const ZendeskIdentitySync = () => {
  const user = useUser();
  const { company } = useCompany();

  useEffect(() => {
    if (!window.zE) {
      return;
    }

    window.zE('webWidget', 'identify', {
      name: user.displayName,
      email: user.email,
      organization: company?.companyName,
    });
  }, [user.displayName, user.email, company?.companyName]);

  return null;
};
