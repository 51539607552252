import { navigate } from '@reach/router';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';

import { advertFormApi, FormOverview } from '../api/advert-form';
import { ThemeProvider } from '../theme/ThemeProvider';
import { BasicSelect } from './common/BasicSelect';
import { Box } from './common/Box';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTextButton,
  ModalTitle,
} from './common/Modal';
import { Text } from './common/Text';

const theme = {
  forms: {
    select: {
      menu: {
        position: 'absolute',
        top: 'calc(100% + 0.5rem)',
        maxHeight: '220px',
        width: '100%',
        left: 0,
      },
    },
  },
};

interface AdvertTypeSelectionModalProps {
  isOpen: boolean;
  onDismiss: () => void;
}

export const AdvertTypeSelectionModal: React.FC<AdvertTypeSelectionModalProps> = (props) => {
  const { status, data: forms } = advertFormApi.useFormOverviewsQuery();

  const [advertForm, setAdvertForm] = useState<FormOverview | undefined>(undefined);

  useEffect(() => {
    if (forms) {
      const defaultForm = forms.find((form) => form.defaultForm);

      if (defaultForm) {
        setAdvertForm(defaultForm);
      }
    }
  }, [forms]);

  const goToForm = useCallback(() => {
    if (advertForm) {
      navigate(`/advert/new?type=${advertForm.slug}`);
    }
  }, [advertForm]);

  return (
    <ThemeProvider theme={theme as TSThemeFixMe}>
      <Modal
        aria-label="Advert type selection"
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        style={{ width: '75vw' }}
      >
        <ModalClose onClick={props.onDismiss} />
        <ModalContainer>
          <ModalTitle>What kind of job are you recruiting for?</ModalTitle>

          <ModalContent>
            {status === 'loading' || !advertForm ? (
              <Box>Fetching the available advert types</Box>
            ) : status === 'error' || !forms ? (
              <Box>Something went wrong whilst fetching the advert types</Box>
            ) : (
              <React.Fragment>
                {forms &&
                  forms.map((form) => (
                    <Text key={form.id} mb={3}>
                      <strong>{form.name}</strong>: <em>{form.description}</em>
                    </Text>
                  ))}

                <Box sx={{ mt: 7 }}>
                  <BasicSelect
                    name="advertForm"
                    items={forms}
                    selectedItem={advertForm}
                    renderButtonText={(item) => item.name}
                    renderItem={(item) => item.name}
                    onChange={setAdvertForm}
                  />
                </Box>
              </React.Fragment>
            )}
          </ModalContent>

          <ModalActions>
            <ModalButton
              data-testid="create-new-advert"
              type="button"
              disabled={!advertForm}
              onClick={goToForm}
            >
              {advertForm ? `Create a ${advertForm.name} advert` : 'Create an advert'}
            </ModalButton>

            <ModalTextButton type="button" onClick={props.onDismiss}>
              Cancel
            </ModalTextButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    </ThemeProvider>
  );
};
