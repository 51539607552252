import { ErrorMessage, Formik } from 'formik';
import * as React from 'react';

import { AuthUtils } from '../../utils/auth/auth-utils';
import { Flex } from '../common/Flex';
import { TextField } from '../common/form';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTextButton,
  ModalTitle,
} from '../common/Modal';

interface ImpersonateUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ImpersonateUserModal: React.FC<ImpersonateUserModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal  aria-label="Impersonate user" isOpen={isOpen} onDismiss={() => onClose()}>
      <ModalClose onClick={() => onClose()} />

      <Formik
        initialValues={{ email: AuthUtils.getSwitchUserHeaderValue() ?? '' }}
        onSubmit={async (values) => {
          AuthUtils.setSwitchUserHeaderValue(values.email);
          window.location.reload();
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalContainer>
              <ModalTitle style={{ paddingBottom: 0 }}>Impersonate a user</ModalTitle>

              <ModalContent>
                <Flex sx={{ flexDirection: 'row', pt: 5 }}>
                  <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                    <TextField
                      type="email"
                      name="email"
                      placeholder="Email address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <ErrorMessage name="email" />
                  </Flex>
                </Flex>
              </ModalContent>

              <ModalActions>
                <ModalButton type="submit">Impersonate</ModalButton>
                <ModalTextButton type="button" onClick={() => onClose()}>
                  Cancel
                </ModalTextButton>
              </ModalActions>
            </ModalContainer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
