import { AxiosRequestConfig } from 'axios';

import { client } from './client';

export enum GenderResultBiasId {
  StronglyFeminine = 1,
  Feminine = 2,
  SlightlyFeminine = 3,
  Neutral = 4,
  SlightlyMasculine = 5,
  Masculine = 6,
  StronglyMasculine = 7,
}

export interface GradingInclusivityGenderResult {
  biasId: GenderResultBiasId;
  bias: string;
  score: number;
  scorePercent: number;
}

export enum MatchedContentType {
  WORD = 1,
  PHRASE = 2,
  REGEX = 3,
}

export interface MatchedContent {
  type: MatchedContentType;
  content: string;
  reason: string;
  matchedText: string;
}

export enum GradingFlaggedCollectionId {
  Feminine = 'feminine',
  Masculine = 'masculine',
}

export interface GradingFlaggedCollectionResult {
  id: string;
  name: string;
  colour: string;
  accentColour: string;
  alternatives: { [key: string]: string[] };
  matchedContent: MatchedContent[];
  excludedPhrases: string[];
}

export interface GradingInclusivity {
  score: number;
  genderResult: GradingInclusivityGenderResult;
  collectionResults: GradingFlaggedCollectionResult[];
}

export interface GradingReadability {
  score: number;
  readingEase: number;
  readingGrade: number;
  wordCountScore: number;
  collectionResults: GradingFlaggedCollectionResult[];
}

export interface Grading {
  inclusivity: GradingInclusivity;
  readability: GradingReadability;
}

export interface GradeVariables {
  content: string;
  wordCount: number;
}

const grade = async (
  variables: GradeVariables,
  config?: AxiosRequestConfig
): Promise<{ grading: Grading }> => {
  const response = await client.post<{ grading: Grading }>(`/grade`, variables, config);

  return response.data;
};

export const gradeContentApi = {
  grade,
};
