import { client } from './client';

export interface ApiAdvertTag {
  id: number;
  label: string;
  backgroundColor: string;
  borderColor: string;
  system: boolean;
}

const fetchTags = async (): Promise<{ tags: ApiAdvertTag[] }> => {
  const response = await client.get('/listing-tags');

  return {
    tags: response.data.tags,
  };
};

const createTag = async (label: string): Promise<{ tag: ApiAdvertTag }> => {
  const response = await client.post('/listing-tags', { label });

  return {
    tag: response.data.tag,
  };
};

export const advertTagsApi = {
  fetchTags,
  createTag,
};
