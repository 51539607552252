import { AxiosResponse } from 'axios';

import { AuthUtils } from '../../utils/auth/auth-utils';
import { ClientType } from '../client';

export type RefreshTokenCallback = () => Promise<string | null>;

export type RetryRequest = (apiKey: string) => void;

export const attachApiRefreshAuthInterceptor = (
  client: ClientType,
  refreshApiKey: RefreshTokenCallback
) => {
  let isRefreshing = false;
  let pendingRequestSubscribers: RetryRequest[] = [];

  const addPendingRequest = (cb: RetryRequest) => {
    console.debug('Adding pending request');

    pendingRequestSubscribers.push(cb);
  };

  const onApiKeyFetched = (apiKey: string) => {
    pendingRequestSubscribers.forEach((cb) => cb(apiKey));
    pendingRequestSubscribers = [];
  };

  const isTokenExpiredError = (response: AxiosResponse) => {
    return response && response.status === 401;
  };

  const refreshApiKeyAndRetryRequest = async (error: any) => {
    try {
      console.debug('Request returned an expired token error');
      const { response } = error;

      const refreshToken = await AuthUtils.getRefreshToken();

      if (!refreshToken) {
        console.debug('No refresh token found');

        return Promise.reject(error);
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addPendingRequest((apikKey) => {
          console.debug('Executing pending request');

          response.config.headers.Authorization = `Bearer ${apikKey}`;
          resolve(client(response.config));
        });
      });

      if (!isRefreshing) {
        isRefreshing = true;

        console.debug('Refreshing API key');

        const apiKey = await refreshApiKey();

        if (!apiKey) {
          console.debug('Refreshing API key failed');

          return Promise.reject(error);
        }

        console.debug('API key refreshed');

        isRefreshing = false;
        onApiKeyFetched(apiKey);
      }

      return retryOriginalRequest;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (!isTokenExpiredError(error.response)) {
        return Promise.reject(error);
      }

      return refreshApiKeyAndRetryRequest(error);
    }
  );
};
