import { useCallback, useContext, useMemo, useState } from 'react';
import * as React from 'react';

interface ContextValue {
  active: {
    [key: string]: boolean;
  };
  hasActiveOverlay: boolean;
  isOverlayActive: (id: string) => boolean;
  setOverlayStatus: (id: string, status: boolean) => void;
}

const ActiveOverlayContext = React.createContext<ContextValue>({
  active: {},
  hasActiveOverlay: false,
  isOverlayActive: (id: string) => false,
  setOverlayStatus: () => {
    // no-op
  },
});

export const ActiveOverlayProvider: React.FC = (props) => {
  const [overlayStatuses, setOverlayStatuses] = useState<{
    [key: string]: boolean;
  }>({});

  const setOverlayStatus = useCallback(
    (id: string, status: boolean) => {
      const currentStatus = overlayStatuses[id];

      if (currentStatus === status) {
        return;
      }

      setOverlayStatuses({
        ...overlayStatuses,
        [id]: status,
      });
    },
    [overlayStatuses]
  );

  const isOverlayActive = useCallback((id: string) => Boolean(overlayStatuses[id]), [
    overlayStatuses,
  ]);

  const value = useMemo(() => {
    return {
      active: overlayStatuses,
      hasActiveOverlay: Object.keys(overlayStatuses).some((id) => overlayStatuses[id]),
      isOverlayActive,
      setOverlayStatus,
    };
  }, [isOverlayActive, overlayStatuses, setOverlayStatus]);

  return <ActiveOverlayContext.Provider value={value} {...props} />;
};

export const useActiveOverlay = () => {
  const context = useContext(ActiveOverlayContext);

  if (context === undefined) {
    throw new Error(`useActiveOverlay must be used within a ActiveOverlayProvider`);
  }

  return context;
};
