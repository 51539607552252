// TODO - Think about extracting all of this config to the API
export const REGISTRATION_WIZARD_STEPS = ['Your details', 'Business details', 'Confirm'];

export const EMPLOYEE_COUNT_OPTIONS = [
  { id: 1, name: '1' },
  { id: 2, name: '2-5' },
  { id: 3, name: '6-20' },
  { id: 4, name: '21-50' },
  { id: 5, name: '51-200' },
  { id: 6, name: '201-500' },
  { id: 7, name: '500+' },
];

export enum UsageGoal {
  FillMoreRoles = 1,
  MakeMoreMoney = 2,
  ImproveAdvertisingROI = 3,
  ImproveStaffProductivity = 4,
  ReduceCosts = 5,
  StopUsingRecruitmentAgencies = 6,
  AttractBetterApplications = 7,
  SaveMoney = 8,
}

export const USAGE_GOAL_OPTIONS = [
  { id: UsageGoal.FillMoreRoles, name: 'Fill more roles' },
  { id: UsageGoal.MakeMoreMoney, name: 'Make more money' },
  { id: UsageGoal.ImproveAdvertisingROI, name: 'Improve advertising ROI' },
  { id: UsageGoal.ImproveStaffProductivity, name: 'Improve staff productivity' },
  { id: UsageGoal.ReduceCosts, name: 'Reduce costs' },
];
