import { Link } from '@reach/router';
import { parseISO } from 'date-fns';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import qs from 'qs';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';

import { Flex } from '../components/common/Flex';
import { Text } from '../components/common/Text';
import { useAuth } from '../context/AuthContext';
import { useCompany } from '../context/CompanyContext';
import { useToggle } from '../hooks/useToggle';
import logger from '../utils/logger';
import { Box } from './common/Box';
import { Button } from './common/Button';
import {
  Modal,
  ModalActions,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTitle,
} from './common/Modal';
import { CompanyOwnerOnly } from './Permissions/CompanyOwnerOnly';

const OPEN_MODAL_AUTOMATICALLY_QUERY_STRING_KEY = 'showFixCancellationBanner';

export const SubscriptionCancelledBanner: React.FC = () => {
  const { refreshUser } = useAuth();
  const { company, resumeSubscription } = useCompany();
  const { on: isOpen, toggle: toggleModal, setOn: openModal, setOff: closeModal } = useToggle();
  const { on: hasOpenedModalAutomatically, setOn: setHasOpenedModalAutomatically } = useToggle();
  const [isResuming, setIsResuming] = useState(false);

  useEffect(() => {
    if (hasOpenedModalAutomatically) {
      return;
    }

    const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const shouldOpenModal = queryParams[OPEN_MODAL_AUTOMATICALLY_QUERY_STRING_KEY];

    if (shouldOpenModal === '1') {
      setHasOpenedModalAutomatically();
      openModal();
    }
  }, [hasOpenedModalAutomatically, setHasOpenedModalAutomatically, openModal]);

  if (!company) {
    return null;
  }

  const handleResumeSubscription = async () => {
    setIsResuming(true);

    try {
      await resumeSubscription();
      await refreshUser();
      toggleModal();
    } catch (e) {
      logger.logError(e);
      toast.error('Something went wrong whilst resuming your subscription');
    } finally {
      setIsResuming(false);
    }
  };

  return (
    <Flex sx={{ alignItems: 'center', pl: 7, bg: 'red.7', py: 2 }}>
      <Text color="white">
        Your account will close in{' '}
        {formatDistanceStrict(parseISO(company.subscriptionCurrentPeriodEnds), new Date())}. If you
        want to keep your subscription going, click on this button.
      </Text>

      <CompanyOwnerOnly>
        <Box ml={7}>
          <Button variant="dangerInverted" py="2" onClick={openModal} onKeyPress={openModal}>
            Fix It &rarr;
          </Button>

          <Modal
            aria-label="Reinstance company subscription"
            isOpen={isOpen}
            data-testid="reinstate-company-subscription"
            onDismiss={() => closeModal()}
          >
            <ModalClose onClick={() => closeModal()} />
            <ModalContainer>
              <ModalTitle>Want to reinstate your subscription?</ModalTitle>
              <ModalContent>
                <Text sx={{ mb: 4 }}>No problem. We're delighted you want to stay with us.</Text>

                <Text sx={{ mb: 4 }}>
                  You've got three options - you can either continue with your current plan, you can
                  change it to a plan more suitable to your current requirements, or you can
                  indefinitely pause your subscription for £10/month so that you don't lose you
                  adverts.
                </Text>

                <Text>Click the relevant button below.</Text>
              </ModalContent>
              <ModalActions>
                <Flex>
                  <Button
                    variant={isResuming ? 'disabled' : 'primary'}
                    disabled={isResuming}
                    mr="3"
                    sx={{ textDecoration: 'none' }}
                    onClick={handleResumeSubscription}
                    onKeyPress={handleResumeSubscription}
                  >
                    Continue with my current plan
                  </Button>
                  <Button
                    as={Link}
                    // @ts-ignore
                    to="/subscriptions/change"
                    variant="primary"
                    mr="3"
                    sx={{ textDecoration: 'none' }}
                    onClick={toggleModal}
                    onKeyPress={toggleModal}
                  >
                    Change my plan
                  </Button>
                  <Button
                    as={Link}
                    // @ts-ignore
                    to="/settings/billing/pause-subscription"
                    variant="primary"
                    sx={{ textDecoration: 'none' }}
                    onClick={toggleModal}
                    onKeyPress={toggleModal}
                  >
                    Pause my subscription
                  </Button>
                </Flex>
              </ModalActions>
            </ModalContainer>
          </Modal>
        </Box>
      </CompanyOwnerOnly>
    </Flex>
  );
};
