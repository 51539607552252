import { Redirect, RouteComponentProps } from '@reach/router';
import * as React from 'react';

import { AuthConsumer } from '../../context/AuthContext';

interface AdminRouteProps extends RouteComponentProps {
  component?: any;
}

export const AdminRoute: React.FC<AdminRouteProps> = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ user }) =>
      user && user.isAdmin ? (
        <Component {...rest} />
      ) : (
        <Redirect path="" from="" to="/dashboard" noThrow />
      )
    }
  </AuthConsumer>
);
