import * as React from 'react';

import { Heading } from '../components/common/Heading';
import { Text } from '../components/common/Text';
interface PlanHeaderProps {
  title: string;
  plan: React.ReactNode;
}

export const PlanHeader: React.FC<PlanHeaderProps> = ({ title, plan }) => (
  <Heading as="h2" sx={{ letterSpacing: '-0.05rem', fontSize: 6, fontWeight: 'semibold' }}>
    <Text as="span" sx={{ mr: 3, color: 'grey.9' }}>
      {title}
    </Text>
    <Text as="span" sx={{ color: 'accent', fontWeight: 'bold' }}>
      {plan}
    </Text>
  </Heading>
);
