import logger from './logger';

export const AffiliateUtils = {
  recordLead: (email: string) => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    if (!window.fpr) {
      logger.logError(
        new Error(
          'Attempted to record a lead but the fpr window variable could not be found. This could be due to an adblocker'
        )
      );
      return;
    }

    window.fpr('referral', { email });
  },
};
