import * as React from 'react';
import { Assign, ForwardRef } from 'theme-ui';

import { Box, BoxOwnProps } from './Box';

export interface ImageProps extends Assign<React.ComponentProps<'img'>, BoxOwnProps> {}

export const Image: ForwardRef<HTMLImageElement, ImageProps> = React.forwardRef((props, ref) => {
  return <Box ref={ref} as="img" {...props} __themeKey="images" />;
});
