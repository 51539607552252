import * as React from 'react';
import { Transition } from 'react-transition-group';

import { NavigationDirection } from '../AdvertBuilderContext';

const slideInDuration = 300;

const slideInDefaultStyle: any = {
  opacity: 0,
  transform: 'none',
  transition: `all ${slideInDuration}ms cubic-bezier(0.25, 0.35, 0.24, 1.25) 0s`,
};

const buildSlideInTransitionStyles = ({ direction }: { direction: NavigationDirection }) => {
  // Standard forward transition
  let startX = -150;
  let bounceX = 25;

  if (direction === NavigationDirection.Backwards) {
    // User is going backwards so inverse the start and bounce positions
    startX = startX * -1;
    bounceX = bounceX * -1;
  }

  return {
    entering: { opacity: 1, transform: `translate3d(${bounceX}px, 0, 0)` },
    entered: { opacity: 1, transform: 'none' },
    exiting: { opacity: 0, transform: `translate3d(${startX}px, 0, 0)` },
    exited: { opacity: 0, transform: `translate3d(${startX}px, 0, 0)` },
    unmounted: { opacity: 0, transform: `translate3d(${startX}px, 0, 0)` },
  };
};

export const SlideIn: React.FC<{ active: boolean; direction: NavigationDirection }> = ({
  children,
  active,
  direction,
}) => {
  const slideInTransitionStyles = buildSlideInTransitionStyles({ direction });

  return (
    <Transition in={active} timeout={slideInDuration}>
      {(state) => (
        <div
          style={{
            ...slideInDefaultStyle,
            ...slideInTransitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};
