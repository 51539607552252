import { client } from './client';

export const SUPPORTED_CURRENCIES = ['GBP', 'EUR', 'USD'];
export const DEFAULT_CURRENCY = 'GBP';

export enum ApiSubscriptionPlanPeriodUnit {
  Month = 'month',
  Year = 'year',
}

export enum ApiSubscriptionPlanTierType {
  Fixed = 'fixed',
  Custom = 'custom',
}

export interface SubscriptionPlanTier {
  id: string;
  planId: string;
  name: string;
  seats: number;
  billingPeriod: ApiSubscriptionPlanPeriodUnit;
  price: number;
  monthlyPrice: number;
  currencyCode: string;
  type: ApiSubscriptionPlanTierType;
  features: string[];
}

interface GETFixedSubscriptionPlanTiersParams {
  currencyCode: string;
  billingPeriod?: ApiSubscriptionPlanPeriodUnit;
  discountCode?: string;
}

const getFixedSubscriptionPlanTiers = async (
  params: GETFixedSubscriptionPlanTiersParams
): Promise<{
  planTiers: SubscriptionPlanTier[];
}> => {
  const response = await client.get('/subscription-plan-pricing/fixed', {
    params,
  });

  return {
    planTiers: response.data.planTiers,
  };
};

interface GETCustomSubscriptionPlanTiersParams {
  currencyCode: string;
  billingPeriod: string;
  seats: number;
  discountCode?: string;
}

const getCustomSubscriptionPlanTiers = async (
  params: GETCustomSubscriptionPlanTiersParams
): Promise<{
  planTier: SubscriptionPlanTier;
}> => {
  const response = await client.get('/subscription-plan-pricing/custom', {
    params,
  });

  return {
    planTier: response.data.planTier,
  };
};

interface GETValidateDiscountCodeParams {
  discountCode?: string;
}

const validateDiscountCode = async (
  params: GETValidateDiscountCodeParams
): Promise<{
  valid: boolean;
}> => {
  const response = await client.get('/subscription-plan-pricing/validate-discount-code', {
    params: { discountCode: params.discountCode },
  });

  return {
    valid: response.data.valid,
  };
};

export const subscriptionPlanPricingApi = {
  getFixedSubscriptionPlanTiers,
  getCustomSubscriptionPlanTiers,
  validateDiscountCode,
};
