import * as Sentry from '@sentry/browser';
import { Scope } from '@sentry/types';
import LogRocket from 'logrocket';

interface LogContext {
  level?: Sentry.Severity;
  tags?: {
    [key: string]: string;
  };
  extra?: {
    [key: string]: string | number | boolean;
  };
}
interface LogRocketCaptureOptions {
  tags?: {
    [tagName: string]: string | number | boolean;
  };
  extra?: {
    [tagName: string]: string | number | boolean;
  };
}

interface LogResponse {
  sentry: {
    id?: string;
  };
}

const withSentryScope = (ctx: LogContext, fn: (scope: Scope) => void) => {
  Sentry.withScope((scope) => {
    if (ctx.level) {
      scope.setLevel(ctx.level);
    }

    if (ctx.tags) {
      scope.setTags(ctx.tags);
    }

    if (ctx.extra) {
      scope.setExtras(ctx.extra);
    }

    fn(scope);
  });
};

const withLogRocketScope = (ctx: LogContext, fn: (scope: LogRocketCaptureOptions) => void) => {
  const scope = {
    tags: ctx ? ctx.tags : undefined,
    extra: ctx ? { ...ctx.extra, level: ctx.level || Sentry.Severity.Info } : undefined,
  };

  fn(scope);
};

const logError = (err: Error, context?: LogContext): LogResponse => {
  const response: LogResponse = {
    sentry: {
      id: undefined,
    },
  };

  withSentryScope(context || {}, () => {
    const id = Sentry.captureException(err);
    response.sentry.id = id;
  });

  withLogRocketScope(context || {}, (scope) => {
    LogRocket.captureException(err, scope);
  });

  if (process.env.NODE_ENV !== 'production') {
    console.error(err);
  }

  return response;
};

const logMessage = (message: string, context?: LogContext): LogResponse => {
  const response: LogResponse = {
    sentry: {
      id: undefined,
    },
  };

  withSentryScope(context || {}, () => {
    Sentry.captureMessage(message);
  });

  withLogRocketScope(context || {}, (scope) => {
    LogRocket.captureMessage(message, scope);
  });

  if (process.env.NODE_ENV !== 'production') {
    console.info(message);
  }

  return response;
};

const addBreadcrumb = (href: string, pathname: string) => {
  if (process.env.NODE_ENV !== 'production') {
    console.debug('Breadcrumb added:', { href, pathname });
  }

  Sentry.addBreadcrumb({
    category: 'navigation',
    message: `Navigated to ${href}`,
    data: {
      href,
      pathname,
    },
    timestamp: Date.now(),
    level: Sentry.Severity.Info,
  });
};

const addAnalyticsBreadcrumb = (message: string, extraData?: any) => {
  if (process.env.NODE_ENV !== 'production') {
    console.debug(`Analytics: ${message}`, { data: extraData });
  }

  if (process.env.NODE_ENV === 'production') {
    LogRocket.track(message);
  }

  Sentry.addBreadcrumb({
    category: 'analytics',
    message: message,
    data: extraData,
    timestamp: Date.now(),
    level: Sentry.Severity.Info,
  });
};

const logger = {
  logError,
  logMessage,
  addBreadcrumb,
  addAnalyticsBreadcrumb,
};

export default logger;
