import ReachTooltip, { TooltipProps } from '@reach/tooltip';
import * as React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

import { Box } from './Box';

interface Props extends TooltipProps {
  variant?: string;
  style?: any;
  sx?: ThemeUIStyleObject;
}

export const Tooltip: React.FC<Props> = ({ variant, ...props }) => {
  return <Box as={ReachTooltip} variant="tooltip.primary" {...props} />;
};
