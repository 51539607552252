import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as React from 'react';

import { Box } from '../../../components/common/Box';
import { Flex } from '../../../components/common/Flex';
import { LinkMenu } from '../../../components/LinkMenu';
import { BillingDetails } from './BillingDetails';
import { CancelSubscription } from './CancelSubscription';
import { PauseSubscription } from './PauseSubscription';
import { PaymentHistory } from './PaymentHistory';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string);

export const Billing: React.FC<RouteComponentProps> = () => {
  const menuLinks = [
    { title: 'Billing Details', path: 'billing-details' },
    { title: 'Payment History', path: 'payment-history' },
    { title: 'Pause Subscription', path: 'pause-subscription' },
    { title: 'Cancel Subscription', path: 'cancel-subscription' },
  ];

  return (
    <Flex sx={{ pt: 7 }}>
      <LinkMenu links={menuLinks} />
      <Box sx={{ pl: 5, width: '100%' }}>
        <Box sx={{ width: '100%', maxWidth: '950px' }}>
          <Elements stripe={stripePromise}>
            <Router>
              <BillingDetails path="billing-details" />
              <PaymentHistory path="payment-history" />
              <CancelSubscription path="cancel-subscription" />
              <PauseSubscription path="pause-subscription" />

              <Redirect path="/" from="/" to="billing-details" noThrow />
            </Router>
          </Elements>
        </Box>
      </Box>
    </Flex>
  );
};
