import '@reach/combobox/styles.css';
import '@reach/menu-button/styles.css';
import '@reach/tooltip/styles.css';
import 'react-app-polyfill/ie11';
import 'react-toastify/dist/ReactToastify.min.css';
import './index.css';
import './polyfil';
import './theme/fonts/fonts.css';
import './window';

import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import ReactDOM from 'react-dom';

import App from './App';
import { AppProviders } from './context/AppProviders';
import * as serviceWorker from './serviceWorker';
import LogRocketFuzzySearchSanitizer from './utils/logrocket-fuzzy-search-sanitizer';

const { requestSanitizer, responseSanitizer } = LogRocketFuzzySearchSanitizer.setup([
  'password',
  'apiKey',
  'token',
  'refreshToken',
]);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });

  if (process.env.REACT_APP_LOG_ROCKET_ID) {
    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID, {
      network: {
        requestSanitizer,
        responseSanitizer,
      },
    });
    setupLogRocketReact(LogRocket);

    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });
  }
}

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
