import { Link as RRLink, Match } from '@reach/router';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import { v4 as buildUUID } from 'uuid';

import { ApiCompany } from '../../api/auth';
import { useAuth } from '../../context/AuthContext';
import { useCompany } from '../../context/CompanyContext';
import logger from '../../utils/logger';
import { Flex } from '../common/Flex';
import { Link } from '../common/Link';
import { useActiveOverlay } from './ActiveOverlayContext';
import { Overlay, OverlayAction, OverlayContent, OverlayText, OverlayTitle } from './Overlay';

const SubscriptionPaused: React.FC = () => {
  const { refreshUser } = useAuth();
  const { resumeSubscription } = useCompany();
  const [isResuming, setIsResuming] = useState(false);

  const handleResumeSubscription = async () => {
    setIsResuming(true);

    try {
      await resumeSubscription();
      await refreshUser();
    } catch (e) {
      logger.logError(e);
      toast.error('Something went wrong whilst resuming your subscription');
    } finally {
      setIsResuming(false);
    }
  };

  return (
    <Overlay>
      <OverlayContent>
        <OverlayTitle>Your account is in lockdown.</OverlayTitle>

        <OverlayText>
          To reactivate your account with exactly the same setup as before (package and users),
          click the first button. If you want to choose a new package and create new users, click
          the second button.
        </OverlayText>

        <Flex sx={{ flexDirection: 'column' }}>
          <Flex mb="4">
            <OverlayAction
              variant={isResuming ? 'disabled' : 'primaryInverted'}
              disabled={isResuming}
              maxWidth="250px"
              mr="3"
              onClick={handleResumeSubscription}
              onKeyPress={handleResumeSubscription}
            >
              Continue with your old account and settings &rarr;
            </OverlayAction>

            <OverlayAction
              as={RRLink}
              to="/subscriptions/change"
              variant={isResuming ? 'disabled' : 'warningInverted'}
              disabled={isResuming}
              maxWidth="250px"
            >
              Choose a new package and create new users &rarr;
            </OverlayAction>
          </Flex>
          <Flex sx={{ justifyContent: 'flex-end' }}>
            <Link to="/settings/billing/cancel-subscription" sx={{ color: 'white' }}>
              Cancel my subscription
            </Link>
          </Flex>
        </Flex>
      </OverlayContent>
    </Overlay>
  );
};

interface SubscriptionPausedOverlayImplProps {
  canDisplay: boolean;
  company: ApiCompany;
}

const SubscriptionPausedOverlayImpl: React.FC<SubscriptionPausedOverlayImplProps> = (props) => {
  const uuid = useRef(buildUUID());
  const { isOverlayActive, setOverlayStatus } = useActiveOverlay();

  useEffect(() => {
    const showOverlay = props.canDisplay && props.company.subscriptionStatus === 'paused';

    setOverlayStatus(uuid.current, showOverlay);
  }, [props.canDisplay, setOverlayStatus, props.company.subscriptionStatus]);

  if (!isOverlayActive(uuid.current)) {
    return null;
  }

  const elementRoot = document.getElementById('subscription-paused-overlay-root');

  if (!elementRoot) {
    throw new Error(
      'Attempted to render the SubscriptionPausedOverlay but the root node does not exist'
    );
  }

  return ReactDOM.createPortal(<SubscriptionPaused />, elementRoot);
};

export const SubscriptionPausedOverlay: React.FC = () => {
  const { company } = useCompany();

  if (!company) {
    return null;
  }

  return (
    <Match path="/subscriptions/*">
      {(subscriptionsProps) => (
        <Match path="/settings/billing/cancel-subscription">
          {(cancelSubscriptionProps) => {
            const isSubscriptionPage = Boolean(subscriptionsProps.match);
            const isCancelSubscription = Boolean(cancelSubscriptionProps.match);
            return (
              <SubscriptionPausedOverlayImpl
                canDisplay={!isSubscriptionPage && !isCancelSubscription}
                company={company}
              />
            );
          }}
        </Match>
      )}
    </Match>
  );
};
