import styled from '@emotion/styled/macro';
import { Link as RRLink, RouteComponentProps } from '@reach/router';
import { useMachine } from '@xstate/react';
import { useState } from 'react';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { ApiTeam, ApiTeamUserOverview } from '../../../api/team';
import { ColouredTooltip } from '../../../components/ColouredTooltip';
import { Box } from '../../../components/common/Box';
import { Button } from '../../../components/common/Button';
import { Flex } from '../../../components/common/Flex';
import { Heading } from '../../../components/common/Heading';
import { Text } from '../../../components/common/Text';
import { Tooltip } from '../../../components/common/Tooltip';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { DragHandle } from '../../../components/Icon';
import { EditUserModal } from '../../../components/Modal/EditUserModal';
import { CompanyOwnerOnly } from '../../../components/Permissions/CompanyOwnerOnly';
import { CreateTeam } from './CreateTeam';
import { RelocateUserOptions } from './models';
import { teamsListMachine } from './teamsListMachine';

interface TeamsProps extends RouteComponentProps {}

export const Teams: React.FC<TeamsProps> = () => {
  const [current, send] = useMachine(teamsListMachine);

  return (
    <Flex sx={{ justifyContent: 'center', px: 5, pt: 7, letterSpacing: '-0.01rem' }}>
      <Flex sx={{ flex: 1, flexDirection: 'column', maxWidth: '1000px' }}>
        <ErrorBoundary>
          <Flex sx={{ flex: 1, flexDirection: 'column', mb: 7 }}>
            <Flex sx={{ justifyContent: 'space-between', alignItems: 'flex-end', pb: 7 }}>
              <Heading
                as="h2"
                sx={{
                  pt: 0,
                  mb: 0,
                  fontSize: 6,
                  fontWeight: 'semibold',
                  letterSpacing: '-0.05rem',
                }}
              >
                Teams
              </Heading>
              <CreateTeam
                onTeamCreated={(team) => {
                  send({ type: 'ADD_TEAM', data: { team } });
                }}
              />
            </Flex>

            {current.matches('idle') || current.matches('loading') ? (
              <TeamsLoading />
            ) : current.matches('error') ? (
              <TeamsError
                // Remove type once @xstate/react is updated
                // https://github.com/davidkpiano/xstate/issues/934
                error={current.context.error as string}
                onReload={() => {
                  send({ type: 'RELOAD' });
                }}
              />
            ) : current.matches('ready') ? (
              <TeamsList
                teams={current.context.teams}
                onUpdatedUser={() => {
                  send('REFETCH_TEAMS');
                }}
                onRelocateUser={(opts) => {
                  send({ type: 'RELOCATE_USER', data: opts });
                }}
              />
            ) : null}
          </Flex>
        </ErrorBoundary>
      </Flex>
    </Flex>
  );
};

export const TeamsLoading: React.FC = () => {
  return (
    <Flex sx={{ flex: 1, flexDirection: 'column' }}>
      <Text>Fetching your teams</Text>
    </Flex>
  );
};

interface TeamsErrorProps {
  error: string;
  onReload: () => void;
}

export const TeamsError: React.FC<TeamsErrorProps> = (props) => {
  return (
    <Flex sx={{ flex: 1, flexDirection: 'column', alignItems: 'center' }}>
      <Text>{props.error}</Text>
      <Box py={1} />
      <div>
        <Button variant="warning" onClick={props.onReload}>
          Reload teams
        </Button>
      </div>
    </Flex>
  );
};

interface TeamRowProps {
  isDraggingOver?: boolean;
}

const TeamRow = styled(Flex)<TeamRowProps>`
  flex-direction: column;
  background-color: ${(props: TeamRowProps) => (props.isDraggingOver ? '#cccccc' : '#eaeaea')};
  transition: all 200ms ease-in-out;
`;

interface TeamRowDroppableAreaProps {
  isDraggingOver?: boolean;
}

const TeamRowDroppableArea = styled(Flex)<TeamRowDroppableAreaProps>`
  background-color: ${(props: TeamRowDroppableAreaProps) =>
    props.isDraggingOver ? '#cccccc' : '#eaeaea'};
  flex-direction: column;
`;

const TeamCell = styled(Flex)`
  padding: ${(props) => props.theme.space[3]};
  align-items: center;
`;

interface TeamsListProps {
  teams: ApiTeam[];
  onUpdatedUser: () => void;
  onRelocateUser: (opts: RelocateUserOptions) => void;
}

const TeamLink = styled(RRLink)`
  color: ${(props) => props.theme.colors.accent};
  text-decoration: none;
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: 1.25rem;
  border-bottom: 2px solid;
  border-bottom-color: transparent;
  letter-spacing: -0.02rem;

  &:hover {
    border-bottom-color: ${(props) => props.theme.colors.accent};
    border-radius: 0;
    text-decoration: none;
  }
`;

interface EditableTeamUserOverview extends ApiTeamUserOverview {
  teamId: number;
}

export const TeamsList: React.FC<TeamsListProps> = (props) => {
  const countColumnsWidth = '50px';
  const [userToEdit, setUserToEdit] = useState<EditableTeamUserOverview | undefined>();

  const calculateTeamAdvertCount = (team: ApiTeam) => {
    return team.teamUsers.reduce((acc, user) => user.advertCount + acc, 0);
  };

  const onDragEnd = ({ source, destination, draggableId }: DropResult) => {
    if (!destination) {
      return;
    }

    const fromTeamId = parseInt(source.droppableId, 10);
    const toTeamId = parseInt(destination.droppableId, 10);
    const userId = parseInt(draggableId, 10);

    if (fromTeamId === toTeamId) {
      return;
    }

    props.onRelocateUser({ userId, fromTeamId, toTeamId });
  };

  return (
    <>
      <Box pt="2">
        <Flex sx={{ width: '100%' }}>
          <Flex sx={{ flex: 1 }}>
            <Text as="span" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
              Name
            </Text>
          </Flex>
          <Flex sx={{ minWidth: countColumnsWidth, justifyContent: 'center' }}>
            <Text as="span" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
              Adverts
            </Text>
          </Flex>
          <Flex sx={{ minWidth: countColumnsWidth, justifyContent: 'center' }}>
            <Text as="span" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
              Users
            </Text>
          </Flex>
        </Flex>

        <DragDropContext onDragEnd={onDragEnd}>
          {props.teams.map((team, index) => (
            <TeamRow
              key={team.id}
              sx={{ mt: index === 0 ? 0 : 7 }}
              data-testid={`team-row-${team.id}`}
            >
              <Flex sx={{ width: '100%' }}>
                <TeamCell sx={{ flex: 1 }}>
                  <ColouredTooltip label="Edit team details" sx={{ bg: 'accent' }}>
                    <TeamLink to={team.id.toString()}>{team.name}</TeamLink>
                  </ColouredTooltip>
                </TeamCell>
                <TeamCell sx={{ minWidth: countColumnsWidth, justifyContent: 'center' }}>
                  {calculateTeamAdvertCount(team)}
                </TeamCell>
                <TeamCell sx={{ minWidth: countColumnsWidth, justifyContent: 'center' }}>
                  {team.teamUsers.length}
                </TeamCell>
              </Flex>

              <Droppable droppableId={team.id.toString()}>
                {(provided, snapshot) => (
                  <TeamRowDroppableArea
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                    {...provided.droppableProps}
                  >
                    {team.teamUsers.length > 0 ? (
                      team.teamUsers.map((user, index) => (
                        <Draggable key={user.id} draggableId={user.id.toString()} index={index}>
                          {(provided, snapshot) => (
                            <Flex
                              sx={{
                                width: '100%',
                                bg: 'white',
                                pl: 2,
                                py: 3,
                                borderBottom:
                                  index === team.teamUsers.length - 1
                                    ? undefined
                                    : '1px solid #cccccc',
                                boxShadow: snapshot.isDragging
                                  ? '0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -2px rgba(0,0,0,.05)'
                                  : undefined,
                                outline: snapshot.isDragging
                                  ? 'rgba(0, 0, 0, 0.10) solid 1px'
                                  : 'none',
                              }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <TeamCell sx={{ minWidth: '20px', justifyContent: 'center' }}>
                                <Text sx={{ color: 'grey.5', m: 0 }}>
                                  <DragHandle {...provided.dragHandleProps} />
                                </Text>
                              </TeamCell>
                              <TeamCell sx={{ flex: 1 }}>
                                <CompanyOwnerOnly fallback={<>{user.displayName}</>}>
                                  <ColouredTooltip label="Edit user details" sx={{ bg: 'black' }}>
                                    <Text
                                      sx={{
                                        cursor: 'pointer',
                                        '&:hover, &:focus': {
                                          textDecoration: 'underline',
                                        },
                                      }}
                                      onClick={() => {
                                        setUserToEdit({
                                          ...user,
                                          teamId: team.id,
                                        });
                                      }}
                                    >
                                      {user.displayName}
                                    </Text>
                                  </ColouredTooltip>
                                </CompanyOwnerOnly>
                              </TeamCell>
                              <TeamCell
                                sx={{ minWidth: countColumnsWidth, justifyContent: 'center' }}
                              >
                                <UserAdvertCount user={user} />
                              </TeamCell>
                              <TeamCell
                                sx={{ minWidth: countColumnsWidth, justifyContent: 'center' }}
                              />
                            </Flex>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <Box sx={{ bg: 'white', pt: 2 }}>This team has no users</Box>
                    )}

                    {provided.placeholder}
                  </TeamRowDroppableArea>
                )}
              </Droppable>
            </TeamRow>
          ))}
        </DragDropContext>
      </Box>

      <CompanyOwnerOnly>
        <EditUserModal
          isOpen={Boolean(userToEdit)}
          user={userToEdit ?? undefined}
          onUserEdited={() => {
            setUserToEdit(undefined);
            props.onUpdatedUser();
          }}
          onClose={() => {
            setUserToEdit(undefined);
          }}
        />
      </CompanyOwnerOnly>
    </>
  );
};

interface UserAdvertCountProps {
  user: ApiTeamUserOverview;
}

const UserAdvertCount: React.FC<UserAdvertCountProps> = ({ user }) => {
  const teamIds = Object.keys(user.advertCountsPerTeam);
  const totalCount = teamIds.reduce(
    (acc, teamId) => acc + (user.advertCountsPerTeam as any)[teamId].count,
    0
  );
  const teamsWithAdverts = teamIds.map((teamId) => user.advertCountsPerTeam[parseInt(teamId, 10)]);

  if (teamsWithAdverts.length <= 1) {
    return <Text as="span">{totalCount}</Text>;
  }

  return (
    <Tooltip
      label={
        <div>
          {teamsWithAdverts.map((team, index) => (
            <Box key={index} sx={{ px: 2, pb: 2, pt: index === 0 ? 2 : 0 }}>
              {team.name}: {team.count}
            </Box>
          ))}
        </div>
      }
      style={{ padding: 0 }}
    >
      <Text as="span">
        {totalCount}
        <Text as="span" sx={{ fontWeight: 'bold' }}>
          *
        </Text>
      </Text>
    </Tooltip>
  );
};
