import styled from '@emotion/styled/macro';
import { useMachine } from '@xstate/react';
import { FormikHelpers, FormikValues } from 'formik';
import * as React from 'react';
import { toast } from 'react-toastify';
import { assign, createMachine } from 'xstate';

import { advertFormPageApi } from '../../../api/advert-form-page';
import { FormPage, Question, QuestionType } from '../../../api/form';
import { ApiTeam, ApiTeamAdvertCompany, ApiTeamAdvertCompanyData } from '../../../api/team';
import { ColouredTooltip } from '../../../components/ColouredTooltip';
import { Box } from '../../../components/common/Box';
import { Flex } from '../../../components/common/Flex';
import {
  Modal,
  ModalActions as RawModalActions,
  ModalButton,
  ModalClose,
  ModalContainer as RawModalContainer,
  ModalTextButton,
} from '../../../components/common/Modal';
import { Text } from '../../../components/common/Text';
import { builderTheme } from '../../../theme/builderTheme';
import { ThemeProvider } from '../../../theme/ThemeProvider';
import { ApiFormError } from '../../../utils/errors/ApiFormError';
import logger from '../../../utils/logger';
import { FormGenerator } from '../../AdvertBuilder/components/FormGenerator';

const ClickableText = styled(Text)`
  color: ${(props) => props.theme.colors.accent};
  text-decoration: none;
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  cursor: pointer;
  border-bottom: 2px solid;
  border-bottom-color: transparent;
  letter-spacing: -0.02rem;

  &:hover {
    border-bottom-color: ${(props) => props.theme.colors.accent};
    border-radius: 0;
  }
`;

const ModalContainer = styled(RawModalContainer)`
  max-height: 75vh;
  overflow-y: auto;
  padding: 0 !important;
  background-color: #fafafa;
`;

const ModalActions = styled(RawModalActions)`
  position: sticky;
  bottom: 0;
  padding: 0;
  background-color: #ffffff;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #cccccc;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

interface EditCompanyDefaultAnswersContext {
  companyFormPage?: FormPage;
}

type EditCompanyDefaultAnswersEvent =
  | {
      type: 'OPEN';
    }
  | {
      type: 'CLOSE';
    };

type EditCompanyDefaultAnswersState =
  | {
      value: 'idle';
      context: EditCompanyDefaultAnswersContext;
    }
  | {
      value: 'loading';
      context: EditCompanyDefaultAnswersContext;
    }
  | {
      value: 'editing';
      context: EditCompanyDefaultAnswersContext & {
        companyFormPage: FormPage;
      };
    }
  | {
      value: 'error';
      context: EditCompanyDefaultAnswersContext;
    };

const editCompanyDefaultAnswersMachine = createMachine<
  EditCompanyDefaultAnswersContext,
  EditCompanyDefaultAnswersEvent,
  EditCompanyDefaultAnswersState
>({
  id: 'editCompanyDefaultAnswers',
  context: {
    companyFormPage: undefined,
  },
  initial: 'idle',
  states: {
    idle: {
      on: {
        OPEN: 'loading',
      },
    },
    loading: {
      invoke: {
        id: 'fetchCompanyFormPage',
        src: 'fetchCompanyFormPage',
        onDone: {
          actions: 'cacheCompanyFormPage',
          target: 'editing',
        },
        onError: {
          target: 'error',
        },
      },
      on: {
        CLOSE: 'idle',
      },
    },
    editing: {
      on: {
        CLOSE: 'idle',
      },
    },
    error: {
      on: {
        OPEN: 'loading',
        CLOSE: 'idle',
      },
    },
  },
});

interface EditCompanyDefaultAnswersProps {
  team: ApiTeam;
  company: ApiTeamAdvertCompany;
  onUpdateCompanyData: (data: ApiTeamAdvertCompanyData) => Promise<void>;
}

export const EditCompanyDefaultAnswers: React.FC<EditCompanyDefaultAnswersProps> = (props) => {
  const [state, send] = useMachine(editCompanyDefaultAnswersMachine, {
    actions: {
      cacheCompanyFormPage: assign({
        companyFormPage: (_ctx, ev: any) => {
          return ev.data.formPage;
        },
      }),
    },
    services: {
      fetchCompanyFormPage: async () => {
        const response = await advertFormPageApi.fetchFormPages({ slug: 'company' });

        // @TODO - Handle multiple returned forms for the slug.
        // Use a dropdown to allow the user to chose which form perhaps?

        return { formPage: response.formPages[0] };
      },
    },
  });

  const closeModal = () => send('CLOSE');

  return (
    <React.Fragment>
      <ColouredTooltip label="Edit the default answers for this company" sx={{ bg: 'accent' }}>
        <ClickableText as="span" onClick={() => send('OPEN')}>
          {props.company.name}
        </ClickableText>
      </ColouredTooltip>

      <Modal
        aria-label="Edit company defaults"
        isOpen={state.matches('loading') || state.matches('editing')}
        style={{ maxWidth: '1000px', width: '95vw' }}
        onDismiss={closeModal}
      >
        <Box sx={{ mt: 2, mr: 2 }}>
          <ModalClose onClick={closeModal} />
        </Box>
        <ModalContainer>
          <Flex sx={{ flexDirection: 'column', p: 0 }}>
            {state.matches('loading') ? (
              <Loading />
            ) : state.matches('error') ? (
              <Error />
            ) : state.matches('editing') ? (
              <CompanyFormPageForm
                formPage={state.context.companyFormPage}
                data={props.company.data}
                onUpdate={async (data) => {
                  const formattedData = Object.keys(data).reduce((acc, key) => {
                    const question = state.context.companyFormPage.advertFormQuestions.find(
                      (q) => q.id === parseInt(key, 10)
                    );

                    if (!question) {
                      return acc;
                    }

                    (acc as any)[question.shortCode] = data[key];

                    return acc;
                  }, {});

                  await props.onUpdateCompanyData(formattedData);

                  closeModal();

                  toast.success('Default answers updated');
                }}
                onClose={closeModal}
              />
            ) : null}
          </Flex>
        </ModalContainer>
      </Modal>
    </React.Fragment>
  );
};

const Loading = () => {
  return <Flex sx={{ p: 5, justifyContent: 'center', alignItems: 'center' }}>Loading...</Flex>;
};

const Error = () => {
  return (
    <Flex sx={{ p: 5, justifyContent: 'center', alignItems: 'center' }}>
      Encountered an error whilst fetching the answers
    </Flex>
  );
};

interface CompanyFormPageFormProps {
  formPage: FormPage;
  data: ApiTeamAdvertCompanyData;
  onUpdate: (data: ApiTeamAdvertCompanyData) => Promise<void>;
  onClose: () => void;
}

const CompanyFormPageForm: React.FC<CompanyFormPageFormProps> = (props) => {
  const orderedQuestions = props.formPage.advertFormQuestions.sort(
    (a: Question, b: Question) => a.position - b.position
  );

  const initialValues = orderedQuestions.reduce((acc: any, question: Question) => {
    const value = props.data[question.shortCode];
    if (question.type === QuestionType.Collection) {
      if (Array.isArray(value)) {
        acc[question.id] = value;
        return acc;
      }

      acc[question.id] = props.data[question.shortCode]
        ? JSON.parse(props.data[question.shortCode])
        : [];
      return acc;
    }

    acc[question.id] = props.data[question.shortCode] || '';
    return acc;
  }, {});

  const formikProps = {
    key: props.formPage.id,
    initialValues,
    onSubmit: async (values: FormikValues, actions: FormikHelpers<FormikValues>) => {
      actions.setSubmitting(true);

      try {
        await props.onUpdate(values);
      } catch (e) {
        if (e instanceof ApiFormError) {
          actions.setErrors(e.errors);
        } else {
          // TODO - Handle this.
          logger.logError(e);
        }
      }
    },
  };

  return (
    <ThemeProvider theme={builderTheme as TSThemeFixMe}>
      <FormGenerator
        formikProps={formikProps}
        questions={orderedQuestions}
        questionsContainerProps={{ innerSx: { maxWidth: '850px' } }}
        extras={(formikProps) => (
          <React.Fragment>
            <ModalActions>
              <ModalButton
                type="button"
                variant={formikProps.isSubmitting ? 'disabled' : 'primaryInverted'}
                disabled={formikProps.isSubmitting}
                onClick={() => {
                  formikProps.handleSubmit();
                }}
              >
                Save changes
              </ModalButton>

              <ModalTextButton type="button" onClick={props.onClose}>
                Cancel
              </ModalTextButton>
            </ModalActions>
          </React.Fragment>
        )}
      />
    </ThemeProvider>
  );
};
