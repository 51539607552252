import styled from '@emotion/styled/macro';
import { Match } from '@reach/router';
import differenceInDays from 'date-fns/differenceInDays';
import isSameDay from 'date-fns/isSameDay';
import { useState } from 'react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Banner } from '../components/common/Banner';
import { Box } from '../components/common/Box';
import { Text } from '../components/common/Text';
import { useAuth } from '../context/AuthContext';
import {
  DiscountCodeStatus,
  TokenSource,
  useDripIntegrationDiscountFlow,
} from '../context/DripIntegrationDiscountFlowContext';
import { useInterval } from '../hooks/useInterval';
import { calculateCountdownUnits } from '../utils/date';
import { Link } from './common/Link';

interface DiscountCountdownBannerProps {}

export const DiscountCountdownBanner: React.FC<DiscountCountdownBannerProps> = () => {
  const { expiresAt, status, source } = useDripIntegrationDiscountFlow();

  const [tick, setTick] = useState(true);

  // Causes a rerender every one second
  useInterval(() => {
    if (!expiresAt) {
      return;
    }

    setTick(!tick);
  }, 1000);

  if (!expiresAt) {
    return null;
  }

  return (
    <Match path="/subscriptions/*">
      {(props) => {
        const isSubscriptions = Boolean(props.match);

        if (isSubscriptions) {
          return null;
        }

        return status === DiscountCodeStatus.Active ? (
          <ActiveDiscount expiresAt={expiresAt} />
        ) : status === DiscountCodeStatus.Expired && source === TokenSource.Url ? (
          <ExpiredDiscount expiresAt={expiresAt} />
        ) : null;
      }}
    </Match>
  );
};

interface ActiveDiscountProps {
  expiresAt: Date;
}

const ActiveDiscount: React.FC<ActiveDiscountProps> = (props) => {
  const { user } = useAuth();

  const expiresToday = differenceInDays(props.expiresAt, new Date()) === 0;

  return (
    <Banner
      variant={expiresToday ? 'danger' : 'primary'}
      containerStyles={{ position: 'sticky', top: 0, zIndex: 2 }}
      innerContainerStyles={{ maxWidth: 'none' }}
    >
      <Box>
        <Text sx={{ color: 'white', fontSize: 2, fontWeight: 500, lineHeight: '1.5rem' }}>
          50% Discount special offer.{' '}
          <Text as="span" sx={{ color: 'whitefade80', fontWeight: 300 }}>
            Get 50% off for the first three months of your subscription.{' '}
            <Box as="span" sx={{ whiteSpace: 'nowrap' }}>
              Special offer expires in:{' '}
              <Text
                as="span"
                sx={{ display: 'inline-block', width: '12rem', color: 'white', fontWeight: 500 }}
              >
                <TimeRemaining
                  futureDate={props.expiresAt}
                  startingUnit={expiresToday ? 'hour' : 'day'}
                  format={expiresToday ? 'short' : 'long'}
                />
              </Text>
              {user ? (
                <StartSubscriptionButton to="subscriptions/new">
                  Use Discount &rarr;
                </StartSubscriptionButton>
              ) : null}
            </Box>
          </Text>
        </Text>
      </Box>
    </Banner>
  );
};

interface TimeRemainingProps {
  futureDate: Date;
  startingUnit: 'day' | 'hour';
  format: 'short' | 'long';
}

const TimeRemaining: React.FC<TimeRemainingProps> = ({
  futureDate,
  startingUnit = 'day',
  format = 'long',
}) => {
  const units = calculateCountdownUnits(futureDate, startingUnit === 'day');

  switch (format) {
    case 'short':
      return (
        <React.Fragment>
          {`${units.days ? `${units.days}d` : ''}  ${units.hours}h  ${units.minutes}m  ${
            units.seconds
          }s`}
        </React.Fragment>
      );
    default:
      return (
        <FormattedMessage
          id="discount_countdown.duration"
          description="Discount duration countdown"
          defaultMessage="{days} {days, plural, one {day} other {days}}, {hours} {hours, plural, one {hour} other {hours}}, {minutes} {minutes, plural, one {minutes} other {minutes}}"
          values={units}
        />
      );
  }
};

interface ExpiredDiscountProps {
  expiresAt: Date;
}

const ExpiredDiscount: React.FC<ExpiredDiscountProps> = (props) => {
  const { user } = useAuth();

  const expiresToday = isSameDay(new Date(), props.expiresAt);

  return (
    <Banner
      variant={expiresToday ? 'danger' : 'primary'}
      containerStyles={{ position: 'sticky', top: 0, zIndex: 2 }}
      innerContainerStyles={{ maxWidth: 'none' }}
    >
      <Box>
        <Text sx={{ color: 'white', fontSize: 2, fontWeight: 500, lineHeight: '1.5rem' }}>
          The 50% discount special offer has now expired.{' '}
          <Text as="span" sx={{ color: 'whitefade80', fontWeight: 300 }}>
            However, you're still able to purchase an AdBuilder subscription.{' '}
            {user ? (
              <StartSubscriptionButton to="subscriptions/new">
                Start a Subscription &rarr;
              </StartSubscriptionButton>
            ) : null}
          </Text>
        </Text>
      </Box>
    </Banner>
  );
};

const StartSubscriptionButton = styled(Link)`
  background-color: rgba(255, 255, 255, 0.3);
  border: 1.5px solid #ffffff;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 500;
  font-size: 0.9rem;
  margin: 0 1rem;
  padding: 0.5rem 1.25rem;
  text-decoration: none;
  white-space: nowrap;
  transition: 0.2s ease-in-out;
  :hover {
    background-color: ${(props) => props.theme.colors.primary};
  }
`;
