import * as React from 'react';

import { Advert } from '../../../api/advert';
import { Box } from '../../../components/common/Box';
import { useAdvertTableMachine, useAdvertTableMachineActions } from '../AdvertTableMachineContext';
import {
  ActiveFilterPill,
  ActiveFilterPillList,
  Filterable,
  SortableHeader,
} from '../DashboardTable';
import { ColumnOptions, FILTER_KEYS, SORT_KEYS, validateIsSingularFilter } from '.';

export const buildCompanyColumn = (options: ColumnOptions) => {
  return {
    id: 'company',
    Header: () => {
      const [{ filters }, actions] = useAdvertTableMachine();

      const companyFilter = filters.get(FILTER_KEYS.COMPANY);

      validateIsSingularFilter(companyFilter);

      return (
        <div>
          <SortableHeader sortName={SORT_KEYS.COMPANY}>Company</SortableHeader>

          {companyFilter ? (
            <React.Fragment>
              <Box sx={{ height: '4px' }} />

              <ActiveFilterPillList>
                <ActiveFilterPill onClear={() => actions.clearFilter(FILTER_KEYS.COMPANY)}>
                  {companyFilter.label}
                </ActiveFilterPill>
              </ActiveFilterPillList>
            </React.Fragment>
          ) : null}
        </div>
      );
    },
    accessor: (props: Advert) => {
      return {
        companyId: props.advertCompany.rootId,
        companyName: props.advertCompany.name,
      };
    },
    Cell: ({ value }: { value: { companyId: number; companyName: string } }) => {
      const { setFilter } = useAdvertTableMachineActions();

      return (
        <Filterable
          helperText="Filter list by this company"
          onClick={() => {
            setFilter(FILTER_KEYS.COMPANY, {
              label: value.companyName,
              value: value.companyId,
            });
          }}
        >
          {value.companyName}
        </Filterable>
      );
    },
    width: options.width,
  };
};
