import { SortableHeader } from '../DashboardTable';
import { ColumnOptions, DateCell, SORT_KEYS } from '.';

export const buildCreatedAtColumn = (options: ColumnOptions) => {
  return {
    id: 'createdAt',
    Header: () => <SortableHeader sortName={SORT_KEYS.CREATED_AT}>Created</SortableHeader>,
    accessor: 'createdAt',
    Cell: ({ value }: { value: Date }) => <DateCell>{value}</DateCell>,
    width: options.width,
  };
};
