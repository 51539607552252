import { Advert, ApiAdvertStatus } from '../../../api/advert';

export enum AdvertBuilderPages {
  Basics,
  Package,
  Candidate,
  Role,
  Company,
  Advert,
}

export const orderedAdvertBuilderPages = [
  AdvertBuilderPages.Basics,
  AdvertBuilderPages.Package,
  AdvertBuilderPages.Candidate,
  AdvertBuilderPages.Role,
  AdvertBuilderPages.Company,
  AdvertBuilderPages.Advert,
];

const advertStatusToAdvertBuilderPageMap = {
  [ApiAdvertStatus.Basics]: AdvertBuilderPages.Basics,
  [ApiAdvertStatus.Package]: AdvertBuilderPages.Package,
  [ApiAdvertStatus.Candidate]: AdvertBuilderPages.Candidate,
  [ApiAdvertStatus.Role]: AdvertBuilderPages.Role,
  [ApiAdvertStatus.Company]: AdvertBuilderPages.Company,
  [ApiAdvertStatus.Complete]: AdvertBuilderPages.Advert,
};

export const getAdvertBuilderPageForAdvertStatus = (status: ApiAdvertStatus) => {
  return advertStatusToAdvertBuilderPageMap[status];
};

export const isAdvertFormPageComplete = (advert: Advert, pageToCheck: AdvertBuilderPages) => {
  if (advert.advertStatus === ApiAdvertStatus.Complete) {
    return true;
  }

  const advertStatusPage = getAdvertBuilderPageForAdvertStatus(advert.advertStatus);

  const pageToCheckIndex = orderedAdvertBuilderPages.findIndex((page) => page === pageToCheck);
  const advertCurrentPageIndex = orderedAdvertBuilderPages.findIndex(
    (page) => page === advertStatusPage
  );

  return pageToCheckIndex < advertCurrentPageIndex;
};

export const isAdvertFormPageAccessible = (advert: Advert, pageToCheck: AdvertBuilderPages) => {
  switch (pageToCheck) {
    case AdvertBuilderPages.Basics:
      return true;
    case AdvertBuilderPages.Package:
      return true;
    case AdvertBuilderPages.Candidate:
      return isAdvertFormPageComplete(advert, AdvertBuilderPages.Package);
    case AdvertBuilderPages.Role:
      return isAdvertFormPageComplete(advert, AdvertBuilderPages.Candidate);
    case AdvertBuilderPages.Company:
      return isAdvertFormPageComplete(advert, AdvertBuilderPages.Company);
    case AdvertBuilderPages.Advert:
      return advert.advertStatus === ApiAdvertStatus.Complete;
    default:
      throw new Error(`The AdvertBuilderPages ${pageToCheck} does not exist`);
  }
};

export const getPageUrl = (advertId: string, page: AdvertBuilderPages) => {
  const pagePath = (() => {
    switch (page) {
      case AdvertBuilderPages.Basics:
        return 'basics';
      case AdvertBuilderPages.Package:
        return 'package';
      case AdvertBuilderPages.Candidate:
        return 'candidate';
      case AdvertBuilderPages.Role:
        return 'role';
      case AdvertBuilderPages.Company:
        return 'company';
      case AdvertBuilderPages.Advert:
        return 'variations';
      default:
        throw new Error(`The AdvertBuilderPages ${page} does not exist`);
    }
  })();

  return `/advert/${advertId}/${pagePath}`;
};

export const getAdvertCurrentPageUrl = (advert: Advert) => {
  const page = getAdvertBuilderPageForAdvertStatus(advert.advertStatus);

  return getPageUrl(advert.id.toString(), page);
};
