export const authTheme = {
  buttons: {
    submit: {
      backgroundColor: 'green.5',
      color: 'white',
      borderColor: 'white',
      px: '60px',
      py: '17px',
      fontSize: '1rem',
      border: 'none',
      transition: (t: any) => t.transitions[0],
      '&:hover, &:focus': {
        backgroundColor: 'green.6',
        color: 'white',
      },
      '&:disabled': {
        background: '#8ea287',
      },
    },
  },
  forms: {
    input: {
      borderRadius: 2,
      p: 3,
      mb: 2,
      borderStyle: 0,
      borderWidth: 2,
      borderColor: 'grey.1',
      backgroundColor: 'grey.0',
      fontSize: 2,
      outline: 'none',
      width: '100%',
      color: 'blackfade85',
      height: 'unset',
      WebkitAppearance: 'none',
      '::placeholder': {
        color: 'blackfade50',
      },
      ':disabled': {
        color: 'grey.4',
      },
    },
    select: {
      item: {
        fontSize: '0.9rem',
      },
    },
  },
};
