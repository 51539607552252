import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import { advertJobTitlesApi } from '../api/advert-job-titles';
import { QuestionFieldOption } from '../screens/AdvertBuilder/components/QuestionFieldProps';
import logger from '../utils/logger';

export const useJobTitlesOptions = (
  ignore = false
): [QuestionFieldOption[], boolean, AxiosError | undefined] => {
  const [jobTitles, setJobTitles] = useState<QuestionFieldOption[]>([]);
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState<AxiosError | undefined>(undefined);
  const [skipLoad, setSkipLoad] = useState(ignore);

  useEffect(() => {
    const fetch = async () => {
      setError(undefined);
      setInProgress(true);

      try {
        if (skipLoad) {
          return;
        }

        const response = await advertJobTitlesApi.fetchAdvertJobTitles();
        setJobTitles(response.jobTitles.map((c, i) => ({ id: i, value: c })));
      } catch (e) {
        logger.logError(e as any);
        setError(e as any);
      }

      setInProgress(false);
    };

    fetch();

    return () => {
      setSkipLoad(true);
    };
  }, [skipLoad]);

  return [jobTitles, inProgress, error];
};
