import { MenuItem } from '@reach/menu-button';
import { useNavigate } from '@reach/router';
import { useCallback } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';

import { analytics } from '../../../analytics/analytics';
import { Advert, advertApi } from '../../../api/advert';
import { Box } from '../../../components/common/Box';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalTextButton,
  ModalTitle,
} from '../../../components/common/Modal';
import { useToggle } from '../../../hooks/useToggle';
import logger from '../../../utils/logger';
import { AdvertBuilderPages } from '../../AdvertBuilder/utils/advertBuilderPages';
import { JobSummary } from '.';

interface DuplicateActionProps {
  advert: Advert;
}

export const DuplicateAction: React.FC<DuplicateActionProps> = ({ advert }) => {
  const navigate = useNavigate();
  const { on: modalOpen, setOn, setOff } = useToggle();

  const onDuplicate = useCallback(async () => {
    try {
      const response = await advertApi.duplicateAdvert(advert.id);
      analytics.advertDuplicated(response.advert.id);
      navigate(`/advert/${response.advert.id}?target=${AdvertBuilderPages.Basics}`);
    } catch (e) {
      logger.logError(e as any);
      toast.error('Something went wrong whilst duplicating the advert');
    }
  }, [advert.id, navigate]);

  return (
    <React.Fragment>
      <Modal aria-label="Duplicate advert confirmation" isOpen={modalOpen} onDismiss={setOff}>
        <ModalClose onClick={setOff} />
        <ModalContainer>
          <ModalTitle>Are you sure you want to duplicate this advert?</ModalTitle>

          <JobSummary advert={advert} />

          <Box py="3" />

          <ModalActions>
            <ModalButton type="button" data-testid="duplicate-advert" onClick={onDuplicate}>
              I'm sure, duplicate this advert
            </ModalButton>

            <ModalTextButton type="button" onClick={setOff}>
              Cancel
            </ModalTextButton>
          </ModalActions>
        </ModalContainer>
      </Modal>

      <MenuItem key="duplicate" data-testid="advert-actions-duplicate" onSelect={setOn}>
        Duplicate Advert
      </MenuItem>
    </React.Fragment>
  );
};
