import styled from '@emotion/styled/macro';
import * as React from 'react';
import { toast } from 'react-toastify';

import { companyApi } from '../../../api/company';
import { ApiInvite } from '../../../api/invitation';
import { Box } from '../../../components/common/Box';
import { Button } from '../../../components/common/Button';
import { Table, TableBody, TableCell, TableHead, TableRow } from '../../../components/common/Table';
import { RevokeInviteModal } from '../../../components/Modal/RevokeInviteModal';

const Strong = styled.strong`
  font-weight: 600;
`;

interface CompanyInviteListProps {
  invites: ApiInvite[];
  fetchingInvites: boolean;
  error?: string;
  actionsBackgroundColour?: string;
  renderActions(invite: ApiInvite): React.ReactChild;
}

export const CompanyInviteList: React.FC<CompanyInviteListProps> = ({
  invites,
  fetchingInvites,
  error,
  actionsBackgroundColour = '#ffffff',
  renderActions,
}) => {
  if (error) {
    return <div>Something went wrong whilst fetching the invites</div>;
  }

  if (!fetchingInvites && invites.length === 0) {
    return <div>No invites found</div>;
  }

  return (
    <Box pb="3" data-testid="company-invite-list">
      <Table
        loading={fetchingInvites}
        loadingRows={3}
        loadingColumns={3}
        sx={{ fontSize: '0.9rem' }}
      >
        <TableHead>
          <TableRow>
            <TableCell width="17.5%">Name</TableCell>
            <TableCell width="40%">Email Address</TableCell>
            <TableCell width="17.5%">Invited By</TableCell>
            <TableCell width="25%">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody slim bg="grey.1">
          {invites.map((invite: ApiInvite) => (
            <TableRow key={invite.id}>
              <TableCell width="17.5%">
                <Strong>{invite.firstName}</Strong>
              </TableCell>
              <TableCell width="40%">{invite.email}</TableCell>
              <TableCell width="17.5%">{invite.createdBy.firstName}</TableCell>
              <TableCell
                bg={actionsBackgroundColour}
                width="25%"
                sx={{ border: 'none !important' }}
              >
                {renderActions(invite)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

interface RevokeActionProps {
  invite: ApiInvite;
  onFetchInvites: () => void;
}

export const RevokeAction = (props: RevokeActionProps) => {
  const [inviteToRevoke, setInviteToRevoke] = React.useState<ApiInvite | undefined>(undefined);

  return (
    <React.Fragment>
      <Button
        type="button"
        variant="danger"
        sx={{
          px: 4,
          py: 2,
          fontWeight: 'semibold',
          minWidth: '6rem',
          letterSpacing: '-0.01rem',
          fontSize: '0.85rem',
        }}
        onClick={() => {
          setInviteToRevoke(props.invite);
        }}
      >
        Revoke
      </Button>

      <RevokeInviteModal
        isOpen={inviteToRevoke ? inviteToRevoke.id === props.invite.id : false}
        invite={inviteToRevoke ? inviteToRevoke : undefined}
        onRevoked={() => {
          setInviteToRevoke(undefined);
          props.onFetchInvites();
        }}
        onClose={() => {
          setInviteToRevoke(undefined);
        }}
      />
    </React.Fragment>
  );
};

interface ResendActionProps {
  invite: ApiInvite;
  canResend: boolean;
  onRefresh: () => Promise<void>;
}

export const ResendAction = (props: ResendActionProps) => {
  const [resending, setResending] = React.useState(false);

  const {
    invite: {
      company: { id: companyId },
      id: inviteId,
    },
    onRefresh,
  } = props;

  React.useEffect(() => {
    if (!resending) {
      return;
    }

    const resendInvite = async () => {
      try {
        await companyApi.resendCompanyInvite(companyId, inviteId);
        await onRefresh();

        setResending(false);
      } catch (e) {
        toast.error('Something went wrong resending the invite');
      }
    };
    resendInvite();
  }, [resending, companyId, inviteId, onRefresh]);

  return (
    <React.Fragment>
      <Button
        type="button"
        variant={resending || props.canResend ? 'disabled' : 'primary'}
        disabled={resending || props.canResend}
        sx={{
          px: 4,
          py: 2,
          fontWeight: 'semibold',
          minWidth: '6rem',
          letterSpacing: '-0.01rem',
          fontSize: '0.85rem',
        }}
        onClick={() => {
          setResending(true);
        }}
      >
        Re-send
      </Button>
    </React.Fragment>
  );
};
