import * as React from 'react';

import { Advert } from '../../../api/advert';
import { ModalKeyInformation } from '../../../components/common/Modal';

interface JobSummaryProps {
  advert: Advert;
}

export const JobSummary: React.FC<JobSummaryProps> = ({ advert }) => {
  const jobSummary = [
    advert.jobTitle,
    advert.jobLocation,
    advert.advertCompany.name,
    advert.formattedSalary,
  ]
    .filter(Boolean)
    .join(', ');

  return <ModalKeyInformation>{jobSummary}</ModalKeyInformation>;
};
