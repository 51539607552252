import * as React from 'react';

import { BooleanType, getBooleanTypeByValue } from '../../api/advert';
import { ApiUser, AuthorityLevel, getAuthorityLevelOption } from '../../api/auth';
import { companyApi } from '../../api/company';
import { useUser } from '../../context/UserContext';
import { UserFacingError } from '../../Error/BaseErrors';
import logger from '../../utils/logger';
import { FormMode, UserDetailsModal } from './UserDetailsModal';

interface InitialUserValues {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isCompanyOwner: boolean;
  isTeamOwner: boolean;
  teamId: number;
}

interface EditUserModalProps {
  isOpen: boolean;
  user?: InitialUserValues;
  cancelable?: boolean;
  onUserEdited: (user: ApiUser) => void;
  onClose: () => void;
}

export const EditUserModal: React.FC<EditUserModalProps> = ({
  isOpen,
  user,
  cancelable = true,
  onUserEdited,
  onClose,
}) => {
  const currentUser = useUser();

  const authorityLevel = getAuthorityLevelOption(
    user && user.isCompanyOwner ? AuthorityLevel.Owner : AuthorityLevel.User
  );

  const isTeamOwnerBool = user ? user.isTeamOwner : false;
  const isTeamOwner = getBooleanTypeByValue(isTeamOwnerBool ? BooleanType.Yes : BooleanType.No);

  if (!authorityLevel) {
    throw new UserFacingError("Couldn't find the authority level for that user");
  }

  return (
    <UserDetailsModal
      isOpen={isOpen}
      title="Edit a user."
      cancelable={cancelable}
      confirmationTitle={<span>Save Amendments</span>}
      initialValues={{
        firstName: user ? user.firstName : '',
        lastName: user ? user.lastName : '',
        email: user ? user.email : '',
        authorityLevel: authorityLevel,
        isTeamOwner: isTeamOwner,
        team: user ? user.teamId : currentUser.teamId,
      }}
      formMode={FormMode.Edit}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        if (!user) {
          return;
        }

        setSubmitting(true);

        try {
          const { email, ...data } = values;

          const response = await companyApi.editCompanyMember(currentUser.company.id, user.id, {
            ...data,
            authorityLevel: data.authorityLevel.value,
            isTeamOwner: `${Boolean(data.isTeamOwner.value)}`,
          });

          onUserEdited(response.user);
        } catch (e) {
          logger.logError(e);
          setFieldError('email', 'Something went wrong whilst editing the user');
        }

        setSubmitting(false);
      }}
      onClose={onClose}
    />
  );
};
