import styled from '@emotion/styled/macro';
import { useContext, useMemo } from 'react';
import * as React from 'react';

import { Box } from './Box';

const TableContext = React.createContext({
  loading: false,
  loadingRows: 0,
  loadingColumns: 0,
  fixedHeader: false,
});

interface TableProps {
  loading?: boolean;
  loadingRows?: number;
  loadingColumns?: number;
  fixedHeader?: boolean;
}

export const Table: React.FC<TableProps & any> = ({
  loading = false,
  loadingRows,
  loadingColumns,
  fixedHeader = false,
  sx,
  ...props
}) => {
  const contextValue = useMemo(() => {
    return {
      loading,
      loadingRows,
      loadingColumns,
      fixedHeader,
    };
  }, [loading, loadingRows, loadingColumns, fixedHeader]);

  return (
    <TableContext.Provider value={contextValue}>
      <Box
        as="table"
        aria-busy={loading}
        sx={{ letterSpacing: '-0.01rem', width: '100%', ...sx }}
        {...props}
      />
    </TableContext.Provider>
  );
};

const TableNestedContext = React.createContext({ variant: 'body' });

export const TableHead: React.FC<any> = ({ children, sx, ...rest }) => {
  const contextValue = { variant: 'head' };

  return (
    <Box as="thead" sx={{ textAlign: 'left', ...sx }} {...rest}>
      <TableNestedContext.Provider value={contextValue}>{children}</TableNestedContext.Provider>
    </Box>
  );
};

interface TBodyProps {
  slim?: boolean;
  theme?: any;
}

const TBody = styled(Box)`
  td {
    border-right: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    padding: ${(props: TBodyProps) => {
      return props.slim
        ? `${props.theme.space[3]} ${props.theme.space[2]}`
        : `${props.theme.space[4]} ${props.theme.space[2]}`;
    }};
  }
`;

export const TableBody: React.FC<any> = ({ children, domRef, slim = false, ...rest }) => {
  const { loading, loadingRows, loadingColumns } = useContext(TableContext);

  return (
    <TBody as="tbody" slim={slim} {...rest} ref={domRef}>
      <TableNestedContext.Provider value={{ variant: 'body' }}>
        {loading ? (
          <React.Fragment>
            {Array.from({ length: loadingRows }, (val, rowIndex) => (
              <TableRow key={rowIndex} as="tr">
                <TableCell colSpan={loadingColumns} />
              </TableRow>
            ))}
          </React.Fragment>
        ) : (
          children
        )}
      </TableNestedContext.Provider>
    </TBody>
  );
};

const BaseTableRow: React.FC<any> = ({ domRef, ...props }) => (
  <Box as="tr" ref={domRef} {...props} />
);

interface TableRowProps {
  isDragging?: boolean;
}

export const TableRow = styled(BaseTableRow)<TableRowProps>`
  ${(props: TableRowProps) =>
    props.isDragging
      ? `
  background: #eaeaea;
  display: table;
`
      : ''}
`;

export const TableCell: React.FC<any> = ({ sx, ...props }) => {
  const { loading, fixedHeader } = useContext(TableContext);
  const { variant } = useContext(TableNestedContext);

  if (loading) {
    return <LoadingTableCell sx={sx} {...props} />;
  }

  if (variant === 'head') {
    return (
      <Box
        as="th"
        sx={{ position: 'relative', ...(fixedHeader && { position: 'sticky', top: 0 }), ...sx }}
        {...props}
      >
        {typeof props.children === 'string' ? (
          <Box as="span" sx={{ fontSize: '0.8rem' }}>
            {props.children}
          </Box>
        ) : (
          props.children
        )}
      </Box>
    );
  }

  return <Box as="td" sx={sx} {...props} />;
};

export const LoadingTableCell: React.FC<any> = ({ children, sx, ...props }) => {
  const { variant } = useContext(TableNestedContext);

  if (variant === 'head') {
    return (
      <Box as="th" sx={{ position: 'relative', ...sx }} {...props}>
        <Box as="span" sx={{ fontSize: '0.8rem' }}>
          {children}
        </Box>
      </Box>
    );
  }

  return <Box as="td" className="loading-shimmer" sx={{ height: '65px', ...sx }} {...props} />;
};
