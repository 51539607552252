import { Redirect, RouteComponentProps, useNavigate } from '@reach/router';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';

import { accountSetupApi } from '../../api/account-setup';
import { businessTypes } from '../../api/auth';
import { Box } from '../../components/common/Box';
import { Button } from '../../components/common/Button';
import { Flex } from '../../components/common/Flex';
import { ErrorMessage as ThemedErrorMessage, TextField } from '../../components/common/form';
import { Image } from '../../components/common/Image';
import { Text } from '../../components/common/Text';
import { RegistrationWizard } from '../../components/Registration/RegistrationWizardContainer';
import { RegistrationFormLabel } from '../../components/RegistrationFormLabel';
import {
  EMPLOYEE_COUNT_OPTIONS,
  REGISTRATION_WIZARD_STEPS,
  USAGE_GOAL_OPTIONS,
} from '../../config/registration';
import { useAuth } from '../../context/AuthContext';
import { useCompany } from '../../context/CompanyContext';
import { useTheme } from '../../hooks/useTheme';
import { ApiFormError } from '../../utils/errors/ApiFormError';
import logger from '../../utils/logger';
import { useRegistrationSharedValues } from '../Auth/Registration';

export const SetupCompanyAccount: React.FC<RouteComponentProps> = () => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Helmet title="Company information" />

      <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
        <Box p={4}>
          <a href="https://adbuilder.io">
            <Image sx={{ width: '12rem' }} src={theme.assets.logo.dark} alt="AdBuilder logo" />
          </a>
        </Box>
      </Box>

      <RegistrationWizard.Container>
        <RegistrationWizard.FreeTrialHeader />
        <RegistrationWizard.StepIndicator activeStepIndex={1} steps={REGISTRATION_WIZARD_STEPS} />
        <RegistrationWizard.Content>
          <CompanyDetailsForm />
        </RegistrationWizard.Content>
      </RegistrationWizard.Container>
    </React.Fragment>
  );
};

const CompanyDetailFormSchema = Yup.object().shape({
  companyName: Yup.string().required('This is a required field'),
  businessTypes: Yup.object()
    .shape({ label: Yup.string(), value: Yup.number() })
    .required('This is a required field'),
  employeeCount: Yup.object()
    .shape({ label: Yup.string(), value: Yup.number() })
    .required('This is a required field'),
  usageGoal: Yup.object()
    .shape({ label: Yup.string(), value: Yup.number() })
    .required('This is a required field'),
});

const CompanyDetailsForm = () => {
  const sharedValues = useRegistrationSharedValues(window.location.search);
  const navigate = useNavigate();
  const { fetchUser, logout } = useAuth();
  const { company } = useCompany();

  if (company) {
    if (company.subscriptionStatus === 'not_created') {
      return <Redirect path="/" from="/" to="/account-setup/subscription" noThrow />;
    }

    return <Redirect path="/" from="/" to="/dashboard" noThrow />;
  }

  return (
    <Box sx={{ p: 5 }}>
      <Formik
        initialValues={{
          companyName: '',
          businessType: sharedValues.initialFormValues.businessType,
          employeeCount: EMPLOYEE_COUNT_OPTIONS[0],
          usageGoal: USAGE_GOAL_OPTIONS[0],
        }}
        validationSchema={CompanyDetailFormSchema}
        onSubmit={async (values, { setErrors }) => {
          try {
            await accountSetupApi.createCompany({
              companyName: values.companyName,
              businessType: values.businessType.id,
              employeeCount: values.employeeCount.id,
              usageGoal: values.usageGoal.id,
            });

            await fetchUser();

            navigate('/account-setup/subscription');
          } catch (e) {
            if (e instanceof ApiFormError) {
              setErrors(e.errors);
            } else {
              logger.logError(e);
            }
          }
        }}
      >
        {({ values, handleChange, handleBlur, isSubmitting }) => (
          <Form>
            <Flex sx={{ pb: 5 }}>
              <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                <RegistrationFormLabel>Company name:</RegistrationFormLabel>
                <TextField
                  type="text"
                  name="companyName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.companyName}
                />
                <ErrorMessage name="companyName">
                  {(msg) => <ThemedErrorMessage>{msg}</ThemedErrorMessage>}
                </ErrorMessage>
              </Flex>
            </Flex>

            <Box sx={{ pb: 5 }}>
              <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                <Field name="businessType">
                  {({ field, form }: FieldProps) => (
                    <RegistrationWizard.Select
                      name={field.name}
                      label="Our business is a:"
                      items={businessTypes}
                      selectedItem={field.value}
                      onChange={(item) => {
                        form.setFieldValue(field.name, item);
                      }}
                    />
                  )}
                </Field>

                <ErrorMessage name="businessType">
                  {(msg) => <ThemedErrorMessage>{msg}</ThemedErrorMessage>}
                </ErrorMessage>
              </Flex>
            </Box>

            <Box sx={{ pb: 5 }}>
              <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                <Field name="employeeCount">
                  {({ field, form }: FieldProps) => (
                    <RegistrationWizard.Select
                      name={field.name}
                      label="How many people work in your business:"
                      items={EMPLOYEE_COUNT_OPTIONS}
                      selectedItem={field.value}
                      onChange={(item) => {
                        form.setFieldValue(field.name, item);
                      }}
                    />
                  )}
                </Field>

                <ErrorMessage name="employeeCount">
                  {(msg) => <ThemedErrorMessage>{msg}</ThemedErrorMessage>}
                </ErrorMessage>
              </Flex>
            </Box>

            <Box sx={{ pb: 8 }}>
              <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                <Field name="usageGoal">
                  {({ field, form }: FieldProps) => (
                    <RegistrationWizard.Select
                      name={field.name}
                      label="What is your main goal with adbuilder:"
                      items={USAGE_GOAL_OPTIONS}
                      selectedItem={field.value}
                      onChange={(item) => {
                        form.setFieldValue(field.name, item);
                      }}
                    />
                  )}
                </Field>

                <ErrorMessage name="usageGoal">
                  {(msg) => <ThemedErrorMessage>{msg}</ThemedErrorMessage>}
                </ErrorMessage>
              </Flex>
            </Box>

            <Flex
              sx={{
                flexDirection: ['column', 'row'],
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                type="submit"
                variant="primaryInverted"
                disabled={isSubmitting}
                sx={{
                  fontWeight: 500,
                  letterSpacing: '-0.01rem',
                  fontSize: '1.1rem',
                  p: '1.25rem 2.5rem',
                  mb: [5, 0],
                }}
              >
                Next &rarr;
              </Button>

              <Box>
                <Text sx={{ fontWeight: 500, letterSpacing: '-0.02rem' }}>
                  Already got an account?{' '}
                  <Button
                    variant="text"
                    onClick={() => {
                      logout('/login');
                    }}
                    sx={{
                      borderRadius: '0px',
                      fontWeight: 500,
                      color: 'rgb(50, 146, 207)',
                      letterSpacing: '-0.01rem',
                      borderBottom: '2px solid',
                      '&:hover': {
                        textDecoration: 'none',
                        borderBottom: '2px solid #3292cf',
                      },
                    }}
                  >
                    Log in &rarr;
                  </Button>
                </Text>
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
