import styled from '@emotion/styled/macro';
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { animated, useTransition } from 'react-spring';
import Transition, { TransitionStatus } from 'react-transition-group/Transition';

import { useTheme } from '../hooks/useTheme';
import { randomNumberInRange, sleep } from '../utils/common';
import { Box } from './common/Box';
import { Flex } from './common/Flex';
import { Image } from './common/Image';
import { Text } from './common/Text';
import { Fade } from './Headless/Fade';

const CURTAIN_SLIDE_DURATION = 700;

interface ContextState {
  isActive: boolean;
  setCurtainSpinnerActiveStatus: (value: boolean) => void;
  closeCurtains: () => Promise<void>;
}

const CurtainLoadingSpinnerContext = createContext<ContextState | undefined>(undefined);

export const CurtainLoadingSpinnerProvider: React.FC = ({ children }) => {
  const [isActive, setIsActive] = useState(false);

  const state = useMemo(() => {
    return {
      isActive,
      setCurtainSpinnerActiveStatus: setIsActive,
      closeCurtains: async () => {
        setIsActive(true);
        await sleep(CURTAIN_SLIDE_DURATION);
      },
    };
  }, [isActive]);

  return (
    <CurtainLoadingSpinnerContext.Provider value={state}>
      {children}
      <CurtainLoadingSpinner />
    </CurtainLoadingSpinnerContext.Provider>
  );
};

export const useCurtainLoadingSpinnerState = () => {
  const state = useContext(CurtainLoadingSpinnerContext);

  if (typeof state === undefined) {
    throw new Error(
      'useCurtainLoadingSpinnerState must be used within a CurtainLoadingSpinnerProvider'
    );
  }

  return state as ContextState;
};

export const CurtainLoadingSpinner: React.FC = () => {
  const portalNode = useRef(document.createElement('div'));
  const [portalNodeMounted, setPortalNodeMounted] = useState(false);

  useEffect(() => {
    const portalNodeElement = portalNode.current;

    portalNodeElement.dataset.testid = 'curtain-spinner-root';
    document.body.appendChild(portalNodeElement);

    setPortalNodeMounted(true);

    return () => {
      setPortalNodeMounted(false);
      portalNodeElement.parentNode?.removeChild(portalNodeElement);
    };
  }, []);

  if (!portalNodeMounted) {
    return null;
  }

  if (portalNodeMounted && !portalNode.current) {
    throw new Error(
      'Attempted to render the CurtainLoadingSpinner but the root node does not exist'
    );
  }

  return ReactDOM.createPortal(<Spinner />, portalNode.current);
};

const Stage = styled(Box)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 4;
  overflow: hidden;
`;

const Curtain = styled(Box)`
  position: relative;
  width: 100vw;
  height: 50vh;
  background-color: ${(props) => props.theme.colors.offWhite};
  transition: all ${CURTAIN_SLIDE_DURATION}ms cubic-bezier(0.72, 0.35, 0.24, 0.99);
`;

const TopCurtain = styled(Curtain)`
  border-bottom: 5px solid rgba(50, 146, 207, 1);
  transform: translateY(-50vh);
`;
const BottomCurtain = styled(Curtain)`
  border-top: 5px solid rgba(50, 146, 207, 1);
  transform: translateY(50vh);
`;

const transitionStyles = {
  entering: { transform: 'translateY(0vh)', borderColor: 'rgba(50, 146, 207, 0)' },
  entered: { transform: 'translateY(0vh)', borderColor: 'rgba(50, 146, 207, 0)' },
  exiting: {},
  exited: {},
  unmounted: {},
};

const Spinner: React.FC = () => {
  const theme = useTheme();
  const spinnerState = useCurtainLoadingSpinnerState();

  return (
    <Transition in={spinnerState.isActive} timeout={CURTAIN_SLIDE_DURATION}>
      {(state: TransitionStatus) => (
        <Stage style={state === 'exited' ? { height: 0 } : {}}>
          <TopCurtain style={transitionStyles[state]} />
          <BottomCurtain style={transitionStyles[state]} />

          <Fade active={spinnerState.isActive && state === 'entered'}>
            <Box sx={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
              <Flex
                sx={{
                  height: '100%',
                  width: '100%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 5,
                }}
              >
                <Image
                  src={theme.assets.logo.dark}
                  alt="AdBuilder logo"
                  sx={{
                    maxWidth: '22rem',
                    width: '100%',
                    paddingBottom: '2.25rem',
                  }}
                />

                <Text
                  sx={{
                    color: 'blackfade90',
                    fontsize: '1.125rem',
                    letterSpacing: '-0.01rem',
                    pb: 2,
                  }}
                >
                  Building your adverts.
                </Text>

                <SpinnerLoadingBar isActive={['entered', 'exiting'].includes(state)} />
              </Flex>
            </Box>
          </Fade>
        </Stage>
      )}
    </Transition>
  );
};

const LoadingBar = styled(Box)`
  position: absolute;
  border: 5px solid ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.accent};
  height: 100%;
`;

const AnimatedLoadingBar = animated(LoadingBar);

interface SpinnerLoadingBarProps {
  isActive: boolean;
}

const SpinnerLoadingBar: React.FC<SpinnerLoadingBarProps> = (props) => {
  const transitions = useTransition(props.isActive, null, {
    from: { width: '0%' },
    enter: () => async (next: any) => {
      let current = 0;

      while (current < 100) {
        await sleep(200);

        // Randomly increment our current position but ensure we cap at 100
        current = Math.min(current + randomNumberInRange(20, 40), 100);

        await next({ width: `${current}%` });
      }
    },
    leave: { width: '0%' },
  } as any);

  return (
    <Box sx={{ height: '50px', width: '100%', maxWidth: '25rem' }}>
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          width: '100%',
          border: '5px solid #555555',
          bg: 'white',
        }}
      >
        {transitions.map(({ item, key, props }) =>
          item ? (
            <AnimatedLoadingBar key={key} style={props}></AnimatedLoadingBar>
          ) : (
            <AnimatedLoadingBar key={key} sx={{ width: '0%' }}></AnimatedLoadingBar>
          )
        )}
      </Box>
    </Box>
  );
};
