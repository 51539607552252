import { useActor } from '@xstate/react';
import * as React from 'react';

import { Advert } from '../../api/advert';
import { QuickViewAdvertModal } from '../../components/QuickViewAdvertModal';
import { useAdvertTableMachineActions } from './AdvertTableMachineContext';
import { QuickViewService } from './machines/quickViewMachine';

interface DashboardQuickViewModalProps {
  service: QuickViewService;
}

export const DashboardQuickViewModal: React.FC<DashboardQuickViewModalProps> = ({ service }) => {
  const actions = useAdvertTableMachineActions();
  const [quickViewState, sendToQuickView] = useActor(service);

  const isVisible =
    quickViewState.matches('opened') ||
    quickViewState.matches('opening') ||
    quickViewState.matches('closing');

  const isOpen = quickViewState.matches('opened') || quickViewState.matches('opening');

  const { advert, initialVariationId } = quickViewState.context;

  return (
    <div>
      {isVisible && (
        <QuickViewAdvertModal
          isOpen={isOpen}
          advert={advert as Advert}
          initialVariationId={initialVariationId}
          onClose={() => sendToQuickView({ type: 'CLOSE' })}
          onFavourite={async (variationId: number) => {
            actions.onFavourite(advert as Advert, variationId);
          }}
          onUnfavourite={async () => {
            actions.onUnfavourite(advert as Advert);
          }}
        />
      )}
    </div>
  );
};
