import * as React from 'react';

import { Advert } from '../../../api/advert';
import { Box } from '../../../components/common/Box';
import { useTheme } from '../../../hooks/useTheme';
import { useAdvertTableMachine, useAdvertTableMachineActions } from '../AdvertTableMachineContext';
import {
  ActiveFilterPill,
  ActiveFilterPillList,
  Filterable,
  SortableHeader,
} from '../DashboardTable';
import { ColumnOptions, FILTER_KEYS, SORT_KEYS, validateIsSingularFilter } from '.';

export const buildCreatedByColumn = (options: ColumnOptions) => {
  return {
    id: 'createdBy',
    Header: () => {
      const theme = useTheme();
      const [{ filters }, actions] = useAdvertTableMachine();

      const createdByFilter = filters.get(FILTER_KEYS.CREATED_BY);

      validateIsSingularFilter(createdByFilter);

      return (
        <div>
          <SortableHeader sortName={SORT_KEYS.CREATED_BY}>Builder</SortableHeader>

          {createdByFilter ? (
            <React.Fragment>
              <Box sx={{ height: '4px' }} />

              <ActiveFilterPillList>
                <ActiveFilterPill
                  borderColor={theme.colors.accent}
                  onClear={() => actions.clearFilter(FILTER_KEYS.CREATED_BY)}
                >
                  {createdByFilter.label}
                </ActiveFilterPill>
              </ActiveFilterPillList>
            </React.Fragment>
          ) : null}
        </div>
      );
    },
    accessor: (props: Advert) => {
      return {
        createdById: props.createdBy.id,
        createdByDisplayName: props.createdBy.displayName,
      };
    },
    Cell: ({ value }: { value: { createdById: number; createdByDisplayName: string } }) => {
      const { setFilter } = useAdvertTableMachineActions();

      return (
        <Filterable
          helperText="Filter list by this user"
          onClick={() => {
            setFilter(FILTER_KEYS.CREATED_BY, {
              label: value.createdByDisplayName,
              value: value.createdById,
            });
          }}
        >
          {value.createdByDisplayName}
        </Filterable>
      );
    },
    width: options.width,
  };
};
