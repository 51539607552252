import { useMemo } from 'react';

import { QuestionFieldOption } from '../components/QuestionFieldProps';
import { removeAutoFillPrefix } from './autoFillPrefixUtils';

export const useExampleAnswerMatch = (
  options: QuestionFieldOption[],
  input: string,
  prefix = '',
  optionLimit = 128
) => {
  return useMemo(() => {
    if (!options || options.length === 0) {
      return [];
    }

    if (!input) {
      return options.slice(0, optionLimit);
    }

    const val = removeAutoFillPrefix(prefix, input).toLowerCase();

    const optionsLimited = [];

    for (const option of options) {
      if (!option.value) {
        continue;
      }

      const withoutPrefix = removeAutoFillPrefix(prefix, option.value).toLowerCase();

      // Don't return exact match
      if (withoutPrefix === val) {
        continue;
      }

      if (withoutPrefix.includes(val)) {
        optionsLimited.push(option);
      }

      if (optionsLimited.length >= optionLimit) {
        break;
      }
    }

    return optionsLimited;
  }, [options, input, prefix, optionLimit]);
};
