import { useMachine } from '@xstate/react';
import * as React from 'react';

import { ColouredTooltip } from '../../components/ColouredTooltip';
import { Box } from '../../components/common/Box';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTextButton,
  ModalTitle,
} from '../../components/common/Modal';
import { Text } from '../../components/common/Text';
import { RuntimeError } from '../../Error/BaseErrors';
import { useTheme } from '../../hooks/useTheme';
import { useAdvertTableMachineActions } from './AdvertTableMachineContext';
import { filterCollectionListMachine } from './machines/filterCollectionListMachine';
import {
  FilterCollection,
  useFilterCollectionManager,
} from './machines/filterCollectionManagerMachine';

interface FilterCollectionListModalProps {
  isOpen: boolean;
  onDismiss: () => void;
}

export const FilterCollectionListModal: React.FC<FilterCollectionListModalProps> = ({
  isOpen,
  onDismiss,
}) => {
  const { applySavedFilter } = useAdvertTableMachineActions();
  const { state, collections, deleteFilterCollection, reload } = useFilterCollectionManager();

  return (
    <Modal aria-label="Dashboard filters list" isOpen={isOpen} onDismiss={onDismiss}>
      <ModalClose onClick={onDismiss} />

      <ModalContainer>
        {state.matches('initialising') ||
        state.matches({ ready: 'idle' }) ||
        state.matches({ ready: 'removingCollection' }) ? (
          <FiltersList
            filters={collections}
            onSelect={applySavedFilter}
            onDelete={deleteFilterCollection}
          />
        ) : state.matches('failure') ? (
          <Failure onRetryClicked={reload} />
        ) : (
          <NotImplemented state={state} />
        )}
      </ModalContainer>
    </Modal>
  );
};

interface FiltersListProps {
  filters: any;
  onSelect: (filterCollection: FilterCollection) => void;
  onDelete: (id: string) => void;
}

const FiltersList: React.FC<FiltersListProps> = (props) => {
  const [listState, sendToList] = useMachine(filterCollectionListMachine, {
    actions: {
      deleteFilterCollection: (ctx, ev) => {
        if (!ctx.collectionToDelete) {
          return;
        }

        props.onDelete(ctx.collectionToDelete.id);
      },
    },
  });

  const filters = Object.entries(Object.fromEntries(props.filters)).reduce<
    { id: string; label: string; value: FilterCollection }[]
  >((acc, [key, value]) => {
    acc.push({ id: key, label: value.name, value: value });
    return acc;
  }, []);

  return (
    <React.Fragment>
      {listState.matches('idle') ? (
        <ListView
          filters={filters}
          onSelect={props.onSelect}
          onDelete={(data) => {
            sendToList({ type: 'DELETE', data });
          }}
        />
      ) : listState.matches('confirming_delete') ? (
        <ConfirmDeleteView
          collectionLabel={listState.context.collectionToDelete.label}
          onAccept={() => {
            sendToList({ type: 'CONFIRM' });
          }}
          onCancel={() => {
            sendToList({ type: 'CANCEL' });
          }}
        />
      ) : (
        <NotImplemented state={listState} />
      )}
    </React.Fragment>
  );
};

interface ListViewProps {
  filters: { id: string; label: string; value: FilterCollection }[];
  onSelect: (filterCollection: FilterCollection) => void;
  onDelete: ({ id, label }: { id: string; label: string }) => void;
}

const ListView: React.FC<ListViewProps> = (props) => {
  const theme = useTheme();

  if (props.filters.length === 0) {
    return <EmptyList />;
  }

  return (
    <React.Fragment>
      <ModalTitle sx={{ pb: '1.25rem', borderBottom: '1px solid #cccccc;' }}>
        Your saved filters.
      </ModalTitle>

      <ModalContent>
        <Box
          as="ul"
          sx={{ listStyle: 'none', maxHeight: '250px', overflowY: 'auto', pt: '1.25rem', pl: 0 }}
        >
          {props.filters.map((filter) => {
            return (
              <Box
                key={filter.label}
                as="li"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  px: 3,
                  py: 2,
                  ':hover': {
                    bg: '#e5e5e5',
                    svg: {
                      opacity: 1,
                      fill: theme.colors.error,
                    },
                  },
                }}
              >
                <ColouredTooltip sx={{ bg: 'primary' }} label="Open this filter">
                  <Box
                    as="button"
                    sx={{
                      bg: 'unset',
                      border: 'none',
                      cursor: 'pointer',
                      color: 'text',
                      fontSize: '1rem',
                      p: 0,
                      borderBottom: '2px solid',
                      borderBottomColor: 'transparent',
                      ':hover': {
                        color: 'accent',
                        borderBottomColor: 'accent',
                      },
                    }}
                    onClick={() => {
                      props.onSelect(filter.value);
                    }}
                  >
                    {filter.label}
                  </Box>
                </ColouredTooltip>

                <ColouredTooltip sx={{ bg: 'error' }} label="Delete this filter">
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      props.onDelete({
                        id: filter.id,
                        label: filter.label,
                      });
                    }}
                  >
                    <DeleteFilterIcon />
                  </Box>
                </ColouredTooltip>
              </Box>
            );
          })}
        </Box>
      </ModalContent>
    </React.Fragment>
  );
};

interface ConfirmDeleteViewProps {
  collectionLabel: string;
  onAccept: () => void;
  onCancel: () => void;
}

const ConfirmDeleteView: React.FC<ConfirmDeleteViewProps> = (props) => {
  return (
    <React.Fragment>
      <ModalTitle>Are you sure you want to delete this filter?</ModalTitle>

      <ModalContent>
        Please confirm you wish to delete the{' '}
        <Text as="span" color="accent">
          {props.collectionLabel}
        </Text>{' '}
        filter.
      </ModalContent>

      <ModalActions>
        <ModalButton data-testid="delete-filter" type="button" onClick={props.onAccept}>
          Delete filter
        </ModalButton>

        <ModalTextButton type="button" onClick={props.onCancel}>
          Cancel
        </ModalTextButton>
      </ModalActions>
    </React.Fragment>
  );
};

const DeleteFilterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 42"
      opacity="0.4"
      height="22px"
      width="22px"
    >
      <g>
        <path d="M26.89 42.14H6.11c-2.25 0-4.08-1.83-4.08-4.08V10.19h28.92v27.87c.01 2.25-1.82 4.08-4.06 4.08zM4.93 13.08v24.98c0 .65.53 1.18 1.18 1.18h20.77c.65 0 1.18-.53 1.18-1.18V13.08H4.93z" />
        <path d="M9.93 34.78c0 .43-.35.79-.79.79H7.82c-.43 0-.79-.35-.79-.79V16.37c0-.43.35-.79.79-.79h1.31c.43 0 .79.35.79.79v18.41zM17.95 34.78c0 .43-.35.79-.79.79h-1.31c-.43 0-.79-.35-.79-.79V16.37c0-.43.35-.79.79-.79h1.31c.43 0 .79.35.79.79v18.41zM25.97 34.78c0 .43-.35.79-.79.79h-1.32c-.43 0-.79-.35-.79-.79V16.37c0-.43.35-.79.79-.79h1.32c.43 0 .79.35.79.79v18.41z" />
        <g>
          <path d="M33 13.08H0V6.64c0-1.52 1.19-2.76 2.66-2.76h27.69c1.46 0 2.66 1.24 2.66 2.76v6.44zM2.89 10.19h27.22V6.77H2.89v3.42z" />
        </g>
        <g>
          <path d="M22.65 4.54c0 .43-.35.79-.79.79h-1.32c-.43 0-.79-.35-.79-.79v-.86c0-.43-.35-.79-.79-.79h-4.93c-.43 0-.79.35-.79.79v.85c0 .43-.35.79-.79.79h-1.31c-.43 0-.79-.35-.79-.79V.79c0-.43.35-.79.79-.79h10.72c.43 0 .79.36.79.79v3.75z" />
        </g>
      </g>
    </svg>
  );
};

const EmptyList = () => {
  return (
    <Box>
      <Text sx={{ pb: 3, fontWeight: 500, color: 'error' }}>
        You haven't created any saved filters yet.
      </Text>
      <Box sx={{ pb: 3 }}>
        To create a saved filter, first filter the dashboard on anything you like. Then click on the
        save icon and give that filter a name.
      </Box>
      <Box>
        For more information on how to do that,{' '}
        <a
          href="https://help.adbuilder.io/dashboard/filtering-your-dashboard/"
          target="_blank"
          rel="noreferrer"
        >
          click here
        </a>
        .
      </Box>
    </Box>
  );
};

interface FailureProps {
  onRetryClicked: () => void;
}

const Failure: React.FC<FailureProps> = (props) => {
  return (
    <React.Fragment>
      <ModalTitle>Something went wrong.</ModalTitle>

      <ModalContent>Something went wrong whilst saving the filter.</ModalContent>

      <ModalActions>
        <ModalButton type="button" onClick={props.onRetryClicked}>
          Retry
        </ModalButton>
      </ModalActions>
    </React.Fragment>
  );
};

const NotImplemented: React.FC<{ state: any }> = ({ state }) => {
  console.debug('Handle me', state.value);
  throw new RuntimeError(
    `Something went wrong whilst saving the filter`,
    `Attempted to a render a state that isn't handled`
  );
};
