import { RouteComponentProps } from '@reach/router';
import { useMachine } from '@xstate/react';
import { useMemo } from 'react';
import * as React from 'react';

import { advertApi } from '../../../api/advert';
import { advertTagsApi } from '../../../api/advert-tags';
import { BusinessType } from '../../../api/auth';
import { companyApi } from '../../../api/company';
import { useCompany } from '../../../context/CompanyContext';
import { RuntimeError } from '../../../Error/BaseErrors';
import {
  buildActionsColumn,
  buildCompanyColumn,
  buildCreatedAtColumn,
  buildCreatedByColumn,
  buildFavouriteOrEditActionColumn,
  buildJobTitleColumn,
  buildLocationColumn,
  buildTagsColumn,
} from '../AdvertTableColumnDefinitions';
import {
  AdvertTableMachineProvider,
  useAdvertTableMachineState,
} from '../AdvertTableMachineContext';
import { useDashboardActions } from '../DashboardContext';
import { DashboardQuickViewModal } from '../DashboardQuickViewModal';
import { DashboardTable } from '../DashboardTable';
import {
  EmptyListContainer,
  EmptyListFiltersActiveView,
  EmptyListImage,
  EmptyListTitle,
} from '../EmptyList';
import { buildAdvertTableMachine } from '../machines/advertTableMachine';

interface FavouriteAdvertsProps extends RouteComponentProps {}

export const FavouriteAdverts: React.FC<FavouriteAdvertsProps> = () => {
  const { company } = useCompany();

  if (!company) {
    throw new RuntimeError(
      'Something went wrong whilst loading the advert',
      "User doesn't have belong to a company yet so they shouldn't have access"
    );
  }

  const { id: companyId, businessType: companyBusinessType } = company;
  const { incrementCount, decrementCount } = useDashboardActions();

  const machine = useMemo(() => {
    const config = {
      id: 'favourite-adverts',
      fetchAdverts: advertApi.fetchFavouriteAdverts,
      fetchCompanyMembers: async () => companyApi.fetchCompanyMembers(companyId),
      fetchAdvertTags: async () => advertTagsApi.fetchTags(),
      incrementDashboardCounter: incrementCount,
      decrementDashboardCounter: decrementCount,
    };

    return buildAdvertTableMachine(config);
  }, [companyId, incrementCount, decrementCount]);

  const columns = useMemo(() => buildColumns(companyBusinessType), [companyBusinessType]);

  const [state, _send, service] = useMachine(machine, {
    context: {
      columns,
    },
  });

  return (
    <div>
      <AdvertTableMachineProvider service={service}>
        <DashboardTable service={service} ListEmptyComponent={EmptyListView} />

        {state.context.quickViewModalServiceRef && (
          <DashboardQuickViewModal service={state.context.quickViewModalServiceRef} />
        )}
      </AdvertTableMachineProvider>
    </div>
  );
};

const EmptyListView = React.memo(() => {
  const { hasActiveFilters } = useAdvertTableMachineState();

  if (hasActiveFilters) {
    return <EmptyListFiltersActiveView />;
  }

  return (
    <EmptyListContainer>
      <EmptyListTitle>You haven't favourited any adverts yet</EmptyListTitle>
      <EmptyListImage />
    </EmptyListContainer>
  );
});

const buildColumns = (businessType: BusinessType) => {
  switch (businessType) {
    case BusinessType.RecruitmentAgency:
      return [
        buildFavouriteOrEditActionColumn({ width: '3%' }),
        buildJobTitleColumn({ width: '22.5%' }),
        buildCompanyColumn({ width: '10%' }),
        buildLocationColumn({ width: '10%' }),
        buildTagsColumn({ width: '27%' }),
        buildCreatedAtColumn({ width: '10%' }),
        buildCreatedByColumn({ width: '10%' }),
        buildActionsColumn({ width: '7.5%' }),
      ];
    case BusinessType.DirectEmployer:
      return [
        buildFavouriteOrEditActionColumn({ width: '3%' }),
        buildJobTitleColumn({ width: '22.5%' }),
        buildLocationColumn({ width: '17.5%' }),
        buildTagsColumn({ width: '29.5%' }),
        buildCreatedAtColumn({ width: '10%' }),
        buildCreatedByColumn({ width: '10%' }),
        buildActionsColumn({ width: '7.5%' }),
      ];
    default:
      throw new RuntimeError(
        'Something went wrong whilst loading the dashboard.',
        'Attempting to build columns for an unsupported business type'
      );
  }
};
