import { PropsWithoutRef } from 'react';
import * as React from 'react';

import { Box, BoxProps } from './Box';

export type ButtonProps = Partial<
  PropsWithoutRef<BoxProps & Omit<React.HTMLProps<HTMLButtonElement>, 'as' | 'children'>>
>;

export const Button = React.forwardRef<any, ButtonProps>((props, ref) => (
  <Box
    ref={ref}
    as="button"
    variant="primary"
    type="button"
    {...props}
    __themeKey="buttons"
    __css={{
      fontSize: '1',
      fontWeight: 'bold',
      px: '5',
      py: '1.2rem',
      bg: 'white',
      borderStyle: '0',
      borderWidth: '2',
      borderRadius: '2',
      minWidth: '8rem',
      textAlign: 'center',
      cursor: 'pointer',
    }}
  />
));
