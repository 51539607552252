import styled from '@emotion/styled/macro';
import { useId } from '@reach/auto-id';
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from '@reach/combobox';
import autosize from 'autosize';
import { FieldArray, useField } from 'formik';
import { Ref, useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { ColouredTooltip } from '../../../components/ColouredTooltip';
import { Box } from '../../../components/common/Box';
import { Button } from '../../../components/common/Button';
import { Flex } from '../../../components/common/Flex';
import { TextField } from '../../../components/common/form';
import { Text } from '../../../components/common/Text';
import { Fade } from '../../../components/Headless/Fade';
import { DragHandle } from '../../../components/Icon';
import { useJobTitlesOptions } from '../../../hooks/useJobTitlesOptions';
import {
  onAutoFillPrefixChange,
  onAutoFillPrefixFocus,
  removeAutoFillPrefix,
} from '../utils/autoFillPrefixUtils';
import { useExampleAnswerMatch } from '../utils/useExampleAnswerMatch';
import { BasicHint } from './BasicHint';
import { ComboboxOptionTextCustomHighlight } from './ComboboxOptionTextCustomHighlight';
import { QuestionFieldProps } from './QuestionFieldProps';

interface CollectionQuestionProps {
  name: string;
  question?: QuestionFieldProps;
}

export const CollectionQuestion: React.FC<CollectionQuestionProps> = React.memo((props) => {
  const isAJobTitleCollection = /JOB_TITLES/.test(props.question?.shortCode || '');
  const [jobTitleOptions] = useJobTitlesOptions(!isAJobTitleCollection);

  return (
    <FieldArray name={props.name}>
      {(arrayHelpers) => {
        return (
          <CollectionInputs
            name={props.name}
            question={
              isAJobTitleCollection
                ? { ...props.question, options: jobTitleOptions }
                : props.question
            }
            addInput={arrayHelpers.push}
            removeInput={arrayHelpers.remove}
          />
        );
      }}
    </FieldArray>
  );
});

interface CollectionInputsProps {
  name: string;
  question?: QuestionFieldProps;
  addInput: (val: any) => void;
  removeInput: (index: number) => void;
}

/**
 * Manages a collection of inputs
 * - addition/removal of rows
 * - focus management
 */
const CollectionInputs: React.FC<CollectionInputsProps> = React.memo((props) => {
  const [field, _meta, helpers] = useField<string[]>(props.name);
  const rowRefs = useRef<any[]>([]);

  const { addInput, removeInput } = props;

  // Only start auto focusing registered inputs once the initial inputs have been rendered.
  // This means the input won't be focused when the page loads which was causing the page to
  // scroll down the page occasionally
  const autoFocusNewInputsOnRegister = useRef<boolean>(false);

  useEffect(() => {
    if (!autoFocusNewInputsOnRegister.current) {
      // Should we start tracking now?
      if (field.value && field.value.length === rowRefs.current.length) {
        autoFocusNewInputsOnRegister.current = true;
      }
    }
  }, [field.value]);

  const handleRegisterInput = useCallback((id, ref) => {
    rowRefs.current.push({ id, ref });

    if (autoFocusNewInputsOnRegister.current) {
      ref.current?.focus();
    }
  }, []);

  const handleUnregisterInput = useCallback((id) => {
    const index = rowRefs.current.findIndex((ref) => ref.id === id);
    const wasLastInput = index === rowRefs.current.length - 1;

    rowRefs.current = rowRefs.current.filter((ref) => ref.id !== id);

    if (wasLastInput && rowRefs.current[rowRefs.current.length - 1]) {
      rowRefs.current[rowRefs.current.length - 1].ref.current?.focus();
    }
  }, []);

  const handleFocusNext = useCallback(
    (position: number) => {
      if (position === field.value.length - 1) {
        addInput('');
        return;
      }

      if (rowRefs.current && rowRefs.current[position + 1]) {
        rowRefs.current[position + 1].ref.current.focus();
      }
    },
    [addInput, field.value.length]
  );

  const handleRemove = useCallback(
    (position: number) => {
      removeInput(position);
    },
    [removeInput]
  );

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newValue = Array.from(field.value);
    const [removed] = newValue.splice(result.source.index, 1);
    newValue.splice(result.destination.index, 0, removed);
    helpers.setValue(newValue);

    if (result.destination) rowRefs.current[result.destination.index].ref.current.focus();
  };
  const multipleItems = field.value.length > 1;

  return (
    <Flex sx={{ flexDirection: 'column', pb: 2 }}>
      <Flex sx={{ flexDirection: 'column', pb: 2 }}>
        {field.value && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`drop-${props.question?.shortCode || 'question'}`}>
              {(provided, _snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {field.value.map((_, index: number) => (
                    <Draggable
                      key={`${props.name}.${index}`}
                      draggableId={`${props.name}.${index}`}
                      index={index}
                      isDragDisabled={!multipleItems}
                    >
                      {(provided, _snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          sx={{ display: 'flex', alignItems: 'center', marginLeft: -20 }}
                        >
                          <Box
                            {...provided.dragHandleProps}
                            sx={{ paddingBottom: '0.75rem', width: 20 }}
                          >
                            <Text sx={{ color: 'grey.5', m: 0 }}>
                              {multipleItems && <DragHandle />}
                            </Text>
                          </Box>

                          <Box sx={{ flex: 1 }}>
                            <CollectionInput
                              key={index}
                              position={index}
                              name={`${props.name}.${index}`}
                              question={props.question}
                              canRemove={multipleItems}
                              onRegister={handleRegisterInput}
                              onUnregister={handleUnregisterInput}
                              onFocusNext={handleFocusNext}
                              onRemove={handleRemove}
                            />
                          </Box>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Flex>

      <Flex sx={{ mb: props.question?.hint ? 5 : 0 }}>
        <Button
          variant="accent"
          type="button"
          sx={{ fontWeight: 500, letterSpacing: '-0.01rem' }}
          onClick={() => props.addInput('')}
        >
          {props.question?.addRowTitle || 'Add a new row'} +
        </Button>
      </Flex>

      {props.question?.hint && (
        <Box>
          <BasicHint>{props.question.hint}</BasicHint>
        </Box>
      )}
    </Flex>
  );
});

export const CloseButton = styled.button`
  background-color: transparent;
  color: ${(props) => props.theme.colors.red[5]};
  border: none;
  font-size: 1.75rem;
  cursor: pointer;
  outline: none;
  &:hover {
    color: ${(props) => props.theme.colors.red[7]};
  }
`;

interface CollectionInputProps {
  name: string;
  position: number;
  question?: QuestionFieldProps;
  canRemove: boolean;
  onRegister: (id: string, ref: Ref<any>) => void;
  onUnregister: (id: string) => void;
  onFocusNext: (currentPosition: number) => void;
  onRemove: (currentPosition: number) => void;
}

/**
 * An individual row
 */
const CollectionInput: React.FC<CollectionInputProps> = React.memo(
  ({ onRegister, onUnregister, ...props }) => {
    const [field, _meta, helpers] = useField(props.name);
    const inputRef = useRef<any>();
    const popoverRef = useRef<any>(null);
    const id = useId();
    const questionPrefix = props.question?.autoFillPrefix || '';

    const possibleResults = useExampleAnswerMatch(
      props.question?.options || [],
      field.value,
      questionPrefix,
      32
    );
    /**
     * When using the value prop of ComboboxInput the ComboboxPopover is default visible.
     * This is due to how Combobox handles a controlled value prop.
     *
     * As a temporary fix, we'll hide the popover until the user focuses the input
     * for the first time
     *
     * https://github.com/reach/reach-ui/issues/224
     */
    const [hasBeenFocused, setHasBeenFocused] = useState(false);

    const onUpdate = (val: string) => {
      const value = val.startsWith(questionPrefix) ? val : `${questionPrefix} ${val}`;

      helpers.setValue(value);
      helpers.setTouched(true);
    };

    // Register the input with the parent
    useEffect(() => {
      if (!id) {
        return;
      }

      onRegister(id, inputRef);

      return () => {
        onUnregister(id);
      };
    }, [id, onRegister, onUnregister]);

    return (
      <Flex sx={{ alignItems: 'center' }}>
        <Flex sx={{ flexDirection: 'column', mb: 3, pr: 1, width: '100%' }}>
          <Box sx={{ position: 'relative' }}>
            <Combobox
              openOnFocus
              onSelect={(val) => {
                onUpdate(val);
              }}
            >
              <AutosizeTextarea
                ref={inputRef}
                name={props.name}
                value={field.value}
                data-testid={
                  props.question?.shortCode
                    ? `${props.question?.shortCode}.${props.position}`
                    : props.name
                }
                data-lpignore="true"
                placeholder={props.question?.placeholder || ''}
                onFocus={(event: React.ChangeEvent<HTMLDivElement>) => {
                  if (!hasBeenFocused) {
                    setHasBeenFocused(true);
                  }

                  onAutoFillPrefixFocus({
                    autoFillPrefix: questionPrefix,
                    event: event as React.ChangeEvent<HTMLInputElement>,
                    setField: onUpdate,
                  });
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onAutoFillPrefixChange({
                    autoFillPrefix: questionPrefix,
                    event,
                    setField: onUpdate,
                    handleEvent: field.onChange,
                  });
                }}
                onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  const {
                    which,
                    keyCode,
                    currentTarget: {
                      selectionStart,
                      selectionEnd,
                      value: { length: valueLength },
                    },
                  } = event;
                  const code = which || keyCode || 0;
                  const cursorAtEnd =
                    selectionStart === selectionEnd && selectionEnd === valueLength;
                  if (code === 13 && cursorAtEnd) {
                    event.preventDefault();
                    props.onFocusNext(props.position);
                  }
                }}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  const {
                    which,
                    keyCode,
                    currentTarget: {
                      selectionStart,
                      selectionEnd,
                      value: { length: valueLength },
                    },
                  } = event;
                  const code = which || keyCode || 0;
                  const cursorAtStart = selectionStart === selectionEnd && selectionEnd === 0;
                  if (props.canRemove && code === 8 && cursorAtStart && valueLength === 0) {
                    event.preventDefault();
                    props.onRemove(props.position);
                  }
                }}
                onBlur={field.onBlur}
              />
              {hasBeenFocused && possibleResults.length > 0 ? (
                <ComboboxPopover ref={popoverRef} portal={false}>
                  <ComboboxList>
                    {possibleResults.map((answer) => (
                      <ComboboxOption key={answer.id} value={answer.value}>
                        <ComboboxOptionTextCustomHighlight
                          highlightValue={removeAutoFillPrefix(questionPrefix, field.value)}
                        />
                      </ComboboxOption>
                    ))}
                  </ComboboxList>
                </ComboboxPopover>
              ) : null}
            </Combobox>
          </Box>
        </Flex>

        <Fade active={props.canRemove} containerStyles={{ width: 'auto' }}>
          <ColouredTooltip label="Remove this answer" sx={{ bg: 'red.5' }}>
            <CloseButton
              type="button"
              aria-label="Remove this answer"
              tabIndex={-1}
              onClick={() => props.onRemove(props.position)}
            >
              <i className="far fa-times-circle" />
            </CloseButton>
          </ColouredTooltip>
        </Fade>
      </Flex>
    );
  }
);

const AutosizeTextarea = React.forwardRef((props: any, ref: any) => {
  useEffect(() => {
    const node = ref.current;

    if (node) {
      autosize(node);
    }

    return () => {
      if (node) {
        autosize.destroy(node);
      }
    };
  }, [ref]);

  return <ComboboxInput as={Textarea} {...props} ref={ref} rows={1} />;
});

const Textarea = React.forwardRef((props: any, ref: any) => {
  return <TextField as="textarea" {...props} ref={ref} />;
});
