import styled from '@emotion/styled/macro';
import { navigate, RouteComponentProps } from '@reach/router';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import * as React from 'react';
import { FormattedNumber } from 'react-intl';

import {
  ApiPaymentEventType,
  companyPaymentHistoryApi,
} from '../../../api/company-payment-history';
import { Box } from '../../../components/common/Box';
import { Button } from '../../../components/common/Button';
import { Table, TableBody, TableCell, TableHead, TableRow } from '../../../components/common/Table';
import { useCompany } from '../../../context/CompanyContext';
import { RuntimeError } from '../../../Error/BaseErrors';

const Container = styled.div`
  max-width: 1000px;
`;

const Title = styled.h2`
  margin: 0;
  padding-bottom: 2rem;
  letter-spacing: -0.05rem;
  font-size: 1.75rem;
  color: ${(props) => props.theme.colors.accent};
  font-weight: 500;
`;

export const PaymentHistory: React.FC<RouteComponentProps> = () => {
  const { company } = useCompany();

  if (!company) {
    throw new RuntimeError(
      'Something went wrong whilst loading the history',
      "User doesn't have belong to a company yet so they shouldn't have access"
    );
  }

  const { status, data } = companyPaymentHistoryApi.useCompanyPaymentHistoryQuery(company.id);

  if (status === 'error' || !data) {
    return <div>Something went wrong whilst fetching the payment history</div>;
  }

  return (
    <Container>
      <Title>Payment history:</Title>

      {status === 'success' && data && data.paymentHistory.length === 0 && (
        <p>Could not find any payment history records.</p>
      )}

      <Box pb="3">
        <Table loading={status === 'loading'} loadingColumns={4} loadingRows={5}>
          <TableHead>
            <TableRow>
              <TableCell width="15%">Date</TableCell>
              <TableCell width="40%">Details</TableCell>
              <TableCell width="15%">Amount</TableCell>
              <TableCell width="15%">Status</TableCell>
              <TableCell width="15%">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody slim bg="grey.1">
            {data &&
              data.paymentHistory.map((payment) => (
                <TableRow key={payment.id}>
                  <TableCell width="15%">
                    {format(parseISO(payment.createdAt), 'LLL dd, yyyy')}
                  </TableCell>
                  <TableCell width="40%">{payment.title}</TableCell>
                  <TableCell width="15%">
                    <FormattedNumber
                      style="currency"
                      currency={payment.currency}
                      value={payment.amount / 100}
                    />
                  </TableCell>
                  <TableCell width="15%">
                    {payment.type === ApiPaymentEventType.SUCCEEDED ? 'Paid' : 'Not Paid'}
                  </TableCell>
                  <TableCell bg="white" width="15%">
                    <Button
                      target="_blank"
                      variant="primary"
                      sx={{
                        m: 0,
                        px: 2,
                        py: 3,
                        fontWeight: 500,
                        letterSpacing: '-0.01rem',
                      }}
                      onClick={async () => {
                        const response = await companyPaymentHistoryApi.fetchPaymentEventPdf(
                          company.id,
                          payment.id
                        );

                        navigate(response.url);
                      }}
                    >
                      View Invoice
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </Container>
  );
};
