/**
 * If a new top level key is added to the theme, ensure it is updated in the `theme.d.ts` type file
 */
import styled from '@emotion/styled/macro';

import darkAdgraderLogoSrc from './images/adgrader-logo-dark.svg';
import coburgbanksLogoSrc from './images/coburgbanks-colour.svg';
import flagAustraliaSrc from './images/flag-au.svg';
import flagCanadaSrc from './images/flag-ca.svg';
import flagEuropeSrc from './images/flag-eu.svg';
import flagNewZealandSrc from './images/flag-nz.svg';
import flagUKSrc from './images/flag-uk.svg';
import flagUSSrc from './images/flag-us.svg';
import linkedinOAuth from './images/linkedin-oauth.png';
import darkLogoSrc from './images/logo-dark.svg';
import lightLogoSrc from './images/logo-light.svg';

const Logo = styled.img`
  width: 9rem;
`;

export const AppTheme = {
  breakpoints: ['768px', '900px', '1100px', '1300px', '1500px', '1700px', '1900px'],
  maxWidths: {
    small: '544px',
    medium: '768px',
    large: '1012px',
    xlarge: '1280px',
  },
  colors: {
    text: '#262626',
    black: '#000000',
    white: '#ffffff',
    offWhite: '#f2f2f2',
    grey: [
      '#F8F8F8',
      '#E1E1E1',
      'rgb(232,232,232)',
      '#CCCCCC',
      '#AEAEAE',
      '#9D9D9D',
      '#818181',
      '#646464',
      '#484848',
      '#333333',
      '#2B2B2B',
    ],
    green: [
      '#EFF8E7',
      '#D1EAB9',
      '#B3DC8B',
      '#95CE5C',
      '#77C02E',
      '#59b200',
      '#499200',
      '#457713',
      '#295100',
      '#193100',
    ],
    orange: [
      '#FDF5ED',
      '#FBE2CA',
      '#F9CFA8',
      '#F6BC85',
      '#F4A962',
      '#F28015',
      '#C77B35',
      '#9B6029',
      '#6E451E',
      '#422912',
    ],
    red: [
      '#FBECEC',
      '#F3C6C6',
      '#EBA0A0',
      '#E37A7A',
      '#DB5454',
      '#D32F2F',
      '#AD2727',
      '#871E1E',
      '#601616',
      '#3A0D0D',
    ],
    blue: [
      '#ECF5FA',
      '#C7E1F1',
      '#A1CDE9',
      '#7CB9E0',
      '#57A5D7',
      '#3292cf',
      '#2978AA',
      '#205D84',
      '#17435F',
      '#0E2839',
    ],
    blackfade15: 'rgba(0, 0, 0, 0.15)',
    blackfade20: 'rgba(0, 0, 0, 0.20)',
    blackfade35: 'rgba(0, 0, 0, 0.35)',
    blackfade50: 'rgba(0, 0, 0, 0.5)',
    blackfade75: 'rgba(0, 0, 0, 0.75)',
    blackfade85: 'rgba(0, 0, 0, 0.85)',
    blackfade90: 'rgba(0, 0, 0, 0.90)',
    whitefade15: 'rgba(255, 255, 255, 0.15)',
    whitefade80: 'rgba(255, 255, 255, 0.80)',
    // This should probably be added by the consumer of theme
    textRed: '#970006',
    header: '#203333',
    headerLink: '#c8c8c8',
    textDim: '#b6bbbb',
    inactive: '#c8c8c8',
    transparent: 'transparent',
    primary: '#59b200',
    accent: '#3292cf',
    warning: '#F28015',
    error: '#D32F2F',
    background: 'white',
  },
  fonts: {
    normal:
      '"GT-Walsheim", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    mono: 'SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace',
  },
  fontWeights: {
    light: 300,
    normal: 400,
    semibold: 500,
    bold: 700,
  },
  backgrounds: {
    header: {
      background: 'linear-gradient(to right, #203333, #3292cf)',
      backgroundSize: '250%',
    },
  },
  borderStyles: ['solid'],
  borderWidths: [0, '1px', '2px', '3px', '4px'],
  borders: ['0', '1px solid', '2px solid', '3px solid', '4px solid'],
  fontSizes: [
    '0.75rem',
    '0.9rem',
    '1rem',
    '1.125rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '2.5rem',
    '3rem',
  ],
  lineHeights: {
    condensedUltra: 1,
    condensed: 1.25,
    default: 1.5,
  },
  radii: [0, 2, 4, 6, 8],
  space: [
    '0rem',
    '0.25rem',
    '0.5rem',
    '1rem',
    '1.5rem',
    '2rem',
    '2.5rem',
    '3rem',
    '4rem',
    '5rem',
    '6rem',
    '7rem',
    '8rem',
  ],
  shadows: {
    selectMenu: '0 0 0 1px rgba(117,117,117,0.4), 0 2px 10px rgba(3,18,42,0.15)',
  },
  transitions: ['all 300ms'],
  components: {
    logo: {
      light: <Logo src={lightLogoSrc} alt="Logo" />,
      dark: <Logo src={darkLogoSrc} alt="Logo" />,
    },
  },
  assets: {
    logo: {
      light: lightLogoSrc,
      dark: darkLogoSrc,
    },
    adgrader: {
      dark: darkAdgraderLogoSrc,
    },
    flags: {
      Australia: flagAustraliaSrc,
      Canada: flagCanadaSrc,
      Europe: flagEuropeSrc,
      NewZealand: flagNewZealandSrc,
      UK: flagUKSrc,
      US: flagUSSrc,
    },
    testimonials: {
      coburgbanks: coburgbanksLogoSrc,
    },
    auth: {
      linkedin: linkedinOAuth,
    },
  },
  text: {
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
    },
    heading: {
      m: 0,
      fontSize: 5,
      fontWeight: 'bold',
      color: 'black',
    },

    pageHeader: {
      color: 'white',
      fontSize: '2.75rem',
      letterSpacing: '-0.1rem',
      textAlign: 'center',
      fontWeight: 'semibold',
    },
  },
  buttons: {
    primary: {
      backgroundColor: 'transparent',
      color: 'green.5',
      borderColor: 'green.5',
      transition: (t: any) => t.transitions[0],
      '&:hover, &:focus': {
        backgroundColor: (t: any) => `${t.colors.green[5]}`,
        color: 'white',
      },
      '&:focus': {
        outlineColor: (t: any) => `${t.colors.green[5]}`,
      },
    },
    primaryInverted: {
      backgroundColor: 'green.5',
      color: 'white',
      borderColor: 'white',
      transition: (t: any) => t.transitions[0],
      '&:hover, &:focus': {
        backgroundColor: (t: any) => t.colors.green[6],
        color: 'white',
      },
      '&:focus': {
        outlineColor: (t: any) => `${t.colors.green[5]}`,
      },
    },
    accent: {
      backgroundColor: 'transparent',
      color: 'blue.5',
      borderColor: 'blue.5',
      transition: (t: any) => t.transitions[0],
      '&:hover, &:focus': {
        backgroundColor: (t: any) => `${t.colors.blue[5]}`,
        color: 'white',
      },
      '&:focus': {
        outlineColor: (t: any) => `${t.colors.blue[5]}`,
      },
    },
    accentInverted: {
      backgroundColor: 'blue.5',
      color: 'white',
      borderColor: 'white',
      transition: (t: any) => t.transitions[0],
      '&:hover, &:focus': {
        backgroundColor: 'blue.6',
        color: 'white',
      },
      '&:focus': {
        outlineColor: (t: any) => `${t.colors.blue[6]}`,
      },
    },
    warning: {
      backgroundColor: 'transparent',
      color: 'orange.5',
      borderColor: 'orange.5',
      transition: (t: any) => t.transitions[0],
      '&:hover, &:focus': {
        backgroundColor: 'orange.5',
        color: 'white',
      },
      '&:focus': {
        outlineColor: (t: any) => `${t.colors.orange[5]}`,
      },
    },
    warningInverted: {
      backgroundColor: 'orange.5',
      color: 'white',
      borderColor: 'white',
      transition: (t: any) => t.transitions[0],
      '&:hover, &:focus': {
        backgroundColor: (t: any) => t.colors.orange[6],
        color: 'white',
      },
      '&:focus': {
        outlineColor: (t: any) => `${t.colors.orange[6]}`,
      },
    },
    danger: {
      backgroundColor: 'transparent',
      color: 'red.5',
      borderColor: 'red.5',
      transition: (t: any) => t.transitions[0],
      '&:hover, &:focus': {
        backgroundColor: 'red.5',
        color: 'white',
      },
      '&:focus': {
        outlineColor: (t: any) => `${t.colors.red[5]}`,
      },
    },
    dangerInverted: {
      backgroundColor: 'red.5',
      color: 'white',
      borderColor: 'white',
      transition: (t: any) => t.transitions[0],
      '&:hover, &:focus': {
        backgroundColor: 'red.8',
        color: 'white',
      },
      '&:focus': {
        outlineColor: (t: any) => `${t.colors.red[8]}`,
      },
    },
    disabled: {
      backgroundColor: 'white',
      color: 'grey.5',
      borderColor: 'grey.5',
      transition: (t: any) => t.transitions[0],
      cursor: 'not-allowed',
    },
    text: {
      minWidth: 'auto',
      cursor: 'pointer',
      padding: 0,
      textAlign: 'left',
      background: 'transparent',
      border: 'none',
      borderWidth: 0,
      color: 'text',
      fontSize: '0.9rem',
      fontWeight: 400,
      '&:hover, &:focus': {
        textDecoration: 'underline',
        background: 'none',
        border: 'none',
      },
    },
  },
  forms: {
    label: {
      fontSize: 1,
      fontWeight: 'semibold',
    },
    input: {
      borderRadius: '2',
      px: 2,
      py: '0.75rem',
      borderStyle: '0',
      borderWidth: '2',
      borderColor: 'grey.1',
      backgroundColor: 'grey.0',
      fontSize: 2,
      outline: 'none',
      width: '100%',
      color: 'blackfade85',
      height: 'unset',
      WebkitAppearance: 'none',
      '::placeholder': {
        color: 'blackfade50',
      },
      ':disabled': {
        color: '#aaaaaa',
        backgroundColor: '#eeeeee',
        border: '2px solid #cccccc',
      },
    },
    select: {
      button: {
        display: 'flex',
        justifyContent: 'space-between',
        p: '3',
        borderRadius: '2',
        borderStyle: '0',
        borderWidth: '2',
        borderColor: 'grey.1',
        backgroundColor: 'grey.0',
        fontSize: 2,
        outline: 'none',
        width: '100%',
        color: 'blackfade85',
        height: 'unset',
      },
      menu: {
        listStyle: 'none',
        outline: 'none',
        padding: 0,
        backgroundColor: 'white',
        borderRadius: 2,
        maxHeight: '150px',
        overflowY: 'auto',
      },
      item: {
        px: 3,
        py: 2,
      },
    },
    errorMessage: {
      backgroundColor: 'red.5',
      color: 'white',
      py: '0.75rem',
      pl: '0.5rem',
      fontSize: '0.85rem',
    },
  },
  accordion: {
    item: {
      primary: {
        mb: 2,
      },
    },
    button: {
      primary: {
        p: 3,
        width: '100%',
        bg: 'rgb(228, 228, 228)',
        border: 'none',
        fontSize: '1rem',
        cursor: 'pointer',
      },
    },
    panel: {
      primary: {
        p: 3,
        border: 1,
        borderColor: 'grey.3',
        borderTop: 'none',
      },
    },
  },
  tabs: {
    tabList: {
      primary: {
        display: 'flex',
        backgroundColor: 'grey.4',
        border: '1px solid',
        borderColor: 'grey.3',
        borderBottom: 'none',
      },
    },
    tab: {
      primary: {
        py: 3,
        width: '100%',
        textAlign: 'center',
        borderTop: 'none',
        borderRight: '2px solid',
        borderBottom: 'none',
        borderLeft: 'none',
        borderColor: 'grey.2',
        cursor: 'pointer',
        letterSpacing: '-0.025rem',
        outline: 'none',
        fontWeight: 'semibold',
        fontSize: '1.1rem',
        color: ' rgba(0,0,0,0.7)',
        transition: 'all 200ms ease-in-out',
        '&:last-child': {
          borderStyle: 'none',
        },
        '&:hover, &:focus, &:active': {
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
          color: 'blue.5',
        },
      },
      selected: {
        py: 3,
        width: '100%',
        textAlign: 'center',
        borderTop: 'none',
        borderRight: '2px solid',
        borderBottom: 'none',
        borderLeft: 'none',
        borderColor: 'grey.2',
        cursor: 'pointer',
        letterSpacing: '-0.025rem',
        outline: 'none',
        fontWeight: 'semibold',
        fontSize: '1.1rem',
        background: (t: any) => t.colors.grey[2],
        color: 'accent',
        '&:last-child': {
          borderStyle: 'none',
        },
      },
    },
    tabPanels: { primary: {} },
    tabPanel: {
      primary: {
        outline: 'none',
      },
    },
  },
  tooltip: {
    primary: {
      backgroundColor: 'white',
      border: 'none',
      borderRadius: '8px',
      padding: '1rem',
      whiteSpace: 'normal',
    },
  },
  banner: {
    primary: {
      container: {
        backgroundColor: 'accent',
        width: '100%',
        p: 4,
        justifyContent: 'center',
      },
      closeButton: {
        borderRadius: '0',
        cursor: 'pointer',
        padding: '0.25rem 0.6rem 0 0.6rem',
        color: 'white',
      },
      closeButtonInner: {
        color: 'white',
        fontSize: '20px',
      },
    },
    danger: {
      container: {
        backgroundColor: 'error',
        width: '100%',
        p: 4,
        justifyContent: 'center',
      },
      closeButton: {
        borderRadius: '0',
        cursor: 'pointer',
        padding: '0.25rem 0.6rem 0 0.6rem',
        color: 'white',
      },
      closeButtonInner: {
        color: 'white',
        fontSize: '20px',
      },
    },
  },
} as const;

export type IAppTheme = typeof AppTheme;
