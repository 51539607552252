import Tooltip from '@reach/tooltip';
import * as React from 'react';

import { Box } from '../../components/common/Box';
import { Flex } from '../../components/common/Flex';
import { Heading } from '../../components/common/Heading';
import { Tooltip as TooltipIcon } from '../../components/Icon';

const Title: React.FC = (props) => {
  return (
    <Heading as="h1" variant="pageHeader" sx={{ pt: '1.75rem', pb: 'calc(3.5rem - 1.25rem)' }}>
      {props.children}
    </Heading>
  );
};

interface TooltipContentProps {
  helperText?: string;
  tooltipActive?: boolean;
  onHelperTextClicked?: () => void;
}

const TooltipContent: React.FC<TooltipContentProps> = ({
  helperText,
  tooltipActive,
  onHelperTextClicked,
}) => {
  if (!helperText && !onHelperTextClicked) {
    return null;
  }

  const shared = (
    <Box
      as="button"
      sx={{
        background: 'transparent',
        border: 'none',
        color: tooltipActive ? '#34aadc' : 'white',
        cursor: 'pointer',
        fontSize: 5,
        outline: 'none',

        ':hover': {
          color: '#34aadc',
        },
      }}
      onClick={onHelperTextClicked}
    >
      <TooltipIcon />
    </Box>
  );

  if (!helperText) {
    return shared;
  }

  return (
    <Tooltip
      label={helperText}
      style={{
        backgroundColor: '#ffffff',
        border: 'none',
        borderRadius: '8px',
        padding: '1rem',
        maxWidth: '300px',
        whiteSpace: 'normal',
      }}
    >
      {shared}
    </Tooltip>
  );
};

interface AdvertBuilderHeaderProps {
  title: string;
  helperText?: string;
  tooltipActive?: boolean;
  onHelperTextClicked?: () => void;
}

export const AdvertBuilderHeader: React.FC<AdvertBuilderHeaderProps> = ({
  title,
  helperText,
  tooltipActive,
  onHelperTextClicked,
}) => {
  return (
    <Box
      as="header"
      sx={{
        background: (theme) => theme.backgrounds.header.background,
        backgroundSize: (theme) => theme.backgrounds.header.backgroundSize,
        color: 'white',
        marginTop: '-1px',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <Title>
            {title}{' '}
            <TooltipContent
              helperText={helperText}
              tooltipActive={tooltipActive}
              onHelperTextClicked={onHelperTextClicked}
            />
          </Title>
        </Flex>
      </Flex>
    </Box>
  );
};
