import styled from '@emotion/styled/macro';
import { Link } from '@reach/router';
import { createRef, Suspense, useEffect, useState } from 'react';
import * as React from 'react';
import ReactQuill from 'react-quill';

import { Advert, AdvertVariation } from '../api/advert';
import { useUser } from '../context/UserContext';
import { useFlag } from '../hooks/useFlag';
import { copyValueToClipboard } from '../utils/clipboard/copyToClipboard';
import { Button } from './common/Button';
import { Flex } from './common/Flex';
import { ErrorBoundary } from './ErrorBoundary';
import { QuickViewVariationGrading } from './QuickViewVariationGrading';

const ContentEditor = React.lazy(() => import('./ContentEditor'));

interface QuickViewVariationProps {
  variation: AdvertVariation;
  formats: React.MutableRefObject<string[]>;
  modules: React.MutableRefObject<{ toolbar: { container: never[] } }>;
  advert: Advert;
}

export const QuickViewVariation: React.FC<QuickViewVariationProps> = ({
  variation,
  formats,
  modules,
  advert,
}) => {
  const user = useUser();
  const editorRef = createRef<ReactQuill>();
  const [content, setContent] = useState<string>();
  const adgraderEnabled = useFlag('adgrader_enabled', false);

  useEffect(() => {
    if (content) return;
    setTimeout(() => {
      const editor = editorRef.current?.getEditor();
      setContent(editor?.getText());
    }, 100);
  }, [content, editorRef]);

  const noEditCompleteAds = user.company.noEditCompleteAds || false;

  return (
    <VariationContainer>
      <ErrorBoundary>
        <Suspense
          fallback={
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                minHeight: '300px',
              }}
            >
              Loading editor
            </Flex>
          }
        >
          <ContentEditor
            ref={editorRef}
            defaultValue={variation.content}
            readOnly
            formats={formats.current}
            modules={modules.current}
          />
        </Suspense>
      </ErrorBoundary>
      <Flex sx={{ alignItems: 'center', pb: 3, px: 3 }}>
        <Button
          type="button"
          variant="accent"
          sx={{ mr: 3, px: 4, py: 3, fontWeight: 500 }}
          onClick={() => {
            copyValueToClipboard(variation.content);
          }}
        >
          Copy
        </Button>

        <Button
          as={Link}
          // @ts-ignore
          to={`/advert/${advert.id}${noEditCompleteAds ? '/variations' : ''}`}
          variant="primary"
          sx={{
            mr: 3,
            px: 4,
            py: 3,
            fontWeight: 500,
            textDecoration: 'none',
            lineHeight: 'initial',
          }}
        >
          {noEditCompleteAds ? 'View' : 'Edit Advert'}
        </Button>

        {adgraderEnabled ? <QuickViewVariationGrading content={content} /> : null}
      </Flex>
    </VariationContainer>
  );
};

const VariationContainer = styled.section`
  background-color: ${(props) => props.theme.colors.grey[2]};
  padding: 0;
  line-height: 1.8;
`;
