import * as React from 'react';

import { ColouredTooltip } from '../../../components/ColouredTooltip';
import { Box } from '../../../components/common/Box';
import { useToggle } from '../../../hooks/useToggle';
import { FilterCollectionListModal } from '../FilterCollectionListModal';

interface OpenSavedFiltersButtonProps {
  isActive: boolean;
}

export const OpenSavedFiltersButton: React.FC<OpenSavedFiltersButtonProps> = ({ isActive }) => {
  const { on, setOn, setOff } = useToggle();

  const iconColor = isActive ? '#3292cf' : '#d8d8d8';

  return (
    <React.Fragment>
      <ColouredTooltip sx={{ bg: 'accent' }} label="View saved filters">
        <Box as="button" sx={{ bg: 'unset', border: 'none', cursor: 'pointer' }} onClick={setOn}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
            height="22px"
            width="22px"
            fill={iconColor}
            stroke={iconColor}
          >
            <g>
              <path
                d="M17.93 28.9L33.22 2.42c.24-.41-.06-.92-.53-.92H2.11c-.47 0-.76.51-.53.92L16.87 28.9c.24.41.83.41 1.06 0z"
                strokeWidth="3"
                strokeMiterlimit="2.6131"
              />
              <path
                d="M20.91 38.83H13.9c-1.1 0-2-.9-2-2v-20.3c0-1.1.9-2 2-2h7.01c1.1 0 2 .9 2 2v20.3c0 1.1-.9 2-2 2z"
                strokeMiterlimit="10"
              />
            </g>
          </svg>
        </Box>
      </ColouredTooltip>

      <FilterCollectionListModal isOpen={on} onDismiss={setOff} />
    </React.Fragment>
  );
};
