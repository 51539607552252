import { Advert } from '../../../api/advert';
import { Box } from '../../../components/common/Box';
import { useAdvertTableMachine, useAdvertTableMachineActions } from '../AdvertTableMachineContext';
import { ColumnOptions, FavouriteOrEditActionCell, OpenSavedFiltersButton } from '.';

export const buildFavouriteOrEditActionColumn = (options: ColumnOptions) => {
  return {
    id: 'favouriteOrEdit',
    Header: () => {
      const [{ hasActiveFilters }] = useAdvertTableMachine();

      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <OpenSavedFiltersButton isActive={hasActiveFilters} />
        </Box>
      );
    },
    accessor: (props: Advert) => props,
    Cell: ({ value: advert }: { value: Advert }) => {
      const actions = useAdvertTableMachineActions();

      return (
        <FavouriteOrEditActionCell
          advert={advert}
          onFavourite={async (variationId) => {
            actions.onFavourite(advert, variationId);
          }}
          onUnfavourite={async () => {
            actions.onUnfavourite(advert);
          }}
          onQuickView={(advert, variationId) => {
            actions.openQuickViewModal(advert, variationId);
          }}
        />
      );
    },
    width: options.width,
  };
};
