import { useEffect, useRef } from 'react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { v4 as buildUUID } from 'uuid';

import { analytics } from '../../analytics/analytics';
import { ApiCompany } from '../../api/auth';
import { useAuth } from '../../context/AuthContext';
import { useCompany } from '../../context/CompanyContext';
import { Flex } from '../common/Flex';
import { useActiveOverlay } from './ActiveOverlayContext';
import { Overlay, OverlayAction, OverlayContent, OverlayText, OverlayTitle } from './Overlay';

const SubscriptionLapsed: React.FC = () => {
  const { logout } = useAuth();

  return (
    <Overlay>
      <OverlayContent>
        <OverlayTitle>Your account subscription has lapsed.</OverlayTitle>

        <OverlayText>To reactivate your account please contact support</OverlayText>

        <Flex>
          <OverlayAction
            as="a"
            href="mailto:support@adbuilder.io"
            variant="primaryInverted"
            sx={{ mr: 3, maxWidth: '250px', textDecoration: 'none' }}
            onClick={() => {
              analytics.contact();
            }}
          >
            Contact Support &rarr;
          </OverlayAction>

          <OverlayAction
            variant="accentInverted"
            sx={{ mr: 3, maxWidth: '250px', textDecoration: 'none' }}
            onClick={logout}
          >
            Log out &rarr;
          </OverlayAction>
        </Flex>
      </OverlayContent>
    </Overlay>
  );
};

interface SubscriptionLapsedOverlayImplProps {
  company: ApiCompany;
}

const SubscriptionLapsedOverlayImpl = (props: SubscriptionLapsedOverlayImplProps) => {
  const uuid = useRef(buildUUID());
  const { subscriptionStatus } = props.company;
  const { isOverlayActive, setOverlayStatus } = useActiveOverlay();

  useEffect(() => {
    setOverlayStatus(uuid.current, subscriptionStatus === 'cancelled');
  }, [setOverlayStatus, subscriptionStatus]);

  if (!isOverlayActive(uuid.current)) {
    return null;
  }

  const elementRoot = document.getElementById('subscription-lapsed-overlay-root');

  if (!elementRoot) {
    throw new Error(
      'Attempted to render the SubscriptionLapsedOverlay but the root node does not exist'
    );
  }

  return ReactDOM.createPortal(<SubscriptionLapsed />, elementRoot);
};

export const SubscriptionLapsedOverlay: React.FC = () => {
  const { company } = useCompany();

  if (!company) {
    return null;
  }

  return <SubscriptionLapsedOverlayImpl company={company} />;
};
