import { Combobox, ComboboxInput, ComboboxOption } from '@reach/combobox';
import { ComboboxPopover } from '@reach/combobox';
import { ComboboxList } from '@reach/combobox';
import autosize from 'autosize';
import { FastField, FieldProps, getIn } from 'formik';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { Box } from '../../../components/common/Box';
import { TextField } from '../../../components/common/form';
import { useDebounce } from '../../../hooks/useDebounce';
import {
  onAutoFillPrefixChange,
  onAutoFillPrefixFocus,
  removeAutoFillPrefix,
} from '../utils/autoFillPrefixUtils';
import { useExampleAnswerMatch } from '../utils/useExampleAnswerMatch';
import { BasicHint } from './BasicHint';
import { ComboboxOptionTextCustomHighlight } from './ComboboxOptionTextCustomHighlight';
import { QuestionFieldProps } from './QuestionFieldProps';

const Textarea = React.forwardRef((props: any, ref: any) => {
  return <TextField as="textarea" {...props} ref={ref} />;
});

const AutosizeTextarea = React.forwardRef((props: any, ref: any) => {
  useEffect(() => {
    const node = ref.current;

    if (node) {
      autosize(node);
    }

    return () => {
      if (node) {
        autosize.destroy(node);
      }
    };
  }, [ref]);

  return <ComboboxInput as={Textarea} {...props} ref={ref} rows={1} />;
});

interface ComboboxTextareaQuestionProps {
  name: string;
  question: QuestionFieldProps;
  limitOptions?: number;
}

function isChangeEvent(val: string | ChangeEvent<any>): val is ChangeEvent<any> {
  return (val as ChangeEvent<any>).target !== undefined;
}

export const ComboboxTextareaQuestion: React.FC<ComboboxTextareaQuestionProps> = React.memo(
  (props) => {
    /**
     * When using the value prop of ComboboxInput the ComboboxPopover is default visible.
     * This is due to how Combobox handles a controlled value prop.
     *
     * As a temporary fix, we'll hide the popover until the user focuses the input
     * for the first time
     *
     * https://github.com/reach/reach-ui/issues/224
     */
    const [hasBeenFocused, setHasBeenFocused] = useState(false);

    return (
      <FastField
        name={props.name}
        hasBeenFocused={hasBeenFocused}
        shouldUpdate={(prevProps: any, props: any) => {
          return (
            prevProps.name !== props.name ||
            getIn(prevProps.formik.values, props.name) !== getIn(props.formik.values, props.name) ||
            getIn(prevProps.formik.errors, props.name) !== getIn(props.formik.errors, props.name) ||
            getIn(prevProps.formik.touched, props.name) !==
              getIn(props.formik.touched, props.name) ||
            Object.keys(props).length !== Object.keys(prevProps).length ||
            prevProps.formik.isSubmitting !== props.formik.isSubmitting ||
            prevProps.hasBeenFocused !== props.hasBeenFocused
          );
        }}
      >
        {({ field, form }: FieldProps) => {
          const onChange = (val: string | ChangeEvent<any>, shouldValidate?: boolean) => {
            if (isChangeEvent(val)) {
              field.onChange(val);
              return;
            }

            form.setFieldValue(field.name, val, shouldValidate);

            // Hacky way to set the value after the current phase
            // Doesn't work without possibly due to autosize + combobox combination
            setTimeout(() => {
              if (shouldValidate) {
                form.setFieldTouched(field.name);
              }
            }, 0);
          };

          const onFocus = () => {
            if (!hasBeenFocused) {
              setHasBeenFocused(true);
            }
          };

          return (
            <ComboboxTextareaQuestionImpl
              name={props.name}
              question={props.question}
              value={field.value}
              showPopover={hasBeenFocused}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={field.onBlur}
              limitOptions={props.limitOptions}
            />
          );
        }}
      </FastField>
    );
  }
);

interface ComboboxTextareaQuestionImplProps {
  name: string;
  question: QuestionFieldProps;
  value: string;
  showPopover: boolean;
  onChange: (val: string | ChangeEvent<any>, shouldValidate: boolean) => void;
  onFocus: () => void;
  onBlur: (e: any) => void;
  limitOptions?: number;
}

export const ComboboxTextareaQuestionImpl: React.FC<ComboboxTextareaQuestionImplProps> = (
  props
) => {
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const popoverRef = useRef<any>(null);
  const questionPrefix = props.question.autoFillPrefix || '';

  const valueDebounced = useDebounce(props.value, 100);

  const possibleResults = useExampleAnswerMatch(
    props.question.options || [],
    valueDebounced,
    questionPrefix,
    props.limitOptions
  );

  const onUpdate = (val: string | ChangeEvent<any>) => {
    props.onChange(val, !isChangeEvent(val) && val.trim() !== questionPrefix);

    setTimeout(() => {
      if (inputRef.current) {
        autosize.update(inputRef.current);
      }
    }, 10);
  };

  return (
    <React.Fragment>
      <Box sx={{ position: 'relative' }}>
        <Combobox
          openOnFocus
          onSelect={(val) => {
            const value = val.startsWith(questionPrefix) ? val : `${questionPrefix} ${val}`;
            onUpdate(value);
          }}
        >
          <AutosizeTextarea
            ref={inputRef}
            name={props.name}
            data-testid={props.question.shortCode || props.name}
            data-lpignore="true"
            autoComplete="off"
            placeholder={props.question.placeholder}
            value={props.value}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              if (event.currentTarget.value.startsWith('\n')) {
                return;
              }

              onAutoFillPrefixChange({
                autoFillPrefix: questionPrefix,
                event,
                setField: onUpdate,
                handleEvent: onUpdate,
              });
            }}
            onFocus={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              props.onFocus();

              onAutoFillPrefixFocus({
                autoFillPrefix: questionPrefix,
                event,
                setField: onUpdate,
              });
            }}
            onBlur={props.onBlur}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (!event.isDefaultPrevented()) {
                const container = popoverRef.current;

                if (!container) {
                  return;
                }

                // Ensure the highlighted item is visible in the capped height popover
                window.requestAnimationFrame(() => {
                  const element = container.querySelector('[aria-selected=true]');
                  if (element) {
                    const top = element.offsetTop - container.scrollTop;
                    const bottom =
                      container.scrollTop +
                      container.clientHeight -
                      (element.offsetTop + element.clientHeight);

                    if (bottom < 0) {
                      container.scrollTop -= bottom;
                    }

                    if (top < 0) {
                      container.scrollTop += top;
                    }
                  }
                });
              }
            }}
          />
          {props.showPopover && possibleResults.length > 0 ? (
            <ComboboxPopover ref={popoverRef} portal={false}>
              <ComboboxList data-testid={`${props.name}-dropdown`}>
                {possibleResults.map((answer) => (
                  <ComboboxOption key={answer.id} value={answer.value}>
                    <ComboboxOptionTextCustomHighlight
                      highlightValue={removeAutoFillPrefix(questionPrefix, props.value)}
                    />
                  </ComboboxOption>
                ))}
              </ComboboxList>
            </ComboboxPopover>
          ) : null}
        </Combobox>
      </Box>

      {props.question.hint && (
        <Box sx={{ mt: 5 }}>
          <BasicHint
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}
          >
            {props.question.hint}
          </BasicHint>
        </Box>
      )}
    </React.Fragment>
  );
};
