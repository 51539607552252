import styled from '@emotion/styled/macro';

import { Label as BaseLabel } from './common/form';

export const RegistrationFormLabel = styled(BaseLabel)`
  display: flex;
  justify-content: space-between;

  padding-bottom: 3px;
  font-size: 0.8rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.75);
  letter-spacing: -0.01rem;
`;
