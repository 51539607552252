import { ApiCompany, ApiUser, AuthorityLevel } from './auth';
import { client } from './client';

export interface ApiInvite {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  sentAt: Date;
  expiresAt: Date;
  createdBy: ApiUser;
  company: ApiCompany;
}

export interface InviteData {
  email: string;
  firstName: string;
  lastName: string;
  authorityLevel: AuthorityLevel;
}

const fetchInvite = async (email: string, token: string): Promise<{ invite: ApiInvite }> => {
  const response = await client.get(`/invite/${email}/${token}`);

  return {
    invite: response.data.invite,
  };
};

const sendInvite = async (
  companyId: number,
  inviteData: InviteData
): Promise<{ invite: ApiInvite }> => {
  const response = await client.post(`/company/${companyId}/invites`, inviteData);

  return {
    invite: response.data.invite,
  };
};

const sendBatchInvite = async (companyId: number, file: File): Promise<void> => {
  const formData = new FormData();
  formData.append('upload', file);

  await client.post(`/company/${companyId}/invites/upload-batch`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return;
};

export const invitationApi = {
  fetchInvite,
  sendInvite,
  sendBatchInvite,
};
