import * as Yup from 'yup';

import { Country } from '../../screens/Subscription/CheckoutForm';

export const isEUCountry = (country: Country) => {
  const euCountryCodes = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'EE',
    'ES',
    'FI',
    'FR',
    'GB',
    'GR',
    'HR',
    'HU',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK',
  ];

  return Boolean(euCountryCodes.find((code) => country.code === code));
};

export const AddressLineOneSchema = Yup.string().required('Line one is required').min(2).max(255);
export const AddressLineTwoSchema = Yup.string().nullable().min(2).max(255);
export const AddressLineThreeSchema = Yup.string().nullable().min(2).max(255);
export const CitySchema = Yup.string().required('City is required').min(2).max(255);
export const PostalCodeSchema = Yup.string().required('Post code is required').min(2).max(255);

export const CountrySchema = Yup.object()
  .shape({
    name: Yup.string(),
    code: Yup.string(),
  })
  .required('Country is required');

export const VATSchema = Yup.string().when('addressCountry', {
  is: isEUCountry,
  then: Yup.string().required('VAT Number is required for EU member states'),
});

export const companyDetailsSchema = Yup.object().shape({
  companyName: Yup.string().required('Company name is required').min(2).max(255),
  addressLineOne: AddressLineOneSchema,
  addressLineTwo: AddressLineTwoSchema,
  addressLineThree: AddressLineThreeSchema,
  addressCity: CitySchema,
  addressPostalCode: PostalCodeSchema,
  addressCountry: CountrySchema,
  accountingEmail: Yup.string().required('Account email is required').email().min(2).max(255),
  vatNumber: VATSchema,
});
