/**
 * Builds an invisible div to host the content we want to copy
 *
 * @param html string
 */
const buildCopyContainer = (html: string) => {
  const container = document.createElement('div');
  container.innerHTML = html;

  // Hide the container element
  container.style.position = 'fixed';
  container.style.pointerEvents = 'none';

  return container;
};

/**
 * @param container The container that holds the content to copy
 */
const convertContent = (container: HTMLDivElement) => {
  replaceAdGraderSpans(container);
  convertContentDivs(container);
};

/**
 * AdGrader adds additional spans to the content. We need to filter those out as they serve
 * no purpose to theoutside world
 */
const replaceAdGraderSpans = (container: HTMLDivElement) => {
  const spans = container.querySelectorAll('span.adgrader');

  for (var i = 0; i < spans.length; i++) {
    const span = spans[i];
    const spanContent = span.textContent;

    if (spanContent === null) {
      span.remove();
    } else {
      span.replaceWith(spanContent);
    }
  }
};

/**
 * To improve compatability with the outside world we need
 * to convert the div per line approach of Quill to <br> tags
 * at the end of each line
 *
 * @param container The container that holds the content to copy
 */
const convertContentDivs = (container: HTMLDivElement) => {
  const divs = container.querySelectorAll('div');

  for (let i = 0; i < divs.length; i++) {
    const div = divs[i];

    // No content then skip
    if (!div.innerHTML) {
      div.remove();
    } else if (div.innerHTML === '<br>') {
      // Content is a new line, no need to add an additional line break
      div.replaceWith(document.createElement('br'));
    } else {
      div.after(document.createElement('br'));
      div.replaceWith(...(div.childNodes as any));
    }
  }
};

const copyContainerToClipboard = (container: HTMLDivElement) => {
  // Mount the container on the DOM
  document.body.appendChild(container);

  // Clear the current selection
  (window.getSelection() as Selection).removeAllRanges();

  // Select the container content
  const range = document.createRange();
  range.selectNode(container);
  (window.getSelection() as Selection).addRange(range);

  // Copy the selected range
  document.execCommand('copy');

  // Unmount the container from the DOM
  document.body.removeChild(container);
};

const copyHtmlToClipboard = (html: string) => {
  const container = buildCopyContainer(html);
  convertContent(container);
  copyContainerToClipboard(container);
};

export const copyValueToClipboard = (html: string) => {
  copyHtmlToClipboard(html);
};
