import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { AppHeader, AppHeaderTab, AppHeaderTabs } from '../../components/AppHeader';
import { AppShell } from '../../components/AppShell';
import { Box } from '../../components/common/Box';
import { ErrorBoundary } from '../../components/ErrorBoundary';

export const Company: React.FC<RouteComponentProps> = (props) => {
  return (
    <AppShell>
      <Helmet title="Settings" />
      <Box>
        <Box
          sx={{
            '@media screen and (max-width: 650px)': {
              display: 'none',
            },
          }}
        >
          <AppHeader title="Company Settings" />
        </Box>

        <AppHeaderTabs>
          <AppHeaderTab to="users">User List</AppHeaderTab>
          <AppHeaderTab to="teams">Teams</AppHeaderTab>
          <AppHeaderTab to="billing">Billing</AppHeaderTab>
        </AppHeaderTabs>

        <ErrorBoundary>{props.children}</ErrorBoundary>
      </Box>
    </AppShell>
  );
};
