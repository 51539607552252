import { EventObject, StatesConfig } from 'xstate';

export function isEvent<T extends EventObject>(ev: any, type: string): ev is T {
  return (ev as T).type === type;
}

interface ParallelStateConfig {
  state: string;
  service: string;
  onDone: string;
  onError: any;
}

interface FetchParallelResult<TContext> {
  type: 'parallel';
  states: StatesConfig<TContext, any, any>;
}

export function fetchParallel<TContext>(
  statesConfig: ParallelStateConfig[]
): FetchParallelResult<TContext> {
  const states = {} as any;

  for (const config of statesConfig) {
    states[config.state] = {
      initial: 'fetching',
      states: {
        fetching: {
          invoke: {
            id: config.service,
            src: config.service,
            onDone: { target: 'success', actions: config.onDone },
            onError: config.onError,
          },
        },
        success: { type: 'final' },
      },
    };
  }

  return {
    type: 'parallel',
    states,
  };
}

export function assertEventType<TE extends EventObject, TType extends TE['type']>(
  event: TE,
  eventType: TType
): asserts event is TE & { type: TType } {
  if (event.type !== eventType) {
    throw new Error(`Invalid event: expected "${eventType}", got "${event.type}"`);
  }
}

export function assertEventTypeOneOf<TE extends EventObject, TType extends TE['type']>(
  event: TE,
  eventTypes: TType[]
): asserts event is TE & { type: TType } {
  if (!eventTypes.includes(event.type as TType)) {
    throw new Error(
      `Invalid event: expected one of "${eventTypes.join(', ')}", got "${event.type}"`
    );
  }
}
