export interface Paginated<T> {
  page: number;
  limit: number;
  total: number;
  pages: number;
  items: T[];
}

export enum RequestState {
  Pending = 1,
  InProgress = 2,
  Success = 3,
  Failed = 4,
}

export interface Company {
  companyName: string;
  address: {
    lineOne: string;
    lineTwo?: string;
    lineThree?: string;
    postalCode: string;
    city: string;
    country: string;
  };
}
