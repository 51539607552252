import { FormattedNumber } from 'react-intl';

import {
  Advert,
  getJobTypeByValue,
  getPositionTypeByValue,
  getRemunerationCurrencyName,
  getRemunerationFrequencyLabel,
} from '../../../api/advert';
import { ColouredTooltip } from '../../../components/ColouredTooltip';
import { Box } from '../../../components/common/Box';
import { Button } from '../../../components/common/Button';
import { Link } from '../../../components/common/Link';
import { Text } from '../../../components/common/Text';
import { Bell } from '../../../components/Icon';
import { useAdvertTableMachine, useAdvertTableMachineActions } from '../AdvertTableMachineContext';
import {
  ActiveFilterPill,
  ActiveFilterPillList,
  InlineFilterable,
  SortableHeader,
} from '../DashboardTable';
import {
  ColumnOptions,
  FILTER_KEYS,
  JobTitleCellValues,
  SORT_KEYS,
  usePrefetchAdvertData,
  validateIsSingularFilter,
} from '.';

export const buildJobTitleColumn = (options: ColumnOptions) => {
  return {
    id: 'jobTitle',
    Header: () => {
      const [{ filters }, actions] = useAdvertTableMachine();

      const jobTypeFilter = filters.get(FILTER_KEYS.JOB_TYPE);
      const positionTypeFilter = filters.get(FILTER_KEYS.POSITION_TYPE);

      validateIsSingularFilter(jobTypeFilter);
      validateIsSingularFilter(positionTypeFilter);

      const hasActiveFilter = jobTypeFilter || positionTypeFilter;

      return (
        <div>
          <SortableHeader sortName={SORT_KEYS.JOB_TITLE}>Job title</SortableHeader>

          {hasActiveFilter ? <Box sx={{ height: '4px' }} /> : null}

          <ActiveFilterPillList>
            {jobTypeFilter ? (
              <ActiveFilterPill onClear={() => actions.clearFilter(FILTER_KEYS.JOB_TYPE)}>
                {jobTypeFilter.label}
              </ActiveFilterPill>
            ) : null}
            {positionTypeFilter ? (
              <ActiveFilterPill onClear={() => actions.clearFilter(FILTER_KEYS.POSITION_TYPE)}>
                {positionTypeFilter.label}
              </ActiveFilterPill>
            ) : null}
          </ActiveFilterPillList>
        </div>
      );
    },
    accessor: (props: Advert): JobTitleCellValues => {
      return {
        original: props,
        id: props.id,
        isComplete: props.isComplete,
        isSharedAndUnseen: props.isSharedAndUnseen,
        jobTitle: props.jobTitle,
        jobType: {
          label: getJobTypeByValue(props.jobType)?.label?.toLowerCase(),
          value: props.jobType,
        },
        positionType: {
          label: getPositionTypeByValue(props.positionType)?.label?.toLowerCase(),
          value: props.positionType,
        },
        formattedSalary: props.formattedSalary,
        salary: props.salary,
      };
    },
    Cell: ({ value }: { value: JobTitleCellValues }) => {
      const actions = useAdvertTableMachineActions();

      const prefetchAdvertData = usePrefetchAdvertData({
        advertId: value.id,
        isComplete: value.isComplete,
      });

      const jobTitleStyles: any = {
        p: 0,
        bg: 'transparent',
        textAlign: 'left',
        color: 'black',
        fontWeight: 500,
        fontSize: 2,
        letterSpacing: '-0.02rem',
        lineHeight: '1.25',
        borderRadius: '0',
        border: 'none',
        borderBottom: '2px solid',
        borderBottomColor: 'transparent',
        textDecoration: 'none',
        outline: 'none',
        cursor: 'pointer',
        minWidth: 'auto',
        transition: 'all 300ms',
        '&:hover, &:focus': {
          bg: 'transparent',
          textDecoration: 'none',
          color: 'accent',
          borderBottomColor: 'accent',
        },
      };

      return (
        <div>
          <ColouredTooltip
            label={value.isComplete ? 'View your advert' : 'Continue building your advert'}
            sx={{ bg: value.isComplete ? 'primary' : 'accent' }}
          >
            <div>
              {value.isComplete ? (
                <Button
                  sx={jobTitleStyles}
                  onClick={() => {
                    actions.openQuickViewModal(value.original);
                  }}
                >
                  {value.jobTitle}
                </Button>
              ) : (
                <Link
                  to={`/advert/${value.id}`}
                  sx={jobTitleStyles}
                  onMouseEnter={prefetchAdvertData}
                >
                  {value.jobTitle}
                </Link>
              )}

              {value.isSharedAndUnseen && (
                <Box sx={{ display: 'inline', color: 'accent', ml: 1, fontSize: 1 }}>
                  <Bell />
                </Box>
              )}
            </div>
          </ColouredTooltip>
          <div>
            {value.formattedSalary && (
              <Text as="span" sx={{ fontSize: '0.8125rem', color: '#464646', mt: '0.3rem' }}>
                <FormattedNumber
                  style="currency"
                  currency={getRemunerationCurrencyName(value.salary.currency)}
                  minimumFractionDigits={parseInt(value.salary.maximumAmount, 10) % 1 !== 0 ? 2 : 0}
                  maximumFractionDigits={parseInt(value.salary.maximumAmount, 10) % 1 !== 0 ? 2 : 0}
                  value={parseInt(value.salary.maximumAmount)}
                />{' '}
                per {getRemunerationFrequencyLabel(value.salary?.frequency)},{' '}
              </Text>
            )}
            <InlineFilterable
              helperText={`Filter by ${value.jobType.label}`}
              onClick={() => {
                actions.setFilter(FILTER_KEYS.JOB_TYPE, value.jobType);
              }}
            >
              {value.jobType.label}
            </InlineFilterable>
            ,{' '}
            <InlineFilterable
              helperText={`Filter by ${value.positionType.label}`}
              onClick={() => {
                actions.setFilter(FILTER_KEYS.POSITION_TYPE, value.positionType);
              }}
            >
              {value.positionType.label}
            </InlineFilterable>
          </div>
        </div>
      );
    },
    width: options.width,
  };
};
