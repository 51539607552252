import * as React from 'react';

import { Advert } from '../../../api/advert';
import { Box } from '../../../components/common/Box';
import { useUser } from '../../../context/UserContext';
import { useAdvertTableMachine } from '../AdvertTableMachineContext';
import {
  ActionMenuCell,
  ClearFiltersButton,
  ColumnOptions,
  DeleteAction,
  DuplicateAction,
  QuickViewAction,
  SaveFilterCollectionButton,
  ShareAction,
} from '.';

export const buildActionsColumn = (options: ColumnOptions) => {
  return {
    id: 'actions',
    Header: () => {
      const [{ hasActiveFilters }, { clearAllFilters }] = useAdvertTableMachine();

      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {hasActiveFilters ? (
            <React.Fragment>
              <SaveFilterCollectionButton />

              <ClearFiltersButton onClick={clearAllFilters} />
            </React.Fragment>
          ) : null}
        </Box>
      );
    },
    accessor: (props: Advert) => props,
    Cell: ({ value: advert }: { value: Advert }) => {
      const user = useUser();
      const [state, actions] = useAdvertTableMachine();

      const noEditCompleteAds = user.company.noEditCompleteAds || false;
      const openQuickView = () => actions.openQuickViewModal(advert);

      return (
        <ActionMenuCell
          advertId={advert.id}
          path={`/advert/${advert.id}`}
          isComplete={advert.isComplete}
          noEditCompleteAds={noEditCompleteAds}
          onQuickView={openQuickView}
        >
          {advert.isComplete && !noEditCompleteAds && (
            <QuickViewAction advert={advert} onQuickView={openQuickView} />
          )}

          <DuplicateAction advert={advert} />

          {advert.createdBy.id === user.id ? (
            <DeleteAction
              advert={advert}
              onDelete={() => {
                actions.deleteAdvert(advert);
              }}
            />
          ) : null}

          <ShareAction advert={advert} users={state.teamMembers} />
        </ActionMenuCell>
      );
    },
    width: options.width,
  };
};
