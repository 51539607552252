import React from 'react';

import { useTheme } from '../hooks/useTheme';
import { Image } from './common/Image';

export const LinkedInOAuthSignIn = () => {
  const theme = useTheme();

  return (
    <a href={process.env.REACT_APP_LINKEDIN_OAUTH_URL}>
      <Image src={theme.assets.auth.linkedin} />
    </a>
  );
};
