import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { Suspense, useEffect } from 'react';
import * as React from 'react';

import { AccountSetupApp } from './AccountSetupApp';
import { AppHeader } from './components/AppHeader';
import { AppShell } from './components/AppShell';
import { Flex } from './components/common/Flex';
import { CurtainLoadingSpinnerProvider } from './components/CurtainLoadingSpinner';
import { DiscountCountdownBanner } from './components/DiscountCountdownBanner';
import { ErrorBoundary } from './components/ErrorBoundary';
import { FullPageSpinner } from './components/FullPageSpinner';
import { LoadingSpinner } from './components/LoadingSpinner';
import { NotSuitableForSmallDevicesWarning } from './components/NotSuitableForSmallDevicesWarning';
import { ActiveOverlayProvider } from './components/Overlay/ActiveOverlayContext';
import { SubscriptionLapsedOverlay } from './components/Overlay/SubscriptionLapsedOverlay';
import { SubscriptionPausedOverlay } from './components/Overlay/SubscriptionPausedOverlay';
import { AdminRoute } from './components/RouteTypes/AdminRoute';
import { CompanyOwnerRoute } from './components/RouteTypes/CompanyOwnerRoute';
import { ZendeskIdentitySync } from './components/ZendeskIdentitySync';
import { useAuth } from './context/AuthContext';
import { CompanyProvider } from './context/CompanyContext';
import { UserProvider } from './context/UserContext';
import { RuntimeError } from './Error/BaseErrors';
import { useAdBlockDetector } from './hooks/useAdBlockDetector';
import { AdvertBuilderAppShell } from './screens/AdvertBuilder/AdvertBuilderAppShell';
import { Logout } from './screens/Auth/Logout';
import { Company } from './screens/Company';
import { Billing } from './screens/Company/Billing';
import { TeamDetails } from './screens/Company/Teams/TeamDetails';
import { Teams } from './screens/Company/Teams/Teams';
import { CompanyUserList } from './screens/Company/UserList';
import { Dashboard } from './screens/Dashboard/Dashboard';
import { ChangeSubscription } from './screens/Subscription/ChangeSubscription';

const Admin = React.lazy(() =>
  import('./screens/Admin').then((module) => ({
    default: module.Admin,
  }))
);

const loadAdvertBuilder = () =>
  import('./screens/AdvertBuilder').then((module) => ({
    default: module.AdvertBuilder,
  }));

const AdvertBuilder = React.lazy(loadAdvertBuilder);

const NotFound: React.FC<RouteComponentProps> = () => {
  return <Redirect path="/" from="/" to="/dashboard/my-adverts" noThrow />;
};

export const AuthenticatedApp: React.FC = () => {
  const { user } = useAuth();

  if (!user) {
    throw new RuntimeError(
      'Something went wrong whilst loading the app',
      'The AuthenticatedApp requires a user from the AuthContext to be present'
    );
  }

  if (user.registrationStatus === 'complete') {
    return <App />;
  }

  return <AccountSetupApp />;
};

const App = () => {
  const { isAdBlockEnabled, AdBlockWarningModal, AdBlockWarningBanner } = useAdBlockDetector();

  useEffect(() => {
    // Pre-load the AdvertBuilder in the background
    loadAdvertBuilder();
  }, []);

  return (
    <NotSuitableForSmallDevicesWarning>
      <ActiveOverlayProvider>
        <ErrorBoundary>
          <Suspense
            fallback={
              <FullPageSpinner>
                <Flex sx={{ flex: 1, minHeight: '100%', flexDirection: 'column', pb: 5 }}>
                  <AppHeader title="Loading" />

                  <Flex
                    sx={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      pt: 8,
                    }}
                  >
                    <LoadingSpinner />
                  </Flex>
                </Flex>
              </FullPageSpinner>
            }
          >
            <UserProvider>
              <CompanyProvider>
                <CurtainLoadingSpinnerProvider>
                  <React.Fragment>
                    <DevTools />

                    <DiscountCountdownBanner />

                    <SubscriptionPausedOverlay />
                    <SubscriptionLapsedOverlay />

                    {isAdBlockEnabled && <AdBlockWarningModal />}
                    {isAdBlockEnabled && <AdBlockWarningBanner />}

                    <ZendeskIdentitySync />

                    <Router primary={false} style={{ height: '100%' }}>
                      <Logout path="/logout" />

                      <Dashboard path="/dashboard/*" />

                      <CompanyOwnerRoute path="/settings">
                        <Company path="/">
                          <CompanyUserList path="users" />
                          <Billing path="billing/*" />

                          <Teams path="teams" />
                          <TeamDetails path="teams/:id" />

                          <Redirect path="/" from="/" to="/settings/users" noThrow />
                        </Company>
                      </CompanyOwnerRoute>

                      <AdvertBuilderAppShell path="/advert/:advertId">
                        <AdvertBuilder path="/*" />
                      </AdvertBuilderAppShell>

                      <AdminRoute path="/admin" component={AppShell}>
                        <Admin path="/*" />
                      </AdminRoute>

                      <ChangeSubscription path="/subscriptions/change/*" />

                      <NotFound default />
                    </Router>
                  </React.Fragment>
                </CurtainLoadingSpinnerProvider>
              </CompanyProvider>
            </UserProvider>
          </Suspense>
        </ErrorBoundary>
      </ActiveOverlayProvider>
    </NotSuitableForSmallDevicesWarning>
  );
};

const DevTools = () => {
  if (process.env.NODE_ENV !== 'development') {
    return null;
  }

  const DevTools = React.lazy(
    () => import(/* webpackChunkName: "DevTools"*/ './components/DevTools')
  );

  return (
    <Suspense fallback={null}>
      <DevTools />
    </Suspense>
  );
};
