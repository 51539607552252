import * as React from 'react';

import { ColouredTooltip } from '../../../components/ColouredTooltip';
import { Box } from '../../../components/common/Box';
import { useToggle } from '../../../hooks/useToggle';
import { useAdvertTableMachineState } from '../AdvertTableMachineContext';
import { FilterCollectionFormModal } from '../FilterCollectionFormModal';

export const SaveFilterCollectionButton: React.FC = React.memo(() => {
  const { filters } = useAdvertTableMachineState();

  const { on, setOn, setOff } = useToggle();

  return (
    <React.Fragment>
      <ColouredTooltip sx={{ bg: 'accent' }} label="Save filters">
        <Box as="button" sx={{ bg: 'unset', border: 'none', cursor: 'pointer' }} onClick={setOn}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
            fill="none"
            stroke="#3292cf"
            strokeWidth="3"
            strokeMiterlimit="2.6131"
            height="22px"
            width="22px"
          >
            <path d="M35.5 1.5h-34v25.42l8.58 8.58H35.5z" />
            <path d="M10.65 26.76h15.7v8.74h-15.7zM7.42 1.5h22.16v17.27H7.42z" />
          </svg>
        </Box>
      </ColouredTooltip>

      <FilterCollectionFormModal isOpen={on} filtersToSave={filters} onDismiss={setOff} />
    </React.Fragment>
  );
});
