import * as React from 'react';

import { Box } from '../../../components/common/Box';
import { useAdvertTableMachine, useAdvertTableMachineActions } from '../AdvertTableMachineContext';
import {
  ActiveFilterPill,
  ActiveFilterPillList,
  Filterable,
  SortableHeader,
} from '../DashboardTable';
import { ColumnOptions, FILTER_KEYS, SORT_KEYS, validateIsSingularFilter } from '.';

export const buildLocationColumn = (options: ColumnOptions) => {
  return {
    id: 'location',
    Header: () => {
      const [{ filters }, actions] = useAdvertTableMachine();

      const locationFilter = filters.get(FILTER_KEYS.LOCATION);

      validateIsSingularFilter(locationFilter);

      return (
        <div>
          <SortableHeader sortName={SORT_KEYS.LOCATION}>Location</SortableHeader>

          {locationFilter ? (
            <React.Fragment>
              <Box sx={{ height: '4px' }} />

              <ActiveFilterPillList>
                <ActiveFilterPill onClear={() => actions.clearFilter(FILTER_KEYS.LOCATION)}>
                  {locationFilter.label}
                </ActiveFilterPill>
              </ActiveFilterPillList>
            </React.Fragment>
          ) : null}
        </div>
      );
    },
    accessor: 'jobLocation',
    Cell: ({ value }: { value: string }) => {
      const { setFilter } = useAdvertTableMachineActions();

      return (
        <Filterable
          helperText="Filter list by this location"
          onClick={() => {
            setFilter(FILTER_KEYS.LOCATION, {
              label: value,
              value: value,
            });
          }}
        >
          {value}
        </Filterable>
      );
    },
    width: options.width,
  };
};
