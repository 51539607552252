import { Link } from '@reach/router';
import * as React from 'react';

import { Heading } from '../components/common/Heading';
import { useCompany } from '../context/CompanyContext';
import { Box } from './common/Box';
import { Button } from './common/Button';

interface TrialAccountRestrictedProps {
  title: React.ReactNode;
  restrictedMessage: React.ReactNode;
  containerProps?: any;
}

export const TrialAccountRestricted: React.FC<TrialAccountRestrictedProps> = ({
  title,
  restrictedMessage,
  containerProps,
  children,
}) => {
  const { company } = useCompany();

  if (!company || ['in_trial', 'not_created'].includes(company.subscriptionStatus)) {
    return (
      <Box pr={4} mb={4} {...containerProps}>
        <Heading mb={4}>{title}</Heading>

        {restrictedMessage}

        <Button
          as={Link}
          // @ts-ignore
          to="/dashboard"
          variant="primary"
          sx={{
            textDecoration: 'none',
          }}
        >
          Go to dashboard &rarr;
        </Button>
      </Box>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};
