import { MenuItem } from '@reach/menu-button';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';

import { analytics } from '../../../analytics/analytics';
import { Advert, advertApi } from '../../../api/advert';
import { ApiUser } from '../../../api/auth';
import { Box } from '../../../components/common/Box';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTextButton,
  ModalTitle,
} from '../../../components/common/Modal';
import { Text } from '../../../components/common/Text';
import { MultipleUserSelector } from '../../../components/MultipleUserSelector';
import { useUser } from '../../../context/UserContext';
import { useToggle } from '../../../hooks/useToggle';
import logger from '../../../utils/logger';
import { JobSummary } from '.';

interface ShareActionProps {
  advert: Advert;
  users: ApiUser[];
}

export const ShareAction: React.FC<ShareActionProps> = (props) => {
  const { on: modalOpen, setOn, setOff } = useToggle();
  const user = useUser();
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [shareError, setShareError] = useState<Error | undefined>(undefined);

  const userList = props.users
    .filter((member) => member.id !== user.id)
    .map((member) => ({
      ...member,
      selected: selectedUserIds.includes(member.id),
    }));

  const onShare = useCallback(async () => {
    setShareError(undefined);

    try {
      await advertApi.shareAdvert(props.advert.id, selectedUserIds);

      analytics.advertShared(props.advert.id);
      toast.success('Advert shared successfully');

      setOff();
      setSelectedUserIds([]);
    } catch (e) {
      logger.logError(e as any);
      setShareError(e as any);
      toast.error('Something went wrong whilst sharing the advert');
    }
  }, [props.advert, selectedUserIds, setOff]);

  return (
    <React.Fragment>
      <Modal
        aria-label="Share advert with team members"
        isOpen={modalOpen}
        data-testid="share-advert-modal"
        onDismiss={setOff}
      >
        <ModalClose onClick={setOff} />
        <ModalContainer>
          <ModalTitle>Who do you want to share with?</ModalTitle>

          <JobSummary advert={props.advert} />

          <Box py="3" />

          <ModalContent>
            <Box sx={{ minHeight: '310px' }}>
              <MultipleUserSelector
                users={userList}
                onUserToggled={(userId) => {
                  if (selectedUserIds.includes(userId)) {
                    setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
                    return;
                  }

                  setSelectedUserIds([...selectedUserIds, userId]);
                }}
              />
            </Box>

            {shareError && <Text>Something went wrong whilst sharing</Text>}
          </ModalContent>

          <ModalActions>
            <ModalButton
              type="button"
              data-testid="share-advert"
              variant={selectedUserIds.length === 0 ? 'disabled' : 'primaryInverted'}
              disabled={selectedUserIds.length === 0}
              onClick={onShare}
            >
              {selectedUserIds.length ? (
                <React.Fragment>
                  Share with {selectedUserIds.length} user{selectedUserIds.length === 1 ? '' : 's'}
                </React.Fragment>
              ) : (
                <React.Fragment>Share</React.Fragment>
              )}
            </ModalButton>

            <ModalTextButton type="button" onClick={setOff}>
              Cancel
            </ModalTextButton>
          </ModalActions>
        </ModalContainer>
      </Modal>

      <MenuItem key="share" data-testid="advert-actions-share" onSelect={setOn}>
        Share Advert
      </MenuItem>
    </React.Fragment>
  );
};
