import styled from '@emotion/styled/macro';
import * as React from 'react';

interface PenProps extends Omit<React.SVGProps<any>, 'ref'> {
  outlineColour?: string;
  backgroundColour?: string;
  hoverColour?: string;
  size?: string;
}

interface ContainerProps {
  outlineColour: string;
  backgroundColour: string;
  hoverColour?: string;
  size?: string;
}

const Container = styled.svg<ContainerProps>`
  cursor: pointer;
  stroke: ${(props: ContainerProps) => props.outlineColour};
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-width: ${(props: ContainerProps) => props.size};
  transition: all 200ms ease-in-out;
  fill: #ffffff;

  polygon,
  line {
    fill: #ffffff;
    transition: all 200ms ease-in-out;
  }

  &:hover {
    polygon,
    line {
      fill: ${(props: ContainerProps) => props.hoverColour};
    }
  }
`;

export const Pen: React.FC<PenProps> = ({
  outlineColour = '#203333',
  backgroundColour = 'transparent',
  size = '20px',
  hoverColour,
  ...props
}) => (
  <Container
    xmlns="http://www.w3.org/2000/svg"
    outlineColour={outlineColour}
    backgroundColour={backgroundColour}
    hoverColour={hoverColour}
    size={size}
    viewBox="0 0 500 500"
    {...props}
    width={size ? size : props.width}
    height={size ? size : props.height}
  >
    <polygon points="485.17 84.24 410.92 10 54.55 366.37 10 485.17 128.79 440.62 485.17 84.24" />
    <line x1="336.68" y1="84.24" x2="410.92" y2="158.49" />
  </Container>
);
