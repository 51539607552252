import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { motion, useSpring, useTransform } from 'framer-motion';
import * as React from 'react';

import {
  GenderResultBiasId,
  GradingFlaggedCollectionId,
  GradingFlaggedCollectionResult,
  GradingInclusivity,
  GradingInclusivityGenderResult,
  GradingReadability,
} from '../../../api/grade-content';
import { useTheme } from '../../../hooks/useTheme';
import { useToggle } from '../../../hooks/useToggle';
import { ColouredTooltip } from '../../ColouredTooltip';
import { Box } from '../../common/Box';
import { Button } from '../../common/Button';
import { Flex } from '../../common/Flex';
import { Grid } from '../../common/Grid';
import { Heading } from '../../common/Heading';
import { Image } from '../../common/Image';
import { Text } from '../../common/Text';
import { ErrorBoundary } from '../../ErrorBoundary';
import { Fade } from '../../Headless/Fade';
import { Rotate } from '../../Headless/Rotate';
import { ChevronDown } from '../../Icon';

const ROW_ANIMATION_TIME = 125;

interface ScorePanelProps {
  lastSelectedSuggestionCollectionId?: string;
  inclusivity: GradingInclusivity;
  readability: GradingReadability & {
    wordCount: number;
  };
  onContentSelected: (content: string) => void;
}

export const ScorePanel = (props: ScorePanelProps) => {
  const hasContent = props.readability.wordCount !== 0;

  const [tabIndex, setTabIndex] = React.useState(0);

  React.useEffect(() => {
    // find the tab index for the collection id

    if (typeof props.lastSelectedSuggestionCollectionId === 'undefined') {
      return;
    }

    const isInList = (results: GradingFlaggedCollectionResult[], id: string) => {
      return results.find((coll) => coll.id === id);
    };

    if (isInList(props.inclusivity.collectionResults, props.lastSelectedSuggestionCollectionId)) {
      setTabIndex(0);
      return;
    }

    if (isInList(props.readability.collectionResults, props.lastSelectedSuggestionCollectionId)) {
      setTabIndex(1);
      return;
    }
  }, [
    props.inclusivity.collectionResults,
    props.readability.collectionResults,
    props.lastSelectedSuggestionCollectionId,
    setTabIndex,
  ]);

  return (
    <Box sx={{ width: '300px', height: '100%', px: 2, overflowY: 'auto' }}>
      <Box
        sx={{
          bg: 'white',
          height: '100%',
          border: '1px solid #ccc',
          '[data-reach-tab][data-selected]': {
            borderBottom: 'none',
            bg: 'white',
          },
        }}
      >
        <Tabs index={tabIndex} onChange={setTabIndex}>
          <TabList>
            <StyledTab
              title="Inclusivity:"
              description="How appealing your job advert is to a broad audience. Aim for a score higher than 85%."
              score={hasContent ? props.inclusivity.score : null}
            />

            <StyledTab
              title="Readability:"
              description="How easy your advert is to read and understand. Aim for a score higher than 80%."
              score={hasContent ? props.readability.score : null}
            />
          </TabList>

          <TabPanels>
            <StyledTabPanel>
              <InclusionScorePanel
                score={props.inclusivity.score}
                genderResult={props.inclusivity.genderResult}
                collectionResults={props.inclusivity.collectionResults}
                onContentSelected={props.onContentSelected}
              />
            </StyledTabPanel>

            <StyledTabPanel>
              <ReadabilityScorePanel
                score={props.readability.score}
                readingEase={props.readability.readingEase}
                readingGrade={props.readability.readingGrade}
                wordCount={props.readability.wordCount}
                collectionResults={props.readability.collectionResults}
                onContentSelected={props.onContentSelected}
              />
            </StyledTabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

interface StyledTabProps {
  title: string;
  description: string;
  score: number | null;
}

const StyledTab = (props: StyledTabProps) => {
  return (
    <ColouredTooltip label={props.description} sx={{ bg: 'black' }}>
      <Box as={Tab} sx={{ p: 3, width: '50%', bg: '#e8e8e8' }}>
        <Heading
          sx={{
            fontWeight: 500,
            fontSize: '1.1rem',
            color: 'accent',
            pb: 2,
            letterSpacing: '-0.025rem',
          }}
        >
          {props.title}
        </Heading>

        <Flex sx={{ justifyContent: 'center' }}>
          <ScoreIndicator score={props.score} />
        </Flex>
      </Box>
    </ColouredTooltip>
  );
};

interface StyledTabPanelProps {
  children: React.ReactNode;
}

const StyledTabPanel = ({ children }: StyledTabPanelProps) => {
  const theme = useTheme();

  return (
    <Box as={TabPanel} sx={{ py: 3 }}>
      {children}

      <Flex sx={{ justifyContent: 'center', pt: 7 }}>
        <Flex sx={{ flexDirection: 'column', rowGap: 2 }}>
          <Image src={theme.assets.adgrader.dark} sx={{ width: '115px' }} />
        </Flex>
      </Flex>
    </Box>
  );
};

interface InclusionScorePanelProps {
  score: number;
  genderResult: GradingInclusivityGenderResult;
  collectionResults: GradingFlaggedCollectionResult[];
  onContentSelected: (content: string) => void;
  disableInteractive?: boolean;
}

export const InclusionScorePanel = (props: InclusionScorePanelProps) => {
  const genderToneColours = useGenderToneColours(
    props.collectionResults,
    props.genderResult.biasId
  );

  return (
    <Box>
      <Box
        as="ul"
        sx={{
          listStyle: 'none',
          p: 0,
        }}
      >
        <ErrorBoundary
          FallbackComponent={
            <Box as="li" sx={{ mx: 3, bg: 'grey.2', p: 2, fontSize: 1 }}>
              Oops. We encountered an issue whilst calculating the gender tone.
            </Box>
          }
        >
          <Box as="li">
            <Box>
              <InclusionGenderScore
                biasId={props.genderResult.biasId}
                bias={props.genderResult.bias}
                score={props.genderResult.score}
                genderToneColour={genderToneColours.colour}
                genderToneAccentColour={genderToneColours.accentColour}
                disableInteractive={props.disableInteractive}
              />
            </Box>
          </Box>
        </ErrorBoundary>
        {props.collectionResults.map((collection) => {
          return (
            <Box key={collection.id} as="li">
              <ScorePanelCollection
                collection={collection}
                onContentSelected={props.onContentSelected}
                disableInteractive={props.disableInteractive}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const useGenderToneColours = (
  collectionResults: GradingFlaggedCollectionResult[],
  biasId: GenderResultBiasId
) => {
  const biasColours = React.useMemo(() => {
    const id = (() => {
      switch (biasId) {
        case GenderResultBiasId.StronglyMasculine:
        case GenderResultBiasId.Masculine:
        case GenderResultBiasId.SlightlyMasculine:
          return GradingFlaggedCollectionId.Masculine;

        case GenderResultBiasId.SlightlyFeminine:
        case GenderResultBiasId.Feminine:
        case GenderResultBiasId.StronglyFeminine:
          return GradingFlaggedCollectionId.Feminine;

        default:
          return null;
      }
    })();

    const defaultColours = { colour: '#e8e8e8', accentColour: '#000000' };

    if (id === null) {
      return defaultColours;
    }

    const collection = collectionResults.find((coll) => coll.id === id);
    if (!collection) {
      return defaultColours;
    }

    return {
      colour: collection.colour,
      accentColour: collection.accentColour,
    };
  }, [biasId, collectionResults]);

  return biasColours;
};

interface InclusionGenderScoreProps {
  biasId: GenderResultBiasId;
  bias: string;
  score: number;
  genderToneColour: string;
  genderToneAccentColour: string;
  disableInteractive?: boolean;
}

const InclusionGenderScore = (props: InclusionGenderScoreProps) => {
  const { on: isOpen, toggle } = useToggle();

  const biasOptions = React.useMemo(() => {
    return [
      { id: GenderResultBiasId.StronglyMasculine, colour: '#3292cf' },
      { id: GenderResultBiasId.Masculine, colour: '#8ac1e4' },
      { id: GenderResultBiasId.SlightlyMasculine, colour: '#c1def1' },
      { id: GenderResultBiasId.Neutral, colour: '#e8e8e8' },
      { id: GenderResultBiasId.SlightlyFeminine, colour: '#fbc0cb' },
      { id: GenderResultBiasId.Feminine, colour: '#f274a8' },
      { id: GenderResultBiasId.StronglyFeminine, colour: '#df0074' },
    ];
  }, []);

  const score = React.useMemo(() => {
    if (props.biasId === GenderResultBiasId.Neutral) {
      return 0;
    }

    // Translate the 0 to 1 score scale (1 = neutral, 0 = skewed heavily)
    // into a -1 to 1 scale (-1 = skewed masculine, 0 = neutral, 1 = skewed feminine)

    const isMascLeaning = [
      GenderResultBiasId.StronglyMasculine,
      GenderResultBiasId.Masculine,
      GenderResultBiasId.SlightlyMasculine,
    ].includes(props.biasId);

    let score = isMascLeaning ? -1 + props.score : 1 - props.score;

    return Math.round(score * 100);
  }, [props.score, props.biasId]);

  return (
    <ScorePanelRow isActive={isOpen}>
      <Box sx={{ pb: '0.85rem' }}>
        <ScorePanelRowSummary>
          <ScorePanelRowLabel>Gender tone:</ScorePanelRowLabel>

          <ScorePanelRowIndicator>
            <Flex
              as="button"
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                bg: props.genderToneColour,
                color: props.genderToneAccentColour,
                fontWeight: '500',
                fontSize: '13px',
                outline: 'none',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={toggle}
            >
              {props.bias}
            </Flex>
          </ScorePanelRowIndicator>

          {!props.disableInteractive ? (
            <ScorePanelRowDropdownChevron isOpen={isOpen} onToggle={toggle} />
          ) : null}
        </ScorePanelRowSummary>
      </Box>

      <GenderScoreBar biasOptions={biasOptions} biasId={props.biasId} score={score} />

      <Fade active={isOpen} duration={ROW_ANIMATION_TIME}>
        <Box sx={{ mt: 2, fontSize: 0, color: 'rgba(0, 0, 0, 0.6)', fontWeight: '400' }}>
          <Text sx={{ pb: 2 }}>Research shows that some words contain inherent gender bias.</Text>

          <Text>
            Aim to create a job advert that's ideally neutral or feminine-coded, as the number of
            applicants you receive from men typically aren't impacted that way around.
          </Text>
        </Box>
      </Fade>
    </ScorePanelRow>
  );
};

interface GenderScoreBarProps {
  biasOptions: { id: GenderResultBiasId; colour: string }[];
  biasId: GenderResultBiasId;
  score: number;
}

const GenderScoreBar = (props: GenderScoreBarProps) => {
  const scoreLeftValue = useSpring(props.score);
  React.useEffect(() => {
    scoreLeftValue.set(props.score);
  }, [scoreLeftValue, props.score]);

  // Translate the -1 to 1 scale into a percentage
  // the additional input: -1, 1 and output: 42%, 58% ensure that only neutral bias
  // is permitted in the grey segment
  const input = [-100, -1, 0, 1, 100];
  const output = ['0%', '42%', '50%', '58%', '100%'];

  const scoreLeft = useTransform(scoreLeftValue, input, output);

  return (
    <Box sx={{ position: 'relative', mb: 3, mt: '0.25rem' }}>
      <Grid
        sx={{ gridTemplateColumns: `repeat(${props.biasOptions.length}, 1fr)`, height: '15px' }}
      >
        {props.biasOptions.map((option) => {
          return <Box key={option.id} sx={{ bg: option.colour }} />;
        })}
      </Grid>

      <motion.div
        style={{ position: 'absolute', top: '-10px', left: scoreLeft, marginLeft: '-14px' }}
      >
        <GenderScoreBarIndicator />
      </motion.div>
    </Box>
  );
};

const GenderScoreBarIndicator = () => {
  return (
    <svg height="28" width="28">
      <polygon
        points="14,16 2,2 26,2"
        style={{
          fill: '#ffffff',
          stroke: '#666666',
          strokeWidth: '2px',
        }}
      />
    </svg>
  );
};

interface ReadabilityScorePanelProps {
  score: number;
  readingEase: number;
  readingGrade: number;
  wordCount: number;
  collectionResults: GradingFlaggedCollectionResult[];
  onContentSelected: (content: string) => void;
  disableInteractive?: boolean;
}

export const ReadabilityScorePanel = (props: ReadabilityScorePanelProps) => {
  return (
    <Box>
      <Box as="ul" sx={{ listStyle: 'none', p: 0 }}>
        <Box as="li">
          <TimeToRead wordCount={props.wordCount} />
        </Box>

        <Box as="li">
          <ReadingDifficulty
            wordCount={props.wordCount}
            readingEase={props.readingEase}
            readingGrade={props.readingGrade}
            disableInteractive={props.disableInteractive}
          />
        </Box>

        <Box as="li">
          <WordCount wordCount={props.wordCount} disableInteractive={props.disableInteractive} />
        </Box>

        {props.collectionResults.map((collection) => {
          return (
            <Box key={collection.id} as="li">
              <ScorePanelCollection
                collection={collection}
                onContentSelected={props.onContentSelected}
                disableInteractive={props.disableInteractive}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const calculateReadingDifficulty = (wordCount: number, readingEase: number) => {
  if (wordCount <= 0) {
    return {
      label: 'n/a',
      colour: '#D32F2F',
    };
  }

  if (readingEase < 40) {
    return {
      label: 'Tough',
      colour: '#D32F2F',
    };
  }

  if (readingEase < 75) {
    return {
      label: 'Middling',
      colour: '#ffd345',
    };
  }

  return {
    label: 'Easy',
    colour: '#59b200',
  };
};

const UnderstandReadingEaseScoreLink = () => {
  return (
    <a
      href="https://help.adbuilder.io/adgrader/understanding-and-improving-your-reading-ease-score/"
      target="_blank"
      rel="noreferrer"
    >
      How to improve your reading ease score &#62;
    </a>
  );
};

const calculateReadingEaseText = (readingEase: number) => {
  if (readingEase >= 90) {
    return {
      label: 'Kids books or comics',
      description: null,
    };
  }

  if (readingEase >= 70) {
    return {
      label: 'The Chronicles of Narnia books',
      description: (
        <Text>
          <UnderstandReadingEaseScoreLink />
        </Text>
      ),
    };
  }

  if (readingEase >= 60) {
    return {
      label: 'National Geographic',
      description: (
        <Text>
          <UnderstandReadingEaseScoreLink />
        </Text>
      ),
    };
  }

  if (readingEase >= 40) {
    return {
      label: 'The Times newspaper',
      description: (
        <Box>
          <Text sx={{ mb: 2 }}>
            Ideally you want to be aiming for a reading ease score of over 60%. Anything below that
            and your advert might be too difficult to read and understand.
          </Text>
          <Text>
            <UnderstandReadingEaseScoreLink />
          </Text>
        </Box>
      ),
    };
  }

  if (readingEase >= 30) {
    return {
      label: 'Jane Austen',
      description: (
        <Box>
          <Text sx={{ mb: 2 }}>
            This is quite a challenging advert to read and understand. Aim for a reading ease score
            of over 60%.
          </Text>
          <Text>
            <UnderstandReadingEaseScoreLink />
          </Text>
        </Box>
      ),
    };
  }

  if (readingEase >= 20) {
    return {
      label: 'The Lancet',
      description: (
        <Box>
          <Text sx={{ mb: 2 }}>
            This is a very challenging advert for the majority of people to read and understand. Aim
            for a reading ease score of over 60%.
          </Text>
          <Text>
            <UnderstandReadingEaseScoreLink />
          </Text>
        </Box>
      ),
    };
  }

  return {
    label: 'Strident intellectuals',
    description: (
      <Box>
        <Text sx={{ mb: 2 }}>
          This advert is really difficult for most people to read and understand. Aim for a reading
          ease score of over 60%.
        </Text>
        <Text>
          <UnderstandReadingEaseScoreLink />
        </Text>
      </Box>
    ),
  };
};

interface ReadingDifficultyProps {
  wordCount: number;
  readingEase: number;
  readingGrade: number;
  disableInteractive?: boolean;
}

const ReadingDifficulty = (props: ReadingDifficultyProps) => {
  const { on: isOpen, toggle } = useToggle();
  const info = React.useMemo(() => {
    return calculateReadingDifficulty(props.wordCount, props.readingEase);
  }, [props.wordCount, props.readingEase]);

  const { label: readingEaseLabel, description: readingEaseDescription } = calculateReadingEaseText(
    props.readingEase
  );

  return (
    <ScorePanelRow isActive={isOpen}>
      <ScorePanelRowSummary>
        <ScorePanelRowLabel onClick={toggle}>Reading difficulty:</ScorePanelRowLabel>

        <ScorePanelRowIndicator>
          <Box
            as="button"
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              px: 2,
              color: 'white',
              backgroundColor: info.colour,
              fontSize: '13px',
              fontWeight: '500',
              cursor: 'pointer',
              outline: 'none',
              border: 'none',
            }}
            onClick={toggle}
          >
            {info.label}
          </Box>
        </ScorePanelRowIndicator>

        {!props.disableInteractive ? (
          <ScorePanelRowDropdownChevron isOpen={isOpen} onToggle={toggle} />
        ) : null}
      </ScorePanelRowSummary>

      <Fade active={isOpen} duration={ROW_ANIMATION_TIME}>
        <Box sx={{ mt: 2, fontSize: 0, color: 'rgba(0, 0, 0, 0.6)', fontWeight: '400' }}>
          <Grid sx={{ gridTemplateColumns: 'auto 1fr', rowGap: 2, columnGap: 2 }}>
            <Text sx={{ fontWeight: '700' }}>Reading ease:</Text>
            <Text>{props.readingEase}%</Text>

            <Text sx={{ fontWeight: '700' }}>Reading level:</Text>
            <Text>{readingEaseLabel}</Text>
          </Grid>

          <Text sx={{ mt: 2 }}>{readingEaseDescription}</Text>
        </Box>
      </Fade>
    </ScorePanelRow>
  );
};

const calculateWordCountColour = (wordCount: number) => {
  if (wordCount <= 150) {
    return '#D32F2F';
  }

  if (wordCount <= 200) {
    return '#FF9A45';
  }

  if (wordCount <= 300) {
    return '#FFD345';
  }

  if (wordCount <= 800) {
    return '#59b200';
  }

  if (wordCount <= 900) {
    return '#FFD345';
  }

  if (wordCount <= 1050) {
    return '#FF9A45';
  }

  return '#D32F2F';
};

interface WordCountProps {
  wordCount: number;
  disableInteractive?: boolean;
}

const WordCount = (props: WordCountProps) => {
  const { on: isOpen, toggle } = useToggle();

  const wordCountColour = React.useMemo(() => {
    return calculateWordCountColour(props.wordCount);
  }, [props.wordCount]);

  const wordCountDescription = React.useMemo(() => {
    if (props.wordCount <= 150) {
      return `Your advert is too short and you need to make it longer.  The ideal length for a job advert is anywhere between 300 and 800 words.`;
    }

    if (props.wordCount <= 300) {
      return `Your advert needs to be a bit longer. The ideal length for a job advert is anywhere between 300 and 800 words.`;
    }

    if (props.wordCount <= 800) {
      return `Your advert is the perfect length for readability. The best job adverts are normally between 300 and 800 words.`;
    }

    if (props.wordCount <= 900) {
      return `Your advert is a touch long.  Consider pruning some text to make it shorter and quicker to digest. The ideal length for a job advert is anywhere between 300 and 800 words.`;
    }

    if (props.wordCount <= 1050) {
      return `Your advert is too long.  You'll need to delete some text or you risk putting candidates off who prefer to read shorter, more pertinent adverts. The ideal length for a job advert is anywhere between 300 and 800 words.`;
    }

    return `Your advert is way too long.  Candidates will lose interest in your advert if it's too long, so delete any text you can that isn't relevant or adds to the advert. The ideal length for a job advert is anywhere between 300 and 800 words.`;
  }, [props.wordCount]);

  return (
    <ScorePanelRow>
      <ScorePanelRowSummary>
        <ScorePanelRowLabel onClick={toggle}>Word count:</ScorePanelRowLabel>

        <ScorePanelRowIndicator>
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '30px',
              height: '100%',
              color: 'white',
              backgroundColor: wordCountColour,
              fontSize: '13px',
              fontWeight: '500',
              outline: 'none',
              border: 'none',
            }}
            onClick={toggle}
          >
            {props.wordCount}
          </Flex>
        </ScorePanelRowIndicator>

        {!props.disableInteractive ? (
          <ScorePanelRowDropdownChevron isOpen={isOpen} onToggle={toggle} />
        ) : null}
      </ScorePanelRowSummary>

      <Fade active={isOpen} duration={ROW_ANIMATION_TIME}>
        <Box sx={{ mt: 2, fontSize: 0, color: 'rgba(0, 0, 0, 0.6)', fontWeight: '400' }}>
          <Text sx={{ mt: 2 }}>{wordCountDescription}</Text>
        </Box>
      </Fade>
    </ScorePanelRow>
  );
};

const calculateTimeToRead = (wordCount: number) => {
  if (wordCount === 0) {
    return 0;
  }

  return wordCount / 200;
};

const calculateTimeToReadLabel = (wordCount: number): string => {
  const timeToRead = calculateTimeToRead(wordCount);

  if (timeToRead === 0) {
    return `n/a`;
  }

  if (timeToRead < 1) {
    return `less than 1 minute`;
  }

  if (timeToRead >= 1 && timeToRead <= 1.5) {
    return `1 minute`;
  }

  return `${Math.ceil(timeToRead)} minutes`;
};

const calculateTimeToReadColour = (wordCount: number) => {
  const timeToRead = calculateTimeToRead(wordCount);

  if (timeToRead <= 1) {
    return { backgroundColour: '#e8e8e8', fontColour: 'rgba(0,0,0,0.6)' };
  }

  if (timeToRead <= 1.5) {
    return { backgroundColour: '#ffd345', fontColour: '#ffffff' };
  }

  if (timeToRead <= 4) {
    return { backgroundColour: '#59b200', fontColour: '#ffffff' };
  }

  if (timeToRead <= 4.5) {
    return { backgroundColour: '#ffd345', fontColour: '#ffffff' };
  }

  if (timeToRead <= 5.25) {
    return { backgroundColour: '#ff9a45', fontColour: '#ffffff' };
  }

  return { backgroundColour: '#D32F2F', fontColour: '#ffffff' };
};

interface TimeToReadProps {
  wordCount: number;
}

const TimeToRead = (props: TimeToReadProps) => {
  const timeToReadInfo = React.useMemo(() => {
    const colour = calculateTimeToReadColour(props.wordCount);

    return {
      label: calculateTimeToReadLabel(props.wordCount),
      fontColour: colour.fontColour,
      backgroundColour: colour.backgroundColour,
    };
  }, [props.wordCount]);

  return (
    <ScorePanelRow>
      <ScorePanelRowSummary>
        <ScorePanelRowLabel>Time to read:</ScorePanelRowLabel>

        <ScorePanelRowIndicator>
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              px: 2,
              color: timeToReadInfo.fontColour,
              backgroundColor: timeToReadInfo.backgroundColour,
              fontSize: '13px',
              fontWeight: '500',
            }}
          >
            {timeToReadInfo.label}
          </Flex>
        </ScorePanelRowIndicator>
      </ScorePanelRowSummary>
    </ScorePanelRow>
  );
};

const calculateIndicatorColour = (score: number | null): string => {
  if (score === null) {
    return '#e8e8e8';
  }

  if (score >= 90) {
    return '#59B200';
  }

  if (score >= 60) {
    return '#FFD345';
  }

  if (score >= 40) {
    return '#FF9A45';
  }

  return '#D32F2F';
};

const scoreIndicatorSizes = {
  medium: { width: 60, height: 45, fontSize: 4 },
  large: { width: 80, height: 60, fontSize: 5 },
};

interface ScoreIndicatorProps {
  score: number | null;
  size?: 'medium' | 'large';
}

export const ScoreIndicator: React.FC<ScoreIndicatorProps> = ({ score, size = 'large' }) => {
  const colour = React.useMemo(() => {
    return calculateIndicatorColour(score);
  }, [score]);

  return (
    <Flex
      sx={{
        backgroundColor: colour,
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 700,
        letterSpacing: ' -0.05rem',
        ...scoreIndicatorSizes[size],
      }}
    >
      <Box>{score === null ? '--' : `${score}%`}</Box>
    </Flex>
  );
};

interface ScorePanelRowProps {
  children: React.ReactNode;
  isActive?: boolean;
}

const ScorePanelRow = (props: ScorePanelRowProps) => {
  return (
    <Box sx={{ px: 2 }}>
      <Box
        sx={{
          p: 2,
          backgroundColor: props.isActive ? '#f6f6f6' : 'unset',
          transition: `background-color ${ROW_ANIMATION_TIME}ms ease-in-out`,
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

interface ScorePanelRowSummaryProps {
  children: React.ReactNode;
}

const ScorePanelRowSummary = (props: ScorePanelRowSummaryProps) => {
  return (
    <Grid
      sx={{
        gridTemplateColumns: ['auto auto 7.5px'],
        columnGap: '2',
        alignItems: 'center',
        height: '30px',
      }}
    >
      {props.children}
    </Grid>
  );
};

interface ScorePanelRowLabelProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const ScorePanelRowLabel = (props: ScorePanelRowLabelProps) => {
  return (
    <Text
      as={typeof props.onClick === 'undefined' ? 'div' : 'button'}
      sx={{
        fontWeight: 500,
        fontSize: '13px',
        color: '#000000',
        letterSpacing: '-0.01rem',
        outline: 'none',
        border: 'none',
        textAlign: 'left',
        bg: 'unset',
        p: 0,
        cursor: typeof props.onClick === 'undefined' ? 'default' : 'pointer',
      }}
      onClick={props.onClick}
    >
      {props.children}
    </Text>
  );
};

const ScorePanelRowIndicator: React.FC = (props) => {
  return (
    <Flex
      sx={{
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {props.children}
    </Flex>
  );
};

interface ScorePanelRowDropdownChevronProps {
  isOpen: boolean;
  onToggle: () => void;
}

const ScorePanelRowDropdownChevron = (props: ScorePanelRowDropdownChevronProps) => {
  return (
    <Flex sx={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
      <Rotate active={props.isOpen}>
        <Box
          as="button"
          sx={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px' }}
          onClick={props.onToggle}
        >
          <ChevronDown />
        </Box>
      </Rotate>
    </Flex>
  );
};

interface ScorePanelCollectionProps {
  collection: GradingFlaggedCollectionResult;
  onContentSelected: (matchedText: string) => void;
  disableInteractive?: boolean;
}

const ScorePanelCollection = (props: ScorePanelCollectionProps) => {
  const count = props.collection.matchedContent.length;
  const { on: isOpen, toggle, setOff: close } = useToggle();

  const hasItems = count > 0;

  React.useEffect(() => {
    if (!hasItems) {
      // no words left so close it
      close();
    }
  }, [hasItems, close]);

  return (
    <ScorePanelRow isActive={isOpen}>
      <ScorePanelRowSummary>
        <ScorePanelRowLabel onClick={hasItems ? toggle : undefined}>
          {props.collection.name}:
        </ScorePanelRowLabel>

        <ScorePanelRowIndicator>
          <Flex
            as="button"
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '30px',
              height: '100%',
              bg: props.collection.colour,
              color: props.collection.accentColour,
              fontWeight: '500',
              fontSize: '13px',
              outline: 'none',
              border: 'none',
              cursor: hasItems ? 'pointer' : 'cursor',
            }}
            onClick={hasItems ? toggle : undefined}
          >
            {count}
          </Flex>
        </ScorePanelRowIndicator>

        {count > 0 && !props.disableInteractive ? (
          <ScorePanelRowDropdownChevron isOpen={isOpen} onToggle={toggle} />
        ) : (
          <Box />
        )}
      </ScorePanelRowSummary>

      <Fade active={isOpen} duration={ROW_ANIMATION_TIME}>
        <Box sx={{ mt: 2 }}>
          <ScorePanelCollectionMatchedTextList
            collection={props.collection}
            onContentSelected={props.onContentSelected}
          />
        </Box>
      </Fade>
    </ScorePanelRow>
  );
};

interface ScorePanelCollectionMatchedTextListProps {
  collection: GradingFlaggedCollectionResult;
  onContentSelected: (matchedText: string) => void;
}
const ScorePanelCollectionMatchedTextList = (props: ScorePanelCollectionMatchedTextListProps) => {
  const countsPerMatchedText = React.useMemo(() => {
    let seen: { [key: string]: number } = {};

    for (let content of props.collection.matchedContent) {
      seen[content.matchedText] =
        typeof seen[content.matchedText] === 'undefined' ? 1 : seen[content.matchedText] + 1;
    }

    return seen;
  }, [props.collection.matchedContent]);

  return (
    <Box
      as="ul"
      sx={{ display: 'flex', flexWrap: 'wrap', columnGap: 1, rowGap: 1, listStyle: 'none', p: 0 }}
    >
      {Object.entries(countsPerMatchedText).map(([matchedText, count]) => {
        return (
          <Box key={matchedText} as="li" sx={{ display: 'inline' }}>
            <Button
              type="button"
              sx={{
                bg: props.collection.colour,
                color: props.collection.accentColour,
                fontWeight: 500,
                pt: 1,
                pr: 1,
                pb: 1,
                pl: 1,
                minWidth: 'unset',
                border: 'none',
                borderRadius: '0',
                fontSize: '13px',
                '&:hover, &:focus': {
                  backgroundColor: props.collection.colour,
                  color: props.collection.accentColour,
                },
                '&:focus': {
                  outlineColor: props.collection.colour,
                },
              }}
              onClick={() => {
                props.onContentSelected(matchedText);
              }}
            >
              {count > 1 ? `${count} |` : ''} {matchedText}
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};
