import '@reach/accordion/styles.css';

import {
  Accordion as ReachAccordion,
  AccordionButton as ReachAccordionButton,
  AccordionButtonProps as ReachAccordionButtonProps,
  AccordionItem as ReachAccordionItem,
  AccordionItemProps as ReachAccordionItemProps,
  AccordionPanel as ReachAccordionPanel,
  AccordionPanelProps as ReachAccordionPanelProps,
  AccordionProps as ReachAccordionProps,
} from '@reach/accordion';
import { forwardRef } from 'react';

import { Box } from './Box';

export type AccordionProps = ReachAccordionProps;

export const Accordion = forwardRef((props: ReachAccordionProps, ref) => {
  // @ts-ignore
  return <Box as={ReachAccordion} {...props} ref={ref} />;
});

export type AccordionButtonProps = ReachAccordionButtonProps;

export const AccordionButton = forwardRef((props: ReachAccordionButtonProps, ref) => {
  return (
    <Box
      as={ReachAccordionButton}
      variant="primary"
      {...props}
      ref={ref}
      __themeKey="accordion.button"
    />
  );
});

export type AccordionItemProps = ReachAccordionItemProps;

export const AccordionItem = forwardRef((props: ReachAccordionItemProps, ref) => {
  return (
    <Box
      as={ReachAccordionItem}
      variant="primary"
      {...props}
      ref={ref}
      __themeKey="accordion.item"
    />
  );
});

export type AccordionPanelProps = ReachAccordionPanelProps;

export const AccordionPanel = forwardRef((props: ReachAccordionPanelProps, ref) => (
  <Box
    as={ReachAccordionPanel}
    variant="primary"
    {...props}
    ref={ref}
    __themeKey="accordion.panel"
  />
));
