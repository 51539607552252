import addDays from 'date-fns/addDays';
import addHours from 'date-fns/addHours';
import addMinutes from 'date-fns/addMinutes';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';

export const calculateCountdownUnits = (futureDate: Date, startWithDays: boolean) => {
  let counterDate = new Date();

  let days = null;

  if (startWithDays) {
    days = differenceInDays(futureDate, counterDate);
    counterDate = addDays(counterDate, days);
  }

  const hours = differenceInHours(futureDate, counterDate);
  counterDate = addHours(counterDate, hours);

  const minutes = differenceInMinutes(futureDate, counterDate);
  counterDate = addMinutes(counterDate, minutes);

  const seconds = differenceInSeconds(futureDate, counterDate);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};
