import styled from '@emotion/styled/macro';

import { AppTheme, IAppTheme } from '../../theme/appTheme';
import { Box, BoxProps } from './Box';

interface HideProps extends BoxProps {
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  print?: boolean;
  theme?: IAppTheme;
}

const getMaxWidth = (px: string) => parseInt(px) - 1;

const breakpoints = (props: { theme: IAppTheme }) => ({
  xs: `@media screen and (max-width: ${getMaxWidth(props.theme.breakpoints[0])}px)`,
  sm: `@media screen and (min-width: ${props.theme.breakpoints[0]}) and (max-width: ${getMaxWidth(
    props.theme.breakpoints[1]
  )}px)`,
  md: `@media screen and (min-width: ${props.theme.breakpoints[1]}) and (max-width: ${getMaxWidth(
    props.theme.breakpoints[2]
  )}px)`,
  lg: `@media screen and (min-width: ${props.theme.breakpoints[2]}) and (max-width: ${getMaxWidth(
    props.theme.breakpoints[3]
  )}px)`,
  xl: `@media screen and (min-width: ${props.theme.breakpoints[3]})`,
  print: `@media print`,
});

const hidden = (key: string) => (props: HideProps) =>
  (props as any)[key]
    ? {
        [(breakpoints(props as any) as any)[key]]: {
          display: 'none',
        },
      }
    : null;

export const Hide: React.FC<React.PropsWithoutRef<HideProps>> = styled(Box)<HideProps>`
  ${hidden('xs')} ${hidden('sm')} ${hidden('md')} ${hidden('lg')} ${hidden('xl')} ${hidden(
    'print'
  )};
`;

Hide.defaultProps = {
  theme: AppTheme,
};
