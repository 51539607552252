import { RouteComponentProps } from '@reach/router';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';

import { ApiUser } from '../../api/auth';
import { companyApi } from '../../api/company';
import { Box } from '../../components/common/Box';
import { Flex } from '../../components/common/Flex';
import { Heading } from '../../components/common/Heading';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { ScrollToTop } from '../../components/Headless/ScrollToTop';
import { InviteUserButton } from '../../components/InviteUserButton';
import { PlanHeader } from '../../components/PlanHeader';
import { useCompany } from '../../context/CompanyContext';
import { useUser } from '../../context/UserContext';
import { RuntimeError } from '../../Error/BaseErrors';
import { subscriptionTheme } from '../../theme/subscriptionTheme';
import { ThemeProvider } from '../../theme/ThemeProvider';
import logger from '../../utils/logger';
import {
  CompanyInviteList,
  ResendAction,
  RevokeAction,
} from '../Company/UserList/CompanyInviteList';
import { CompanyLicencesStats } from '../Company/UserList/CompanyLicencesStats';
import { UserList } from '../Company/UserList/UserList';
import { SubscriptionPageHeader } from './SubscriptionPageHeader';

interface AllocateUsersProps extends RouteComponentProps {
  pageHeaderTitle: string;
  pageHeaderDescription: string;
}

export const AllocateUsers: React.FC<AllocateUsersProps> = (props) => {
  return (
    <ThemeProvider theme={subscriptionTheme as TSThemeFixMe}>
      <ErrorBoundary>
        <AllocateUsersImpl {...props} />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

interface AllocateUsersImplProps {
  pageHeaderTitle: string;
  pageHeaderDescription: string;
}

export const AllocateUsersImpl: React.FC<AllocateUsersImplProps> = ({
  pageHeaderTitle,
  pageHeaderDescription,
}) => {
  const { company, refetchCompany } = useCompany();

  if (!company) {
    throw new RuntimeError(
      'Something went wrong whilst loading',
      "User doesn't have belong to a company yet so they shouldn't have access"
    );
  }

  const [fetchingCompany, setFetchingCompany] = useState(true);
  const user = useUser();

  const {
    status: fetchingMembersStatus,
    data: companyMembersData,
    error: fetchingMembersError,
    refetch: refetchMembers,
  } = companyApi.useCompanyMembers(company.id);

  const {
    status: fetchingExpiredInvitesStatus,
    data: companyExpiredInvitesData,
    error: fetchingExpiredInvitesError,
    refetch: refetchExpiredInvites,
  } = companyApi.useCompanyExpiredInvites(company.id);

  const {
    status: fetchingInvitesStatus,
    data: companyInvitesData,
    error: fetchingInvitesError,
    refetch: refetchInvites,
  } = companyApi.useCompanyInvites(company.id);

  const { companyMembers } = companyMembersData || {};
  const { invites: companyInvites } = companyInvitesData || {};
  const { invites: companyExpiredInvites } = companyExpiredInvitesData || {};

  useEffect(() => {
    const fetchCompany = async () => {
      await refetchCompany();
      setFetchingCompany(false);
    };

    fetchCompany();
  }, [refetchCompany, setFetchingCompany]);

  const { companySubscription } = company;

  if (fetchingMembersError) {
    return (
      <div>
        <SubscriptionPageHeader
          title="Loading"
          subTitle={<Box>Something went wrong whilst fetching your members & invites</Box>}
        />

        <Box sx={{ height: '3.75rem' }} />
        <Flex
          sx={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}
        >
          Encountered an error whilst fetching members & invites
        </Flex>
      </div>
    );
  }

  const licencesInUse =
    companyMembers && companyInvites ? companyMembers.length + companyInvites.length : undefined;

  return (
    <ScrollToTop>
      <Box>
        {fetchingMembersStatus === 'loading' || fetchingCompany || !companyMembers ? (
          <SubscriptionPageHeader
            title="Loading"
            subTitle={<Box>Fetching members & invites</Box>}
          />
        ) : (
          <SubscriptionPageHeader title={pageHeaderTitle} subTitle={pageHeaderDescription} />
        )}

        <Box sx={{ height: '3.75rem' }} />

        <Flex sx={{ justifyContent: 'center', px: '5', pt: '7', pb: '9' }}>
          <Flex sx={{ flex: '1', flexDirection: 'column', maxWidth: '1000px' }}>
            <ErrorBoundary>
              <Flex sx={{ flex: '1', flexDirection: 'column', mb: '8' }}>
                <Box pb="5">
                  <PlanHeader
                    title="Current Plan:"
                    plan={
                      <React.Fragment>
                        {company.companySubscription.tierName}{' '}
                        {company.subscriptionStatus === 'in_trial' ? '(Trial)' : null}
                      </React.Fragment>
                    }
                  />
                </Box>

                <CompanyLicencesStats total={companySubscription.seats} used={licencesInUse} />
              </Flex>
            </ErrorBoundary>

            <ErrorBoundary>
              <Heading as="h2" mb="3">
                User list:
              </Heading>

              <UserList
                currentUser={user}
                users={companyMembers || []}
                fetchingMembers={fetchingMembersStatus === 'loading'}
                // Handled above
                error={undefined}
                actionsBackgroundColour="#f6f6f6"
                onFetchMembers={refetchMembers}
                onRemoveMember={async (member: ApiUser) => {
                  try {
                    await companyApi.removeCompanyMember(company.id, member.id);
                    await refetchMembers();
                    toast.success('The user has been removed from the team');
                  } catch (e) {
                    toast.error('Something went wrong whilst removing the user');
                    logger.logError(e);
                  }
                }}
              />
            </ErrorBoundary>

            <ErrorBoundary>
              <h2>Invite list:</h2>

              <CompanyInviteList
                invites={companyInvites || []}
                fetchingInvites={fetchingInvitesStatus === 'loading'}
                actionsBackgroundColour="#f6f6f6"
                error={fetchingInvitesError ? fetchingInvitesError.message : undefined}
                renderActions={(invite) => {
                  return <RevokeAction invite={invite} onFetchInvites={refetchInvites} />;
                }}
              />

              <Flex sx={{ mt: 5 }}>
                <InviteUserButton
                  disabled={
                    companyMembers ? companyMembers.length >= companySubscription.seats : true
                  }
                  onInviteSent={() => {
                    toast.success('Invite sent successfully!');
                    refetchInvites();
                  }}
                />
              </Flex>
            </ErrorBoundary>

            <ErrorBoundary>
              <h2>Expired invite list:</h2>

              <CompanyInviteList
                invites={companyExpiredInvites || []}
                fetchingInvites={fetchingExpiredInvitesStatus === 'loading'}
                actionsBackgroundColour="#f6f6f6"
                error={
                  fetchingExpiredInvitesError ? fetchingExpiredInvitesError.message : undefined
                }
                renderActions={(invite) => {
                  return (
                    <Flex>
                      <Box sx={{ mr: 3 }}>
                        <ResendAction
                          invite={invite}
                          canResend={
                            licencesInUse
                              ? licencesInUse >= company.companySubscription.seats
                              : true
                          }
                          onRefresh={async () => {
                            await Promise.all([refetchInvites(), refetchExpiredInvites()]);
                          }}
                        />
                      </Box>
                      <RevokeAction invite={invite} onFetchInvites={refetchExpiredInvites} />
                    </Flex>
                  );
                }}
              />
            </ErrorBoundary>
          </Flex>
        </Flex>
      </Box>
    </ScrollToTop>
  );
};
