import * as React from 'react';

import { analytics } from '../analytics/analytics';
import { invitationApi } from '../api/invitation';
import { useUser } from '../context/UserContext';
import { UserFacingError } from '../Error/BaseErrors';
import { useToggle } from '../hooks/useToggle';
import logger from '../utils/logger';
import { ColouredTooltip } from './ColouredTooltip';
import { Box } from './common/Box';
import { Button } from './common/Button';
import { Plus } from './Icon/Plus';
import { FormMode, UserDetailsModal } from './Modal/UserDetailsModal';

interface InviteUserButtonProps {
  disabled?: boolean;
  onInviteSent: () => void;
}

export const InviteUserButton: React.FC<InviteUserButtonProps> = ({
  disabled = false,
  onInviteSent,
}) => {
  const user = useUser();

  const { on, toggle, set } = useToggle();

  return (
    <React.Fragment>
      <ColouredTooltip
        label="Upgrade your plan to invite new users"
        sx={{ bg: 'accent' }}
        isVisible={disabled}
      >
        <Box>
          <Button
            type="button"
            variant={disabled ? 'disabled' : 'primary'}
            sx={{ fontWeight: 'semibold', fontSize: 2, letterSpacing: '-0.01rem' }}
            disabled={disabled}
            onClick={toggle}
          >
            Invite A New User{' '}
            <Plus
              style={{
                fontSize: '0.7rem',
                paddingLeft: '0.25rem',
              }}
            />
          </Button>
        </Box>
      </ColouredTooltip>

      <UserDetailsModal
        isOpen={on}
        title="Add a new user."
        cancelable
        confirmationTitle={
          <span>
            Add new user &amp; send invite email{' '}
            <Plus
              style={{
                fontSize: '0.7rem',
                paddingLeft: '0.25rem',
              }}
            />
          </span>
        }
        formMode={FormMode.Create}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          setSubmitting(true);

          try {
            const data = {
              ...values,
              authorityLevel: values.authorityLevel.value,
              isTeamOwner: `${Boolean(values.isTeamOwner.value)}`,
            };

            await invitationApi.sendInvite(user.company.id, data);
            set(false);
            analytics.newUserInvited();
            onInviteSent();
          } catch (e) {
            if (e instanceof UserFacingError) {
              setFieldError('email', e.message);
              return;
            }

            logger.logError(e);
            setFieldError('email', 'Something went wrong whilst sending the invite');
          }

          setSubmitting(false);
        }}
        onClose={() => set(false)}
      />
    </React.Fragment>
  );
};
