import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface IReCaptchaFieldComponentProps {
  onChange: (token: string | null) => void;
}

export const ReCaptchaFieldComponent: React.FC<IReCaptchaFieldComponentProps> = ({ onChange }) => {
  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

  return <ReCAPTCHA sitekey={reCaptchaKey} onChange={(value) => onChange(value || '')} />;
};
