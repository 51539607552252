import styled from '@emotion/styled/macro';

interface SelectedItemContainerProps {
  backgroundColor: string;
  borderColor: string;
}

export const SelectedItemContainer = styled('div')<SelectedItemContainerProps>(
  {
    display: 'inline-block',
    fontSize: '0.8rem',
    padding: '0.5rem',
    marginRight: '0.2rem',
    marginBottom: '0.2rem',
    border: '2px solid',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  ({ backgroundColor, borderColor }) => ({
    backgroundColor,
    borderColor,
  })
);

export const SelectedItemLabel = styled.span`
  font-size: 0.8rem;
  margin-right: 0.2rem;
  padding: 0.4rem 0.5rem;
`;

export const RemoveButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;
