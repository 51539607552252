import axios from 'axios';
import * as React from 'react';
import { toast } from 'react-toastify';

import { invitationApi } from '../api/invitation';
import logger from '../utils/logger';
import { Button } from './common/Button';

interface UploadBatchUserInvitesButtonProps {
  disabled?: boolean;
  companyId: number;
  onFileUploaded: () => void;
}

export const UploadBatchUserInvitesButton: React.FC<UploadBatchUserInvitesButtonProps> = ({
  disabled = false,
  companyId,
  onFileUploaded,
}) => {
  const fileInputRef = React.useRef<any>();

  const resetFileField = React.useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, []);

  const onFileChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    async (e) => {
      const files = e.currentTarget.files;

      if (!files) {
        toast.error('No file found');
        resetFileField();
        return;
      }

      if (files.length > 1) {
        toast.error('Only one file can be uploaded at a time');
        resetFileField();
        return;
      }

      try {
        await invitationApi.sendBatchInvite(companyId, files[0]);
        onFileUploaded();
      } catch (e: any) {
        if (axios.isAxiosError(e) && e.response?.data.error) {
          toast.error(e.response?.data.error);
        } else {
          toast.error('Something went wrong whilst processing the invites');
        }

        logger.logError(e);
      }

      resetFileField();
    },
    [companyId, resetFileField, onFileUploaded]
  );

  return (
    <React.Fragment>
      <input ref={fileInputRef} type="file" hidden onChange={onFileChange} />

      <Button
        type="button"
        variant={disabled ? 'disabled' : 'primary'}
        sx={{ fontWeight: 'semibold', fontSize: 2, letterSpacing: '-0.01rem' }}
        disabled={disabled}
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        }}
      >
        Upload Invites
      </Button>
    </React.Fragment>
  );
};
