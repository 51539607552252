import { useQuery } from 'react-query';

import { client } from './client';

export interface ApiTeamOverview {
  id: number;
  name: string;
  userCount: number;
  advertCount: number;
}

interface ApiTeamOverviewAdvertCounts {
  [teamId: number]: {
    name: string;
    count: number;
  };
}

export interface ApiTeamUserOverview {
  id: number;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  isCompanyOwner: boolean;
  isTeamOwner: boolean;
  advertCount: number;
  advertCountsPerTeam: ApiTeamOverviewAdvertCounts;
}

export interface ApiTeam extends ApiTeamOverview {
  teamUsers: ApiTeamUserOverview[];
  teamAdverts: {
    id: number;
    jobTitle: string;
    companyName: string;
    createdByName: string;
  }[];
  teamCompanies: ApiTeamAdvertCompany[];
}

export interface ApiTeamAdvertCompany {
  id: number;
  rootId: number;
  name: string;
  data: ApiTeamAdvertCompanyData;
}

export interface ApiTeamAdvertCompanyData {
  [key: string]: string;
}

const getTeams = async (): Promise<{ teams: ApiTeam[] }> => {
  const response = await client.get('/team');

  return {
    teams: response.data.teams,
  };
};

const useTeamsQuery = () => {
  return useQuery('teams', getTeams);
};

const getTeam = async (id: number): Promise<{ team: ApiTeam }> => {
  const response = await client.get(`/team/${id}`);

  return {
    team: response.data.team,
  };
};

export interface TeamData {
  name: string;
}

const createTeam = async (data: TeamData): Promise<{ team: ApiTeam }> => {
  const response = await client.post('/team', data);

  return {
    team: response.data.team,
  };
};

const updateTeam = async (id: number, data: TeamData): Promise<{ team: ApiTeam }> => {
  const response = await client.post(`/team/${id}`, data);

  return {
    team: response.data.team,
  };
};

const transferTeam = async (id: number, transferToTeam: number): Promise<{ team: ApiTeam }> => {
  const response = await client.post(`/team/${id}/transfer`, { transferToTeam });

  return {
    team: response.data.team,
  };
};

const addUserToTeam = async (
  userId: number,
  teamId: number
): Promise<{ team: ApiTeam; fromTeamId: number; toTeamId: number; userId: number }> => {
  const response = await client.post(`/team/${teamId}/users/${userId}`);

  return {
    team: response.data.team,
    fromTeamId: response.data.fromTeamId,
    toTeamId: response.data.toTeamId,
    userId: response.data.userId,
  };
};

const updateTeamAdvertCompanyData = async (
  teamId: number,
  advertCompanyId: number,
  data: { [key: string]: string }
) => {
  const response = await client.post(`/team/${teamId}/listing-company/${advertCompanyId}`, {
    data: JSON.stringify(data),
  });

  return {
    updated: response.data.updated,
  };
};

export const teamApi = {
  getTeams,
  getTeam,
  createTeam,
  updateTeam,
  transferTeam,
  addUserToTeam,
  updateTeamAdvertCompanyData,

  useTeamsQuery,
};
