import { Redirect, RouteComponentProps } from '@reach/router';
import * as React from 'react';

import { AuthConsumer } from '../../context/AuthContext';

interface CompanyOwnerRouteProps extends RouteComponentProps {
  component?: any;
}

export const CompanyOwnerRoute: React.FC<CompanyOwnerRouteProps> = ({ children }) => {
  return (
    <AuthConsumer>
      {({ user }) =>
        user && user.isCompanyOwner ? (
          children
        ) : (
          <Redirect path="" from="" to="/dashboard" noThrow />
        )
      }
    </AuthConsumer>
  );
};
