import * as React from 'react';

import { Button } from '../common/Button';
import { Flex } from '../common/Flex';
import { Heading } from '../common/Heading';
import { Text, TextProps } from '../common/Text';

export const Overlay: React.FC = (props) => (
  <Flex
    sx={{
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      bg: 'rgba(32,51,51,0.85)',
    }}
    {...props}
  />
);

export const OverlayContent: React.FC = (props) => (
  <Flex
    sx={{
      flex: '1',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      maxWidth: '750px',
      color: 'white',
    }}
    {...props}
  />
);

export const OverlayTitle: React.FC = (props) => (
  <Heading
    as="h1"
    sx={{
      color: 'white',
      fontSize: '8',
      fontWeight: 500,
      mt: '0',
      mb: '5',
      textAlign: 'center',
      letterSpacing: '-0.1rem',
    }}
    {...props}
  />
);

export const OverlayText: React.FC<React.PropsWithoutRef<TextProps>> = ({ sx, ...props }) => (
  <Text
    sx={{
      fontSize: '2',
      textAlign: 'center',
      mt: '0',
      mb: '3.5rem',
      color: 'whitefade80',
      ...sx,
    }}
    {...props}
  />
);

export const OverlayAction: React.FC<any> = (props) => (
  <Button
    variant="primaryInverted"
    px="7"
    py="4"
    sx={{ textDecoration: 'none', fontWeight: 500, letterSpacing: '-0.01rem' }}
    {...props}
  />
);
