import { Location, WindowLocation } from '@reach/router';
import { useEffect } from 'react';
import * as React from 'react';

import { analytics } from '../../analytics/analytics';
import logger from '../../utils/logger';

export const LocationChangeObserver = () => {
  return (
    <Location>
      {({ location }) => {
        return <LocationChangeSubscriber location={location} />;
      }}
    </Location>
  );
};

interface LocationChangeSubscriberProps {
  location: WindowLocation;
}

const LocationChangeSubscriber: React.FC<LocationChangeSubscriberProps> = ({ location }) => {
  const { href, pathname } = location;

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      return;
    }

    logger.addBreadcrumb(href, pathname);
    analytics.pageView();
  }, [href, pathname]);

  return null;
};
