import { ReactNode, useCallback, useState } from 'react';
import * as React from 'react';

import { Box } from '../../../components/common/Box';
import { Label } from '../../../components/common/form';
import useTimeout from '../../../hooks/useTimeout';
import { useAdvertBuilder } from '../AdvertBuilderContext';
import { SlideIn } from './SlideIn';

interface QuestionProps {
  number: string | number;
  name: string;
  label: string | ReactNode;
}

export const Question: React.FC<QuestionProps> = (props) => {
  const state = useAdvertBuilder();

  return state ? <AnimatedQuestion {...props} /> : <RawQuestion {...props} />;
};

const AnimatedQuestion: React.FC<QuestionProps> = (props) => {
  const { navigationDirection } = useAdvertBuilder();
  const [slideInActive, setSlideInActive] = useState(false);

  const position =
    (typeof props.number === 'string' ? parseInt(props.number, 10) : props.number) - 1;
  const slideInDelay = position === 0 ? 0 : (position / 5) * 1000;

  const slideIn = useCallback(() => {
    setSlideInActive(true);
  }, []);

  useTimeout(slideIn, slideInDelay);

  return (
    <SlideIn active={slideInActive} direction={navigationDirection}>
      <RawQuestion {...props} />
    </SlideIn>
  );
};

const RawQuestion: React.FC<QuestionProps> = (props) => {
  const labelProps = {
    sx: { display: 'block', pb: 5, width: '100%' },
    htmlFor: props.name,
  };

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '3rem auto' }}>
      <Box sx={{ color: 'accent', fontWeight: 600, textAlign: 'left' }}>#{props.number}.</Box>
      <Box>
        {typeof props.label === 'string' ? (
          <Label {...labelProps} dangerouslySetInnerHTML={{ __html: props.label }} />
        ) : (
          <Label {...labelProps}>{props.label}</Label>
        )}
        {props.children}
      </Box>
    </Box>
  );
};
