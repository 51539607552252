import { useCallback } from 'react';

import { advertApi } from '../../../api/advert';

interface UsePrefetchAdvertDataVariables {
  advertId: number;
  isComplete: boolean;
}

export const usePrefetchAdvertData = (variables: UsePrefetchAdvertDataVariables) => {
  const prefetchAdvert = advertApi.usePrefetchAdvertFn(variables.advertId);
  const prefetchAdvertVariations = advertApi.usePrefetchAdvertVariationsFn(variables.advertId);

  const prefetchAdvertData = useCallback(() => {
    prefetchAdvert();

    if (variables.isComplete) {
      prefetchAdvertVariations();
    }
  }, [variables.isComplete, prefetchAdvert, prefetchAdvertVariations]);

  return prefetchAdvertData;
};
