import { navigate } from '@reach/router';
import qs from 'qs';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '../../context/AuthContext';
import { AuthUtils } from '../../utils/auth/auth-utils';

const OAUTH_RESPONSE_TYPE = 'oauth_response';

export const OAuthResponseHandler: React.FC = () => {
  const { refreshUser } = useAuth();

  useEffect(() => {
    const { type, token, error } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if (!type || typeof type !== 'string' || type !== OAUTH_RESPONSE_TYPE) {
      return;
    }

    if (error) {
      const errorMessage =
        typeof error !== 'undefined' ? error : 'Something went wrong whilst authenticating';
      toast.error(errorMessage);
      return;
    }

    if (!token || typeof token !== 'string') {
      return;
    }

    AuthUtils.setApiKey(token);
    refreshUser();
    navigate(window.location.pathname, { replace: true });
  }, [refreshUser]);

  return null;
};
