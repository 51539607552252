import styled from '@emotion/styled/macro';
import * as React from 'react';

import { Box } from '../../components/common/Box';
import { Flex } from '../../components/common/Flex';
import { Heading } from '../../components/common/Heading';

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 16rem;
  padding-left: ${(props) => props.theme.space[3]};
  padding-right: ${(props) => props.theme.space[3]};
  background: ${(props) => props.theme.backgrounds.header.background};
  background-size: ${(props) => props.theme.backgrounds.header.backgroundSize};
`;

export const SubscriptionPageHeader: React.FC<{ title: string; subTitle?: React.ReactNode }> = ({
  title,
  subTitle,
}) => (
  <Container>
    <Heading as="h1" variant="pageHeader" sx={{ pt: '6rem', pb: subTitle ? '4' : '0' }}>
      {title}
    </Heading>
    <Box sx={{ maxWidth: '750px' }}>
      <Heading
        as="h2"
        sx={{
          color: 'textDim',
          fontSize: '2',
          fontWeight: 400,
          m: '0',
          pt: 3,
          pb: '5.625rem',
          textAlign: 'center',
          letterSpacing: '-0.01rem',
          lineHeight: 1.6875,
        }}
      >
        {subTitle}
      </Heading>
    </Box>
  </Container>
);
