import * as React from 'react';

type onToggleCallback = (state: ToggleState) => void;

interface ToggleProps {
  defaultOn?: boolean;
  onToggleCallback?: onToggleCallback;
  children: ({
    on,
    onToggle,
    setToggleValue,
  }: {
    on: boolean;
    onToggle: (cb?: onToggleCallback) => void;
    setToggleValue: (value: boolean, cb?: onToggleCallback) => void;
  }) => React.ReactElement<any>;
}

interface ToggleState {
  on: boolean;
}

export class Toggle extends React.Component<ToggleProps, ToggleState> {
  state = {
    on: this.props.defaultOn || false,
  };

  handleToggle = (cb?: onToggleCallback) => {
    this.setToggleValue(!this.state.on, cb);
  };

  setToggleValue = (newValue: boolean, cb?: onToggleCallback) => {
    this.setState({ on: newValue }, () => {
      this.props.onToggleCallback && this.props.onToggleCallback(this.state);
    });
  };

  render() {
    return this.props.children({
      on: this.state.on,
      onToggle: this.handleToggle,
      setToggleValue: this.setToggleValue,
    });
  }
}
