import styled from '@emotion/styled/macro';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as React from 'react';

import { analytics } from '../analytics/analytics';
import { Advert, advertApi, getRemunerationFrequencyLabel } from '../api/advert';
import { FavouriteVariationButton } from '../screens/AdvertBuilder/FavouriteVariationButton';
import { Box } from './common/Box';
import { Flex } from './common/Flex';
import { Heading } from './common/Heading';
import { Modal, ModalClose } from './common/Modal';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from './common/Tabs';
import { Text } from './common/Text';
import { ErrorBoundary } from './ErrorBoundary';
import { QuickViewVariation } from './QuickViewVariation';

interface QuickViewAdvertModalProps {
  isOpen: boolean;
  initialVariationId?: number;
  advert: Advert;
  onClose: () => void;
  onFavourite?: (variationId: number) => Promise<void>;
  onUnfavourite?: () => Promise<void>;
}

export const QuickViewAdvertModal: React.FC<QuickViewAdvertModalProps> = ({
  isOpen,
  initialVariationId,
  advert,
  onClose,
  onFavourite,
  onUnfavourite,
}) => {
  const [activeFavourite, setActiveFavourite] = useState<number | undefined>(undefined);

  const { status: fetchVariationsStatus, data } = advertApi.useAdvertVariationsQuery(advert.id);

  useEffect(() => {
    if (isOpen) {
      analytics.quickViewModalOpened(advert.id);
    }
  }, [advert.id, isOpen]);

  useEffect(() => {
    if (fetchVariationsStatus === 'success' && data && data.variations) {
      const active = data.variations.find((variation) => variation.isFavourited);
      setActiveFavourite(active ? active.id : undefined);
    }
  }, [fetchVariationsStatus, data, setActiveFavourite]);

  const formats = useRef(['bold', 'italic', 'underline', 'list']);
  const modules = useRef({ toolbar: { container: [] } });

  const isFavouriteButtonVisible =
    typeof onFavourite !== 'undefined' && typeof onUnfavourite !== 'undefined';

  const handleFavouriteAdvert = async (variationId: number) => {
    if (onFavourite) {
      await onFavourite(variationId);
      setActiveFavourite(variationId);
    }
  };

  const handleUnfavouriteAdvert = async () => {
    if (onUnfavourite) {
      await onUnfavourite();
      setActiveFavourite(undefined);
    }
  };

  const initialTabIndex = useMemo(() => {
    if (!data || !data.variations) {
      return 0;
    }

    const matchedIndex = data.variations.findIndex((variation) =>
      initialVariationId ? variation.id === initialVariationId : variation.isFavourited
    );

    return Math.max(0, matchedIndex);
  }, [initialVariationId, data]);

  let summaryFields = [advert.advertCompany.name, advert.jobLocation];

  if (advert.formattedSalary) {
    summaryFields.push(
      `${advert.formattedSalary} per ${getRemunerationFrequencyLabel(advert.salary?.frequency)}`
    );
  }

  const summary = summaryFields.filter(Boolean).join(', ');

  return (
    <Modal
      aria-label="Quick view advert"
      style={{ maxWidth: '1000px', width: '90vw', minHeight: '300px' }}
      contentWrapperStyles={{ outline: 'rgba(255, 255, 255, 0.4) solid 10px' }}
      isOpen={isOpen}
      variant="neutral"
      data-testid="quick-view-modal"
      onDismiss={onClose}
    >
      <ModalClose style={{ color: 'white' }} onClick={onClose} />

      <ErrorBoundary>
        <Box className="quick-view-modal">
          <AdvertSummary>
            <Title>{advert.jobTitle}</Title>
            <Description>{summary}</Description>
          </AdvertSummary>

          {fetchVariationsStatus === 'loading' ? (
            <Flex sx={{ justifyContent: 'center', alignItems: 'center', py: 5 }}>Loading</Flex>
          ) : fetchVariationsStatus === 'error' || !data ? (
            <Flex sx={{ justifyContent: 'center', alignItems: 'center', py: 5 }}>Error</Flex>
          ) : (
            <React.Fragment>
              {data.variations && (
                <React.Fragment>
                  <Flex sx={{ justifyContent: 'center', width: '100%' }}>
                    <Flex sx={{ flexDirection: 'column', width: '100%' }}>
                      <Tabs defaultIndex={initialTabIndex}>
                        <TabList sx={{ border: 'none' }}>
                          {data.variations.map((variation) => (
                            <Tab key={variation.templateId}>
                              <Box sx={{ position: 'relative' }}>
                                {variation.name}
                                <Box sx={{ position: 'absolute', top: 0, right: '1rem' }}>
                                  {isFavouriteButtonVisible && (
                                    <FavouriteVariationButton
                                      initialValue={variation.id === activeFavourite}
                                      onFavourite={async () =>
                                        await handleFavouriteAdvert(variation.id)
                                      }
                                      onUnfavourite={async () => await handleUnfavouriteAdvert()}
                                    />
                                  )}
                                </Box>
                              </Box>
                            </Tab>
                          ))}
                        </TabList>

                        <TabPanels>
                          {data.variations.map((variation, index) => (
                            <TabPanel key={variation.templateId} data-testid={`variation-${index}`}>
                              <QuickViewVariation
                                variation={variation}
                                formats={formats}
                                modules={modules}
                                advert={advert}
                              />
                            </TabPanel>
                          ))}
                        </TabPanels>
                      </Tabs>
                    </Flex>
                  </Flex>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Box>
      </ErrorBoundary>
    </Modal>
  );
};

const AdvertSummary = styled(Box)`
  background: ${(props) => props.theme.backgrounds.header.background};
  background-size: ${(props) => props.theme.backgrounds.header.backgroundSize};
`;

const Title = styled(Heading)`
  font-size: 2.25rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
  padding: 1.5rem 0 1rem 1.5rem;
  letter-spacing: -0.065rem;
  line-height: 1.2;
`;
const Description = styled(Text)`
  font-size: 1rem;
  font-weight: 400;
  color: rgb(200, 200, 200);
  padding: 0 0 1.5rem 1.5rem;
  letter-spacing: -0.01rem;
  line-height: 1.2;
`;
