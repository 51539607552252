import { useFormikContext } from 'formik';

import { InvalidFormBanner } from '../InvalidFormBanner';

export const FormAwareInvalidFormBanner = () => {
  const { submitCount, errors } = useFormikContext();

  if (submitCount === 0 || Object.keys(errors).length === 0) {
    return null;
  }

  return <InvalidFormBanner errors={errors} />;
};
