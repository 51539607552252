import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import { Link as RRLink, useNavigate } from '@reach/router';
import * as React from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

import { Flex } from '../../../components/common/Flex';
import { Hide } from '../../../components/common/Hide';
import { Link } from '../../../components/common/Link';
import { Rotate } from '../../../components/Headless/Rotate';
import { ChevronDown } from '../../../components/Icon';
import { usePrefetchAdvertData } from '.';

interface ActionMenuCellProps {
  advertId: number;
  path: string;
  isComplete: boolean;
  noEditCompleteAds: boolean;
  onQuickView: () => void;
}

export const ActionMenuCell: React.FC<ActionMenuCellProps> = (props) => {
  const navigate = useNavigate();
  const prefetchAdvertData = usePrefetchAdvertData({
    advertId: props.advertId,
    isComplete: props.isComplete,
  });

  const linkText = props.isComplete ? (props.noEditCompleteAds ? 'View' : 'Edit') : 'Continue';
  const buttonColor = props.isComplete ? 'primary' : 'accent';

  return (
    <Flex
      sx={{
        alignItems: 'center',
        height: '2.25rem',
        ml: '0.2rem',
        borderRadius: '4px',
        fontWeight: 500,
        border: `2px solid ${buttonColor}`,
      }}
    >
      {!(props.isComplete && props.noEditCompleteAds) ? (
        <Link
          as={RRLink}
          to={props.path}
          sx={getActionStyle(buttonColor)}
          onMouseEnter={prefetchAdvertData}
        >
          {linkText}
        </Link>
      ) : (
        <Box
          sx={getActionStyle(buttonColor)}
          onMouseEnter={prefetchAdvertData}
          onClick={props.onQuickView}
        >
          View
        </Box>
      )}

      <Menu>
        {({ isOpen }: { isOpen: boolean }) => (
          <React.Fragment>
            <Flex
              as={MenuButton}
              aria-label="View advert actions"
              data-testid="advert-actions-menu-toggle"
              sx={{
                height: '2rem',
                width: '2rem',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px',
                border: '2px solid',
                transition: '200ms all ease-in-out',
                borderColor: buttonColor,
                color: buttonColor,
                '&:hover': {
                  backgroundColor: buttonColor,
                  borderColor: buttonColor,
                  color: 'white',
                },
                '@media (max-width: 1100px)': {
                  width: '100%',
                  borderRadius: '4px',
                  minWidth: '2rem',
                },
              }}
            >
              <Rotate active={isOpen}>
                <ChevronDown />
              </Rotate>
            </Flex>
            <MenuList data-testid={`advert-actions-menu-${props.advertId}`}>
              <Hide lg xl>
                <MenuItem
                  key="open"
                  data-testid="advert-actions-open"
                  onSelect={
                    props.noEditCompleteAds ? props.onQuickView : () => navigate(props.path)
                  }
                >
                  {linkText}
                </MenuItem>
              </Hide>

              {props.children}
            </MenuList>
          </React.Fragment>
        )}
      </Menu>
    </Flex>
  );
};

const getActionStyle = (buttonColor: string): ThemeUIStyleObject => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  height: '2rem',
  fontSize: 1,
  fontWeight: 500,
  textDecoration: 'none',
  color: buttonColor,
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  border: '2px solid',
  borderRight: 'none',
  borderColor: buttonColor,
  px: 3,
  minWidth: '5.8rem',
  transition: '200ms all ease-in-out',
  '&:hover': {
    color: 'white',
    backgroundColor: buttonColor,
  },
  '@media (max-width: 1100px)': {
    display: 'none',
  },
  cursor: 'pointer',
  userSelect: 'none',
});
