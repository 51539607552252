import Downshift, { DownshiftProps } from 'downshift';
import { useState } from 'react';
import * as React from 'react';

const ADD_NEW_ITEM_ID = '__MultiSelect__:add_new_item';

interface MultiSelectProps extends DownshiftProps<any> {
  children?: (options: any) => React.ReactElement<any> | null;
  initialSelectedItem: any[];
  itemToString: (item: any) => string;
  onChange: (items: any[]) => void;
  onCreateNewItem?: (name: string) => Promise<any>;
}

export const MultiSelect: React.FC<MultiSelectProps> = (props) => {
  const { children, initialSelectedItem, onChange, onCreateNewItem, ...rest } = props;

  const [selectedItems, setSelectedItems] = useState(initialSelectedItem as any[]);

  const removeItem = (item: any) => {
    const items = selectedItems.filter((i) => i.id !== item.id);
    setSelectedItems(items);
    onChange(items);
  };

  const addSelectedItem = (item: any) => {
    const items = [...selectedItems, item];
    setSelectedItems(items);
    onChange(items);
  };

  const getRemoveButtonProps = ({ onClick, item, ...props }: any = {}) => {
    return {
      onClick: (e: any) => {
        onClick && onClick(e);
        e.stopPropagation();
        removeItem(item);
      },
      ...props,
    };
  };

  const addNewItem = async (name: string) => {
    if (!onCreateNewItem) {
      throw new Error(
        'Attempted to the add a new item but the onCreateNewItem prop was not provided'
      );
    }

    const item = await onCreateNewItem(name);
    addSelectedItem(item);
  };

  const getStateAndHelpers = (downshift: any) => {
    return {
      getRemoveButtonProps,
      removeItem,
      addSelectedItem,
      selectedItems,
      newItemId: ADD_NEW_ITEM_ID,
      addNewItem,
      ...downshift,
    };
  };

  return (
    <Downshift
      {...rest}
      onChange={(selectedItem: any) => {
        if (selectedItem.id === ADD_NEW_ITEM_ID) {
          addNewItem(selectedItem.name);
          return;
        }

        if (selectedItems.findIndex((i: any) => i.id === selectedItem.id) !== -1) {
          removeItem(selectedItem);
        } else {
          addSelectedItem(selectedItem);
        }
      }}
      stateReducer={(state, changes) => {
        switch (changes.type) {
          case Downshift.stateChangeTypes.keyDownEnter:
          case Downshift.stateChangeTypes.clickItem:
            return {
              ...changes,
              highlightedIndex: state.highlightedIndex,
              isOpen: true,
              inputValue: '',
            };
          default:
            return changes;
        }
      }}
      selectedItem={null}
    >
      {(downshift: any) => children && children(getStateAndHelpers(downshift))}
    </Downshift>
  );
};
