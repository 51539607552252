import { BusinessType } from './auth';
import { client } from './client';

export enum QuestionType {
  ComboboxTextarea = 1,
  Collection = 2,
  Boolean = 3,
  Selection = 4,
  Text = 5,
  Combobox = 6,
  MultiSelect = 7,
  Remuneration = 8,
  Select = 9,
}

export const getQuestionTypeLabel = (id: number) => {
  switch (id) {
    case QuestionType.ComboboxTextarea:
      return 'Text';
    case QuestionType.Collection:
      return 'Collection';
    case QuestionType.Boolean:
      return 'Boolean';
    case QuestionType.Selection:
      return 'Selection';
    default:
      return 'Unknown';
  }
};

export const getAllQuestionTypes = () => {
  return [
    {
      id: QuestionType.ComboboxTextarea,
      label: getQuestionTypeLabel(QuestionType.ComboboxTextarea),
    },
    { id: QuestionType.Collection, label: getQuestionTypeLabel(QuestionType.Collection) },
    { id: QuestionType.Boolean, label: getQuestionTypeLabel(QuestionType.Boolean) },
  ];
};

export enum QuestionAggregationLevel {
  User = 1,
  Team = 2,
  Company = 3,
}

export const getQuestionAggregationLevelLabel = (id: number) => {
  switch (id) {
    case QuestionAggregationLevel.User:
      return 'User';
    case QuestionAggregationLevel.Team:
      return 'Team';
    case QuestionAggregationLevel.Company:
      return 'Company';
    default:
      return 'Unknown';
  }
};

export const getAllQuestionAggregationLevels = () => {
  return [
    {
      id: QuestionAggregationLevel.User,
      label: getQuestionAggregationLevelLabel(QuestionAggregationLevel.User),
    },
    {
      id: QuestionAggregationLevel.Team,
      label: getQuestionAggregationLevelLabel(QuestionAggregationLevel.Team),
    },
    {
      id: QuestionAggregationLevel.Company,
      label: getQuestionAggregationLevelLabel(QuestionAggregationLevel.Company),
    },
  ];
};

export enum QuestionValidationRuleType {
  REQUIRED = 1,
  STARTS_WITH_A_OR_AN = 2,
  STARTS_WITH_RESPONSIBLE_FOR = 3,
  STARTS_WITH_WE_RE = 4,
  STARTS_WITH_THEY_ARE = 5,
  STARTS_WITH_A_OR_AN_OR_THE = 6,
  FIRST_WORD_STARTS_WITH_ING = 7,
  STARTS_WITH_THE_COMPANY_OR_THEIR = 8,
  STARTS_WITH_WE_OUR_OR_THE_COMPANY = 9,
  FOUR_DIGIT_NUMBER = 10,
}

export const getQuestionRuleLabel = (id: number) => {
  switch (id) {
    case QuestionValidationRuleType.REQUIRED:
      return 'Required';
    case QuestionValidationRuleType.STARTS_WITH_A_OR_AN:
      return 'Starts with a or an';
    case QuestionValidationRuleType.STARTS_WITH_RESPONSIBLE_FOR:
      return 'Starts with responsible for';
    case QuestionValidationRuleType.STARTS_WITH_WE_RE:
      return "Starts with we are or we're";
    case QuestionValidationRuleType.STARTS_WITH_THEY_ARE:
      return "Starts with they are or they're";
    case QuestionValidationRuleType.STARTS_WITH_A_OR_AN_OR_THE:
      return 'Starts with a, an, or the';
    case QuestionValidationRuleType.FIRST_WORD_STARTS_WITH_ING:
      return 'First word ends in ing';
    case QuestionValidationRuleType.STARTS_WITH_THE_COMPANY_OR_THEIR:
      return `Starts with the company, the company's, or their`;
    case QuestionValidationRuleType.STARTS_WITH_WE_OUR_OR_THE_COMPANY:
      return `Starts with the we, our, or the company`;
    case QuestionValidationRuleType.FOUR_DIGIT_NUMBER:
      return `Four digit number`;
    default:
      return 'Unknown';
  }
};

export const getAllQuestionRules = () => {
  const rules = [
    QuestionValidationRuleType.REQUIRED,
    QuestionValidationRuleType.STARTS_WITH_A_OR_AN,
    QuestionValidationRuleType.STARTS_WITH_RESPONSIBLE_FOR,
    QuestionValidationRuleType.STARTS_WITH_WE_RE,
    QuestionValidationRuleType.STARTS_WITH_THEY_ARE,
    QuestionValidationRuleType.STARTS_WITH_A_OR_AN_OR_THE,
    QuestionValidationRuleType.FIRST_WORD_STARTS_WITH_ING,
    QuestionValidationRuleType.STARTS_WITH_THE_COMPANY_OR_THEIR,
    QuestionValidationRuleType.STARTS_WITH_WE_OUR_OR_THE_COMPANY,
    QuestionValidationRuleType.FOUR_DIGIT_NUMBER,
  ];

  return rules.map((rule) => ({
    id: rule,
    label: getQuestionRuleLabel(rule),
  }));
};

export interface ApiForm {
  id: number;
  createdAt: string;
  createdBy: {
    id: number;
    email: string;
  };
  defaultForm: boolean;
  candidateFormPage: FormPage;
  roleFormPage: FormPage;
  companyFormPage: FormPage;
  name: string;
  slug: string;
  description: string;
  advertCount?: number;
  advertFormVariationTemplates?: AdvertFormVariationTemplate[];
  formPageShortCodes: string[];
  businessType: BusinessType;
}

export interface Form extends Omit<ApiForm, 'createdAt' | 'createdBy'> {
  createdAt: Date;
  createdBy: {
    email: string;
  };
}

export interface FormPage {
  id: number;
  advertFormQuestions: Question[];
}

export function isFormPage(formPage: FormPage | undefined): formPage is FormPage {
  return (formPage as FormPage) !== undefined;
}

export interface Question {
  id: number;
  type: QuestionType;
  label: string;
  hint: string;
  placeholder: string;
  autoFillPrefix?: string;
  addRowTitle?: string;
  collectionDefaultRows?: number;
  shortCode: string;
  position: number;
  advertFormQuestionValidationRules: QuestionValidationRule[];
  exampleAnswers: { id: number; value: string }[];
  aggregationLevel: QuestionAggregationLevel;
}

export interface QuestionPredefinedAnswer {
  id: number;
  value: string;
  position: number;
}

export interface QuestionValidationRule {
  id: number;
  rule: QuestionValidationRuleType;
}

export interface AdvertFormVariationTemplate {
  id: number;
  name: string;
  template: string;
}

export const formatForm = (form: ApiForm) => ({
  ...form,
  createdAt: new Date(form.createdAt),
  createdBy: {
    email: form.createdBy.email,
  },
});

const fetchForm = async (formId: number): Promise<Form> => {
  const response = await client.get(`/form/${formId}`);

  return formatForm(response.data.form);
};

export const formApi = {
  fetchForm,
};
