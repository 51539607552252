import styled from '@emotion/styled/macro';
import { useMachine } from '@xstate/react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';
import { assign, Machine } from 'xstate';

import { Advert } from '../../api/advert';
import { Box } from '../../components/common/Box';
import { Button } from '../../components/common/Button';
import { Heading } from '../../components/common/Heading';
import { Tooltip } from '../../components/common/Tooltip';
import { CaretUp, Star } from '../../components/Icon';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { useTheme } from '../../hooks/useTheme';

const VariationListContainer = styled(Box)`
  position: absolute;
  top: -26px;
  left: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  width: 225px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 45.9%, 0.4), 0 2px 10px rgba(3, 18, 42, 0.15);
`;

const VariationListHeading = styled(Heading)`
  text-align: center;
  padding: 1rem 1.5rem;
  font-size: ${(props) => props.theme.fontSizes[2]};
  background-color: ${(props) => props.theme.colors.grey[0]};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 500;
`;

const VariationList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const VariationListItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #eeeeee;
  }
`;

const VariationListItemButton = styled(Button)`
  width: 100%;
  text-align: left;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  letter-spacing: -0.01rem;
  transition: all 200ms ease-in-out;
`;

const VariationListItemQuickViewLink = styled(Box)`
  padding: 0.75rem 2rem 0.75rem 0.75rem;
  color: ${(props) => props.theme.colors.accent};
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.8rem;

  &:hover {
    text-decoration: underline;
  }
`;

const VariationListConnector = styled.span`
  color: ${(props) => props.theme.colors.grey[0]};
`;

interface FavouriteMachineContext {
  isFavourited: boolean;
}

const favouriteMachine = Machine<FavouriteMachineContext>(
  {
    id: 'favourite',
    initial: 'unknown',
    context: {
      isFavourited: false,
    },
    states: {
      unknown: {
        always: [
          { target: 'favourited', cond: (context) => context && context.isFavourited },
          { target: 'unfavourited.openable', cond: (context) => context && !context.isFavourited },
        ],
      },
      unfavourited: {
        initial: 'delayed',
        states: {
          delayed: {
            on: {
              OPEN_MENU: 'delayed',
            },
            after: {
              100: 'openable',
            },
          },
          openable: {},
        },
        on: {
          OPEN_MENU: 'menu_open',
          SET_FAVOURITED: {
            target: 'favourited',
            actions: ['markAsFavourited'],
          },
        },
      },
      favourited: {
        on: {
          UNFAVOURITE: {
            target: 'unfavouriting',
            cond: 'canUnfavourite',
          },
          SET_UNFAVOURITED: {
            target: 'unfavourited',
            actions: ['markAsUnfavourited'],
          },
        },
      },
      menu_open: {
        on: {
          CLOSE_MENU: 'menu_closing',
          FAVOURITE: {
            target: 'favouriting',
            cond: 'canFavourite',
          },
        },
      },
      menu_closing: {
        always: [
          { target: 'favourited', cond: (context) => context && context.isFavourited },
          { target: 'unfavourited', cond: (context) => context && !context.isFavourited },
        ],
      },
      favouriting: {
        invoke: {
          src: 'favourite',
          onDone: {
            target: 'favourited',
            actions: ['markAsFavourited'],
          },
          onError: {
            target: 'failure',
          },
        },
        on: {
          UNFAVOURITE: 'unfavouriting',
        },
      },
      unfavouriting: {
        invoke: {
          src: 'unfavourite',
          onDone: {
            target: 'unfavourited',
            actions: ['markAsUnfavourited'],
          },
          onError: {
            target: 'failure',
          },
        },
        on: {
          FAVOURITE: 'favouriting',
        },
      },
      failure: {
        entry: ['notifyError'],
        always: [
          { target: 'favourited', cond: (context) => context && context.isFavourited },
          { target: 'unfavourited', cond: (context) => context && !context.isFavourited },
        ],
      },
    },
  },
  {
    guards: {
      canFavourite: (context: FavouriteMachineContext) => {
        return !context.isFavourited;
      },
      canUnfavourite: (context: FavouriteMachineContext) => {
        return context.isFavourited;
      },
    },
  }
);

interface FavouriteAdvertButtonProps {
  advert: Advert;
  onFavourite: (variationId: number) => Promise<void>;
  onUnfavourite: () => Promise<void>;
  onQuickView: (variationId: number) => void;
}

export const FavouriteAdvertButton: React.FC<FavouriteAdvertButtonProps> = ({
  advert,
  onFavourite,
  onUnfavourite,
  onQuickView,
  ...props
}) => {
  const theme = useTheme();
  const listRef = useRef();
  const [current, send] = useMachine(favouriteMachine, {
    context: {
      isFavourited: advert.isFavourited,
    },
    actions: {
      notifyError: (context) => {
        toast.error(
          `Encountered an issue whilst ${context.isFavourited ? 'unfavouriting' : 'favouriting'}`
        );
      },
      markAsFavourited: assign<FavouriteMachineContext>({
        isFavourited: () => true,
      }),
      markAsUnfavourited: assign<FavouriteMachineContext>({
        isFavourited: () => false,
      }),
    },
    services: {
      favourite: async (ctx: FavouriteMachineContext, event: any) => {
        onFavourite(event.variationId);
      },
      unfavourite: onUnfavourite,
    },
  });

  useEffect(() => {
    send(advert.isFavourited ? 'SET_FAVOURITED' : 'SET_UNFAVOURITED');
  }, [advert.isFavourited, current.value, send]);

  const closeMenu = useCallback(() => send('CLOSE_MENU'), [send]);

  useOnClickOutside(listRef, closeMenu);

  const { isFavourited } = current.context;

  const sortedVariations = useMemo(() => {
    return advert.advertVariations.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
  }, [advert.advertVariations]);

  return (
    <Box
      sx={{ position: 'relative' }}
      data-testid="favourite-variation-menu-toggle"
      aria-disabled={current.matches({ unfavourited: 'delayed' })}
      onClick={() => {
        if (current.matches('favourited')) {
          send('UNFAVOURITE');
          return;
        }

        send(
          current.matches('unfavourited') || current.matches('favourited')
            ? 'OPEN_MENU'
            : 'CLOSE_MENU'
        );
      }}
    >
      <Tooltip label={isFavourited ? 'Unfavourite this advert' : 'Add to favourites'}>
        <div>
          <Star
            {...props}
            key={`${advert.id}_${isFavourited}`}
            aria-label={isFavourited ? 'Unfavourite this advert' : 'Favourite this advert'}
            width="20"
            height="20"
            outlineColour={isFavourited ? theme.colors.accent : undefined}
            backgroundColour={isFavourited ? theme.colors.accent : 'transparent'}
            hoverColour={isFavourited ? theme.colors.accent : theme.colors.blue[3]}
          />
        </div>
      </Tooltip>

      {current.matches('menu_open') && (
        <VariationListContainer ref={listRef}>
          <Box>
            <VariationListHeading>
              Which advert version do you want to favourite?
            </VariationListHeading>
            <VariationList>
              {sortedVariations.map((variation) => (
                <VariationListItem key={variation.id}>
                  <VariationListItemButton
                    variant="text"
                    onClick={() => send('FAVOURITE', { variationId: variation.id })}
                  >
                    {variation.name}
                  </VariationListItemButton>
                  <VariationListItemQuickViewLink onClick={() => onQuickView(variation.id)}>
                    View
                  </VariationListItemQuickViewLink>
                </VariationListItem>
              ))}
            </VariationList>
          </Box>

          <Box
            sx={{
              position: 'absolute',
              top: '22px',
              left: '-8px',
              fontSize: '1.3rem',
            }}
          >
            <VariationListConnector>
              <CaretUp
                style={{
                  transform: 'rotate(-90deg)',
                  filter: 'drop-shadow(#bbbbbb 0px -1.5px 0px)',
                }}
              />
            </VariationListConnector>
          </Box>
        </VariationListContainer>
      )}
    </Box>
  );
};
