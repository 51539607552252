import { assign, createMachine } from 'xstate';

import { assertEventType } from '../../../utils/xstate-helpers';

interface CollectionInformation {
  id: string;
  label: string;
}

interface FilterCollectionListMachineEventContext {
  collectionToDelete?: CollectionInformation;
}

type FilterCollectionListMachineEvent =
  | {
      type: 'DELETE';
      data: {
        id: string;
        label: string;
      };
    }
  | {
      type: 'CONFIRM';
    }
  | {
      type: 'CANCEL';
    };

type FilterCollectionListMachineTypeState =
  | {
      value: 'idle';
      context: FilterCollectionListMachineEventContext;
    }
  | {
      value: 'confirming_delete';
      context: FilterCollectionListMachineEventContext & {
        collectionToDelete: CollectionInformation;
      };
    };

export const filterCollectionListMachine = createMachine<
  FilterCollectionListMachineEventContext,
  FilterCollectionListMachineEvent,
  FilterCollectionListMachineTypeState
>(
  {
    id: 'filter-collection-list',
    initial: 'idle',
    states: {
      idle: {
        on: {
          DELETE: {
            target: 'confirming_delete',
            actions: 'cacheCollectionData',
          },
        },
      },
      confirming_delete: {
        on: {
          CONFIRM: {
            target: 'idle',
            actions: 'deleteFilterCollection',
          },
          CANCEL: {
            target: 'idle',
          },
        },
      },
    },
  },
  {
    actions: {
      cacheCollectionData: assign({
        collectionToDelete: (ctx, ev) => {
          assertEventType(ev, 'DELETE');

          return ev.data;
        },
      }),
    },
  }
);
