import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AppTheme } from '../theme/appTheme';
import { ThemeProvider } from '../theme/ThemeProvider';
import { AuthProvider } from './AuthContext';
import { DripIntegrationDiscountFlowProvider } from './DripIntegrationDiscountFlowContext';
import { UserPreferencesProvider } from './UserPreferencesContext';
import { WindowSizeContextProvider } from './WindowSizeContext';

export const queryClient = new QueryClient();

export const AppProviders: React.FC = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <IntlProvider locale="en">
      <HelmetProvider>
        <WindowSizeContextProvider>
          <ThemeProvider theme={AppTheme as TSFixMe}>
            <AuthProvider>
              <DripIntegrationDiscountFlowProvider>
                <UserPreferencesProvider>{children}</UserPreferencesProvider>
              </DripIntegrationDiscountFlowProvider>
            </AuthProvider>
          </ThemeProvider>
        </WindowSizeContextProvider>
      </HelmetProvider>
    </IntlProvider>
  </QueryClientProvider>
);
