import { PropsWithoutRef } from 'react';
import * as React from 'react';
import { Assign, ForwardRef } from 'theme-ui';

import { Box, BoxOwnProps, BoxProps } from './Box';

export type LabelProps = Partial<
  PropsWithoutRef<BoxProps & Omit<React.HTMLProps<HTMLLabelElement>, 'as' | 'children'>>
>;

export const Label = React.forwardRef<any, LabelProps>((props, ref) => (
  <Box ref={ref} as="label" variant="label" {...props} __themeKey="forms" />
));

export interface TextFieldProps extends Assign<React.ComponentProps<'input'>, BoxOwnProps> {}

export const TextField: ForwardRef<HTMLInputElement, TextFieldProps> = React.forwardRef(
  (props, ref) => {
    return <Box ref={ref} as="input" variant="input" {...props} __themeKey="forms" />;
  }
);

export type ErrorMessageProps = Partial<PropsWithoutRef<BoxProps>>;

export const ErrorMessage = React.forwardRef<any, ErrorMessageProps>((props, ref) => (
  <Box ref={ref} as="div" variant="errorMessage" {...props} __themeKey="forms" />
));
