import styled from '@emotion/styled/macro';
import * as React from 'react';

import { Box } from '../../../components/common/Box';
import { Flex } from '../../../components/common/Flex';
import { Link } from '../../../components/common/Link';
import { Text } from '../../../components/common/Text';

interface CompanyLicencesStatsProps {
  total: number;
  used?: number;
}

export const CompanyLicencesStats: React.FC<CompanyLicencesStatsProps> = React.memo(
  ({ total, used }) => (
    <StatList>
      <Stat sx={{ bg: 'accent' }}>
        <Text my="0">
          <Text as="span" mr="2">
            Total Licences Available:
          </Text>
          <Text as="span" sx={{ fontWeight: 'bold' }}>
            {total}
          </Text>
        </Text>
      </Stat>

      <Stat sx={{ bg: 'accent' }}>
        <Text my="0">
          <Text as="span" mr="2">
            Total Licences Used:
          </Text>
          <Text as="span" sx={{ fontWeight: 'bold' }}>
            {used ? used : 'N/A'}
          </Text>
        </Text>
      </Stat>

      <Stat
        sx={{ bg: used && used === total ? 'orange.6' : used && used > total ? 'red.6' : 'accent' }}
      >
        <Text my="0">
          <Text as="span" mr="2">
            Remaining Licences To Allocate:
          </Text>
          <Text as="span" sx={{ fontWeight: 'bold' }}>
            {used ? total - used : 'N/A'}
          </Text>
        </Text>
      </Stat>

      <UpgradeLicencesContainer>
        <Text mt="0" mb="1" mr="1">
          Need more licences?
        </Text>
        <Box>
          <Link to="/subscriptions/change">Upgrade your plan &rarr;</Link>
        </Box>
      </UpgradeLicencesContainer>
    </StatList>
  )
);

const Stat = styled(Flex)`
  align-items: center;
  padding: ${(props) => props.theme.space[4]};
  color: ${(props) => props.theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 5px -1px, rgba(0, 0, 0, 0.07) 0px 6px 10px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 18px 0px;
`;

const StatList = styled(Box)`
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: ${(props) => props.theme.space[5]};

  @media (max-width: 800px) {
    grid-template-columns: none;
    gap: ${(props) => props.theme.space[3]};
  }
`;

const UpgradeLicencesContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;

  @media (max-width: 650px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;
