import styled from '@emotion/styled/macro';
import * as React from 'react';
import Transition, { TransitionStatus } from 'react-transition-group/Transition';

interface RotateProps {
  active: boolean;
  duration?: number;
  containerStyles?: React.CSSProperties;
}

const transitionStyles = {
  entering: { transform: 'rotate(0deg)' },
  entered: { transform: 'rotate(180deg)' },
  exiting: {},
  exited: { transform: 'rotate(0deg)' },
  unmounted: {},
};

interface ContainerProps {
  duration: number;
}

const Container = styled.div<ContainerProps>`
  line-height: 0;
  transition: ${(props: ContainerProps) => `transform ${props.duration}ms ease-in-out`};
`;

export const Rotate: React.FC<RotateProps> = ({
  active,
  duration = 200,
  containerStyles = {},
  children,
}) => (
  <Transition in={active} timeout={0}>
    {(state: TransitionStatus) => (
      <Container style={{ ...transitionStyles[state], ...containerStyles }} duration={duration}>
        {children}
      </Container>
    )}
  </Transition>
);
