import * as React from 'react';

import loadingSpinnerSrc from '../theme/images/loading-spinner.gif';

interface LoadingSpinnerProps {
  size?: 'page';
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = 'page' }) => {
  const sizes: { [key: string]: { height: number; width: number } } = {
    page: {
      height: 75,
      width: 90,
    },
  };

  const imageSize = sizes[size];

  return (
    <img src={loadingSpinnerSrc} alt="Loading" height={imageSize.height} width={imageSize.width} />
  );
};
