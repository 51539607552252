export class ApiFormError extends Error {
  public errors: { [key: string]: string } = {};
  public rootErrors: string[] = [];

  constructor(m: string, errors: { [key: string]: string }, rootErrors: string[]) {
    super(m);

    this.errors = errors;
    this.rootErrors = rootErrors;
  }
}

export function isApiFormError<T>(response: ApiFormError | T): response is ApiFormError {
  return response instanceof ApiFormError;
}
