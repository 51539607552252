window._wq = window._wq || [];

// Hacky way of implementing a callback as the callsight only allows for a string representing DOM
function openWistiaPopover(element: HTMLElement, id: string) {
  const VIDEO_ELEMENT_CLASS = `wistia_video_${id}`;

  if (element.getElementsByClassName(VIDEO_ELEMENT_CLASS).length === 0) {
    // Video not loaded yet

    const videoContainer = document.createElement('div');

    videoContainer.classList.add('wistia_embed');
    videoContainer.classList.add(`wistia_async_${id}`);
    videoContainer.classList.add('popover=true');
    videoContainer.classList.add(VIDEO_ELEMENT_CLASS);
    videoContainer.style.height = '0';
    videoContainer.style.width = '0';

    element.appendChild(videoContainer);
  }

  window._wq.push({
    id,
    onReady: function (video: any) {
      video.popover.show();
      video.play();
    },
  });
}

(window as any).openWistiaPopover = openWistiaPopover;

export {};
