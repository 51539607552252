import { escapeRegexp, useComboboxOptionContext } from '@reach/combobox';
import { findAll } from 'highlight-words-core';
import { useMemo } from 'react';
import * as React from 'react';

interface IComboboxOptionTextCustomHighlightProps {
  highlightValue?: string;
}

export const ComboboxOptionTextCustomHighlight: React.FC<IComboboxOptionTextCustomHighlightProps> = ({
  highlightValue,
}) => {
  const { value } = useComboboxOptionContext();

  const results = useMemo(
    () =>
      findAll({
        searchWords: escapeRegexp(highlightValue || '').split(/\s+/),
        textToHighlight: value,
      }),
    [value, highlightValue]
  );

  if (!results.length) return <>{value}</>;

  return (
    <>
      {results.map((result, index) => (
        <span
          key={index}
          data-reach-combobox-option-text=""
          data-user-value={result.highlight ? true : undefined}
          data-suggested-value={result.highlight ? undefined : true}
        >
          {value.slice(result.start, result.end)}
        </span>
      ))}
    </>
  );
};
