import qs from 'qs';
import { useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { ApiAdvertTag } from './advert-tags';
import { ApiUser } from './auth';
import { client } from './client';
import { ApiForm } from './form';
import { Paginated } from './types';

export enum DropdownBoolean {
  No = 0,
  Yes = 1,
}

export enum BooleanType {
  No = 0,
  Yes = 1,
}

export interface BooleanTypeOption {
  label: string;
  value: BooleanType;
}

export const booleanTypes: BooleanTypeOption[] = [
  { label: 'Yes', value: BooleanType.Yes },
  { label: 'No', value: BooleanType.No },
];

export const getBooleanTypeByValue = (value: BooleanType) => {
  const matched = booleanTypes.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching boolean type for ${value}`);
  }

  return matched;
};

export enum LocationType {
  OnSite = 1,
  Office = 2,
  Field = 3,
  Home = 4,
  PartOfficeHome = 5,
  Other = 6,
}

export const locationTypes = [
  { label: 'Office', value: LocationType.Office },
  { label: 'Home', value: LocationType.Home },
  { label: 'Part office / Part-home working', value: LocationType.PartOfficeHome },
  { label: 'Field', value: LocationType.Field },
  { label: 'On-site', value: LocationType.OnSite },
  { label: 'Other', value: LocationType.Other },
];

export const getLocationTypeByValue = (value: LocationType) => {
  const matched = locationTypes.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching location type for ${value}`);
  }

  return matched;
};

export enum PositionType {
  Permanent = 1,
  FixedTermContract = 2,
  Temporary = 3,
  Apprentice = 4,
  ZeroHoursContract = 5,
}

export const positionTypes = [
  { label: 'Permanent', value: PositionType.Permanent },
  { label: 'Fixed Term Contract', value: PositionType.FixedTermContract },
  { label: 'Temporary', value: PositionType.Temporary },
  { label: 'Apprentice', value: PositionType.Apprentice },
  { label: 'Zero Hours Contract', value: PositionType.ZeroHoursContract },
];

export const getPositionTypeByValue = (value: PositionType) => {
  const matched = positionTypes.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching position type for ${value}`);
  }

  return matched;
};

export enum JobType {
  FullTime = 1,
  PartTime = 2,
}

export const jobTypes = [
  { label: 'Full time', value: JobType.FullTime },
  { label: 'Part time', value: JobType.PartTime },
];

export const getJobTypeByValue = (value: JobType) => {
  const matched = jobTypes.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching job type for ${value}`);
  }

  return matched;
};

export enum ContractIR35Type {
  Inside = 1,
  Outside = 2,
}

export const contractIR35Types = [
  { label: 'Inside', value: ContractIR35Type.Inside },
  { label: 'Outside', value: ContractIR35Type.Outside },
];

export const getContractIR35TypeByValue = (value: ContractIR35Type) => {
  const matched = contractIR35Types.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching ir35 type for ${value}`);
  }

  return matched;
};

export enum RemunerationCurrency {
  GBP = 1,
  EUR = 2,
  USD = 3,
  SEK = 4,
  DKK = 5,
  NOK = 6,
  CHF = 7,
}

export const remunerationCurrencies = [
  { label: '£', value: RemunerationCurrency.GBP },
  { label: '€', value: RemunerationCurrency.EUR },
  { label: '$', value: RemunerationCurrency.USD },
  { label: 'SEK', value: RemunerationCurrency.SEK },
  { label: 'DKK', value: RemunerationCurrency.DKK },
  { label: 'NOK', value: RemunerationCurrency.NOK },
  { label: 'CHF', value: RemunerationCurrency.CHF },
];

export const getRemunerationCurrencyByValue = (value: RemunerationCurrency) => {
  const matched = remunerationCurrencies.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching currency for ${value}`);
  }

  return matched;
};

export const getRemunerationCurrencyName = (currency: RemunerationCurrency) => {
  switch (currency) {
    case RemunerationCurrency.GBP:
      return 'GBP';
    case RemunerationCurrency.EUR:
      return 'EUR';
    case RemunerationCurrency.USD:
      return 'USD';
    case RemunerationCurrency.SEK:
      return 'SEK';
    case RemunerationCurrency.DKK:
      return 'DKK';
    case RemunerationCurrency.NOK:
      return 'NOK';
    case RemunerationCurrency.CHF:
      return 'CHF';
    default:
      throw new Error(`Currency ${currency} does not have a matching label`);
  }
};

export enum RemunerationFrequency {
  Annum = 1,
  Month = 2,
  Week = 3,
  Day = 4,
  Hour = 5,
}

export const getRemunerationFrequencyLabel = (freq: RemunerationFrequency) => {
  switch (freq) {
    case RemunerationFrequency.Annum:
      return 'annum';
    case RemunerationFrequency.Month:
      return 'month';
    case RemunerationFrequency.Week:
      return 'week';
    case RemunerationFrequency.Day:
      return 'day';
    case RemunerationFrequency.Hour:
      return 'hour';
    default:
      throw new Error(`Frequency ${freq} does not have a matching label`);
  }
};

export const remunerationFrequencyLabels = [
  RemunerationFrequency.Annum,
  RemunerationFrequency.Month,
  RemunerationFrequency.Week,
  RemunerationFrequency.Day,
  RemunerationFrequency.Hour,
].map((freq) => ({
  label: `per ${getRemunerationFrequencyLabel(freq)}`,
  value: freq,
}));

export const getRemunerationFrequencyLabelByValue = (value: RemunerationFrequency) => {
  const matched = remunerationFrequencyLabels.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching frequency for ${value}`);
  }

  return matched;
};

export enum BonusType {
  IndividualPerfomance = 1,
  CompanyPerformance = 2,
  IndividualAndCompanyPerformance = 3,
}

export const bonusTypes = [
  {
    label: 'Individual performance bonus',
    value: BonusType.IndividualPerfomance,
  },
  {
    label: 'Company performance bonus',
    value: BonusType.CompanyPerformance,
  },
  {
    label: 'Both individual and company performance bonus',
    value: BonusType.IndividualAndCompanyPerformance,
  },
];

export const getBonusTypeByValue = (value: BonusType) => {
  const matched = bonusTypes.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching bonus type for ${value}`);
  }

  return matched;
};

export interface Remuneration {
  currency: RemunerationCurrency;
  minimumAmount: string;
  maximumAmount: string;
  frequency: RemunerationFrequency;
}

export enum WeekendRequired {
  Yes = 1,
  No = 2,
  Occasional = 3,
}

export const weekendRequiredChoices = [
  {
    label: 'Yes',
    value: WeekendRequired.Yes,
  },
  {
    label: 'No',
    value: WeekendRequired.No,
  },
  {
    label: 'Occasional',
    value: WeekendRequired.Occasional,
  },
];

export const getWeekendRequiredChoiceByValue = (value: WeekendRequired) => {
  const matched = weekendRequiredChoices.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching weekend required choice for ${value}`);
  }

  return matched;
};

export enum UnsocialableHoursRequired {
  Yes = 1,
  No = 2,
  Occasional = 3,
}

export const unsocialableHoursRequiredChoices = [
  {
    label: 'Yes',
    value: UnsocialableHoursRequired.Yes,
  },
  {
    label: 'No',
    value: UnsocialableHoursRequired.No,
  },
  {
    label: 'Occasional',
    value: UnsocialableHoursRequired.Occasional,
  },
];

export const getUnsocialableHoursRequiredChoiceByValue = (value: UnsocialableHoursRequired) => {
  const matched = unsocialableHoursRequiredChoices.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching usocialable hours required choice for ${value}`);
  }

  return matched;
};

export enum UnsocialableHoursWorkType {
  ShiftWork = 1,
  OnCall = 2,
  Both = 3,
}

export const unsocialableHoursWorkTypeChoices = [
  {
    label: 'Shift Work',
    value: UnsocialableHoursWorkType.ShiftWork,
  },
  {
    label: 'On-Call',
    value: UnsocialableHoursWorkType.OnCall,
  },
  {
    label: 'Both',
    value: UnsocialableHoursWorkType.Both,
  },
];

export const getUnsocialableHoursWorkTypeChoiceByValue = (value: UnsocialableHoursWorkType) => {
  const matched = unsocialableHoursWorkTypeChoices.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching usocialable hours required choice for ${value}`);
  }

  return matched;
};

export enum ContractPeriodType {
  Weeks = 1,
  Months = 2,
  Years = 3,
}

export const contractPeriodTypes = [
  { label: 'Week(s)', value: ContractPeriodType.Weeks },
  { label: 'Month(s)', value: ContractPeriodType.Months },
  { label: 'Year(s)', value: ContractPeriodType.Years },
];

export const getContractPeriodTypeByValue = (value: ContractPeriodType) => {
  const matched = contractPeriodTypes.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching contract period type for ${value}`);
  }

  return matched;
};

interface ApiAdvertCompany {
  id: number;
  rootId: number;
  name: string;
  data: {
    [key: string]: any;
  };
}

export enum ApiAdvertStatus {
  Basics = 'basics',
  Package = 'package',
  Candidate = 'candidate',
  Role = 'role',
  Company = 'company',
  Complete = 'complete',
}

interface ApiAdvert {
  id: number;
  createdAt: string;
  createdBy: ApiUser;
  updatedAt: string;
  updatedBy: ApiUser;
  advertStatus: ApiAdvertStatus;
  jobTitle: string;
  advertCompany: ApiAdvertCompany;
  showCompanyName: boolean;
  reportsTo?: string;
  locationType: LocationType;
  jobLocation: string;
  positionType: PositionType;
  contractLength: number;
  contractPeriod: number;
  contractIR35Type: ContractIR35Type;
  canBecomePermanent: boolean;
  jobType: JobType;
  hoursPerWeek: string;
  weekendRequired: WeekendRequired;
  unsocialableHoursRequired: UnsocialableHoursRequired;
  unsocialableHoursWorkType: UnsocialableHoursWorkType;
  hasSalary: BooleanType;
  salary: Remuneration;
  isProRata: BooleanType;
  hasBonus: DropdownBoolean;
  showBonus: DropdownBoolean;
  bonusType: BonusType;
  bonus: Remuneration;
  hasShiftPremium: DropdownBoolean;
  hasOnCallAdditions: DropdownBoolean;
  salaryAdditions: Remuneration;
  hasOvertimeAvailable: DropdownBoolean;
  overtimePossibleEarnings: Remuneration;
  hasAdditionalBenefits: boolean;
  additionalBenefits: string[];
  hasApplicationClosingDate: boolean;
  applicationClosingDate: string;
  hasInterviewDates: boolean;
  interviewDates: string;
  hasWorkingHours: boolean;
  workingHours: string;
  advertAnswers: AdvertAnswer[];
  formattedSalary: string;
  advertForm: ApiForm;
  tags: ApiAdvertTag[];
  isFavourited: boolean;
  isSharedAndUnseen: boolean;
  sharedAt?: string;
  advertVariations: ApiAdvertVariation[];
}

export interface ApiAdvertVariation {
  id: number;
  advert: ApiAdvert;
  name: string;
}

export interface AdvertAnswer {
  id: number;
  questionId: number;
  value: string;
}

export interface Advert
  extends Omit<
    ApiAdvert,
    'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy' | 'salary' | 'bonus' | 'sharedAt'
  > {
  createdAt: Date;
  createdBy: ApiUser;
  updatedAt: Date;
  updatedBy: ApiUser;
  sharedAt?: Date;
  salary: Remuneration;
  bonus: Remuneration;
  salaryAdditions: Remuneration;
  overtimePossibleEarnings: Remuneration;
  isComplete: boolean;
}

export function isAdvert(advert: Advert | undefined): advert is Advert {
  return (advert as Advert) !== undefined;
}

export interface ApiAdvertCounts {
  user: number;
  incomplete: number;
  team: number;
  shared: number;
  unseenShared: number;
  favourite: number;
  company: number;
}

export interface ApiAdvertAutocompleteResult {
  id: number;
  jobTitle: string;
  companyName: string;
  location: string;
  createdByName: string;
}

const formatApiAdvert = (apiAdvert: ApiAdvert): Advert => {
  const salaryCurrency = remunerationCurrencies.find(
    (currency) => currency.value === apiAdvert.salary.currency
  );

  const salaryCurrencyLabel = salaryCurrency ? salaryCurrency.label : undefined;
  const formattedSalary = salaryCurrencyLabel
    ? `${salaryCurrencyLabel}${apiAdvert.salary.maximumAmount}`
    : apiAdvert.salary.maximumAmount;

  return {
    ...apiAdvert,
    createdAt: new Date(apiAdvert.createdAt),
    updatedAt: new Date(apiAdvert.updatedAt),
    sharedAt: apiAdvert.sharedAt ? new Date(apiAdvert.sharedAt) : undefined,
    formattedSalary,
    isComplete: apiAdvert.advertStatus === ApiAdvertStatus.Complete,
  };
};

export type ApiAdvertSummaryListResult = Paginated<ApiAdvertSummary>;

export interface ApiAdvertSummary {
  id: number;
  advertStatus: ApiAdvertStatus;
  jobTitle: string;
  jobLocation: string;
  positionType: PositionType;
  jobType: JobType;
  salary: Remuneration;
  advertCompany: {
    id: number;
    name: string;
  };
  tags: ApiAdvertTag[];
  createdAt: Date;
  createdBy: ApiUser;
  isFavourited: boolean;
  isSharedAndUnseen: boolean;
  sharedAt?: Date;
  advertVariations: ApiAdvertVariation[];
}

export interface FetchAdvertsOptions {
  page: number;
  perPage: number;
  sortFields: { field: string; direction: 'ASC' | 'DESC' }[];
  filters: { field: string; value: any }[];
}

const fetchAdverts = async (
  url: string,
  options: FetchAdvertsOptions
): Promise<ApiAdvertSummaryListResult> => {
  const params = qs.stringify(
    {
      page: options.page,
      perPage: options.perPage,
      sortFields: options.sortFields,
      filters: options.filters,
    },
    { arrayFormat: 'indices' }
  );

  const response = await client.get(`${url}?${params}`);

  return {
    page: response.data.page,
    limit: response.data.limit,
    total: response.data.total,
    pages: response.data.pages,
    items: response.data.items.map(formatApiAdvert),
  };
};

const fetchMyAdverts = async (options: FetchAdvertsOptions): Promise<ApiAdvertSummaryListResult> =>
  fetchAdverts('/listing/me', options);

const fetchIncompleteAdverts = async (
  options: FetchAdvertsOptions
): Promise<ApiAdvertSummaryListResult> => fetchAdverts('/listing/incomplete', options);

const fetchTeamAdverts = async (
  options: FetchAdvertsOptions
): Promise<ApiAdvertSummaryListResult> => fetchAdverts('/listing/team', options);

const fetchSharedAdverts = async (
  options: FetchAdvertsOptions
): Promise<ApiAdvertSummaryListResult> => fetchAdverts('/listing/shared', options);

const fetchFavouriteAdverts = async (
  options: FetchAdvertsOptions
): Promise<ApiAdvertSummaryListResult> => fetchAdverts('/listing/favourite', options);

const fetchCompanyAdverts = async (
  options: FetchAdvertsOptions
): Promise<ApiAdvertSummaryListResult> => fetchAdverts('/listing/company', options);

const fetchAdvertCounts = async (): Promise<ApiAdvertCounts> => {
  const response = await client.get('/listing/counts');

  return response.data;
};

const usePrefetchAdvertCountsFn = () => {
  const queryClient = useQueryClient();

  const prefetchAdvertCounts = useCallback(async () => {
    await queryClient.prefetchQuery(['advertCounts'], fetchAdvertCounts);
  }, [queryClient]);

  return prefetchAdvertCounts;
};

const search = async (query: string): Promise<{ adverts: ApiAdvertAutocompleteResult[] }> => {
  const response = await client.get(`/listing/search?query=${query}`);

  return {
    adverts: response.data.adverts,
  };
};

const fetchAdvert = async (advertId: number): Promise<{ advert: Advert }> => {
  const response = await client.get(`/listing/${advertId}`);

  return {
    advert: formatApiAdvert(response.data.advert),
  };
};

const usePrefetchAdvertFn = (advertId: number) => {
  const queryClient = useQueryClient();

  const prefetchAdvert = useCallback(async () => {
    await queryClient.prefetchQuery(['advert', advertId], async () => {
      const response = await advertApi.fetchAdvert(advertId);

      return response.advert;
    });
  }, [queryClient, advertId]);

  return prefetchAdvert;
};

export interface FormAdvert {
  jobTitle: string;
  reportsTo?: string;
  jobLocation: string;
  positionType: PositionType;
  contractLength: string;
  jobType: JobType;
  hoursPerWeek: string;
  hasSalary: DropdownBoolean;
  salary: Remuneration;
  hasBonus: DropdownBoolean;
  bonusAmount: string;
  additionalBenefits: string;
  applicationClosingDate: string;
  interviewDates: string;
}

const createAdvert = async (data: FormAdvert): Promise<{ advert: Advert }> => {
  const response = await client.post('/listing', data);

  return {
    advert: formatApiAdvert(response.data.advert),
  };
};

interface UpdateAdvertOptions {
  resetCompanyFormPageAnswers?: boolean;
  updateAdvertCompanyData?: boolean;
}

const updateAdvert = async (
  advertId: number,
  advert: FormAdvert,
  options?: UpdateAdvertOptions
): Promise<{ advert: Advert }> => {
  const response = await client.post(`/listing/${advertId}`, {
    advert,
    ...options,
  });

  return {
    advert: formatApiAdvert(response.data.advert),
  };
};

const updateDynamicAnswers = async (
  advertId: number,
  advert: { advertAnswers: { [key: number]: any }[] },
  options?: UpdateAdvertOptions
): Promise<{ advert: Advert }> => {
  const response = await client.post(`/listing/${advertId}`, {
    advert,
    ...options,
  });

  return {
    advert: formatApiAdvert(response.data.advert),
  };
};

const duplicateAdvert = async (advertId: number): Promise<{ advert: Advert }> => {
  const response = await client.post(`/listing/${advertId}/duplicate`);

  return {
    advert: formatApiAdvert(response.data.advert),
  };
};

export interface DeleteAdvertResponse {
  advert: {
    id: number;
  };
  deleted: boolean;
}

const deleteAdvert = async (advertId: number): Promise<DeleteAdvertResponse> => {
  const response = await client.delete(`/listing/${advertId}`);

  return {
    advert: response.data.advert,
    deleted: response.data.deleted,
  };
};

const shareAdvert = async (advertId: number, userIds: number[]): Promise<{ shared: boolean }> => {
  const response = await client.post(`/listing/${advertId}/share`, { users: userIds });

  return { shared: response.data.shared };
};

export interface FavouriteAdvertVariationResponse {
  advert: {
    id: number;
  };
  favourite: {
    id: number;
  };
}

const favouriteAdvertVariation = async (
  advertId: number,
  advertVariationId: number
): Promise<FavouriteAdvertVariationResponse> => {
  const response = await client.post(
    `/listing/${advertId}/listing-variation/${advertVariationId}/favourite`
  );

  return { advert: response.data.advert, favourite: response.data.favourite };
};

export interface UnfavouriteAdvertResponse {
  advert: {
    id: number;
  };
  unfavourite: boolean;
}

const unfavouriteAdvert = async (advertId: number): Promise<UnfavouriteAdvertResponse> => {
  const response = await client.post(`/listing/${advertId}/unfavourite`);

  return { advert: response.data.advert, unfavourite: response.data.unfavourite };
};

export interface AdvertVariation {
  id: number;
  templateId: number;
  advertId: number;
  name: string;
  content: string;
  isFavourited: boolean;
}

export interface AdvertVariationList {
  variations: AdvertVariation[];
}

const fetchAdvertVariations = async (advertId: number): Promise<AdvertVariationList> => {
  const response = await client.get(`/listing/${advertId}/listing-variation`);

  return {
    variations: response.data.variations,
  };
};

const useAdvertVariationsQuery = (advertId: number) => {
  const queryInfo = useQuery(
    ['advertVariations', advertId],
    () => {
      return fetchAdvertVariations(advertId);
    },
    { cacheTime: 0, staleTime: 0, keepPreviousData: false }
  );

  const { remove } = queryInfo;

  useEffect(() => {
    return () => {
      remove();
    };
  }, [remove]);

  return queryInfo;
};

const usePrefetchAdvertVariationsFn = (advertId: number) => {
  const queryClient = useQueryClient();

  const prefetchAdvertVariations = useCallback(async () => {
    await queryClient.prefetchQuery(['advertVariations', advertId], () => {
      return advertApi.fetchAdvertVariations(advertId);
    });
  }, [queryClient, advertId]);

  return prefetchAdvertVariations;
};

const updateAdvertVariation = async (
  advertId: number,
  variationId: number,
  content: string
): Promise<{ variation: { id: number } }> => {
  const response = await client.post(`/listing/${advertId}/listing-variation/${variationId}`, {
    content,
  });

  return {
    variation: response.data.variation,
  };
};

export const advertApi = {
  fetchMyAdverts,
  fetchIncompleteAdverts,
  fetchTeamAdverts,
  fetchSharedAdverts,
  fetchFavouriteAdverts,
  fetchCompanyAdverts,
  fetchAdvertCounts,
  search,
  fetchAdvert,
  fetchAdvertVariations,
  createAdvert,
  updateAdvert,
  updateDynamicAnswers,
  duplicateAdvert,
  deleteAdvert,
  shareAdvert,
  favouriteAdvertVariation,
  unfavouriteAdvert,
  updateAdvertVariation,

  usePrefetchAdvertFn,
  usePrefetchAdvertCountsFn,
  useAdvertVariationsQuery,
  usePrefetchAdvertVariationsFn,
};
