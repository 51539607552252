import { navigate, Redirect, RouteComponentProps, Router, useLocation } from '@reach/router';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { analytics } from '../../analytics/analytics';
import { SubscriptionPlanTier } from '../../api/subscription-plan-pricing';
import { AppShell } from '../../components/AppShell';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { useCompany } from '../../context/CompanyContext';
import { RuntimeError } from '../../Error/BaseErrors';
import { subscriptionTheme } from '../../theme/subscriptionTheme';
import { ThemeProvider } from '../../theme/ThemeProvider';
import { AllocateUsers } from './AllocateUsers';
import { OrderConfirmationPage } from './OrderConfirmationPage';
import { PricingPage } from './PricingPage';
import { RemoveUsers } from './RemoveUsers';

interface NewSubscriptionInformation {
  planTier: SubscriptionPlanTier;
  seats: number;
  discountToken?: string;
}

export const ChangeSubscription: React.FC<any> = () => {
  const location = useLocation();
  const { company } = useCompany();

  if (!company) {
    throw new RuntimeError(
      'Something went wrong whilst loading the page',
      "User doesn't have belong to a company yet so they shouldn't have access"
    );
  }

  const [newSubscription, setNewSubscription] = React.useState<
    NewSubscriptionInformation | undefined
  >(undefined);

  const existingSubscription = React.useMemo(() => {
    return {
      planId: company.companySubscription.planId,
      planCurrency: company.companySubscription.currency,
      tierId: company.companySubscription.tierId,
      tierName: company.companySubscription.tierName,
      seats: company.companySubscription.seats,
      billingFrequency: company.companySubscription.billingFrequency,
    };
  }, [company.companySubscription]);

  const onNewPlanTierSelected = React.useCallback(
    (planTier: SubscriptionPlanTier, seats: number, discountToken?: string) => {
      setNewSubscription({ planTier, seats, discountToken });
    },
    []
  );

  React.useEffect(() => {
    if (!newSubscription) {
      return;
    }

    if (
      newSubscription.seats >= existingSubscription.seats ||
      company.licencesInUse <= newSubscription.seats
    ) {
      navigate('/subscriptions/change/confirmation');
      return;
    }

    navigate('/subscriptions/change/remove-users');
  }, [company.licencesInUse, existingSubscription.seats, newSubscription]);

  React.useEffect(() => {
    if (location.pathname === '/subscriptions/change') {
      // reset selected sub
      setNewSubscription(undefined);
    }
  }, [location]);

  React.useEffect(() => {
    analytics.subscriptionCheckoutStarted();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Subscription" />
      <ThemeProvider theme={subscriptionTheme as TSThemeFixMe}>
        <AppShell backgroundColor="#f6f6f6">
          <ErrorBoundary>
            <Router>
              <PricingPage
                path="/"
                existingSubscription={existingSubscription}
                onTierSelected={onNewPlanTierSelected}
              />
              {newSubscription ? (
                <React.Fragment>
                  <RemoveUsers
                    path="remove-users"
                    selectedPlanTier={newSubscription.planTier}
                    seats={newSubscription.seats}
                    onContinue={() => {
                      navigate('/subscriptions/change/confirmation');
                    }}
                  />

                  <OrderConfirmationPage
                    path="confirmation"
                    existingSubscription={existingSubscription}
                    selectedPlanTier={newSubscription.planTier}
                    seats={newSubscription.seats}
                    discountToken={newSubscription.discountToken}
                  />
                </React.Fragment>
              ) : null}

              <AllocateUsers
                path="allocate-users"
                pageHeaderTitle="Allocate users to your plan"
                pageHeaderDescription="Create new users to allocate your additional licences to."
              />

              <RedirectToStart default />
            </Router>
          </ErrorBoundary>
        </AppShell>
      </ThemeProvider>
    </React.Fragment>
  );
};

const RedirectToStart = (props: RouteComponentProps) => {
  return <Redirect from="/" to="/subscriptions/change" noThrow />;
};
