import * as Sentry from '@sentry/browser';
import * as React from 'react';

import { Button } from '../common/Button';
import { Flex } from '../common/Flex';
import { ProductionOnly } from '../Headless/ProductionOnly';

interface ErrorBoundaryFallbackProps {
  error: Error;
  componentStack: string;
  sentryEventId?: string;
  userFacingErrorMessage?: string;
}

export const ErrorBoundaryFallback: React.FC<ErrorBoundaryFallbackProps> = (props) => {
  return (
    <Flex sx={{ flexDirection: 'column', alignItems: 'center', px: '4rem', pt: '2rem' }}>
      <h2>Oops! Something went wrong.</h2>
      {props.userFacingErrorMessage && <p>{props.userFacingErrorMessage}</p>}
      <p>This error has been reported to the developers.</p>

      <ProductionOnly>
        <Flex sx={{ justifyContent: 'center', pt: 3 }}>
          {props.sentryEventId && (
            <Button
              variant="primary"
              onClick={() => Sentry.showReportDialog({ eventId: props.sentryEventId })}
            >
              Report feedback
            </Button>
          )}
        </Flex>
      </ProductionOnly>
    </Flex>
  );
};
