import { client } from './client';
import { FormPage } from './form';

interface FetchFormPagesRequest {
  slug: string;
}

const fetchFormPages = async (req: FetchFormPagesRequest): Promise<{ formPages: FormPage[] }> => {
  const response = await client.get(`/form/form-page/${req.slug}`);

  return {
    formPages: response.data.formPages,
  };
};

export const advertFormPageApi = {
  fetchFormPages,
};
