import { useField } from 'formik';
import { useRef } from 'react';
import * as React from 'react';

import { Box } from '../../../components/common/Box';
import { TextField } from '../../../components/common/form';
import { onAutoFillPrefixChange, onAutoFillPrefixFocus } from '../utils/autoFillPrefixUtils';
import { BasicHint } from './BasicHint';
import { QuestionFieldProps } from './QuestionFieldProps';

interface TextQuestionProps {
  name: string;
  question: QuestionFieldProps;
}

export const TextQuestion: React.FC<TextQuestionProps> = React.memo((props) => {
  const [field, _, helpers] = useField(props.name);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const hasAutoFillPrefix = typeof props.question.autoFillPrefix !== 'undefined';

  const onUpdate = (val: string | React.ChangeEvent<any>) => {
    if (isChangeEvent(val)) {
      field.onChange(val);
      return;
    }
    helpers.setValue(val);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasAutoFillPrefix) {
      field.onChange(event);
      return;
    }

    onAutoFillPrefixChange({
      autoFillPrefix: props.question.autoFillPrefix,
      event,
      setField: onUpdate,
      handleEvent: onUpdate,
    });
  };

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!hasAutoFillPrefix) {
      return;
    }

    onAutoFillPrefixFocus({
      autoFillPrefix: props.question.autoFillPrefix,
      event,
      setField: onUpdate,
    });
  };

  return (
    <React.Fragment>
      <TextField
        {...field}
        data-testid={props.question.shortCode || props.name}
        data-lpignore="true"
        // @ts-ignore
        autoComplete="off"
        placeholder={props.question.placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={field.onBlur}
      />

      {props.question.hint && (
        <Box sx={{ mt: 5 }}>
          <BasicHint
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}
          >
            {props.question.hint}
          </BasicHint>
        </Box>
      )}
    </React.Fragment>
  );
});

function isChangeEvent(val: string | React.ChangeEvent<any>): val is React.ChangeEvent<any> {
  return (val as React.ChangeEvent<any>).target !== undefined;
}
