import * as React from 'react';

import { analytics } from '../../analytics/analytics';
import { companyApi } from '../../api/company';
import { ApiInvite } from '../../api/invitation';
import logger from '../../utils/logger';
import { Box } from '../common/Box';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTextButton,
  ModalTitle,
} from '../common/Modal';

interface RevokeInviteModalProps {
  isOpen: boolean;
  invite?: ApiInvite;
  onRevoked: (invite: ApiInvite) => void;
  onClose: () => void;
}

export const RevokeInviteModal: React.FC<RevokeInviteModalProps> = ({
  isOpen,
  invite,
  onRevoked,
  onClose,
}) => {
  const [revokingInvite, setRevokingInvite] = React.useState(false);
  const [revokingError, setRevokingError] = React.useState<string | undefined>(undefined);

  const revokeInvite = async (invite: ApiInvite) => {
    setRevokingInvite(true);
    setRevokingError(undefined);

    try {
      const response = await companyApi.revokeCompanyInvite(invite.company.id, invite.id);

      analytics.companyInviteRevoked();

      onRevoked(response.invite);
      setRevokingInvite(false);
    } catch (e) {
      logger.logError(e);
      setRevokingInvite(false);
      setRevokingError(e.message);
    }
  };

  return (
    <Modal aria-label="Revoke invite confirmation" variant="destructive" isOpen={isOpen} onDismiss={() => onClose()}>
      <ModalClose onClick={() => onClose()} />
      <ModalContainer>
        <ModalTitle style={{ paddingBottom: 0 }}>
          Are you sure you want to revoke the invitation for
        </ModalTitle>

        <Box py="3" pb="6">
          <Box sx={{ p: 3, bg: 'grey.3', color: 'blackfade85', fontSize: 4 }}>
            {invite && invite.email}
          </Box>
        </Box>

        {revokingError && <ModalContent>{revokingError}</ModalContent>}

        <ModalActions>
          <ModalButton
            type="button"
            disabled={revokingInvite}
            onClick={() => {
              if (invite) {
                revokeInvite(invite);
              }
            }}
          >
            I'm sure, revoke the invite
          </ModalButton>

          <ModalTextButton type="button" onClick={() => onClose()}>
            Cancel
          </ModalTextButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};
