import debounce from 'lodash.debounce';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';

interface State {
  width: number;
  height: number;
}

const WindowSizeStateContext = React.createContext<State | undefined>(undefined);

export const WindowSizeContextProvider: React.FC = (props) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const listener = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 250);

    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  return (
    <WindowSizeStateContext.Provider value={windowSize}>
      {props.children}
    </WindowSizeStateContext.Provider>
  );
};

export const useWindowSize = () => {
  const state = useContext(WindowSizeStateContext);

  if (typeof state === undefined) {
    throw new Error('useWindowSize must be used within a WindowSizeContextProvider');
  }

  return state as State;
};

interface WindowSizeProps {
  children: (windowSize: State) => JSX.Element | null;
}

export const WindowSize: React.FC<WindowSizeProps> = (props) => {
  const windowSize = useWindowSize();
  return props.children(windowSize);
};
